import React, { useCallback, useEffect } from "react";
import { useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import _ from "lodash";
import moment from "moment";
import "moment/locale/es";
import { IoMdArrowRoundBack } from "react-icons/io";

import Spinner from "../../components/Spinner";
import useAuthFetch from "../../hooks/useAuthFetch";
import { METODOS_ENTREGA, USUARIOS_TICOLITAS } from "../../utils/constants";

moment.updateLocale("es");

const useQuery = () => {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
};

export function PedidoPage({ user }) {
  const { idPedido } = useParams();

  const [order, setOrder] = useState(null);
  const [loading, setLoading] = useState(false);
  const [referenceUrl, setReferenceUrl] = useState(null);
  const authFetch = useAuthFetch();
  const query = useQuery();

  useEffect(() => {
    const fromUrl = query.get("desde");
    if (fromUrl) {
      setReferenceUrl(fromUrl);
    }
  }, [query]);

  const fetchOrder = useCallback(async () => {
    setLoading(true);
    const orderFetch = await authFetch(
      `ventas/${idPedido}?filter={"include":[{"relation":"metodoPagoUtilizado"},{"relation":"metodoEntregaUtilizado"},{"relation":"estado"},{"relation":"usuario"},{"relation":"lineas","scope":{"include":[{"relation":"producto"}]}}]}`,
      null,
      "GET",
      user.token
    );

    const order = await orderFetch.json();
    setOrder(order);
    setLoading(false);
  }, [idPedido]);

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchOrder();
  }, [fetchOrder]);

  const getGoBackLink = (reference) => {
    if (reference) {
      if (reference === "checkout") {
        return "/";
      }
      return referenceUrl;
    } else {
      return "/pedidos";
    }
  };

  let diasParaEntrega = 0;
  if (order && order.fechaEntregaEstimada) {
    diasParaEntrega = Math.ceil(moment.duration(moment(order.fechaEntregaEstimada).diff(moment(order.createdAt))).asHours() / 24);
  }

  return !order ? (
    <div className="w-full p-content pt-4 pb-16 min-h-[400px] bg-white">
      <Spinner />
    </div>
  ) : (
    <div className="w-full p-content pb-16">
      {loading && <Spinner />}
      <div>
        <div className="flex w-full items-center">
          <div className="w-full">
            <Link to={getGoBackLink(referenceUrl)}>
              <button className="bg-pink text-white py-2 px-8 font-bold rounded-full float-right flex justify-center items-center"
              >
                <IoMdArrowRoundBack className="text-lg" /> Volver
              </button>
            </Link>
          </div>
        </div>
        <p className="text-2xl font-bold mb-4">
          {referenceUrl === "checkout"
            ? `Orden #${order.id} ingresada exitosamente`
            : `Orden #${order.id}`}
        </p>
        <div className="w-full ring-1 ring-purple mb-4 p-6 rounded-lg">
          <p className="text-lg font-bold mb-4">Información General</p>
          <p className="mb-2">
            Fecha:{" "}
            <span className="font-medium">
              {moment(order.fechaIngreso).format(
                "dddd D [de] MMMM [a la(s)] hh:mm a"
              )}
            </span>
          </p>
          <p className="mb-2">
            Estado: <span className="font-medium">{order.estado.valor}</span>
          </p>
          <p className="mb-2">
            Metodo de Pago:{" "}
            <span className="font-medium">
              {order.metodoPagoUtilizado.nombre}
            </span>
          </p>
          <p className="mb-2">
            Subtotal:{" "}
            <span className="font-medium">
              {Intl.NumberFormat("es-CR", {
                style: "currency",
                currency: "CRC",
              }).format(order.totalConImpuestos / 1.13)}
            </span>
          </p>
          <p className="mb-2">
            Transporte:{" "}
            <span className="font-medium">
              {order.totalDeTransporte
                ? Intl.NumberFormat("es-CR", {
                  style: "currency",
                  currency: "CRC",
                }).format(order.totalDeTransporte / 1.13)
                : "Gratis"}
            </span>
          </p>
          {order.descuentoGlobal > 0 && (
            <p className="mb-2">
              Descuento:{" "}
              <span className="font-medium">
                {Intl.NumberFormat("es-CR", {
                  style: "currency",
                  currency: "CRC",
                }).format(order.descuentoGlobal)}
              </span>
            </p>
          )}
          <p className="mb-2">
            I.V.A:{" "}
            <span className="font-medium">
              {Intl.NumberFormat("es-CR", {
                style: "currency",
                currency: "CRC",
              }).format((order.totalConTransporte * 13) / 113)}
            </span>
          </p>
          <p className="mb-2">
            Total:{" "}
            <span className="font-medium">
              {Intl.NumberFormat("es-CR", {
                style: "currency",
                currency: "CRC",
              }).format(order.totalConTransporte)}
            </span>
          </p>
        </div>
        <div className="w-full ring-1 ring-purple mb-4 p-6 rounded-lg">
          <p className="text-lg font-bold mb-4">Información para entrega</p>
          <p className="mb-2">
            Metodo de Entrega:{" "}
            <span className="font-medium">
              {order.metodoEntregaUtilizado.nombre}
            </span>
          </p>
          {order.usuario.nombre && (
            <p className="mb-2">
              Cliente:{" "}
              <span className="font-medium">
                {USUARIOS_TICOLITAS.includes(order.usuario.id)
                  ? order.nombreCompletoEntrega
                  : `${order.usuario.nombre}${order.usuario.apellido1 ? ` ${order.usuario.apellido1}` : ""
                  }`}
              </span>
            </p>
          )}
          {order.nombreCompletoEntrega && (
            <p className="mb-2">
              A quien entregar:{" "}
              <span className="font-medium">{order.nombreCompletoEntrega}</span>
            </p>
          )}
          {order.fechaEntregaEstimada && (
            <p className="mb-2">
              Fecha de entrega estimada:{" "}
              <span className="font-medium">
                {
                  diasParaEntrega > 2 ? `${diasParaEntrega} días` : `${diasParaEntrega * 24} horas o menos`
                }
              </span>
            </p>
          )}
          {order.direccionExacta && (
            <p className="mb-2">
              Dirección:{" "}
              <span className="font-medium">
                {order.direccionExacta}{" "}
                {order.urlWaze && (
                  <span>
                    (Ir con{" "}
                    <a
                      className="text-cyan cursor-pointer"
                      href={order.urlWaze}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Waze
                    </a>
                    )
                  </span>
                )}
              </span>
            </p>
          )}
          {order.notasEntrega && (
            <p className="mb-2">
              Notas: <span className="font-medium">{order.notasEntrega}</span>
            </p>
          )}
          {user.id !== order.idUsuario ? (
            order.telefono ? (
              <p className="mb-2">
                Telefono:{" "}
                <a
                  className="text-cyan cursor-pointer"
                  href={`https://api.whatsapp.com/send?phone=${order.codigoPaisTelefono}${order.telefono}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {USUARIOS_TICOLITAS.includes(order.usuario.id)
                    ? order.telefono
                    : order.usuario.telefono}
                </a>
              </p>
            ) : (
              <p className="mb-2">
                Telefono:{" "}
                <a
                  className="text-cyan cursor-pointer"
                  href={`https://api.whatsapp.com/send?phone=${order.usuario.codigoPaisTelefono}${order.usuario.telefono}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {order.usuario.telefono}
                </a>
              </p>
            )
          ) : (
            order.telefono && (
              <p className="mb-2">
                Telefono: <span className="font-medium">{order.telefono}</span>
              </p>
            )
          )}
        </div>
        <div className="w-full ring-1 ring-purple mb-4 p-6 rounded-lg">
          <p className="text-lg font-bold mb-4">Información para facturación</p>
          {order.nombreFactura ? (
            <p className="mb-2">
              Nombre: <span className="font-medium">{order.nombreFactura}</span>
            </p>
          ) : (
            <p className="mb-2">El cliente no solicitó factura electrónica</p>
          )}
          {order.identificacionFactura && (
            <p className="mb-2">
              Identificación:{" "}
              <span className="font-medium">{order.identificacionFactura}</span>
            </p>
          )}
          {order.correoFactura && (
            <p className="mb-2">
              Correo: <span className="font-medium">{order.correoFactura}</span>
            </p>
          )}
        </div>
        <div className="w-full ring-1 ring-purple mb-4 p-6 rounded-lg">
          <div>
            <p className="text-lg font-bold mb-4">
              Productos (Total: {_.sum(_.map(order.lineas, "cantidad"))})
            </p>
            {order.lineas.map((l) => {
              return (
                <div
                  key={`venta_${order.id}_${l.linea}`}
                  className="flex items-center my-4 border-b border-borderGray pb-4"
                >
                  <div className="w-4/12 md:w-2/12">
                    <img
                      src={l.producto.fotoPrincipal}
                      alt={`Foto ${l.producto.nombreTienda}`}
                    />
                  </div>
                  <div className="flex flex-wrap items-center w-8/12 md:w-10/12 pl-4">
                    <p className="w-full font-medium">
                      {l.producto.marca} {l.producto.nombreTienda}
                    </p>
                    {
                      l.producto.pesoGramos && (
                        <p className="w-full">
                          Presentación:{" "}
                          <span className="font-medium">
                            {l.producto.pesoGramos < 1000
                              ? `${l.producto.pesoGramos} gramos`
                              : `${(l.producto.pesoGramos / 1000).toFixed(2)} Kg`}
                          </span>
                        </p>
                      )
                    }
                    {user.id !== order.idUsuario && (
                      <>
                        <p className="w-full">
                          Código TiColitas:{" "}
                          <span className="font-medium">{l.producto.id}</span>
                        </p>
                        <p className="w-full">
                          Código Proveedor:{" "}
                          <span className="font-medium">
                            {l.producto.codigoProveedor}
                          </span>
                        </p>
                      </>
                    )}
                    <p className="w-full">
                      Cantidad:{" "}
                      <span className="font-medium">{l.cantidad}</span>
                    </p>
                    {l.mascotas && (
                      <p className="w-full">
                        Mascota(s):{" "}
                        <span className="font-medium">{l.mascotas}</span>
                      </p>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        {user.id !== order.idUsuario &&
          order.idMetodoEntrega === METODOS_ENTREGA.A_DOMICILIO && (
            <>
              <div className="w-full ring-1 ring-purple mb-4 p-6 rounded-lg">
                <p className="text-lg font-bold mb-4">Script de entrega</p>
                <p className="mb-4">
                  Buenos{" "}
                  {new Date().getHours() < 12
                    ? "días"
                    : new Date().getHours() < 18
                      ? "tardes"
                      : "noches"}
                  , Doña / Don{" "}
                  <span className="text-medium">
                    {order.nombreCompletoEntrega || order.usuario.nombre}
                  </span>
                  ,
                </p>
                <p>
                  Gracias por confiar la alimentación de{" "}
                  <span className="font-bold">
                    {_.compact(_.map(order.lineas, "mascotas")).length > 0
                      ? _.compact(_.map(order.lineas, "mascotas")).join(",")
                      : "de su mascota"}
                  </span>{" "}
                  en Ticolitas.{" "}
                  {_.compact(_.map(order.lineas, "mascotas")).length === 0
                    ? "si no le molesta me gustaría saber el nombre de su mascota"
                    : ""}
                  .
                </p>
                <br />
                <p>
                  1. Antes de entregarle su pedido me gustaría comentarle que
                  Ticolitas se toma muy en serio las acciones para proteger el
                  medio ambiente y antes de proceder con la entrega quiero
                  mencionarle que estimulamos el no imprimir vouchers o
                  facturas. Tributamos en el 100% de nuestras ventas y podemos
                  hacerle llegar todos los documentos de forma electrónica.
                </p>
                <br />
                <p>
                  ¿Podríamos por favor revisar el pedido para corroborar que sea
                  lo que usted ordenó?
                </p>
                <br />
                <p className="font-bold">Revisar el pedido.</p>
                {order.lineas.find((l) => l.producto.pesoGramos > 8000) && (
                  <>
                    <br />
                    <p>
                      En sacos grandes:{" "}
                      <span className="font-bold">
                        ¿Le gustaría que lo desinfecte y se lo coloque en el
                        contenedor?.
                      </span>
                    </p>
                  </>
                )}
                <br />
                <p>
                  El monto es de{" "}
                  <span className="font-bold">
                    {Intl.NumberFormat("es-CR", {
                      style: "currency",
                      currency: "CRC",
                    }).format(order.totalConTransporte)}
                  </span>
                </p>
                <br />
                {order.totalConTransporte > 30000 && (
                  <>
                    <p>
                      En Ticolitas nos preocupamos mucho por su seguridad, por
                      eso en montos mayores de{" "}
                      {Intl.NumberFormat("es-CR", {
                        style: "currency",
                        currency: "CRC",
                      }).format(30000)}{" "}
                      requerimos su firma y presentar su cédula.
                    </p>
                    <br />
                  </>
                )}
                <p>
                  Finalmente agradecerle porque al elegirnos usted contribuyó
                  con cero emisiones al ambiente en este trayecto, ya que
                  utilizamos vehículos eléctricos. Un vehículo muy pequeño
                  promedio en un trayecto de 6 Km, utiliza medio litro de
                  combustible.
                </p>
                <br />
                <p>
                  Uno de nuestros objetivos es hacer su vida más fácil, por lo
                  que queremos finalmente obsequiarle este código QR, que lo
                  llevará muy sencillamente hasta la parte final de su próximo
                  pedido y así ahorrarle tiempo y que la experiencia para usted
                  y{" "}
                  {_.compact(_.map(order.lineas, "mascotas")).length > 0
                    ? _.compact(_.map(order.lineas, "mascotas")).join(",")
                    : "(mencionar nombre)"}
                  , sea muy agradable.
                </p>
                <br />
                <p>
                  Está familiarizada / o con el QR, sino con gusto le puedo
                  ayudar.
                </p>
                <br />
                <p>
                  Que tenga{" "}
                  {new Date().getHours() < 12
                    ? "lindo día"
                    : new Date().getHours() < 18
                      ? "linda tarde"
                      : "linda noche"}{" "}
                  Doña / Don{" "}
                  <span className="font-bold">
                    {order.nombreCompletoEntrega || order.usuario.nombre}
                  </span>
                  . Saludos.
                </p>
              </div>
            </>
          )}
      </div>
    </div>
  );
}

export default PedidoPage;
