import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AccountSidebar from "../../components/AccountSidebar";
import Spinner from "../../components/Spinner";
import { logUser } from "../../slices/appSlice";
import useAuthFetch from "../../hooks/useAuthFetch";

const options = [
  {
    label: "Mi Cuenta",
    route: "/cuenta",
  },
  {
    label: "Inventario",
    route: "/inventario",
    roles: ["administradorInventario", "mensajero"],
  },
  {
    label: "Pedidos",
    route: "/pedidos",
    roles: [
      "cliente",
      "mensajero",
      "bodega",
      "admin",
      "administradorTienda",
      "dependiente",
    ],
  },
  {
    label: "Bodega",
    route: "/tienda/bodegas",
    roles: ["bodega", "admin", "administradorTienda"],
  },
  {
    label: "Recoger en tienda",
    route: "/pick-ups",
    roles: ["bodega", "dependiente", "admin", "administradorTienda"],
  },
  {
    label: "Envíos",
    route: "/envios",
    roles: ["mensajero", "admin", "administradorTienda"],
  },
  {
    label: "Rutas",
    route: "/rutas",
    roles: ["mensajero"],
  },
  {
    label: "Toda la tienda",
    route: "/tienda/todos",
    roles: ["dependiente", "admin", "administradorTienda"],
  },
  {
    label: "Clientes",
    route: "/clientes",
    roles: ["admin", "administradorTienda", "dependiente"],
  },
];

export function CuentaDatosPersonalesPage() {
  const user = useSelector((state) => state.app.user);
  const dispatch = useDispatch();
  const authFetch = useAuthFetch();

  const [newPassword, setNewPassword] = useState("");
  const [newPasswordConfirmation, setNewPasswordConfirmation] = useState("");
  const [newUserData, setNewUserData] = useState(null);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [touched, setTouched] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (user) {
      setNewUserData(user);
    }
  }, [user]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const postChanges = async () => {
    setError(null);
    setSuccess(null);
    setLoading(true);
    if (newPassword && newPasswordConfirmation) {
      if (newPassword === newPasswordConfirmation) {
        const changePasswordFetch = await authFetch(
          "/usuarios/update-password",
          {
            email: user.email,
            password: newPassword,
          },
          "PUT",
          user.token,
          dispatch
        );

        if (changePasswordFetch.status === 200) {
          setLoading(false);
          setSuccess("Se ha actualizado la contraseña correctamente.");
        } else {
          setError(
            "Ocurrió un error al actualizar la contraseña. Por favor verifique los datos e intente de nuevo."
          );
          setLoading(false);
          return;
        }
      } else {
        setError("Las nuevas contraseñas no coinciden");
        return;
      }
    } else {
      if (
        (newPassword && !newPasswordConfirmation) ||
        (!newPassword && newPasswordConfirmation)
      ) {
        setError(
          "Debe ingresar ambas contraseñas para poder actualizar la misma"
        );
        return;
      }
    }
    if (touched) {
      const changeUserDataFetch = await authFetch(
        `/usuarios/${user.id}`,
        {
          nombre: newUserData.nombre,
          apellido1: newUserData.apellido1,
          telefono: newUserData.telefono,
        },
        "PUT",
        user.token,
        dispatch
      );

      if (changeUserDataFetch.status === 204) {
        dispatch(logUser(newUserData));
        setLoading(false);
        setSuccess("Se han actualizado los datos del usuario correctamente.");
      } else {
        setError(
          "Ocurrió un error al actualizar los datos del usuario. Por favor verifique los datos e intente de nuevo."
        );
        setLoading(false);
        return;
      }
    }
  };

  return newUserData ? (
    <div className="p-content pb-8">
      {loading && <Spinner float />}
      <div className="flex flex-wrap sm:flex-nowrap w-full">
        <div className="w-full sm:w-3/12">
          <AccountSidebar options={options} user={user} activeIndex={0} />
          <hr className="visible sm:hidden my-4" />
        </div>
        <div className="w-full sm:w-9/12 mt-4">
          <p className="text-2xl font-bold mb-4">Datos Personales</p>
          <div>
            <label htmlFor="name" className="text-base">
              Nombre:
              <span className="text-pink">*</span>
            </label>
            <input
              id="name"
              name="name"
              className="w-full rounded-3xl my-2 h-12 p-6 border border-purple"
              value={newUserData.nombre}
              onChange={(evt) => {
                if (user.nombre !== evt.target.value) {
                  setTouched(true);
                } else {
                  setTouched(false);
                }
                setNewUserData({
                  ...newUserData,
                  nombre: evt.target.value,
                });
              }}
            />
          </div>
          <div>
            <label htmlFor="lastName" className="text-base">
              Apellido:
              <span className="text-pink">*</span>
            </label>
            <input
              id="lastName"
              name="lastName"
              className="w-full rounded-3xl my-2 h-12 p-6 border border-purple"
              value={newUserData.apellido1}
              onChange={(evt) => {
                if (user.nombre !== evt.target.value) {
                  setTouched(true);
                } else {
                  setTouched(false);
                }
                setNewUserData({
                  ...newUserData,
                  apellido1: evt.target.value,
                });
              }}
            />
          </div>
          <div>
            <label htmlFor="phone" className="text-base">
              Teléfono:
              <span className="text-pink">*</span>
            </label>

            <input
              id="telefono"
              name="telefono"
              className="w-full rounded-3xl my-2 h-12 p-6 border border-purple"
              value={newUserData.telefono}
              placeholder={"Por favor ingresa únicamente números"}
              onChange={(evt) => {
                const justNumbers = evt.target.value.replace(/[^0-9]/g, "");
                if (user.telefono !== justNumbers) {
                  setTouched(true);
                } else {
                  setTouched(false);
                }
                setNewUserData({
                  ...newUserData,
                  telefono: justNumbers,
                });
              }}
            />
          </div>
          <div>
            <label htmlFor="email" className="text-base">
              Correo Electrónico:
              <span className="text-pink">*</span>
            </label>
            <input
              disabled
              id="email"
              name="email"
              className="w-full rounded-3xl my-2 h-12 p-6 border border-purple"
              value={newUserData.email}
            />
          </div>
          <div>
            <hr className="my-2" />
            <p className="my-2">
              Si querés actualizar tu contraseña podés realizarlo a
              continuación. Si solamente querés actualizar tus datos no es
              necesario completar los siguientes campos:
            </p>
            <label htmlFor="password" className="text-base">
              Nueva contraseña:
              <span className="text-pink">*</span>
            </label>
            <input
              id="password"
              name="password"
              type="password"
              onChange={(evt) => {
                setNewPassword(evt.target.value);
              }}
              className="w-full rounded-3xl my-2 h-12 p-6 border border-purple"
              value={newPassword}
            />
          </div>
          <div>
            <label htmlFor="passwordConfirmation" className="text-base">
              Confirmar nueva contraseña:
              <span className="text-pink">*</span>
            </label>
            <input
              id="passwordConfirmation"
              name="passwordConfirmation"
              type="password"
              onChange={(evt) => {
                setNewPasswordConfirmation(evt.target.value);
              }}
              className="w-full rounded-3xl my-2 h-12 p-6 border border-purple"
              value={newPasswordConfirmation}
            />
          </div>
          <div className="w-full my-2">
            {error && <p className="text-pink text-center">{error}</p>}
            {success && <p className="text-cyan text-center">{success}</p>}
            <button
              disabled={!touched && !newPassword && !newPasswordConfirmation}
              onClick={() => {
                postChanges();
              }}
              className="bg-purple text-white h-12 px-8 rounded-full w-full disabled:opacity-50"
            >
              Actualizar Datos
            </button>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div className="w-full p-content pt-4 pb-16 min-h-[400px] bg-white">
      <Spinner />
    </div>
  );
}

export default CuentaDatosPersonalesPage;
