import React, { useState } from "react";
import { BiEdit, BiSave } from "react-icons/bi";

function PendingOrdersTable({
  ventas,
  listaRuta,
  setListaRuta,
  deleteFromVentasSinAsignarTable,
  setCoordsToVentaSinAsignar,
}) {
  const [editingCoords, setEditingCoords] = useState(0);
  const [coords, setCoords] = useState("");
  const [errorEditingCoords, setErrorEditingCoords] = useState();

  function convertirCoordenadas(coordenadas) {
    const [latitud, longitud] = coordenadas.split(",").map(parseFloat);

    return { latitud, longitud };
  }

  function esCoordenada(coordenadas) {
    const coordenadasRegex =
      /^-?([0-9]|[1-8][0-9]|90)\.\d{1,15}, -?([0-9]|[1-9][0-9]|1[0-7][0-9]|180)\.\d{1,15}$/;

    return coordenadasRegex.test(coordenadas);
  }

  const saveCoord = (idVenta) => {
    if (esCoordenada(coords)) {
      const coordenadas = convertirCoordenadas(coords);
      setCoordsToVentaSinAsignar(
        idVenta,
        Number(coordenadas.latitud),
        Number(coordenadas.longitud)
      );
      setEditingCoords();
    } else {
      setErrorEditingCoords("Formato de coordenadas incorrecto");
    }
  };

  return (
    <div className="overflow-x-auto flex gap-5 flex-col items-start">
      <table className="table-auto w-full ">
        <thead>
          <tr className="bg-gray-200 text-gray-600 text-sm uppercase">
            <th className="py-2 px-4">No. Orden</th>
            <th className="py-2 px-4">Nombre Completo Entrega</th>
            <th className="py-2 px-4">Dirección Exacta</th>
            <th className="py-2 px-4">Coordenadas</th>
            <th className="py-2 px-4">Acciones</th>
          </tr>
        </thead>
        <tbody className="text-gray-600 text-sm font-light">
          {ventas.map((venta) => (
            <tr
              className="border-b border-gray-200 hover:bg-gray-100"
              key={venta.id}
            >
              <td className="py-2 px-4" align="center">
                {venta.id}
              </td>
              <td className="py-2 px-4" align="center">
                {venta.nombreCompletoEntrega}
              </td>
              <td className="py-2 px-4" align="center">
                {venta.direccionExacta}
              </td>
              <td className="py-2 px-4" align="center">
                {editingCoords === venta.id ? (
                  <>
                    <div className="flex  gap-2 justify-between text-sm items-start">
                      <div className="flex flex-col gap-1">
                        <input
                          className="p-1 border-purple border rounded-sm"
                          placeholder="9.96119198817808, -84.25560161794161"
                          value={coords}
                          onChange={(e) => setCoords(e.target.value)}
                        ></input>
                        {errorEditingCoords && (
                          <span className="text-pink textsm">
                            {errorEditingCoords}
                          </span>
                        )}
                      </div>

                      <button
                        className="bg-orange text-white p-2 rounded-md"
                        onClick={() => saveCoord(venta.id)}
                      >
                        <BiSave />
                      </button>
                    </div>
                  </>
                ) : venta.longitud === null || venta.latitud === null ? (
                  <button
                    className="bg-purple hover:bg-green-600 text-white font-bold py-2 px-4 rounded w-full"
                    onClick={() => setEditingCoords(venta.id)}
                  >
                    Agregar coordenadas
                  </button>
                ) : (
                  <>
                    <div className="flex gap-2 justify-between">
                      {venta.latitud}, {venta.longitud}
                      <button
                        className="bg-purple text-white p-2 rounded-md"
                        onClick={() => {
                          setEditingCoords(venta.id);
                          setCoords(`${venta?.latitud}, ${venta?.longitud}`);
                        }}
                      >
                        <BiEdit />
                      </button>
                    </div>
                  </>
                )}
              </td>
              <td className="py-2 px-4 flex flex-col gap-2 text-xs">
                {sobrepasaLimiteHora(new Date(venta.fechaIngreso)) && (
                  <p className="font-bold text-pink text-center">
                    {" "}
                    NO RECOMENDABLE PARA HOY
                  </p>
                )}
                <button
                  className="bg-purple hover:bg-green-600 text-white font-bold py-2 px-4 rounded w-full"
                  disabled={
                    venta.longitud === null ||
                    venta.latitud === null ||
                    editingCoords === venta.id
                  }
                  onClick={() => {
                    setListaRuta((prev) => [...prev, venta]);
                    deleteFromVentasSinAsignarTable(venta.id);
                  }}
                >
                  Agregar a ruta
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

function sobrepasaLimiteHora(fecha) {
  const limiteHora = new Date();
  limiteHora.setHours(21, 0, 0, 0); // Establecer límite a las 21:00:00

  // Obtener la fecha actual sin incluir la hora
  const fechaActual = new Date();
  fechaActual.setHours(0, 0, 0, 0);

  // Comparar si la fecha supera el límite establecido
  return fecha > fechaActual && fecha > limiteHora;
}

export default PendingOrdersTable;
