import React, { useCallback, useEffect, useState } from "react";
import { IoMdArrowRoundBack } from "react-icons/io";
import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import Pagination from "../../components/Pagination";
import Spinner from "../../components/Spinner";
import useAuthFetch from "../../hooks/useAuthFetch";

export const CatalogoValores = () => {
  const { idCatalogo } = useParams();
  const [catalogos, setcatalogos] = useState(null);
  const [page, setPage] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [loading, setLoading] = useState(false);
  const { token } = useSelector((store) => store.app.user);
  const authFetch = useAuthFetch();
  
  const fetchcatalogos = useCallback(async () => {
    const pageSize = 5;
    setLoading(true);

    const catalogoFetch = await authFetch(
      `catalogos?filter={
			"limit": ${pageSize},
			"skip": ${page * pageSize},
			"where":{"and":[{"idCatalogo": ${idCatalogo}},{"activo": 1}]},
			"order": ["createdAt DESC"]}`
    );
    const catalogosData = await catalogoFetch.json();
    setcatalogos(catalogosData);
    const totalCatalogoFetch = await authFetch(
      `catalogos/count?where={"and":[{"idCatalogo": ${idCatalogo}},{"activo": 1}]}`
    );
    const totalCatalogoData = await totalCatalogoFetch.json();
    setTotalPages(Math.ceil(totalCatalogoData.count / pageSize));
    setTotalCount(totalCatalogoData.count);
    setLoading(false);
  }, [page, idCatalogo]);

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchcatalogos();
  }, [fetchcatalogos]);

  const fetchDeleteCatalogo = async (id) => {
    const catalogoFetch = await authFetch(
      `catalogos/${id}`,
      {
        activo: 0,
      },
      "DELETE",
      token
    );
    if (
      catalogoFetch.status === 200 ||
      catalogoFetch.status === 201 ||
      catalogoFetch.status === 204
    ) {
      Swal.fire({
        icon: "success",
        title: "¡Correcto!",
        text: "Catálogo inactivado correctamente",
        background: "#76236C",
        color: "#fafafa",
        confirmButtonColor: "#19D3C5",
      });
      fetchcatalogos();
    }
    if (
      catalogoFetch.status === 400 ||
      catalogoFetch.status === 401 ||
      catalogoFetch.status === 404 ||
      catalogoFetch.status === 500
    ) {
      Swal.fire({
        icon: "error",
        title: "¡Error!",
        text: "Error al inactivar un catálogo",
        background: "#76236C",
        color: "#fafafa",
        confirmButtonColor: "#19D3C5",
      });
    }
  };

  const deleteCatalogo = async (id) => {
    Swal.fire({
      title: "¿Estas seguro que deseas inactivar este catálogo?",
      text: "Una vez realizada la acción no podrás revertirla",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Si, inactivar",
      cancelButtonText: "Cancelar",
      confirmButtonColor: "#19D3C5",
      cancelButtonColor: "#E7004C",
      background: "#76236C",
      color: "#fafafa",
    }).then((result) => {
      if (result.isConfirmed) {
        fetchDeleteCatalogo(id);
        fetchcatalogos();
        setPage(0);
      }
    });
  };

  return (
    <div className="w-full p-content pb-16">
      {loading && <Spinner />}
      {catalogos && catalogos.length > 0 ? (
        <div className="w-full">
          <div className="w-full">
            <div>
              <div className="flex w-full items-center">
                <div className="w-full flex justify-between">
                  <div>
                    <p className="text-2xl font-bold mb-4">
                      Total: {totalCount}
                    </p>
                    <Link to={`/catalogos/valores/${idCatalogo}/form/new`}>
                      <button className="bg-cyan text-white py-2 px-8 font-bold rounded-full float-left flex justify-center items-center">
                        + Nuevo valor
                      </button>
                    </Link>
                  </div>
                  <Link to="/catalogos">
                    <button className="bg-pink text-white py-2 px-8 font-bold rounded-full float-right flex justify-center items-center">
                      <IoMdArrowRoundBack className="text-lg" /> Volver
                    </button>
                  </Link>
                </div>
              </div>
              <br />
            </div>
          </div>
          <div className="w-full flex flex-wrap mb-8">
            <div className="flex flex-col w-full">
              {catalogos.map((b, index) => {
                return (
                  <div
                    className="flex border-b justify-between items-center w-full py-3 px-2 gap-2"
                    key={index}
                  >
                    <div className="text-center">{b.valor}</div>
                    <div>
                      <Link
                        className="w-1/12 mx-2 text-center"
                        to={`/catalogos/valores/${idCatalogo}/form/${b.id}`}
                      >
                        <button className="text-center bg-cyan px-5 h-9 rounded-md text-white py-2 cursor-pointer">
                          Editar
                        </button>
                      </Link>
                      <button
                        className="text-center mx-2 bg-pink px-5 h-9 rounded-md text-white py-2 cursor-pointer"
                        onClick={() => deleteCatalogo(b.id)}
                      >
                        Eliminar
                      </button>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <Pagination
            totalPages={totalPages}
            currentPage={page}
            onPageChange={(page) => {
              setPage(page);
            }}
          />
        </div>
      ) : (
        <div className="flex flex-col justify-center">
          <div className="w-full flex justify-between">
            <div>
              <p className="text-2xl font-bold mb-4">Total: {totalCount}</p>
              <Link to={`/catalogos/valores/${idCatalogo}/form/new`}>
                <button className="bg-cyan text-white py-2 px-8 font-bold rounded-full float-left flex justify-center items-center">
                  + Nuevo catálogo
                </button>
              </Link>
            </div>
            <Link to="/catalogos">
              <button className="bg-pink text-white py-2 px-8 font-bold rounded-full float-right flex justify-center items-center">
                <IoMdArrowRoundBack className="text-lg" /> Volver
              </button>
            </Link>
          </div>
          <br />
          <p className="text-center text-2xl font-bold">
            No hay catálogos asociados
          </p>
        </div>
      )}
    </div>
  );
};
