import React from "react";
import "./index.css";
export const TableVariations = ({
	editVariation,
	listVariations,
	removeVariation,
	variations,
}) => {
	return (
		<div className="w-full my-6  ">
			<hr className="my-4 bg-purple text-purple-border" />
			<p>Variaciones de este producto</p>
			<table className="w-full my-5">
				<thead className="w-full">
					<tr>
						<th>Producto</th>
						{listVariations.map((v) => (
							<th className="tabla2" key={v.id}>{v.nombre}</th>
						))}
						<th></th>
					</tr>
					{variations.map((l, index) => (
						<tr className={`${index % 2 ? "bg-purple/10" : ""}`} key={index}>
							<th className="items-center flex">
								<img
									className="w-16 h-16 m-3 rounded-lg text-xs font-normal"
									src={
										l.product.fotoPrincipal
											? l.product.fotoPrincipal
											: "https://polpo-assets.s3.amazonaws.com/production/ticolitas/productos/800/noactivo.jpg"
									}
									alt={`Foto de ${index}`}
								/>
								<p className="text-xs font-normal overflow-hidden">
									{l.product.nombre}
								</p>
							</th>
							{listVariations.map((v) => (
								<th className="font-normal tabla2" key={v.id}>{l[v.nombre]}</th>
							))}
							<th className="">
								<div
									className="cursor-pointer text-xs py-2 px-3 rounded-lg bg-pink text-white mx-5 my-3"
									onClick={() => removeVariation(index, l)}
								>
									Eliminar
								</div>
								<div
									className="cursor-pointer text-xs py-2 px-3 rounded-lg bg-purple text-white mx-5 my-3"
									onClick={() => editVariation(l, index)}
								>
									Editar
								</div>
							</th>
						</tr>
					))}
				</thead>
			</table>
		</div>
	);
};
