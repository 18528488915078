import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	ordenesActive: null,
	categoriesProduct: null,
	jerarquia: null,
	lineasOrdeCompra: [],
	OCbodega: "",
	OCProveedor: "",
	OCRefProveedor: "",
};

const inventorySlice = createSlice({
	name: "inventorySlice",
	initialState,
	reducers: {
		setActiveOrdenes: (state, action) => {
			state.ordenesActive = action.payload;
		},
		setCategoriesProduct: (state, action) => {
			state.categoriesProduct = action.payload;
		},
		setJerarquia: (state, action) => {
			state.jerarquia = action.payload;
		},
		setLineaOrdenCompra: (state, action) => {
			state.lineasOrdeCompra.push(action.payload);
		},
		updateLineaOrdenCompra: (state, action) => {
			state.lineasOrdeCompra = state.lineasOrdeCompra.map((l) =>
				l.id === action.payload.id ? action.payload : l
			);
		},
		deleteLineaOrdenCompra: (state, action) => {
			state.lineasOrdeCompra = state.lineasOrdeCompra.filter(
				(l) => l.idProducto !== action.payload.idProducto
			);
		},
		setDefaultLienasOrdenCompra: (state, action) => {
			state.lineasOrdeCompra = action.payload;
		},
		setOCBodega: (state, action) => {
			state.OCbodega = action.payload;
		},
		setOCProveedor: (state, action) =>{
			state.OCProveedor = action.payload
		},
		setOCRefProveedor: (state, action) =>{
			state.OCRefProveedor = action.payload
		}
	},
});

export const {
	setActiveOrdenes,
	setCategoriesProduct,
	setJerarquia,
	setLineaOrdenCompra,
	deleteLineaOrdenCompra,
	updateLineaOrdenCompra,
	setDefaultLienasOrdenCompra,
	setOCBodega,
	setOCProveedor,
	setOCRefProveedor
} = inventorySlice.actions;

export default inventorySlice.reducer;
