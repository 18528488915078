import React from "react";

export function Tabs({ tabs, onTabChange, activeTab }) {
  return (
    <div className="flex flex-wrap justify-start">
      {tabs.map((t, index) => {
        return (
          <div
            onClick={() => {
              onTabChange(index);
            }}
            key={`tab_${index}`}
            className="my-6 cursor-pointer mr-2"
          >
            <span
              className={`${
                index === activeTab ? "border-b-2 border-orange" : ""
              } text-listingProductGray px-2 pb-1`}
            >
              {t.label}
            </span>
          </div>
        );
      })}
    </div>
  );
}

export default Tabs;
