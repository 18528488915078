import React, { useEffect } from "react";
import mascotas from "../../assets/mascotas.svg";
import mision from "../../assets/mision.png";
import vision from "../../assets/vision.png";
import valores from "../../assets/valores.png";

export function AboutUsPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="bg-white w-full p-content pb-16 pt-4 flex items-center flex-col">
      <div className="w-full flex flex-wrap">
        <div className="shadow-lg w-full md:w-2/3 p-8 bg-backgroundGray">
          <p className="font-title text-3xl mb-4">MISIÓN</p>
          <p className="leading-10">
            Somos el emprendimiento que facilita la vida de los amantes de las{" "}
            <img
              className="h-8 inline"
              src={mascotas}
              alt="Mascotas TiColitas"
            />{" "}
            ayudándoles a cuidarlos y protegerlos con los mejores alimentos y
            artículos para ellos, de una forma segura, sencilla e innovadora,
            con responsabilidad social y ambiental.
          </p>
        </div>
        <div className="shadow-lg px-32 pt-4 md:px-8 flex w-full md:w-1/3 bg-yellow padding-8 rounded-bl-3xl rounded-br-3xl md:rounded-bl-none md:rounded-tr-3xl md:rounded-br-3xl">
          <img className="self-end" src={mision} alt="Mision TiColitas" />
        </div>
      </div>
      <div className="w-full flex flex-wrap mt-10">
        <div className="shadow-lg flex w-full md:w-1/3 bg-cyan rounded-tl-3xl rounded-tr-3xl md:rounded-tr-none md:rounded-tl-3xl md:rounded-bl-3xl">
          <img className="self-end" src={vision} alt="Mision TiColitas" />
        </div>
        <div className="shadow-lg w-full md:w-2/3 p-8 bg-backgroundGray">
          <p className="font-title text-3xl mb-4">VISIÓN</p>
          <p className="leading-10">
            Ser el emprendimiento aliado de los amantes de las{" "}
            <img
              className="h-8 inline"
              src={mascotas}
              alt="Mascotas TiColitas"
            />{" "}
            que sabemos que lo darían todo por alimentarlos y cuidarlos con
            productos de alta calidad, pero que además se quieren sentir
            seguros, altamente satisfechos del servicio que reciben y orgullosos
            de las acciones diarias que hacemos en conjunto para impactar
            positivamente el medio ambiente.
          </p>
        </div>
      </div>
      <div className="w-full flex flex-wrap  mt-10">
        <div className="shadow-lg w-full md:w-2/3 p-8 bg-backgroundGray">
          <p className="font-title text-3xl mb-4">VALORES</p>
          <ul>
            <li className="mb-4">Honestidad en todo lo que hacemos</li>
            <li className="mb-4 self-center">
              Respeto por todas las personas,{" "}
              <img
                className="h-8 inline"
                src={mascotas}
                alt="Mascotas TiColitas"
              />{" "}
              y el medio ambiente
            </li>
            <li className="mb-4">
              Compromiso para mantener la calidad y seguridad.
            </li>
          </ul>
        </div>
        <div className="shadow-lg px-32 pt-4 md:px-8 flex w-full md:w-1/3 bg-pink padding-8 rounded-bl-3xl rounded-br-3xl md:rounded-bl-none md:rounded-tr-3xl md:rounded-br-3xl">
          <img className="self-end" src={valores} alt="Valores TiColitas" />
        </div>
      </div>
    </div>
  );
}

export default AboutUsPage;
