import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { IoMdArrowRoundBack } from "react-icons/io";

import CategoryBreadcrumbs from "../../components/CategoryBreadcrumbs";
import NotFoundForm from "../../components/NotFoundForm";
import Spinner from "../../components/Spinner";
import useAuthFetch from "../../hooks/useAuthFetch";

export function CategoriaPage() {
  const navigate = useNavigate();
  const { idCategoria } = useParams();
  const authFetch = useAuthFetch();

  const [category, setCategory] = useState(null);

  const goBack = () => {
    if (!category.idCategoriaPadre) {
      navigate(`/tienda/`);
    } else {
      navigate(`/tienda/categoria/${category.idCategoriaPadre}`);
    }
  };

  useEffect(() => {
    setCategory(null);
    const fetchCategory = async (id) => {
      const data = await authFetch(
        `categoria-categoria-padre/${idCategoria}/?filter={"include":[{"relation":"categorias","scope":{"fields":{"id":true,"idCategoria":true,"idCategoriaPadre":true},"include":[{"relation":"categoria","scope":{"fields":{"id":true,"nombre":true,"colorFondo":true,"imagen":true,"subTitulo":true}}}]}},{"relation":"marcas","scope":{"order": "orden asc","fields":{"createdAt":false,"updatedAt":false}}},{"relation":"categoria","scope":{"fields":{"id":true,"nombre":true,"colorFondo":true,"imagen":true}}},{"relation":"categoriaPadre","scope":{"fields":{"createdAt":false,"updatedAt":false},"include":[{"relation":"categoriaPadre","scope":{"fields":{"createdAt":false,"updatedAt":false},"include":[{"relation":"categoriaPadre","scope":{"fields":{"createdAt":false,"updatedAt":false},"include":[{"relation":"categoria","scope":{"fields":{"id":true,"nombre":true}}}]}},{"relation":"categoria","scope":{"fields":{"id":true,"nombre":true}}}]}},{"relation":"categoria","scope":{"fields":{"id":true,"nombre":true}}}]}}],"fields":{"createdAt":false,"updatedAt":false}}`
      );
      const dataJSON = await data.json();
      setCategory(dataJSON);
      window.scrollTo(0, 0);
    };

    fetchCategory(idCategoria);
  }, [idCategoria]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      {!category ? (
        <div className="w-full p-content pt-4 pb-16 min-h-[400px] bg-white">
          <Spinner />
        </div>
      ) : (
        <div className="w-full p-content pt-4 pb-16">
          <div className="flex w-full items-center">
            <div className="w-full">
              <button
                onClick={goBack}
                className="bg-pink text-white py-2 px-8 font-bold rounded-full float-right flex justify-center items-center"
                >
                <IoMdArrowRoundBack className="text-lg" /> Volver
              </button>
            </div>
          </div>
          <div
            className={`${category.categoria.colorFondo} flex items-center w-full p-4 rounded-3xl my-4`}
          >
            <div className="w-11/12 flex">
              {<CategoryBreadcrumbs category={category} />}
            </div>
            <div className="w-1/12">
              {category.categoria.imagen && (
                <img
                  className="max-h-14 float-right"
                  src={category.categoria.imagen}
                  alt="Perro TiColitas"
                />
              )}
            </div>
          </div>
          {category.categorias && (
            <div className="flex flex-wrap gap-2">
              {category.categorias.map((cat, index) => {
                return (
                  <Link
                    key={`category_${index}`}
                    to={`/tienda/categoria/${cat.id}`}
                    className={`cursor-pointer w-1/3 h-20 sm:h-28 md:h-36 flex flex-[0_0_calc(50%-0.5rem)] sm:flex-[0_0_calc(50%-0.5rem)] md:flex-[0_0_calc(50%-0.5rem) lg:flex-[0_0_calc(33.3333333%-0.5rem)] justify-center items-center ${cat.categoria.colorFondo} py-4 px-8 rounded-3xl`}
                  >
                    <div
                      className={`flex flex-[0_0_calc(50%-0.5rem)] sm:flex-[0_0_calc(50%-0.5rem)] md:flex-[0_0_calc(50%-0.5rem) lg:flex-[0_0_calc(33.3333333%-0.5rem)] justify-center items-center`}
                    >
                      <p className="text-white font-title text-md sm:text-2xl md:text-3xl uppercase text-center">
                        {cat.categoria.nombre} <br />
                        <span className="text-">{cat.categoria.subTitulo}</span>
                      </p>
                    </div>
                  </Link>
                );
              })}
            </div>
          )}
          {category.marcas && (
            <div className="flex flex-wrap gap-2">
              {category.marcas.map((m, index) => {
                return (
                  <Link
                    key={`cat_brand_${index}`}
                    to={`/tienda/categoria/${category.id}/marca/${m.id}`}
                    className={`cursor-pointer h-52 sm:h-32 md:h-36 lg:h-32 xl:h-40 flex flex-[0_0_calc(50%-0.5rem)] sm:flex-[0_0_calc(25%-0.5rem)] md:flex-[0_0_calc(25%-0.5rem)] lg:flex-[0_0_calc(20%-0.5rem)] justify-center items-center bg-backgroundGray border border-purple py-4 px-8 rounded-2xl transition-all duration-150  hover:shadow-xl`}
                  >
                    <img
                      className="max-h-44 sm:max-h-24 md:max-h-24 lg:max-h-32 xl:max-h-32"
                      src={m.logo}
                      alt={`Logo ${m.nombre}`}
                    />
                  </Link>
                );
              })}
              {category.marcas.length > 0 && (
                <Link
                  to={`/tienda/categoria/${category.id}/marca/todas`}
                  className={`cursor-pointer h-52 sm:h-32 md:h-36 lg:h-32 xl:h-40 flex flex-[0_0_calc(50%-0.5rem)] sm:flex-[0_0_calc(25%-0.5rem)] md:flex-[0_0_calc(25%-0.5rem)] lg:flex-[0_0_calc(20%-0.5rem)] justify-center items-center bg-purple border border-purple py-4 px-8 rounded-3xl`}
                >
                  <p className="text-white text-xl text-center">Ver todas las marcas</p>
                </Link>
              )}
            </div>
          )}
          <p className="mt-4">
            La segmentación de nuestra tienda por etapa de la vida y talla se
            realiza según las presentaciones de las marcas que ponemos a tu
            disposición. Si tenés dudas sobre como clasificar a tu mascota
            consultá al médico veterinario de tu confianza.
          </p>
        </div>
      )}
      <NotFoundForm />
    </div>
  );
}

export default CategoriaPage;
