import React, { useCallback, useEffect, useState } from "react";
import {
  IoMdArrowRoundBack,
  IoMdAddCircle,
  IoMdTrash,
  IoMdEye,
} from "react-icons/io";
import { Link } from "react-router-dom";

import { ClipLoader } from "react-spinners";
import { useSelector } from "react-redux";
import useAuthFetch from "../../../hooks/useAuthFetch";

export default function RoutesList() {
  const authFetch = useAuthFetch();
  const [rutas, setRutas] = useState({
    data: [],
    loading: false,
  });
  const [deleteLoading, setDeleteLoading] = useState(false);
  const { token } = useSelector((store) => store.app.user);

  const fetchRutas = useCallback(async () => {
    setRutas((prev) => ({
      ...prev,
      loading: true,
    }));

    const viajeResponse = await authFetch(`transportista/viaje`);
    const data = await viajeResponse.json();

    setRutas((prev) => ({
      ...prev,
      loading: false,
      data,
    }));
  }, []);

  useEffect(() => {
    fetchRutas();
  }, [fetchRutas]);

  const deleteRuta = async (idRuta) => {
    setDeleteLoading(true);
    await authFetch(`transportista/viaje/${idRuta}`, null, "DELETE", token);
    setDeleteLoading(false);
    await fetchRutas();
  };

  return (
    <div className="w-full p-content pb-16">
      <div className="flex flex-col gap-4">
        <Link to="/cuenta">
          <button className="bg-pink text-white py-2 px-8 font-bold rounded-full float-right flex justify-center items-center">
            <IoMdArrowRoundBack className="text-lg" /> Volver
          </button>
        </Link>
      </div>
      <div className="flex justify-between my-10">
        <h2 className="font-bold text-xl">Listado de rutas</h2>
        <Link to={"/rutas/create"}>
          <button className="bg-cyan text-white py-2 px-8 font-bold rounded-full float-right flex justify-center items-center">
            <IoMdAddCircle className="text-lg" /> Nueva ruta
          </button>
        </Link>
      </div>
      {rutas.loading ? (
        <div className="w-full flex justify-center text-purple">
          <ClipLoader size={40} />
        </div>
      ) : rutas.data.length === 0 ? (
        <div className="w-full flex justify-center text-purple">
          <h2>No hay rutas en este momento </h2>
        </div>
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5">
          {rutas.data.map((ruta) => (
            <div className="flex flex-col p-10 shadow-lg rounded-lg gap-2">
              <h3 className="font-bold text-lg text-purple">
                Ruta # {ruta.id}{" "}
              </h3>
              <p className="text-lg">
                Transportista: <strong> {ruta.transportista} </strong>{" "}
              </p>

              <p className="text-lg">
                Fecha: <strong> {ruta.fecha} </strong>{" "}
              </p>

              <p className="text-lg">
                Tiempo:{" "}
                <strong>
                  {" "}
                  {(ruta.tiempoSegundos / 3600).toFixed(2)} {" horas"}
                </strong>
              </p>

              <p className="text-lg">
                Distancia en kilometros:{" "}
                <strong>
                  {" "}
                  {(ruta.distanciaMetros / 1000).toFixed(2)} {" kilometros"}
                </strong>
              </p>

              <div className="flex justify-end gap-2 mt-4">
                <Link to={`${ruta.id}`}>
                  <button className="bg-purple text-white py-2 px-8 font-bold rounded-full float-right flex justify-center items-center gap-2">
                    Ver ruta <IoMdEye className="text-lg" />
                  </button>
                </Link>

                <button
                  className="bg-pink text-white py-2 px-8 font-bold rounded-full float-right flex justify-center items-center gap-2"
                  onClick={() => deleteRuta(ruta.id)}
                >
                  Eliminar ruta
                  {deleteLoading ? (
                    <ClipLoader color="#fff" size={12} />
                  ) : (
                    <IoMdTrash className="text-lg" />
                  )}
                </button>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
