import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import _ from "lodash";

import {
  getCountries,
  getCountryCallingCode,
} from "react-phone-number-input/input";
import es from "react-phone-number-input/locale/es.json";

import useAuthFetch from "../../hooks/useAuthFetch";
import { logUser } from "../../slices/appSlice";
import { Spinner } from "../../components/Spinner";
import Select from "../Select";
import { GrFormViewHide, GrFormView } from "react-icons/gr";

const sortedCountries = (countries, labels) => {
  const listOfCountries = [];
  countries.forEach((country) => {
    listOfCountries.push({
      label: `${labels[country]} +${getCountryCallingCode(country)}`,
      value: `${labels[country]} +${getCountryCallingCode(country)}`,
      numericValue: parseInt(getCountryCallingCode(country)),
    });
  });
  return [
    {
      label: "Costa Rica +506",
      value: "CR",
      numericValue: 506,
    },
  ].concat(
    _.sortBy(listOfCountries, (c) => {
      return c.label;
    })
  );
};

export function SignupForm({ redirectAddress }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [userData, setUserData] = useState({
    nombre: "",
    apellido1: "",
    email: "",
    telefono: "",
    password: "",
    idOrganizacion: "",
    idEnOrganizacion: "",
    codigoPaisTelefono: "",
  });
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [viewPassword, setViewPassword] = useState(false);
  const [viewPasswordConfirmation, setViewPasswordConfirmation] =
    useState(false);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [organizations, setOrganizations] = useState([]);
  const [countryValue, setCountryValue] = useState();
  const authFetch = useAuthFetch();

  const postSignUp = async () => {
    if (
      userData.email &&
      userData.password &&
      userData.nombre &&
      userData.apellido1 &&
      userData.telefono &&
      userData.codigoPaisTelefono
    ) {
      setLoading(true);
      setError(null);
      const signUpFetch = await authFetch(
        `usuarios`,
        {
          email: userData.email,
          password: userData.password,
          nombre: userData.nombre,
          apellido1: userData.apellido1,
          telefono: parseInt(userData.telefono),
          codigoPaisTelefono: userData.codigoPaisTelefono,
          idOrganizacion: userData.idOrganizacion || undefined,
          idEnOrganizacion:
            userData.idOrganizacion && userData.idEnOrganizacion
              ? userData.idEnOrganizacion
              : undefined,
        },
        "POST"
      );
      try {
        if (signUpFetch.status === 200) {
          const loginFetch = await authFetch(
            `usuarios/login`,
            {
              email: userData.email,
              password: userData.password,
            },
            "POST"
          );
          const loginData = await loginFetch.json();
          setLoading(false);
          dispatch(logUser(loginData));
          if (redirectAddress) {
            navigate(redirectAddress);
          }
        } else {
          setLoading(false);
          if (signUpFetch.status === 422) {
            const signUpData = await signUpFetch.json();
            if (signUpData.error.code === "ER_DUP_ENTRY") {
              setError(
                "El correo ingresado ya se encuentra registrado en el sistema."
              );
            } else {
              setError(
                "Ha ocurrido un error. Por favor verifique los datos y vuelva a intentarlo."
              );
            }
          } else {
            setError(
              "Ha ocurrido un error. Por favor verifique los datos y vuelva a intentarlo."
            );
          }
        }
      } catch (error) {
        setLoading(false);
        setError(
          "Ha ocurrido un error. Por favor verifique los datos y vuelva a intentarlo."
        );
      }
    } else {
      setError("Debe completar todos los campos");
    }
  };

  useEffect(() => {
    const fetchOrganizations = async () => {
      const fetchOrganizations = await authFetch(
        'organizaciones?filter={"order":"nombre"}'
      );
      const organizationsData = await fetchOrganizations.json();
      setOrganizations(organizationsData);
    };

    fetchOrganizations();
  }, []);

  return (
    <>
      <div>
        {loading && <Spinner float />}
        <label htmlFor="name" className="text-base">
          Nombre:
          <span className="text-pink">*</span>
        </label>
        <input
          id="name"
          name="name"
          className="w-full rounded-3xl my-2 h-12 p-6 border border-purple"
          value={userData.nombre}
          onChange={(evt) => {
            setUserData({
              ...userData,
              nombre: evt.target.value,
            });
          }}
        />
      </div>
      <div>
        <label htmlFor="lastName" className="text-base">
          Apellido:
          <span className="text-pink">*</span>
        </label>
        <input
          id="lastName"
          name="lastName"
          className="w-full rounded-3xl my-2 h-12 p-6 border border-purple"
          value={userData.apellido1}
          onChange={(evt) => {
            setUserData({
              ...userData,
              apellido1: evt.target.value,
            });
          }}
        />
      </div>
      <div>
        <Select
          required
          containerClasses="my-2"
          labelColor="text-base"
          buttonClasses="border border-purple"
          label="Código Telefónico de País:"
          options={sortedCountries(getCountries(), es)}
          onChange={(value, option) => {
            setCountryValue(value);
            setUserData({
              ...userData,
              codigoPaisTelefono: option.numericValue,
            });
          }}
          value={countryValue}
        />
      </div>
      <div>
        <label htmlFor="phone" className="text-base">
          Teléfono:
          <span className="text-pink">*</span>
        </label>
        <input
          id="telefono"
          name="telefono"
          className="w-full rounded-3xl my-2 h-12 p-6 border border-purple"
          value={userData.telefono}
          placeholder={"Por favor ingresa únicamente números"}
          onChange={(evt) => {
            const justNumbers = evt.target.value.replace(/[^0-9]/g, "");
            setUserData({
              ...userData,
              telefono: justNumbers,
            });
          }}
        />
      </div>
      <div>
        <label htmlFor="email" className="text-base">
          Correo Electrónico:
          <span className="text-pink">*</span>
        </label>
        <input
          id="email"
          name="email"
          className="w-full rounded-3xl my-2 h-12 p-6 border border-purple"
          value={userData.email}
          onChange={(evt) => {
            setUserData({
              ...userData,
              email: evt.target.value.toLowerCase(),
            });
          }}
        />
      </div>
      <div className="relative w-full">
        <label htmlFor="password" className="text-base">
          Contraseña:
          <span className="text-pink">*</span>
        </label>
        <input
          id="password"
          name="password"
          onChange={(evt) => {
            setUserData({
              ...userData,
              password: evt.target.value,
            });
          }}
          className="w-full rounded-3xl my-2 h-12 p-6 border border-purple"
          value={userData.password}
          type={viewPassword ? "text" : "password"}
        />
        <div className="absolute right-5 top-9">
          <button
            onClick={() => {
              setViewPassword(!viewPassword);
            }}
          >
            {viewPassword ? (
              <GrFormView className="text-3xl text-borderGray" />
            ) : (
              <GrFormViewHide className="text-3xl text-borderGray" />
            )}
          </button>
        </div>
      </div>
      <div className="relative w-full">
        <label htmlFor="passwordConfirmation" className="text-base">
          Confirmar contraseña:
          <span className="text-pink">*</span>
        </label>
        <input
          id="passwordConfirmation"
          name="passwordConfirmation"
          onChange={(evt) => {
            setPasswordConfirmation(evt.target.value);
          }}
          className="w-full rounded-3xl my-2 h-12 p-6 border border-purple"
          value={passwordConfirmation}
          type={viewPasswordConfirmation ? "text" : "password"}
        />
        <div className="absolute right-5 top-9">
          <button
            onClick={() => {
              setViewPasswordConfirmation(!viewPasswordConfirmation);
            }}
          >
            {viewPasswordConfirmation ? (
              <GrFormView className="text-3xl text-borderGray" />
            ) : (
              <GrFormViewHide className="text-3xl text-borderGray" />
            )}
          </button>
        </div>
      </div>
      <div>
        <Select
          containerClasses="my-2"
          required
          labelColor="text-base"
          buttonClasses="border border-purple"
          label="¿Perteneces a alguna asociación o condominio?:"
          options={[
            {
              value: 0,
              label: "No pertenezco a una asociación o condominio",
            },
          ].concat(
            organizations.map((o) => {
              return {
                value: o.id,
                label: o.nombre,
              };
            })
          )}
          onChange={(value) => {
            setUserData({
              ...userData,
              idOrganizacion: value,
              idEnOrganizacion: "",
            });
          }}
          value={userData.idOrganizacion}
        />
      </div>
      {userData.idOrganizacion &&
      organizations.find((o) => o.id === userData.idOrganizacion)
        .esAsociacion ? (
        <div>
          <label htmlFor="idInOrganization" className="text-base">
            Ingrese su número de identificación o número de asociado:
            <span className="text-pink">*</span>
          </label>
          <input
            id="idInOrganization"
            name="idInOrganization"
            className="w-full rounded-3xl my-2 h-12 p-6 border border-purple"
            value={userData.idEnOrganizacion}
            onChange={(evt) => {
              setUserData({
                ...userData,
                idEnOrganizacion: evt.target.value,
              });
            }}
          />
        </div>
      ) : null}
      <div className="w-full mt-8 mb-2">
        {error && <p className="text-pink text-center">{error}</p>}
        <button
          onClick={() => {
            postSignUp();
          }}
          className="bg-purple text-white h-12 px-8 rounded-full w-full"
        >
          Registrarse
        </button>
      </div>
    </>
  );
}

export default SignupForm;
