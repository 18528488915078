import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  printBill: false,
  billing: false,
  billingDetails: null,
};

export const checkoutTiendaSlice = createSlice({
  name: "checkoutTienda",
  initialState,
  reducers: {
    setPrintBill: (state, action) => {
      state.printBill = action.payload;
    },
    setBilling: (state, action) => {
      state.billing = action.payload;
    },
    setBillingDetails: (state, action) => {
      state.billingDetails = action.payload;
    },
    clearAfterPurchase: (state) => {
      state.whatsappReconfirmation = false;
      state.acceptTermsAndConditions = false;
      state.printBill = false;
      state.addressDetails = null;
      state.selectedStore = null;
      state.billing = false;
      state.billingDetails = null;
      state.paymentMethod = null;
      state.activeSection = null;
      state.stores = [];
      state.paymentMethods = [];
      state.cashAmount = 0;
      state.orderNotes = null;
      state.shippingMethods = [];
      state.shippingMethod = null;
      state.shippingCost = null;
      state.tollCost = null;
      state.shippingDate = null;
      state.estimatedHoursForShipping = null;
      state.shippingDistance = null;
      state.shippingTime = null;
      state.userAddresses = [];
      state.userBillingAddresses = [];
      state.newUserName = "";
      state.newUserLastName = "";
      state.newUserEmail = "";
      state.newUserPhone = "";
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setPrintBill,
  setBilling,
  setBillingDetails,
  clearAfterPurchase,
} = checkoutTiendaSlice.actions;

export default checkoutTiendaSlice.reducer;
