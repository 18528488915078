import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  closedOnlyShippingModal: false,
  closedPromoModal: false,
};

export const appSessionSlice = createSlice({
  name: "appSession",
  initialState,
  reducers: {
    userClosedOnlyShippingModal: (state) => {
      state.closedOnlyShippingModal = true;
    },
    userClosedPromoModal: (state) => {
      state.closedPromoModal = true;
    }
  },
});

// Action creators are generated for each case reducer function
export const {
  userClosedOnlyShippingModal,
  userClosedPromoModal,
} = appSessionSlice.actions;

export default appSessionSlice.reducer;
