import React, { useEffect } from "react";
import TermsAndConditionsGiveaway from "../../components/TermsAndConditionsGiveaway";

export function PromoTermsAndConditions() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="bg-white w-full p-content pb-16 pt-2 flex items-center flex-col">
      <TermsAndConditionsGiveaway textColor='text-base' />
    </div>
  );
}

export default PromoTermsAndConditions;