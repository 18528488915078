import React from "react";

export function Spinner({ color }) {
  return (
    <div className="fixed top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] z-50">
      <div className="flex items-center justify-center ">
        <div className={`w-24 h-24 border-l-4 border-${color || "purple"} rounded-full animate-spin my-24`}></div>
      </div>
    </div>
  );
}

export default Spinner;
