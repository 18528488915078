import React from "react";
import { Link } from "react-router-dom";
import ProductPrice from "../ProductPrice";
import scrollDown from "../../assets/scroll-down-purple.gif";

export function ProductList({
  hideNotFound,
  notFoundFormRef,
  products,
  categoryId,
  marcaId,
  searchQuery,
}) {
  return (
    products && (
      <div className="flex flex-wrap gap-3">
        {products.map((p) => {
          return (
            p.idFamilia === null && (
              <Link
                key={`producto_${p.id}`}
                to={
                  categoryId && marcaId
                    ? `/tienda/producto/${p.id}?categoria=${categoryId}&marca=${marcaId}`
                    : searchQuery
                    ? `/tienda/producto/${p.id}?query=${searchQuery}`
                    : `/tienda/producto/${p.id}`
                }
                className={`cursor-pointer flex flex-wrap flex-[0_0_calc(100%)] sm:flex-[0_0_calc(33.3333333333%-0.5rem)] md:flex-[0_0_calc(33.3333333%-0.5rem)] lg:flex-[0_0_calc(33.3333333%-0.5rem)] items-center bg-backgroundGray py-4 px-8 rounded-xl transition-all duration-150  hover:drop-shadow-xl`}
              >
                <div
                  className={`flex flex-wrap items-center bg-backgroundGray py-4 px-8 rounded-md `}
                >
                  {p.fotoPrincipal ? (
                    <img
                      className="rounded-md"
                      src={p.fotoPrincipal}
                      alt={`Foto de ${p.nombreTienda}`}
                    />
                  ) : (
                    <p className="text-listingProductGray font-bold">
                      Fotografía no disponible
                    </p>
                  )}
                  <p className="w-full text-sm text-listingProductGray mt-2 font-bold">
                    {p.nombreTienda}
                  </p>
                  {p.prescrito === 1 && (
                    <span className="text-pink">Alimento de Prescripción</span>
                  )}
                  {p.pesoGramos && (
                    <p className="w-full text-sm text-listingProductGray mt-2">
                      {"Presentación: "}
                      <br />
                      <span className="font-bold">
                        {p.pesoGramos < 1000
                          ? `${p.pesoGramos} gramos`
                          : `${(p.pesoGramos / 1000).toFixed(2)} Kg`}
                      </span>
                    </p>
                  )}
                  <div className="flex flex-wrap">
                    <ProductPrice hideDiscountText product={p} />
                  </div>
                </div>
              </Link>
            )
          );
        })}
        {!hideNotFound && (
          <div
            onClick={() => {
              notFoundFormRef.current.scrollIntoView();
            }}
            className={`cursor-pointer flex flex-wrap content-center flex-[0_0_calc(100%)] sm:flex-[0_0_calc(33.3333333333%-0.5rem)] md:flex-[0_0_calc(33.3333333%-0.5rem)] lg:flex-[0_0_calc(33.3333333%-0.5rem)] items-center bg-backgroundGray py-4 px-8 rounded-md`}
          >
            <h1 className="text-pink font-bold w-full text-center">
              Si no encontraste lo que buscabas, seguí bajando para ayudarte.
            </h1>
            <div className="w-full">
              <img
                className="h-40 mx-auto"
                src={scrollDown}
                alt="Continue bajando"
              />
            </div>
          </div>
        )}
      </div>
    )
  );
}

export default ProductList;
