import React, { useCallback, useEffect, useState } from "react";
import _ from "lodash";
import {
  getCountries,
  getCountryCallingCode,
} from "react-phone-number-input/input";
import es from "react-phone-number-input/locale/es.json";
import GoogleMap from "../GoogleMap";
import SelectByIndex from "../Select/SelectByIndex";
import SelectCodNumber from "../Select/SelectCodNumber";
import useAuthFetch from "../../hooks/useAuthFetch";

const sortedCountries = (countries, labels) => {
  const listOfCountries = [];
  countries.forEach((country) => {
    listOfCountries.push({
      label: `${labels[country]} +${getCountryCallingCode(country)}`,
      value: `${labels[country]} +${getCountryCallingCode(country)}`,
      numericValue: parseInt(getCountryCallingCode(country)),
    });
  });
  return [
    {
      label: "Costa Rica +506",
      value: "Costa Rica +506",
      numericValue: 506,
    },
  ].concat(
    _.sortBy(listOfCountries, (c) => {
      return c.label;
    })
  );
};

export function AddressForm({
  address,
  onChangeValue,
  forceSpecificDeliveryPoint,
  preCompleted,
}) {
  const [locations, setLocations] = useState([]);
  const [addressDistrito, setAddressDistrito] = useState([]);
  const [distritoIndex, setDistritoIndex] = useState();

  const authFetch = useAuthFetch();

  useEffect(() => {
    const fetchLocations = async () => {
      const locationsFetch = await authFetch("provincia-canton-distrito");
      const locationsData = await locationsFetch.json();
      setLocations(locationsData);
    };
    fetchLocations();
  }, []);

  const changeAddress = useCallback(() => {
    if (address.id) {
      const value = locations.find(
        (l) =>
          l.idProvincia === address.provincia &&
          l.idCanton === address.canton &&
          l.idDistrito === address.distrito
      );
      setAddressDistrito(value || null);
      if (value) {
        onChangeValue("distrito", value);
      }
    } else {
      setAddressDistrito(address.distrito || null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locations, address.id]);

  useEffect(() => {
    if (locations.length > 0) {
      changeAddress();
    }
  }, [changeAddress, locations]);

  useEffect(() => {
    if (address.id) {
      changeAddress();
    } else {
      setAddressDistrito(null);
    }
  }, [changeAddress, address.id]);

  return (
    <div className="mt-4">
      <div>
        <label htmlFor="nombreCompleto" className="text-base">
          Nombre de la persona que recibe el pedido:
          <span className="text-pink">*</span>
        </label>
        <input
          id="nombreCompleto"
          name="nombreCompleto"
          className="w-full rounded-3xl my-2 h-12 p-6 border border-purple"
          required={true}
          onChange={(evt) => {
            onChangeValue("nombreCompleto", evt.target.value);
          }}
          value={address.nombreCompleto}
        />
      </div>
      <div>
        <SelectByIndex
          containerClasses="my-2"
          labelColor="text-base"
          buttonClasses="border border-purple"
          label="Seleccioná el distrito donde se encuentra tu punto de entrega. Recordá que solo entregaremos en estos lugares:"
          required
          options={locations.map((d, index) => {
            return {
              value: d,
              label: d.nombreUbicacion,
              IDindex: index,
            };
          })}
          onChange={(value, IDindex) => {
            setAddressDistrito(value);
            onChangeValue("distrito", value);
            setDistritoIndex(IDindex);
          }}
          value={addressDistrito}
          IDindex={distritoIndex}
        />
      </div>
      <div className="mt-4">
        <GoogleMap
          preCompleted={preCompleted}
          forceSpecificDeliveryPoint={forceSpecificDeliveryPoint}
          address={address}
          onPointChange={(value) => {
            onChangeValue("latitud", value);
          }}
        />
      </div>
      <div>
        <label htmlFor="direccionExacta" className="text-base">
          Dirección exacta:<span className="text-pink">*</span>
        </label>
        <textarea
          id="direccionExacta"
          name="direccionExacta"
          className="w-full rounded-3xl mt-2 p-6 border border-purple"
          rows={3}
          onChange={(evt) => {
            onChangeValue("direccionExacta", evt.target.value);
          }}
          value={address.direccionExacta}
        />
      </div>
      <div>
        <SelectCodNumber
          required
          containerClasses="my-2"
          labelColor="text-base"
          buttonClasses="border border-purple"
          label="Código Telefónico de País:"
          options={sortedCountries(getCountries(), es)}
          onChange={(value, option, numericValue) => {
            onChangeValue("codigoPaisTelefono", numericValue, option);
          }}
          value={address.codigoPaisTelefono}
          CodNumber={address.codigoPaisTelefono}
        />
      </div>
      <div>
        <label htmlFor="telefono" className="text-base">
          Teléfono:<span className="text-pink">*</span>
        </label>
        <input
          id="telefono"
          name="telefono"
          className="w-full rounded-3xl my-2 h-12 p-6 border border-purple"
          value={address.telefono}
          placeholder={"Por favor ingresá únicamente números"}
          onChange={(evt) => {
            const justNumbers = evt.target.value.replace(/[^0-9]/g, "");
            onChangeValue("telefono", justNumbers);
          }}
        />
      </div>
      <div>
        <label htmlFor="addressId" className="text-base">
          Para referencia de tus futuras compras. ¿Cómo deseas identificar esta
          dirección?:<span className="text-pink">*</span>
        </label>
        <input
          id="addressId"
          name="addressId"
          placeholder="Ej: Casa, Oficina"
          className="w-full rounded-3xl my-2 h-12 p-6 border border-purple"
          onChange={(evt) => {
            onChangeValue("identificador", evt.target.value);
          }}
          value={address.identificador}
        />
      </div>
    </div>
  );
}

export default AddressForm;
