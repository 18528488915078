import React, { forwardRef, useState } from "react";
import { Link } from "react-router-dom";

import logo from "../../assets/logo.svg";
import waze from "../../assets/waze.svg";
import facebook from "../../assets/facebook.svg";
import instagram from "../../assets/instagram-2.svg";
import whatsapp from "../../assets/whatsapp.svg";
import TermsAndConditions from "../TermsAndConditions";
import Modal from "../Modal";
import Mixpanel from "../../services/mixpanel";

export const Footer = forwardRef((props, ref) => {
  const [showTermsAndConditions, setShowTermsAndConditions] = useState(false);

  const closeTermsAndConditionsModal = () => {
    setShowTermsAndConditions(false);
  };

  return (
    <div className="w-full bg-purple">
      <div className="flex items-center py-16 p-content">
        <div ref={ref} id="#contacto" className="w-1/2 flex px-4">
          <Link
            onClick={() => {
              Mixpanel.track(Mixpanel.TYPES.FOOTER_IR_A_HOME);
            }}
            to="/"
          >
            <img
              className="w-60 brightness-[300]"
              src={logo}
              alt="Logo TiColitas"
            />
          </Link>
        </div>
        <div className="w-1/2 flex flex-col px-4 justify-center">
          <div>
            <p className="text-white text-2xl">Ticolitas Sociedad de Responsabilidad Limitada.</p>
            <p className="text-white text-2xl">La Guácima, Alajuela</p>
            <a
              onClick={() => {
                Mixpanel.track(Mixpanel.TYPES.FOOTER_LLAMAR_OFICINA);
              }}
              href="tel:+50624518181"
              className="text-white text-2xl"
            >
              2451-8181
            </a>
            <br />
            <a
              onClick={() => {
                Mixpanel.track(Mixpanel.TYPES.FOOTER_LLAMAR_CELULAR);
              }}
              href="tel:+50683133113"
              className="text-white text-2xl"
            >
              8313-3113
            </a>
          </div>
          <div className="flex mt-4">
            <a
              onClick={() => {
                Mixpanel.track(Mixpanel.TYPES.FOOTER_WAZE);
              }}
              target="_blank"
              rel="noreferrer"
              href="https://waze.com/ul/hd1u0dtqgw"
            >
              <img className="h-10 brightness-[400]" src={waze} alt="waze" />
            </a>
            <a
              onClick={() => {
                Mixpanel.track(Mixpanel.TYPES.FOOTER_WHATSAPP);
              }}
              className="ml-4"
              target="_blank"
              rel="noreferrer"
              href="https://api.whatsapp.com/send?phone=50683133113"
            >
              <img
                className="h-10 brightness-[400]"
                src={whatsapp}
                alt="whatsapp"
              />
            </a>
            <a
              onClick={() => {
                Mixpanel.track(Mixpanel.TYPES.FOOTER_FACEBOOK);
              }}
              className="ml-4"
              target="_blank"
              rel="noreferrer"
              href="https://facebook.com/ticolitas"
            >
              <img
                className="h-10 brightness-[400]"
                src={facebook}
                alt="facebook"
              />
            </a>
            <a
              onClick={() => {
                Mixpanel.track(Mixpanel.TYPES.FOOTER_INSTAGRAM);
              }}
              className="ml-4"
              target="_blank"
              rel="noreferrer"
              href="https://instagram.com/ticolitas"
            >
              <img
                className="h-12 brightness-[400]"
                src={instagram}
                alt="instagram"
              />
            </a>
          </div>
          <div>
            <Modal
              backgroundColor="bg-purple"
              closeModalFunction={closeTermsAndConditionsModal}
              open={showTermsAndConditions}
            >
              <TermsAndConditions />
            </Modal>
            <p
              className="mt-4 text-white cursor-pointer"
              onClick={() => {
                Mixpanel.track(Mixpanel.TYPES.FOOTER_ABRIR_TERMINOS_CONDICIONES);
                setShowTermsAndConditions(true);
              }}
            >
              Términos y Condiciones / Política de privacidad
            </p>
          </div>
        </div>
      </div>
      <div>
        <div className="flex items-center py-4 p-content border border-t border-purple-border">
          <p className="text-white">
            Ticolitas Sociedad de Responsabilad Limitada. Todos los derechos reservados.
          </p>
        </div>
      </div>
    </div>
  );
});

export default Footer;
