import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import useWindowSize from "../../utils/useWindowSize";

import heroCat from "../../assets/hero-cat.png";
import heroDog from "../../assets/hero-dog.png";
import wizardBottomImg from "../../assets/wizard-bottom.png";
import wizardStep3 from "../../assets/wizard-step-3.png";
import AboutUsBanner from "../../components/AboutUsBanner";
import Modal from "../../components/Modal";
import PromoBanner from "../../components/PromoBanner";

import logo from "../../assets/logo.svg";
import {
  userClosedOnlyShippingModal,
  userClosedPromoModal,
} from "../../slices/appSessionSlice";

import Mixpanel from "../../services/mixpanel";

export function HomePage() {
  const dispatch = useDispatch();

  const step1 = useRef(null);
  const step4 = useRef(null);

  const closedOnlyShippingModal = useSelector(
    (state) => state.appSession.closedOnlyShippingModal
  );

  const closedPromoModal = useSelector(
    (state) => state.appSession.closedPromoModal
  );

  const [borderLineHeight, setBorderLineHeight] = useState(0);
  const [openOnlyShippingModal, setOpenOnlyShippingModal] = useState(true);
  const [openPromoModal, setOpenPromoModal] = useState(false);

  const currentWindowSize = useWindowSize();

  useEffect(() => {
    const step1Location = step1.current.getBoundingClientRect();
    const step4Location = step4.current.getBoundingClientRect();
    setBorderLineHeight(step4Location.top - step1Location.top);
  }, [currentWindowSize]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Modal
        backgroundColor="bg-purple no-scrollbar"
        closeModalFunction={() => {
          setOpenOnlyShippingModal(false);
          dispatch(userClosedOnlyShippingModal());
          Mixpanel.track(Mixpanel.TYPES.HOME_CERRO_MODAL_INICIO);
          /*setTimeout(() => {
            setOpenPromoModal(true);
          }, 2000); Uncomment this to enable promo banner */
        }}
        open={openOnlyShippingModal && !closedOnlyShippingModal}
      >
        <div className="w-full flex flex-wrap items-center justify-center py-8 px-8 sm:py-8 sm:px-8 md:py-16 md:px-20 no-scrollbar">
          <p className="w-full text-center uppercase font-title font-bold text-2xl sm:text-3xl md:text-4xl lg:text-6xl text-white">
            ¡Bienvenidos!
          </p>
          <div className="w-full flex justify-center mt-4">
            <img
              className="w-60 brightness-[300]"
              src={logo}
              alt="Logo TiColitas"
            />
          </div>
          <div className="w-full flex justify-center mt-4">
            <p className="text-white text-center text-base md:text-lg lg:text-2xl">
              Gracias por visitarnos. Queremos diferenciarnos por brindarte el
              mejor servicio, por esta razón, las entregas a domicilio las
              estaremos realizando en zonas particulares. Esperamos pronto
              llegar a la puerta de tu casa. Por favor consulta en el whastapp{" "}
              <a
                className="font-bold"
                target="_blank"
                rel="noreferrer"
                href="https://api.whatsapp.com/send?phone=50683133113"
              >
                8313-3113
              </a>, las zonas y entregas programadas que realizamos en diferentes
              partes de San José, Alajuela y Heredia.
            </p>
          </div>
        </div>
      </Modal>
      <Modal
        backgroundColor="bg-purple"
        closeModalFunction={() => {
          setOpenPromoModal(false);
          dispatch(userClosedPromoModal());
          Mixpanel.track(Mixpanel.TYPES.HOME_CERRAR_PROMO);
        }}
        open={openPromoModal && !closedPromoModal}
      >
        <PromoBanner />
      </Modal>
      <div className="flex bg-yellow bg-hero-image bg-contain w-full sm:pt-16">
        <div className="w-1/3 self-end">
          <img className="max-h-[400px]" src={heroCat} alt="Gato TiColitas" />
        </div>
        <div className="w-1/2 p-5 md:p-10">
          <Link
            onClick={() => {
              Mixpanel.track(Mixpanel.TYPES.BANNER_HOME_IR_A_TIENDA_BANNER);
            }}
            to="/tienda"
          >
            <button className="bg-dog rounded-full text-xl sm:text-xl md:text-3xl lg:text-5xl py-3 px-6 sm:px-8 text-white font-bold mb-4">
              Ir a la tienda
            </button>
          </Link>
          <p className="font-body tracking-widest text-white text-2xl sm:text-4xl md:text-5xl lg:text-7xl">
            Seguro,
            <br /> fácil y <br />
            <span className="font-title">confiable</span>
          </p>
        </div>
        <div className="w-1/3 self-end">
          <img
            className="max-h-[400px] float-right"
            src={heroDog}
            alt="Perro TiColitas"
          />
        </div>
      </div>
      <div className="bg-cyan bg-confetti bg-contain p-content pt-12">
        <div className="w-full text-center text-white text-3xl sm:text-4xl mb-10">
          <p className="font-title">¿Cómo funciona Ticolitas?</p>
        </div>
        <div>
          <div className="w-full flex items-center">
            <div className="w-5/12 px-4">
              <p className="text-white text-xl text-right">
                Si sos nuevo acá,{" "}
                <span className="font-bold">completá tu perfil</span>, esto nos
                ayudará a darte el mejor servicio.
              </p>
              <Link
                onClick={() => {
                  Mixpanel.track(Mixpanel.TYPES.WIZARD_IR_A_CREAR_CUENTA);
                }}
                to="/registro"
              >
                <button className="bg-white text-cyan text-sm sm:text-base px-4 sm:px-12 py-3 my-3 rounded-full font-bold float-right">
                  Crear cuenta
                </button>
              </Link>
            </div>
            <div className="w-1/12 flex justify-center">
              <div
                ref={step1}
                className="bg-white w-9 h-9 md:w-11 md:h-11 rounded-full flex items-center justify-center z-10"
              >
                <span className="text-cyan font-bold text-xl md:text-2xl">
                  1
                </span>
              </div>
              <div
                style={{ height: `${borderLineHeight}px` }}
                className="bg-dotted bg-right bg-[length:1px_20px] bg-repeat-y w-1 absolute"
              />
            </div>
            <div className="w-5/12" />
          </div>
          <div className="w-full flex items-center md:my-12">
            <div className="w-5/12" />
            <div className="w-1/12 flex justify-center">
              <div className="bg-white w-9 h-9 md:w-11 md:h-11 rounded-full flex items-center justify-center z-10 float-right">
                <span className="text-cyan font-bold text-xl md:text-2xl">
                  2
                </span>
              </div>
            </div>
            <div className="w-5/12 px-4">
              <p className="text-white text-xl md:text-2xl">
                <span className="font-bold">Buscá el alimento</span> favorito de
                tu mascota y agregalo a tu tacita de compra.
              </p>
            </div>
          </div>
          <div className="w-full flex items-center md:my-12">
            <div className="w-5/12 px-4">
              <p className="text-white text-xl text-right">
                Podés elegir entre una{" "}
                <span className="font-bold">entrega verde a domicilio</span> o
                te daremos un código QR para{" "}
                <span className="font-bold">recoger tu pedido (“pick-up”)</span>{" "}
                desde la comodidad de tu vehículo en una de nuestras tiendas,
                sin la necesidad de bajarte.
              </p>
            </div>
            <div className="w-1/12 flex justify-center">
              <div className="bg-white w-9 h-9 md:w-11 md:h-11 rounded-full flex items-center justify-center z-10">
                <span className="text-cyan font-bold text-xl md:text-2xl">
                  3
                </span>
              </div>
            </div>
            <div className="w-5/12 md:pl-14">
              <img src={wizardStep3} alt="Perro comiendo" />
            </div>
          </div>
          <div className="w-full flex items-center md:mt-12">
            <div className="w-5/12 self-end">
              <img src={wizardBottomImg} alt="Gato y perro jugando" />
            </div>
            <div className="w-1/12 flex justify-center">
              <div
                ref={step4}
                className="bg-white w-9 h-9 md:w-11 md:h-11 rounded-full flex items-center justify-center z-10 float-right"
              >
                <span className="text-cyan font-bold text-xl md:text-2xl">
                  4
                </span>
              </div>
            </div>
            <div className="w-5/12 px-4 pb-8">
              <p className="text-white text-xl md:text-2xl">
                Si nos autorizás{" "}
                <span className="font-bold">TiColitas te podrá notificar</span>{" "}
                cuando tus mascotas necesiten de nuevo su comida favorita para
                que no tengás que estar en apuros a la hora de alimentarlos.{" "}
                {/*
                <span onClick={() => { Mixpanel.track(Mixpanel.TYPES.WIZARD_VER_VIDEO_BENEFICIOS); }} className="text-white font-bold cursor-pointer">
                  Conocé aquí como te beneficias con esto.
                </span>
                  */}
              </p>
              <Link
                onClick={() => {
                  Mixpanel.track(Mixpanel.TYPES.WIZARD_IR_A_TIENDA);
                }}
                to="/tienda"
              >
                <button className="bg-white text-cyan my-3 text-sm sm:text-base px-4 sm:px-12 py-3  rounded-full font-bold">
                  Ir a la tienda
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <AboutUsBanner />
    </div>
  );
}

export default HomePage;
