import mixpanel from "mixpanel-browser";

mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN, {
  persistence: "localStorage",
  secure_cookie: true,
});

const Mixpanel = {
  identify: (id) => {
    mixpanel.identify(id);
  },
  alias: (id) => {
    mixpanel.alias(id);
  },
  track: (name, props) => {
    mixpanel.track(name, props);
  },
  people: {
    set: (props) => {
      mixpanel.people.set(props);
    },
  },
  TYPES: {
    //HOME
    HOME_CERRO_MODAL_INICIO: "HOME_CERRO_MODAL_INICIO",
    HOME_CERRAR_PROMO: "HOME_CERRAR_PROMO",
    //HEADER
    HEADER_IR_A_TIENDA_BIENVENIDO: "HEADER_IR_A_TIENDA_BIENVENIDO",
    HEADER_IR_A_OPCION_MENU: "HEADER_IR_A_OPCION_MENU",
    //SEARCH_BAR
    SEARCH_BAR_IR_A_HOME: "SEARCH_BAR_IR_A_HOME",
    SEARCH_BAR_BUSCAR: "SEARCH_BAR_BUSCAR",
    SEARCH_BAR_ABRIR_TACITA: "SEARCH_BAR_ABRIR_TACITA",
    SEARCH_BAR_ABRIR_MENU_CUENTA: "SEARCH_BAR_ABRIR_MENU_CUENTA",
    SEARCH_BAR_CERRAR_SESION: "SEARCH_BAR_CERRAR_SESION",
    SEARCH_BAR_IR_A_INICIAR_SESION: "SEARCH_BAR_IR_A_INICIAR_SESION",
    //BANNER_HOME
    BANNER_HOME_IR_A_TIENDA_BANNER: "BANNER_HOME_IR_A_TIENDA_BANNER",
    //WIZARD
    WIZARD_IR_A_CREAR_CUENTA: "WIZARD_IR_A_CREAR_CUENTA",
    WIZARD_IR_A_TIENDA: "WIZARD_IR_A_TIENDA",
    WIZARD_VER_VIDEO_BENEFICIOS: "WIZARD_VER_VIDEO_BENEFICIOS",
    //BANNER_ABOUT_US
    BANNER_ABOUT_US_IR_A_ACERCA_DE: "BANNER_ABOUT_US_IR_A_ACERCA_DE",
    //FOOTER
    FOOTER_IR_A_HOME: "FOOTER_IR_A_HOME",
    FOOTER_LLAMAR_OFICINA: "FOOTER_LLAMAR_OFICINA",
    FOOTER_LLAMAR_CELULAR: "FOOTER_LLAMAR_CELULAR",
    FOOTER_WAZE: "FOOTER_WAZE",
    FOOTER_FACEBOOK: "FOOTER_FACEBOOK",
    FOOTER_INSTAGRAM: "FOOTER_INSTAGRAM",
    FOOTER_WHATSAPP: "FOOTER_WHATSAPP",
    FOOTER_ABRIR_TERMINOS_CONDICIONES: "FOOTER_ABRIR_TERMINOS_CONDICIONES",
    //TACITA
    TACITA_CERRAR: "TACITA_CERRAR",
    TACITA_REDUCIR_CANTIDAD_PRODUCTO: "TACITA_REDUCIR_CANTIDAD_PRODUCTO",
    TACITA_ERROR_REDUCIR_CANTIDAD_PRODUCTO: "TACITA_ERROR_REDUCIR_CANTIDAD_PRODUCTO",
    TACITA_AUMENTAR_CANTIDAD_PRODUCTO: "TACITA_AUMENTAR_CANTIDAD_PRODUCTO",
    TACITA_ERROR_AUMENTAR_CANTIDAD_PRODUCTO: "TACITA_ERROR_AUMENTAR_CANTIDAD_PRODUCTO",
    TACITA_SEGUIR_COMPRANDO: "TACITA_SEGUIR_COMPRANDO",
    TACITA_FINALIZAR_COMPRA: "TACITA_FINALIZAR_COMPRA",
    TACITA_LIMPIAR_CARRITO: "TACITA_LIMPIAR_CARRITO",
    TACITA_IR_A_PRODUCTO: "TACITA_IR_A_PRODUCTO",
    //ACCOUNT_MENU
    ACCOUNT_MENU_IR_A_MI_CUENTA: "ACCOUNT_MENU_IR_A_MI_CUENTA",
    ACCOUNT_MENU_CERRAR_SESION: "ACCOUNT_MENU_CERRAR_SESION",
    //TERMS_AND_CONDITIONS
    TERMS_AND_CONDITIONS_CERRAR: "TERMS_AND_CONDITIONS_CERRAR",
    //STORE
    STORE_IR_A_CATEGORIA_PRINCIPAL: "STORE_IR_A_CATEGORIA_PRINCIPAL",
    STORE_IR_A_SUB_CATEGORIA: "STORE_IR_A_SUB_CATEGORIA",
    STORE_IR_A_MARCA: "STORE_IR_A_MARCA",
    STORE_IR_A_TODAS_LAS_MARCAS: "STORE_IR_A_TODAS_LAS_MARCAS",
    STORE_VER_PRODUCTO: "STORE_VER_PRODUCTO",
    STORE_SEGUI_BAJANDO: "STORE_SEGUI_BAJANDO",
    STORE_ENVIAR_SOLICITUD_PRODUCTO: "STORE_ENVIAR_SOLICITUD_PRODUCTO",
    STORE_REDUCIR_CANTIDAD_PRODUCTO: "STORE_REDUCIR_CANTIDAD_PRODUCTO",
    STORE_AUMENTAR_CANTIDAD_PRODUCTO: "STORE_AUMENTAR_CANTIDAD_PRODUCTO",
    STORE_REDUCIR_GRAMOS_DOSIS_DIARIA: "STORE_REDUCIR_GRAMOS_DOSIS_DIARIA",
    STORE_AUMENTAR_GRAMOS_DOSIS_DIARIA: "STORE_AUMENTAR_GRAMOS_DOSIS_DIARIA",
    STORE_AUTORIZA_WHATSAPP: "STORE_AUTORIZAR_WHATSAPP",
    STORE_INGRESAR_MASCOTA_PEDIDO: "STORE_INGRESAR_MASCOTA_PEDIDO",
    STORE_VER_VIDEO_BENEFICIOS: "STORE_VER_VIDEO_BENEFICIOS",
    STORE_PRODUCTO_AGREGAR_A_LA_TACITA: "STORE_PRODUCTO_AGREGAR_A_LA_TACITA",
    STORE_BUSQUEDA_IR_A_NAVEGAR_TIENDA: "STORE_BUSQUEDA_IR_A_NAVEGAR_TIENDA",
    //CHECKOUT
    CHECKOUT_REDUCIR_CANTIDAD_PRODUCTO: "CHECKOUT_REDUCIR_CANTIDAD_PRODUCTO",
    CHECKOUT_AUMENTAR_CANTIDAD_PRODUCTO: "CHECKOUT_AUMENTAR_CANTIDAD_PRODUCTO",
    CHECKOUT_CAMBIAR_DELIVERY_A_PICKUP: "CHECKOUT_CAMBIAR_DELIVERY_A_PICKUP",
    CHECKOUT_LOGIN: "CHECKOUT_LOGIN",
    CHECKOUT_REGISTRARSE: "CHECKOUT_REGISTRARSE",
    CHECKOUT_OLVIDO_CONTRASENIA: "CHECKOUT_OLVIDO_CONTRASENIA",
    CHECKOUT_OLVIDO_CONTRASENIA_INICIO: "CHECKOUT_OLVIDO_CONTRASENIA_INICIO",
    CHECKOUT_OLVIDO_CONTRASENIA_FINAL: "CHECKOUT_OLVIDO_CONTRASENIA_FINAL",
    CHECKOUT_SELECCIONA_METODO_ENTREGA: "CHECKOUT_SELECCIONA_METODO_ENTREGA",
    CHECKOUT_SELECCIONA_TIENDA: "CHECKOUT_SELECCIONA_TIENDA",
    CHECKOUT_SELECCIONA_DIRECCION: "CHECKOUT_SELECCIONA_DIRECCION",
    CHECKOUT_SELECCIONA_FACTURACION: "CHECKOUT_SELECCIONA_FACTURACION",
    CHECKOUT_SELECCIONA_METODO_PAGO: "CHECKOUT_SELECCIONA_METODO_PAGO",
    CHECKOUT_ACEPTA_TERMINOS: "CHECKOUT_ACEPTA_TERMINOS",
    CHECKOUT_RE_AUTORIZA_WHATSAPP: "CHECKOUT_RE_AUTORIZA_WHATSAPP",
    CHECKOUT_IMPRIME_FACTURA: "CHECKOUT_IMPRIME_FACTURA",
    CHECKOUT_FINALIZA_COMPRA: "CHECKOUT_FINALIZA_COMPRA",
    //LOGIN
    LOGIN_INICIAR_SESION: "LOGIN_INICIAR_SESION",
    LOGIN_ERROR_INICIAR_SESION: "LOGIN_ERROR_INICIAR_SESION",
    LOGIN_OLVIDO_CONTRASENIA: "LOGIN_OLVIDO_CONTRASENIA",
    LOGIN_SOLICITA_TOKEN: "LOGIN_SOLICITA_TOKEN",
    LOGIN_SOLICITA_TOKEN_ERROR: "LOGIN_SOLICITA_TOKEN_ERROR",
    LOGIN_RESTAURA_CONTRASENIA: "LOGIN_RESTAURA_CONTRASENIA",
    LOGIN_RESTAURA_CONTRASENIA_ERROR: "LOGIN_RESTAURA_CONTRASENIA_ERROR",
    //SIGNUP
    SIGNUP_REGISTRO_NUEVO: "SIGNUP_REGISTRO_NUEVO",
    SIGNUP_REGISTRO_FALLIDO: "SIGNUP_REGISTRO_FALLIDO",
  },
};

export default Mixpanel;