import React, { useEffect, useState } from "react";
import _ from "lodash";
import { Link } from "react-router-dom";
import NotFoundForm from "../../components/NotFoundForm";
import Spinner from "../../components/Spinner";
import useAuthFetch from "../../hooks/useAuthFetch";

const canSeeHiddenCategories = (user) => {
  if (user && user.token) {
    if (
      _.intersection(user.roles, [
        "admin",
        "dependiente",
        "administradorTienda",
      ]).length > 0 ||
      user.roles.indexOf("superAdmin") > -1
    ) {
      return true;
    }
  }
  return false;
};

export function TiendaPage({ user }) {
  const authFetch = useAuthFetch();
  const [categories, setCategories] = useState(null);

  useEffect(() => {
    fetchMainCategories();
    window.scrollTo(0, 0);
  }, []);

  const fetchMainCategories = async () => {
    const data = await authFetch(
      `categoria-categoria-padre?filter={
        "where": {
          "idCategoriaPadre": null
        },
        "include": [
          {
            "relation": "categoria"
          }
        ]
      }`
    );
    const dataJSON = await data.json();
    setCategories(dataJSON);
  };

  return (
    <div>
      {!categories ? (
        <div className="w-full p-content pt-4 pb-16 min-h-[400px] bg-white">
          <Spinner />
        </div>
      ) : (
        <div className="w-full p-content pt-4 pb-16">
          <div className="flex flex-wrap gap-2 mt-10">
            {categories.map((cat, index) => {
              if (cat.categoria.principal || canSeeHiddenCategories(user)) {
                return (
                  <div
                    key={`category_${index}`}
                    className={`cursor-pointer w-1/2 flex flex-[0_0_calc(50%-0.5rem)] items-center ${cat.categoria.colorFondo} py-4 px-8 rounded-3xl`}
                  >
                    <Link className="w-full" to={`/tienda/categoria/${cat.id}`}>
                      <div className={`flex items-center`}>
                        <div className="w-8/12 md:w-7/12">
                          <p className="text-white font-title text-lg sm:text-2xl md:text-3xl uppercase">
                            {cat.categoria.nombre}
                          </p>
                        </div>
                        <div className="w-4/12 md:w-5/12 pl-2 flex justify-end">
                          <img
                            className="max-h-40"
                            src={cat.categoria.imagen}
                            alt={`Categoria ${cat.categoria.nombre}`}
                          />
                        </div>
                      </div>
                    </Link>
                  </div>
                );
              }
              return null;
            })}
          </div>
        </div>
      )}
      <NotFoundForm />
    </div>
  );
}

export default TiendaPage;
