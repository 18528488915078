import React, { useEffect, useRef, useState } from "react";

export const SearchProductOrden = ({
	products,
	setProduct,
	product,
	conPrecio,
	setPrecioUnitario,
}) => {
	const [showSelect, setShowSelect] = useState(false);
	const ulRef = useRef(null);
	const [options, setOptions] = useState(products || []);
	useEffect(() => {
		document.addEventListener("mousedown", closeSelect);
	}, []);

	const closeSelect = (evt) => {
		if (ulRef && ulRef.current && !ulRef.current.contains(evt.target)) {
			setShowSelect(false);
		}
	};

	const onSearch = (e) => {
		setProduct({
			...product,
			nombre: e.target.value,
		});
		setShowSelect(true);

		setOptions(
			products.filter((i) =>
				i.nombre.toLowerCase().includes(product.nombre.toLowerCase())
				|| i.codigoProveedor.toLowerCase().includes(product.nombre.toLowerCase())
			)
		);
	};

	return (
		<div className="my-2 relative">
			<input
				onChange={onSearch}
				className="w-full rounded-3xl my-2 h-12 p-6 border border-purple outline-none"
				type={"text"}
				placeholder="Seleccione un producto"
				name="product"
				value={product.nombre}
			/>

			{showSelect && (
				<div className="absolute mt-1 w-full min-h-[3rem] z-30 rounded-3xl bg-white shadow-lg none border border-purple">
					<ul
						ref={ulRef}
						className="max-h-56 rounded-md py-1 text-base leading-6 shadow-xs overflow-auto focus:outline-none sm:text-sm sm:leading-5"
					>
						{options.map((o, index) => {
							return (
								<li
									key={`option_${index}`}
									id="assigned-to-option-1"
									className="text-gray-900 cursor-default select-none relative py-2 pl-4 pr-9"
									onClick={() => {
										setShowSelect(false);
										setProduct({
											idProducto: o.id,
											nombre:
												o.codigoProveedor +
												" - " +
												o.marca.nombre +
												" - " +
												o.nombre +
												" - " +
												o.pesoGramos / 1000 +
												" kg",
											fotoPrincipal: o.fotoPrincipal,
										});
										conPrecio &&
											setPrecioUnitario(
												o.hasOwnProperty("lineasOrdenCompras") &&
													o.lineasOrdenCompras[0].precioUnitario
											);
									}}
								>
									<div className="flex items-center space-x-3">
										<span className="font-normal block truncate text-black">
											{o.nombre
												? `${o.codigoProveedor} -  ${o.marca.nombre} - ${
														o.nombre
												  } - ${o.pesoGramos / 1000}Kg`
												: "Producto no encontrado"}
										</span>
									</div>
									{product.idProducto === o.id && (
										<span className="absolute inset-y-0 right-0 flex items-center pr-4 text-indigo-600 none">
											<svg
												className="h-5 w-5"
												fill="rgb(118 35 108)"
												viewBox="0 0 20 20"
											>
												<path
													fillRule="evenodd"
													d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
													clipRule="evenodd"
												></path>
											</svg>
										</span>
									)}
								</li>
							);
						})}
					</ul>
				</div>
			)}
		</div>
	);
};
