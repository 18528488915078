import React, { useEffect, useRef, useState } from "react";

export const SearchProduct = ({
	products,
	setProduct,
	product,
	notQuantity = false,
	allProducts = false
}) => {
	const [showSelect, setShowSelect] = useState(false);
	const ulRef = useRef(null);
	const [options, setOptions] = useState([]);
	const [searchValue, setSearchValue] = useState("");

	
	useEffect(() => {
		document.addEventListener("mousedown", closeSelect);
	}, []);

	useEffect(() => {
		setOptions(products);
	}, [products]);

	const closeSelect = (evt) => {
		if (ulRef && ulRef.current && !ulRef.current.contains(evt.target)) {
			setShowSelect(false);
		}
	};

	const onSearch = (e) => {
		setSearchValue(e.target.value);
		setProduct(null);
		setShowSelect(true);
		
		if(allProducts){
			setOptions(
				products.filter((i) =>
					i.nombre?.toLowerCase().includes(searchValue.toLowerCase())
					|| i.codigoProveedor?.toLowerCase().includes(searchValue.toLowerCase())
					|| i.marca.nombre?.toLowerCase().includes(searchValue.toLowerCase())
				)
			);
		}else{
			setOptions(
				products.filter((i) => {
					if (i && i.nombre) {
						return (i.marca + " " + i.nombre)
							.toLowerCase()
							.includes(e.target.value.toLowerCase());
					}
					return false;
				})
			);
		}
	};

	return (
		<div className="my-2 relative">
			<input
				onChange={onSearch}
				className="w-full rounded-3xl my-2 h-12 p-6 border border-purple outline-none"
				type={"text"}
				placeholder="Seleccione un producto"
				name="product"
				value={
					searchValue
						? searchValue
						: product
						? ` ${
								notQuantity
									? allProducts ? `${product.codigoProveedor} - ${product.marca.nombre} - ${product.nombre}` : `${product.marca} - ${product.nombreProducto} ${product.etiquetaPeso}`
									: `(Cant disp. ${product.cantidadEnBodega}) ${product.marca} - ${product.nombre} ${product.etiquetaPeso}`
						  } `
						: ""
				}
				onFocus={() => {
					setShowSelect(true);
				}}
			/>

			{showSelect && (
				<div className="absolute mt-1 w-full min-h-[3rem] z-30 rounded-3xl bg-white shadow-lg none border border-purple">
					<ul
						ref={ulRef}
						className="max-h-56 rounded-md py-1 text-base leading-6 shadow-xs overflow-auto focus:outline-none sm:text-sm sm:leading-5"
					>
						{options.map((o, index) => {
							return (
								<li
									key={`option_${index}`}
									id="assigned-to-option-1"
									className="text-gray-900 cursor-default select-none relative py-2 pl-4 pr-9"
									onClick={() => {
										setShowSelect(false);
										setSearchValue("");
										setProduct(o);
									}}
								>
									<div className="flex items-center space-x-3">
										<span className="font-normal block truncate text-black">
											{notQuantity ? (
												<p>
													{allProducts ? `${o.codigoProveedor} - ${o.marca.nombre} - ${o.nombre}`: `${o.marca} - ${o.nombreProducto} - ${o.etiquetaPeso}` }
												</p>
											) : (
												<p>
													(Cant disp. {o.cantidadEnBodega}) - {o.marca} -{" "}
													{o.nombre} {o.etiquetaPeso}
												</p>
											)}
										</span>
									</div>
									{product && product.idProducto === o.idProducto && (
										<span className="absolute inset-y-0 right-0 flex items-center pr-4 text-indigo-600 none">
											<svg
												className="h-5 w-5"
												fill="rgb(118 35 108)"
												viewBox="0 0 20 20"
											>
												<path
													fillRule="evenodd"
													d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
													clipRule="evenodd"
												></path>
											</svg>
										</span>
									)}
								</li>
							);
						})}
					</ul>
				</div>
			)}
		</div>
	);
};
