const USUARIOS_TICOLITAS = [5, 2415, 2416, 20];
const UNIDAD_MEDIDA_UNIDAD = 1;
const DEFAULT_POSITION_IN_SHIPPING_ORDER = 99999;

const METODOS_PAGO = {
  TARJETA: 1,
  EFECTIVO: 2,
  SINPE: 3,
  TRANSFERENCIA: 4,
};

const METODOS_ENTREGA = {
  LOCAL: 1,
  PICKUP: 2,
  A_DOMICILIO: 3,
};

const TIENDAS = {
  TICOLITAS_GUACIMA: 1,
};

const BODEGAS = {
  TICOLITAS_GUACIMA: 1,
};

const ESTADOS_ORDENES = {
  PREPARANDO_ORDEN: 14,
  LISTO_EN_BODEGA: 15,
  EN_CAMINO: 16,
  ENTREGADA: 17,
  PENDIENTE_PAGO: 18,
  FINALIZADA: 19,
  CANCELADA: 20,
  EN_APROBACION_POR_ASOCIACION: 125,
  RECHAZADA_POR_ASOCIACION: 126,
};

const CATALOGOS = {
  UNIDAD_DE_MEDIDA: 1,
  TIPO_IDENTIFICACION: 7,
  ESTADO_DE_VENTA: 13,
  INDICACIONES: 13,
  GENERO: 100,
  ESTADO_VACUNACION: 103,
  TIPO_UNIDAD_TRANSPORTE: 107,
  TIPO_LICENCIA: 110,
  ESTADO_RUTA: 115,
  PAIS: 120,
  SABORES: 123,
  PROTEINA: 124,
};

const CODIGO_IMPUESTO = [
  {
    descripción: "Impuesto al valor agregado",
    valor: 1,
  },
  {
    descripción: "Impuesto selectivo de consumo",
    valor: 2,
  },
  {
    descripción: "Impuesto único a los combustibles",
    valor: 3,
  },
  {
    descripción: "Impuesto específico de bebidas alcohólicas",
    valor: 4,
  },
  {
    descripción:
      "Impuesto específico sobre las bebidas envasadas sin contenido alcohólico y jabones de tocador",
    valor: 5,
  },
  {
    descripción: "Impuesto a los productos de tabaco",
    valor: 6,
  },
  {
    descripción: "IVA (cálculo especial)",
    valor: 7,
  },
  {
    descripción: "IVA régimen de bienes usados (factor)",
    valor: 8,
  },
  {
    descripción: "Impuesto específico al cemento",
    valor: 12,
  },
  {
    descripción: "Otros",
    valor: 99,
  },
];

const CODIGO_TARIFA = [
  {
    descripción: "Tarifa general 13%",
    valor: 8,
  },
  {
    descripción: "Tarifa 0% (exento)",
    valor: 1,
  },
  {
    descripción: "Tarifa reducida 1%",
    valor: 2,
  },
  {
    descripción: "Tarifa reducida 2%",
    valor: 3,
  },
  {
    descripción: "Tarifa reducida 4%",
    valor: 4,
  },
  {
    descripción: "Transitorio 0%",
    valor: 5,
  },
  {
    descripción: "Transitorio 4%",
    valor: 6,
  },
  {
    descripción: "Transitorio 8%",
    valor: 7,
  },
];

const ESTADO_ORDEN_COMPRA = [
  { id: 131, valor: "Ingresada por TiColitas" },
  { id: 132, valor: "Recibida" },
  { id: 133, valor: "Pendiente de Pago" },
  { id: 135, valor: "Finalizada" },
];

const METODOS_PAGO_DESC = {
  1: "Pago con tarjeta contra entrega",
  2: "Pago en efectivo contra entrega",
  3: "SINPE Móvil",
  4: "Transferencia Bancaria",
  5: "Pago con AsoAZ",
};

const INPUTS_VALORES = [
  { id: 150, valor: "Foto principal" },
  { id: 151, valor: "Botón" },
];

const CATEGORIAS = {
  ALIMENTO_SECO: 9,
};

const COMPRA_EN_TIENDA = 153;
const COMPRA_POR_HUGUITO = 154;
const COMPRA_EN_LINEA = 155;
const COMPRA_POR_WHATSAPP = 156;

const HILL_PAWS = [807, 4270, 808, 806, 809, 848, 849];

export {
  HILL_PAWS,
  USUARIOS_TICOLITAS,
  METODOS_ENTREGA,
  METODOS_PAGO,
  METODOS_PAGO_DESC,
  ESTADOS_ORDENES,
  UNIDAD_MEDIDA_UNIDAD,
  DEFAULT_POSITION_IN_SHIPPING_ORDER,
  CATALOGOS,
  BODEGAS,
  TIENDAS,
  CODIGO_IMPUESTO,
  CODIGO_TARIFA,
  CATEGORIAS,
  INPUTS_VALORES,
  ESTADO_ORDEN_COMPRA,
  COMPRA_EN_LINEA,
  COMPRA_EN_TIENDA,
  COMPRA_POR_WHATSAPP,
  COMPRA_POR_HUGUITO,
};
