import React from "react";
import { useContext } from "react";
import { CierreContext } from "../../containers/CierresPage";
import { getCurrentDay } from "../../utils/formatDates";

import { BeatLoader } from "react-spinners";
import Select from "../Select";

export default function CierreForm() {
  const {
    filter,
    setFilter,
    transportistaValue,
    fechaValue,
    setFechaValue,
    setTransportistaValue,
    transportistas,
    actualizarCierre,
    loadingTransportistas,
    setCierre,
  } = useContext(CierreContext);

  const handleSubmit = async (e) => {
    e.preventDefault();
    await actualizarCierre();
  };

  return (
    <form className="flex flex-col gap-4 mb-4" onSubmit={handleSubmit}>
      <div className="flex gap-3">
        <button
          type="button"
          className={`${filter === "transportista" && "text-purple font-bold"}`}
          onClick={() => setFilter("transportista")}
        >
          Buscar por transportista
        </button>
        <button
          type="button"
          className={`${filter === "tienda" && "text-purple font-bold"}`}
          onClick={() => setFilter("tienda")}
        >
          Buscar por tienda
        </button>
        <button
          type="button"
          className={`${filter === "dia" && "text-purple font-bold"}`}
          onClick={() => {
            setCierre(null);
            setFilter("dia");
          }}
        >
          Total del día
        </button>
      </div>

      <div className="flex flex-col gap-2">
        <label>Fecha:</label>
        <input
          type={"date"}
          max={getCurrentDay()}
          value={fechaValue}
          required={true}
          onChange={(e) => setFechaValue(e.target.value)}
          className="border-purple outline-purple p-4 rounded-full border"
        />
      </div>

      {filter === "transportista" && (
        <div className="flex flex-col gap-2">
          <label>Transportista:</label>

          {loadingTransportistas ? (
            <BeatLoader size={20} color={"#76236C"} />
          ) : (
            <Select
              className="p-4 border-purple border outline-purple rounded-full w-full pr-2"
              value={transportistaValue}
              containerClasses="my-2"
              labelColor="text-base"
              buttonClasses="border border-purple"
              onChange={(value) => setTransportistaValue(value)}
              options={transportistas.map((transportista) => ({
                label: transportista.nombre,
                value: transportista.id,
              }))}
            />
          )}
        </div>
      )}

      {filter === "tienda" && (
        <div className="flex flex-col gap-2">
          <label>Tienda:</label>

          <Select
            className="p-4 border-purple border outline-purple rounded-full w-full pr-2"
            value={transportistaValue}
            containerClasses="my-2"
            labelColor="text-base"
            buttonClasses="border border-purple"
            onChange={(value) => setTransportistaValue(value)}
            options={[
              {
                label: "Ticolitas - La Guácima",
                value: 1,
              },
            ]}
          />
        </div>
      )}

      <div className="flex justify-end">
        <button className="bg-purple text-white px-4 py-3 rounded-full mt-2">
          Buscar Cierre
        </button>
      </div>
    </form>
  );
}
