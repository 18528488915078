import React from "react";
import { Link } from "react-router-dom";
import Mixpanel from "../../services/mixpanel";

export function AboutUsBanner() {
  return (
    <div className="bg-pink p-content py-16 ">
      <div className="w-full text-center text-white text-3xl sm:text-4xl mb-10">
        <p className="font-title">TE INVITAMOS A CONOCER MÁS DE TICOLITAS</p>
      </div>
      <div className="w-full text-center">
        <Link onClick={() => { Mixpanel.track(Mixpanel.TYPES.BANNER_ABOUT_US_IR_A_ACERCA_DE); }} to="/acerca-de">
          <button className="text-pink bg-white rounded-full px-12 py-3">
            Acerca de
          </button>
        </Link>
      </div>
    </div>
  );
}

export default AboutUsBanner;
