import React from "react";

export function ProductName({
  product,
  color,
  additionalStyle,
  showPrescription,
}) {
  return (
    <>
      <p
        className={`${
          color || "text-listingProductGray"
        } text-xl ${additionalStyle}`}
      >
        {product.marca} {product.nombreTienda}{" "}
      </p>
      {product.pesoGramos && (
        <p
          className={`${
            color || "text-listingProductGray"
          } text-xl mt-2 ${additionalStyle}`}
        >
          Presentación:{" "}
          {product.pesoGramos < 1000
            ? `${product.pesoGramos} gramos`
            : `${(product.pesoGramos / 1000).toFixed(2)} Kg`}{" "}
        </p>
      )}
      {showPrescription && product.prescrito === 1 && (
        <p
          className={`${color || "text-pink"} text-md mt-2 ${additionalStyle}`}
        >
          Si es la primera vez que le suministrarás a tu mascota este alimento
          es importante la recomendación de tu veterinario de confianza.
        </p>
      )}
    </>
  );
}

export default ProductName;
