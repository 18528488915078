import {
  DirectionsRenderer,
  GoogleMap,
  Marker,
  useJsApiLoader,
} from "@react-google-maps/api";
import React, { useCallback } from "react";

export default function Map({ origin, route, markers, finalStop }) {
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  });

  const containerStyle = {
    width: "2000px",
    height: "600px",
  };

  const onLoad = useCallback((map) => {}, []);

  const onUnmount = useCallback((map) => {}, []);

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      origin={origin}
      zoom={7}
      onLoad={onLoad}
      onUnmount={onUnmount}
    >
      <DirectionsRenderer
        directions={route}
        options={{
          polylineOptions: {
            strokeColor: "#FF0000",
          },
          suppressMarkers: true,
        }}
      />

      {origin === finalStop ? (
        <Marker
          position={{
            lat: origin.lat,
            lng: origin.lng,
          }}
          label={{
            text: "O/F",
            color: "#FFF",
          }}
        />
      ) : (
        <>
          <Marker
            position={{
              lat: origin.lat,
              lng: origin.lng,
            }}
            label={{
              text: "O",
              color: "#FFF",
            }}
          />

          <Marker
            position={{
              lat: finalStop.lat,
              lng: finalStop.lng,
            }}
            label={{
              text: "F",
              color: "#FFF",
            }}
          />
        </>
      )}

      {markers.map((marker) => (
        <Marker
          key={marker.label}
          position={{
            lat: marker.lat,
            lng: marker.lng,
          }}
          label={{
            text: marker.label,
            color: "#FFF",
          }}
        />
      ))}
    </GoogleMap>
  ) : (
    <></>
  );
}
