export function getCurrentDay() {
  const today = new Date();
  const yyyy = today.getFullYear();
  let mm = today.getMonth() + 1; // Months start at 0!
  let dd = today.getDate();

  if (dd < 10) dd = "0" + dd;
  if (mm < 10) mm = "0" + mm;

  const formattedToday = yyyy + "-" + mm + "-" + dd;

  return formattedToday;
}

export function formatDate(date) {
  const yyyy = date.getFullYear();
  let mm = date.getMonth() + 1; // Months start at 0!
  let dd = date.getDate();

  if (dd < 10) dd = "0" + dd;
  if (mm < 10) mm = "0" + mm;

  const formattedToday = yyyy + "-" + mm + "-" + dd;

  return formattedToday;
}

export function formatDateSpanishToInputFormat(date) {
  return date.replace(/(\d+)\/(\d+)\/(\d+)/, function (match, dia, mes, anio) {
    // Asegúrate de que el mes y el día tengan dos dígitos
    // agregando un cero a la izquierda si es necesario
    if (mes.length === 1) mes = "0" + mes;
    if (dia.length === 1) dia = "0" + dia;

    // Devuelve la fecha en el nuevo formato
    return anio + "-" + mes + "-" + dia;
  });
}

export function spanishDate(date) {
  const fecha = new Date(date);
  const fechaSpanish = fecha.toLocaleDateString("es-ES");

  return fechaSpanish;
}

export function plusDaysDate(date, days) {
  let fecha = new Date(date);
  fecha.setDate(fecha.getDate() + days);
  const fechaSpanish = fecha.toLocaleDateString("es-ES");
  return fechaSpanish;
}
