import React, { useEffect } from "react";

export function PromoHills() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="bg-white w-full p-content pb-16 pt-2 flex items-center flex-col">
      <h1 className="text-2xl my-8">
        Reglamento Plan Fidelidad 8+1 TiColitas Promoción "Hill’s Premia tu
        Lealtad"
      </h1>
      <a
        href="https://polpo-assets.s3.amazonaws.com/production/ticolitas/promociones/Hill's+-+Reglamento+Cliente+Frecuente+veterinarias_231017_115722.pdf"
        target="_blank"
        rel="noreferrer"
        className="text-purple mb-8"
      >
        <button className="bg-purple text-white py-2 px-8 rounded-full">
          Ver Reglamento de la promoción
        </button>
      </a>
    </div>
  );
}

export default PromoHills;
