import React, { useCallback, useEffect } from "react";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { IoMdArrowRoundBack } from "react-icons/io";
import { BiFile, BiShowAlt } from "react-icons/bi";

import Swal from "sweetalert2";

import CheckboxInput from "../../components/CheckboxInput";
import Select from "../../components/Select";
import Spinner from "../../components/Spinner";
import {
  fileUploadFetch,
} from "../../utils/apiRequest";
import {
  CATALOGOS,
  CODIGO_IMPUESTO,
  CODIGO_TARIFA,
} from "../../utils/constants";
import SelectMultiple from "../../components/Select/Select-multiple";
import { useDispatch, useSelector } from "react-redux";
import { setCategoriesProduct } from "../../slices/inventorySlice";
import useAuthFetch from "../../hooks/useAuthFetch";

export function InventarioProductosNuevoPage({ user }) {
  const { idProducto } = useParams();
  const dispatch = useDispatch();
  const { token } = useSelector((store) => store.app.user);
  const authFetch = useAuthFetch();

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [product, setProduct] = useState({});
  const [productPhoto, setProductPhoto] = useState(null);
  const [tipoCategoria, setTipoCategoria] = useState([]);
  const [catalogs, setCatalogs] = useState({
    brands: [],
    unitsOfMeassure: [],
    categoryLevels: [],
    providers: [],
  });
  const [error, setError] = useState("");
  const [imageDimensions, setImageDimensions] = useState(null);

  const [descuento, setDescuento] = useState({
    activo: false,
    porcentaje: 0,
    validoDesde: "",
    validoHasta: "",
    cantidadDisponible: 0,
    soloEnEfectivo: false,
  });

  const [discountForAll, setDiscountForAll] = useState(false);

  const [isDescuentoActive, setIsDescuentoActive] = useState(false);

  useEffect(() => {
    const fetchCatalogs = async () => {
      setLoading(true);
      const brandsFetch = await authFetch(
        `marcas?filter={"order": ["nombreTienda asc"]}`
      );
      const brands = await brandsFetch.json();

      const unitsOfMeassureFetch = await authFetch(
        `catalogos?filter={"where": { "idCatalogo": ${CATALOGOS.UNIDAD_DE_MEDIDA} }, "order": ["valor asc"]}`
      );
      const unitsOfMeassure = await unitsOfMeassureFetch.json();

      const categoryLevelsFetch = await authFetch(
        `categoria-categoria-padre?filter={
          "where": {
            "idCategoriaPadre": {
              "neq": null
            }
          },
          "order": ["id asc"],
          "include":[{"relation":"nombre"}]
        }`
      );
      const categoryLevels = await categoryLevelsFetch.json();

      const providersFetch = await authFetch(
        `proveedores?filter={
          "where": {"activo": 1},
          "order": ["nombre asc"]}`
      );
      const providers = await providersFetch.json();
      setCatalogs({
        brands,
        unitsOfMeassure,
        categoryLevels,
        providers,
      });
      setLoading(false);
    };
    dispatch(setCategoriesProduct([]));

    fetchCatalogs();
  }, [dispatch]);

  const fetchProduct = useCallback(async () => {
    setTipoCategoria([]);
    const data = await authFetch(
      `productos/${idProducto}?filter={"include": [{"relation":"categorias"},{"relation":"descuentos"}]}`
    );
    const dataJSON = await data.json();
    if (dataJSON.margen) {
      dataJSON.margen = ((dataJSON.margen - 1) * 100).toFixed(2);
    }
    setProduct(dataJSON);
    console.log(dataJSON);

    if (dataJSON.descuentos.length > 0 && dataJSON.descuentos) {
      setIsDescuentoActive(true);
      setDescuento({
        validoDesde:
          dataJSON.descuentos[0].validoDesde &&
          dataJSON.descuentos[0].validoDesde.split("T")[0],
        validoHasta:
          dataJSON.descuentos[0].validaHasta &&
          dataJSON.descuentos[0].validaHasta.split("T")[0],
        cantidadDisponible: dataJSON.descuentos[0].cantidadDisponible,
        activo: descuento.activo,
        porcentaje: dataJSON.descuentos[0].porcentaje * 100,
        soloEnEfectivo: dataJSON.descuentos[0].soloEnEfectivo,
      });
    }

    setLoading(false);
  }, [idProducto]);

  useEffect(() => {
    if (!isNaN(idProducto)) {
      fetchProduct();
      setLoading(true);
    }
    window.scrollTo(0, 0);
  }, [idProducto, fetchProduct]);

  const splitCategories = useCallback(
    (categories) => {
      const categor = [];
      categories.forEach((category) => {
        categor.push(category.id);
      });
      setTipoCategoria([...categor]);
      dispatch(setCategoriesProduct(categor));
    },
    [setTipoCategoria, dispatch]
  );

  useEffect(() => {
    if (product.categorias) {
      splitCategories(product.categorias);
    }
  }, [product, splitCategories]);

  const postProduct = async () => {
    setError(null);

    if (
      isDescuentoActive &&
      !(
        descuento.porcentaje >= 0 &&
        descuento.validoDesde !== "" &&
        descuento.validoHasta !== "" &&
        (descuento.cantidadDisponible >= 0 ||
          descuento.cantidadDisponible === null)
      )
    ) {
      setError("Rellena todos los campos del formulario de descuento.");
      return;
    }

    if (isNaN(idProducto)) {
      if (
        productPhoto &&
        product.nombre &&
        product.unidadMedida &&
        !isNaN(product.margen) &&
        product.nombreTienda &&
        product.idMarca &&
        product.codigoCabys &&
        product.codigoBarras &&
        product.pesoGramos &&
        !isNaN(product.costoTransporte) &&
        product.codigoImpuesto &&
        product.codigoTarifaImpuesto &&
        product.codigoProveedor
      ) {
        if (imageDimensions.height === 800 && imageDimensions.width === 800) {
          if (!product.preescrito) {
            product.preescrito = 0;
          }

          const myNewFile = new File([productPhoto], product.codigoProveedor, {
            type: productPhoto.type,
          });

          sendValuesPost(
            product.nombre,
            product.unidadMedida,
            product.margen,
            product.costoFijo,
            product.nombreTienda,
            product.idMarca,
            product.codigoCabys,
            product.codigoBarras,
            product.pesoGramos,
            product.descripcion,
            product.costoTransporte,
            product.preescrito,
            product.codigoImpuesto,
            product.codigoTarifaImpuesto,
            product.codigoProveedor,
            product.idProveedor,
            tipoCategoria,
            myNewFile
          );
        } else {
          setError(
            "La imagen cargada no cumple con las dimensiones de 800x800 pixeles."
          );
        }
      } else {
        setError(
          "Por favor verifique que todos los campos requeridos (*) estén completos."
        );
      }
    }

    if (!isNaN(idProducto)) {
      if (
        product.nombre &&
        product.unidadMedida &&
        !isNaN(product.margen) &&
        !isNaN(product.costoFijo) &&
        product.idMarca &&
        product.codigoCabys &&
        product.codigoBarras &&
        product.pesoGramos &&
        !isNaN(product.costoTransporte) &&
        product.codigoImpuesto &&
        product.codigoTarifaImpuesto &&
        product.codigoProveedor
      ) {
        if (!product.preescrito) {
          product.preescrito = 0;
        }
        let myNewFile = null;

        if (productPhoto) {
          myNewFile = new File([productPhoto], product.codigoProveedor, {
            type: productPhoto.type,
          });
        }
        if (product.activo === 1) {
          if (product.nombreTienda === "") {
            setError(
              "Por favor verifique que todos los campos requeridos (*) estén completos."
            );
            return;
          }
        }

        sendValuesPatch(
          product.nombre,
          product.unidadMedida,
          product.margen,
          product.costoFijo,
          product.nombreTienda,
          product.idMarca,
          product.codigoCabys,
          product.codigoBarras,
          product.pesoGramos,
          product.descripcion,
          product.costoTransporte,
          product.preescrito,
          product.codigoImpuesto,
          product.codigoTarifaImpuesto,
          product.codigoProveedor,
          product.idProveedor,
          tipoCategoria,
          myNewFile
        );
      } else {
        setError(
          "Por favor verifique que todos los campos requeridos (*) estén completos."
        );
      }
    }
  };

  const handleChage = (v) => {
    setTipoCategoria(v);
  };

  const sendValuesPost = async (
    nombre,
    unidadMedida,
    margen,
    costoFijo,
    nombreTienda,
    idMarca,
    codigoCabys,
    codigoBarras,
    pesoGramos,
    descripcion,
    costoTransporte,
    prescrito,
    codigoImpuesto,
    codigoTarifaImpuesto,
    codigoProveedor,
    idProveedor,
    categoria,
    Photo
  ) => {
    let s3PhotoAddress;

    const precioFijo = 0;
    // let fotoPrincipal = "";

    if (Photo) {
      try {
        const s3PhotoAddressFetch = await fileUploadFetch("upload", Photo);
        if (s3PhotoAddressFetch.status === 200) {
          s3PhotoAddress = await s3PhotoAddressFetch.json();
        }
        // fotoPrincipal = s3PhotoAddress
      } catch (error) {
        console.log(error);
      }
    }

    let request = await authFetch(
      "productos",
      {
        producto: {
          nombre,
          unidadMedida,
          margen: margen / 100 + 1,
          costoFijo,
          precioFijo: !isNaN(precioFijo) && precioFijo > 0 ? precioFijo : null,
          nombreTienda,
          idMarca,
          fotoPrincipal: s3PhotoAddress.s3Address,
          codigoCabys,
          codigoBarras,
          pesoGramos,
          descripcion,
          costoTransporte,
          prescrito,
          codigoImpuesto,
          codigoTarifaImpuesto,
          codigoProveedor,
          idProveedor,
        },
        categoria,
        descuento: {
          ...descuento,
          activo: isDescuentoActive,
          porcentaje: Number(descuento.porcentaje / 100),
          cantidadDisponible: discountForAll
            ? null
            : descuento.cantidadDisponible,
        },
      },
      "POST",
      token
    );

    if (request.status === 200 || request.status === 204) {
      Swal.fire({
        icon: "success",
        title: "Correcto",
        text: "Producto agregado correctamente",
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp",
        },
        background: "#76236C",
        color: "#fafafa",
        confirmButtonColor: "#19D3C5",
      });
      setTimeout(() => {
        navigate("/productos");
      }, 400);
    }

    if (
      request.status === 400 ||
      request.status === 404 ||
      request.status === 500
    ) {
      setError("Ocurrió un error al agregar un producto");
    }
  };

  const sendValuesPatch = async (
    nombre,
    unidadMedida,
    margen,
    costoFijo,
    nombreTienda,
    idMarca,
    codigoCabys,
    codigoBarras,
    pesoGramos,
    descripcion,
    costoTransporte,
    prescrito,
    codigoImpuesto,
    codigoTarifaImpuesto,
    codigoProveedor,
    idProveedor,
    categoria,
    Photo
  ) => {
    let s3PhotoAddress;

    const precioFijo = 0;
    // let fotoPrincipal = "";

    if (Photo) {
      try {
        const s3PhotoAddressFetch = await fileUploadFetch("upload", Photo);
        if (s3PhotoAddressFetch.status === 200) {
          s3PhotoAddress = await s3PhotoAddressFetch.json();
        }
        // fotoPrincipal = s3PhotoAddress
      } catch (error) {
        console.log(error);
      }
    } else {
      s3PhotoAddress = product.fotoPrincipal;
    }

    let request = await authFetch(
      `productos/${idProducto}`,
      {
        producto: {
          nombre,
          unidadMedida,
          margen: margen / 100 + 1,
          costoFijo,
          precioFijo: !isNaN(precioFijo) && precioFijo > 0 ? precioFijo : null,
          nombreTienda,
          idMarca,
          fotoPrincipal: s3PhotoAddress ? s3PhotoAddress.s3Address : "",
          codigoCabys,
          codigoBarras,
          pesoGramos,
          descripcion,
          costoTransporte,
          prescrito,
          codigoImpuesto,
          codigoTarifaImpuesto,
          codigoProveedor,
          idProveedor,
        },
        categoria,
        descuento: {
          ...descuento,
          porcentaje: Number(descuento.porcentaje / 100),
          activo: isDescuentoActive,
          cantidadDisponible: discountForAll
            ? null
            : descuento.cantidadDisponible,
        },
      },
      "PATCH",
      token
    );

    if (
      request.status === 200 ||
      request.status === 204 ||
      request.status === 201
    ) {
      Swal.fire({
        icon: "success",
        title: "Correcto",
        text: "Producto actualizado correctamente",
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp",
        },
        background: "#76236C",
        color: "#fafafa",
        confirmButtonColor: "#19D3C5",
      });
      setTimeout(() => {
        navigate("/productos");
      }, 400);
    }

    if (
      request.status === 400 ||
      request.status === 404 ||
      request.status === 500
    ) {
      setError("Ocurrió un error al actualizar un producto");
      return;
    }
  };

  const fetchDeleteProduct = async (id) => {
    setLoading(true);
    const productoFetch = await authFetch(
      `productos/${id}`,
      {
        producto: {
          activo: 0,
        },
      },
      "PATCH",
      token
    );
    if (
      productoFetch.status === 200 ||
      productoFetch.status === 201 ||
      productoFetch.status === 204
    ) {
      setLoading(false);
      Swal.fire({
        icon: "success",
        title: "¡Correcto!",
        text: "Producto inactivado correctamente",
        background: "#76236C",
        color: "#fafafa",
        confirmButtonColor: "#19D3C5",
      });

      setTimeout(() => {
        navigate("/productos");
      }, 100);
    }
    if (
      productoFetch.status === 400 ||
      productoFetch.status === 401 ||
      productoFetch.status === 404 ||
      productoFetch.status === 422 ||
      productoFetch.status === 500
    ) {
      setError("Ocurrió un error al inactivar el producto");
      return;
    }
  };

  const deleteProduct = async (id) => {
    Swal.fire({
      title: "¿Estas seguro que deseas inactivar este producto?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Si, inactivar",
      cancelButtonText: "Cancelar",
      confirmButtonColor: "#19D3C5",
      cancelButtonColor: "#E7004C",
      background: "#76236C",
      color: "#fafafa",
    }).then((result) => {
      if (result.isConfirmed) {
        fetchDeleteProduct(id);
      }
    });
  };

  const fetchActiveProduct = async (id) => {
    setLoading(true);
    const productoFetch = await authFetch(
      `productos/${id}`,
      {
        producto: {
          activo: 1,
        },
      },
      "PATCH",
      token
    );
    if (
      productoFetch.status === 200 ||
      productoFetch.status === 201 ||
      productoFetch.status === 204
    ) {
      setLoading(true);
      Swal.fire({
        icon: "success",
        title: "¡Correcto!",
        text: "Producto activado correctamente",
        background: "#76236C",
        color: "#fafafa",
        confirmButtonColor: "#19D3C5",
      });
      fetchProduct(product.id);
    }
    if (
      productoFetch.status === 400 ||
      productoFetch.status === 401 ||
      productoFetch.status === 404 ||
      productoFetch.status === 422 ||
      productoFetch.status === 500
    ) {
      setError("Ocurrió un error al inactivar el producto");
      return;
    }
  };

  const activeProduct = async (id) => {
    Swal.fire({
      title: "¿Estas seguro que deseas activar este producto?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Si, activar",
      cancelButtonText: "Cancelar",
      confirmButtonColor: "#19D3C5",
      cancelButtonColor: "#E7004C",
      background: "#76236C",
      color: "#fafafa",
    }).then((result) => {
      if (result.isConfirmed) {
        fetchActiveProduct(id);
      }
    });
  };

  const indexProduct = async (id) => {
    setLoading(true);
    const indexFetch = await authFetch(
      `producto-informacion-agrupada/index/${id}`,
      {},
      "POST",
      token
    );
    const data = await indexFetch.json();
    if (indexFetch.status === 200 && Array.isArray(data) && data.length > 0) {
      Swal.fire({
        icon: "success",
        title: "¡Correcto!",
        text: "Producto indexado correctamente",
        background: "#76236C",
        color: "#fafafa",
        confirmButtonColor: "#19D3C5",
      });
    } else {
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: "Ocurrió un error al indexar el producto. Por favor verifique que existe una orden de compra para el producto, que el producto se encuentra en bodega y que tiene categorías asignadas.",
        background: "#76236C",
        color: "#fafafa",
        confirmButtonColor: "#19D3C5",
      });
    }
    setLoading(false);
  };

  return (
    <div className="w-full p-content pb-16 min-h-[400px]">
      {loading ? (
        <Spinner />
      ) : (
        <div>
          <div className="flex w-full justify-end items-center gap-4">
            {product.activo === 1 && (
              <button
                onClick={() => {
                  indexProduct(product.id);
                }}
                className="bg-purple text-white py-2 px-8 font-bold rounded-full float-right flex justify-center items-center"
              >
                <BiFile className="text-lg" /> Indexar
              </button>
            )}

            {product.activo === 1 && (
              <button
                className="bg-purple text-white py-2 px-8 font-bold rounded-full float-right flex justify-center items-center"
                onClick={() =>
                  (window.location.href = `/tienda/producto/${product.id}`)
                }
              >
                <BiShowAlt className="text-lg" /> Ver en tienda
              </button>
            )}

            <button
              className="bg-pink text-white py-2 px-8 font-bold rounded-full float-right flex justify-center items-center"
              onClick={() => (window.location.href = `/productos`)}
            >
              <IoMdArrowRoundBack className="text-lg" /> Volver
            </button>
          </div>
          <p className="text-2xl font-bold mb-4">
            {isNaN(idProducto) ? "Agregar producto" : "Editar producto"}
          </p>

          <div>
            {product.activo === 1 && (
              <div className="w-full">
                <button
                  className="bg-pink text-white py-2 px-8 font-bold rounded-full float-left flex justify-center items-center hover:shadow-lg"
                  onClick={() => deleteProduct(product.id)}
                >
                  Inactivar producto
                </button>
              </div>
            )}
          </div>

          <div>
            {product.activo === 0 && (
              <div className="w-full">
                <button
                  className="bg-purple text-white py-2 px-8 font-bold rounded-full float-left flex justify-center items-center hover:shadow-lg"
                  onClick={() => activeProduct(product.id)}
                >
                  Activar producto
                </button>
              </div>
            )}
          </div>
          <br />
          <br />

          <div className="w-full mt-4">
            {(product.activo === 1 || isNaN(idProducto)) && (
              <>
                <label className="text-base">Fotografía del producto:</label>
                <p className="text-sm text-pink">
                  La fotografía debe ser de un tamaño de 800x800 pixeles con
                  fondo blanco en formato{" "}
                  <span className="font-bold">.jpg</span>. Este es un estandar
                  del sitio web y no se debe modificar.
                </p>
                <label
                  className="w-fit mt-2 block text-sm text-body py-2 px-4 rounded-full border-0 bg-pink text-white"
                  htmlFor="comprobantePago"
                >
                  Cargar fotografía
                </label>
                <input
                  id="comprobantePago"
                  name="comprobantePago"
                  type="file"
                  accept="image/jpeg"
                  className="hidden"
                  onChange={(evt) => {
                    const img = new Image();
                    const objectUrl = URL.createObjectURL(evt.target.files[0]);
                    img.onload = function () {
                      setImageDimensions({
                        width: this.width,
                        height: this.height,
                      });
                      URL.revokeObjectURL(objectUrl);
                    };
                    img.src = objectUrl;

                    setProductPhoto(evt.target.files[0]);
                  }}
                />
              </>
            )}

            {!isNaN(idProducto) ? (
              product.activo === 1 && product.nombreTienda ? (
                <img
                  className="my-3 h-52"
                  src={
                    productPhoto
                      ? URL.createObjectURL(productPhoto)
                      : product.fotoPrincipal
                  }
                  alt="Imagen del producto"
                />
              ) : (
                <img
                  className="my-3 h-52"
                  src={
                    "https://polpo-assets.s3.amazonaws.com/production/ticolitas/productos/800/noactivo.jpg"
                  }
                  alt="Imagen del producto"
                />
              )
            ) : (
              <img
                className="my-3 h-52"
                src={
                  productPhoto
                    ? URL.createObjectURL(productPhoto)
                    : product.fotoPrincipal
                }
                alt="Imagen del producto"
              />
            )}
          </div>
          <div>
            <Select
              containerClasses="my-2"
              labelColor="text-base"
              buttonClasses="border border-purple"
              label="Marca:"
              required
              options={catalogs.brands.map((b) => {
                return {
                  value: b.id,
                  label: b.nombreTienda,
                };
              })}
              onChange={(value) => {
                setProduct({
                  ...product,
                  idMarca: value,
                });
              }}
              value={product.idMarca}
            />
          </div>
          <div>
            <label htmlFor="nombreProveedor" className="text-base">
              Nombre de producto del proveedor:
              <span className="text-pink">*</span>
            </label>
            <input
              id="nombreProveedor"
              name="nombreProveedor"
              className="w-full rounded-3xl my-2 h-12 p-6 border border-purple"
              onChange={(evt) => {
                setProduct({
                  ...product,
                  nombre: evt.target.value,
                });
              }}
              value={product.nombre}
            />
          </div>
          <div>
            <label htmlFor="nombreTienda" className="text-base">
              Nombre de producto en tienda:
              <span className="text-pink">*</span>
            </label>
            <input
              id="nombreTienda"
              name="nombreTienda"
              className="w-full rounded-3xl my-2 h-12 p-6 border border-purple"
              onChange={(evt) => {
                setProduct({
                  ...product,
                  nombreTienda: evt.target.value,
                });
              }}
              value={product.nombreTienda}
            />
          </div>

          <div>
            <Select
              containerClasses="my-4"
              labelColor="text-base"
              buttonClasses="border border-purple"
              label="Proveedor:"
              required
              options={catalogs.providers.map((b) => {
                return {
                  value: b.id,
                  label: b.nombre,
                };
              })}
              onChange={(value) => {
                setProduct({
                  ...product,
                  idProveedor: value,
                });
              }}
              value={product.idProveedor}
            />
          </div>

          <div>
            <label htmlFor="nombreTienda" className="text-base">
              Código del Proveedor:
              <span className="text-pink">*</span>
            </label>
            <input
              id="nombreTienda"
              name="nombreTienda"
              className="w-full rounded-3xl my-2 h-12 p-6 border border-purple"
              onChange={(evt) => {
                setProduct({
                  ...product,
                  codigoProveedor: evt.target.value,
                });
              }}
              value={product.codigoProveedor}
            />
          </div>

          <div>
            <label htmlFor="pesoGramos" className="text-base">
              Peso en gramos (OJO gramos):<span className="text-pink">*</span>
            </label>
            <input
              id="pesoGramos"
              name="pesoGramos"
              className="w-full rounded-3xl my-2 h-12 p-6 border border-purple"
              value={product.pesoGramos ? product.pesoGramos : ""}
              placeholder={"Por favor ingresa únicamente números"}
              onChange={(evt) => {
                const justNumbers = evt.target.value.replace(/[^0-9]/g, "");
                if (justNumbers) {
                  if (justNumbers > -1) {
                    setProduct({
                      ...product,
                      pesoGramos: justNumbers,
                    });
                  }
                } else {
                  setProduct({
                    ...product,
                    pesoGramos: null,
                  });
                }
              }}
            />
          </div>
          <div>
            <label htmlFor="codigoCabys" className="text-base">
              Código Cabys:
              <span className="text-pink">*</span>
            </label>
            <input
              id="codigoCabys"
              name="codigoCabys"
              className="w-full rounded-3xl my-2 h-12 p-6 border border-purple"
              onChange={(evt) => {
                setProduct({
                  ...product,
                  codigoCabys: evt.target.value,
                });
              }}
              value={product.codigoCabys}
            />
          </div>
          <div>
            <label htmlFor="codigoBarras" className="text-base">
              Código de Barras:
              <span className="text-pink">*</span>
            </label>
            <input
              id="codigoBarras"
              name="codigoBarras"
              className="w-full rounded-3xl my-2 h-12 p-6 border border-purple"
              onChange={(evt) => {
                setProduct({
                  ...product,
                  codigoBarras: evt.target.value,
                });
              }}
              value={product.codigoBarras}
            />
          </div>
          <div>
            <label htmlFor="descripcion" className="text-base">
              Descripción (máx 500 caractéres):
            </label>
            <textarea
              id="descripcion"
              name="descripcion"
              className="w-full rounded-3xl mt-2 p-6 border border-purple"
              rows={3}
              onChange={(evt) => {
                setProduct({
                  ...product,
                  descripcion: evt.target.value,
                });
              }}
              value={product.descripcion}
            />
          </div>
          <div>
            <Select
              containerClasses="my-2"
              labelColor="text-base"
              buttonClasses="border border-purple"
              label="Unidad de medida:"
              required
              options={catalogs.unitsOfMeassure.map((uom) => {
                return {
                  value: uom.id,
                  label: uom.valor,
                };
              })}
              onChange={(value) => {
                setProduct({
                  ...product,
                  unidadMedida: value,
                });
              }}
              value={product.unidadMedida}
            />
          </div>
          <div>
            <CheckboxInput
              containerClass={"my-4"}
              checked={product.prescrito}
              onCheckChange={() => {
                setProduct({
                  ...product,
                  prescrito: !product.prescrito,
                });
              }}
              labelColor="text-base"
              label={
                "Marque esta casilla si este alimento es preescrito y se debe consultar al veterinario para que la mascota lo pueda consumir."
              }
              inputBorderColor="purple"
            />
          </div>
          <hr className="my-4" />
          <div>
            <label htmlFor="margen" className="text-base">
              Porcentaje de Margen (Ej: 12):<span className="text-pink">*</span>
            </label>
            <input
              id="margen"
              name="margen"
              className="w-full rounded-3xl my-2 h-12 p-6 border border-purple"
              value={product.margen ? product.margen : ""}
              placeholder={"Por favor ingresa únicamente números"}
              onChange={(evt) => {
                const justNumbers = evt.target.value.replace(/[^0-9]/g, "");
                if (justNumbers) {
                  if (justNumbers > -1) {
                    setProduct({
                      ...product,
                      margen: justNumbers,
                    });
                  }
                } else {
                  setProduct({
                    ...product,
                    margen: null,
                  });
                }
              }}
            />
          </div>
          <div>
            <label htmlFor="precioFijo" className="text-base">
              Precio fijo (Este precio sustituye cualquier cálculo de precio y
              será el precio + IVA que se le dará al cliente.):
            </label>
            <input
              id="precioFijo"
              name="precioFijo"
              className="w-full rounded-3xl my-2 h-12 p-6 border border-purple"
              value={product.precioFijo ? product.precioFijo : ""}
              placeholder={"Por favor ingresa únicamente números"}
              onChange={(evt) => {
                const justNumbers = evt.target.value.replace(/[^0-9]/g, "");
                if (justNumbers) {
                  if (justNumbers > -1) {
                    setProduct({
                      ...product,
                      precioFijo: justNumbers,
                    });
                  }
                } else {
                  setProduct({
                    ...product,
                    precioFijo: null,
                  });
                }
              }}
            />
          </div>
          <div>
            <label htmlFor="costoFijo" className="text-base">
              Costo Fijo (En colones):<span className="text-pink">*</span>
            </label>
            <input
              id="costoFijo"
              name="costoFijo"
              className="w-full rounded-3xl my-2 h-12 p-6 border border-purple"
              value={product.costoFijo ? product.costoFijo : ""}
              placeholder={"Por favor ingresa únicamente números"}
              onChange={(evt) => {
                const justNumbers = evt.target.value.replace(/[^0-9]/g, "");
                if (justNumbers) {
                  if (justNumbers > -1) {
                    setProduct({
                      ...product,
                      costoFijo: justNumbers,
                    });
                  }
                } else {
                  setProduct({
                    ...product,
                    costoFijo: null,
                  });
                }
              }}
            />
          </div>
          <div>
            <label htmlFor="costoTransporte" className="text-base">
              Costo de Transporte (En colones):
              <span className="text-pink">*</span>
            </label>
            <input
              id="costoTransporte"
              name="costoTransporte"
              className="w-full rounded-3xl my-2 h-12 p-6 border border-purple"
              value={product.costoTransporte ? product.costoTransporte : ""}
              placeholder={"Por favor ingresa únicamente números"}
              onChange={(evt) => {
                const justNumbers = evt.target.value.replace(/[^0-9]/g, "");
                if (justNumbers) {
                  if (justNumbers > -1) {
                    setProduct({
                      ...product,
                      costoTransporte: justNumbers,
                    });
                  }
                } else {
                  setProduct({
                    ...product,
                    costoTransporte: null,
                  });
                }
              }}
            />
          </div>
          <div>
            <Select
              containerClasses="my-2"
              labelColor="text-base"
              buttonClasses="border border-purple"
              label="Impuesto:"
              required
              options={CODIGO_IMPUESTO.map((b) => {
                return {
                  value: b.valor,
                  label: b.descripción,
                };
              })}
              onChange={(value) => {
                setProduct({
                  ...product,
                  codigoImpuesto: value,
                });
              }}
              value={product.codigoImpuesto}
            />
          </div>
          <div>
            <Select
              containerClasses="my-2"
              labelColor="text-base"
              buttonClasses="border border-purple"
              label="Tarifa del impuesto:"
              required
              options={CODIGO_TARIFA.map((b) => {
                return {
                  value: b.valor,
                  label: b.descripción,
                };
              })}
              onChange={(value) => {
                setProduct({
                  ...product,
                  codigoTarifaImpuesto: value,
                });
              }}
              value={Number(product.codigoTarifaImpuesto)}
            />
          </div>

          <hr className="my-4" />
          <div>
            <SelectMultiple
              required
              containerClasses="my-2"
              labelColor="text-base"
              buttonClasses="border border-purple"
              label={`Categorías del producto:`}
              options={catalogs.categoryLevels.map((b) => {
                return {
                  value: b.id,
                  label: b.nombre.nombre,
                };
              })}
              onChange={(value) => handleChage(value)}
              value={tipoCategoria}
            />
          </div>
          <hr className="my-4" />

          <div className="flex flex-col gap-5">
            <p className="font-bold text-lg">Descuento del producto:</p>
            <div className="flex gap-2">
              <label htmlFor="descuento" className="text-base">
                Aplicar descuento
              </label>
              <CheckboxInput
                checked={isDescuentoActive}
                onCheckChange={(e) => {
                  setIsDescuentoActive(!isDescuentoActive);
                  setDescuento({
                    ...descuento,
                    activo: !isDescuentoActive,
                  });
                }}
              />
            </div>

            <div>
              <label htmlFor="descuento" className="text-base">
                Descuento en porcentaje (Ej: 12):
              </label>
              <input
                type="number"
                className="w-full rounded-3xl my-2 h-12 p-6 border border-purple"
                min={1}
                disabled={!isDescuentoActive}
                max={100}
                value={descuento.porcentaje}
                onChange={(evt) => {
                  setDescuento({
                    ...descuento,
                    porcentaje: evt.target.value,
                  });
                }}
              />
            </div>

            <div>
              <label htmlFor="descuento" className="text-base">
                Valido desde:
                <span className="text-pink">*</span>
              </label>
              <input
                type="date"
                className="w-full rounded-3xl my-2 h-12 p-6 border border-purple"
                disabled={!isDescuentoActive}
                value={descuento.validoDesde}
                onChange={(evt) => {
                  setDescuento({
                    ...descuento,
                    validoDesde: evt.target.value,
                  });
                }}
              />

              <label htmlFor="descuento" className="text-base">
                Valido hasta:
                <span className="text-pink">*</span>
              </label>
              <input
                type="date"
                disabled={!isDescuentoActive}
                className="w-full rounded-3xl my-2 h-12 p-6 border border-purple"
                value={descuento.validoHasta}
                onChange={(evt) => {
                  setDescuento({
                    ...descuento,
                    validoHasta: evt.target.value,
                  });
                }}
              />
            </div>

            <div>
              <label htmlFor="descuento" className="text-base">
                Cantidad disponible:
              </label>
              <input
                type="number"
                disabled={!isDescuentoActive}
                className="w-full rounded-3xl my-2 h-12 p-6 border border-purple"
                value={descuento.cantidadDisponible}
                onChange={(evt) => {
                  setDescuento({
                    ...descuento,
                    cantidadDisponible: evt.target.value,
                  });
                }}
              />
            </div>

            <div className="flex gap-2">
              <label>Solo con efectivo</label>
              <CheckboxInput
                checked={descuento.soloEnEfectivo}
                disabled={!isDescuentoActive}
                onCheckChange={() => {
                  setDescuento({
                    ...descuento,
                    soloEnEfectivo: !descuento.soloEnEfectivo,
                  });
                }}
              />
            </div>

            <div className="flex gap-2">
              <label>Aplicar descuento a todas las existencias</label>
              <CheckboxInput
                checked={discountForAll}
                disabled={!isDescuentoActive}
                onCheckChange={() => {
                  setDiscountForAll(!discountForAll);
                }}
              />
            </div>
          </div>

          <div className="w-full mt-4">
            {error && <p className="text-pink text-center">{error}</p>}
            <button
              onClick={() => {
                postProduct();
              }}
              className="bg-purple text-white h-12 px-8 rounded-full w-full"
            >
              Guardar Producto
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default InventarioProductosNuevoPage;
