import React from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { logoutUser } from "../../slices/appSlice";
import { clearAfterPurchase } from "../../slices/checkoutSlice";
import userIsAuthorized from "../../utils/userIsAuthorized";
/*
const options = [
  {
    label: "Datos Personales",
    route: "/cuenta",
  },
  {
    label: "Pedidos",
    route: "/cuenta/pedidos",
    roles: ["cliente"]
  },
  {
    label: "Direcciones",
    route: "/cuenta/direcciones",
    roles: ["cliente"]
  },
  {
    label: "Facturación",
    route: "/cuenta/facturacion",
    roles: ["cliente"]
  },
  {
    label: "Notificaciones",
    route: "/cuenta/notificaciones",
    roles: ["cliente"]
  },
  {
    label: "Notificaciones",
    route: "/cuenta/notificaciones",
    roles: ["cliente"]
  },
  {
    label: "Bodega",
    route: "/bodegas/1/ordenes",
    roles: ["bodega", "admin", "administradorTienda"]
  },
  {
    label: "Pedidos",
    route: "/pedidos",
    roles: ["mensajero", "bodega", "admin", "administradorTienda"]
  },
  {
    label: "Envíos",
    route: "/envios",
    roles: ["mensajero", "admin", "administradorTienda"]
  },
];
*/

export function AccountSidebar({ options, activeIndex, user }) {
  const dispatch = useDispatch();

  return (
    <div>
      {options.map((o, index) => {
        if (!o.roles || userIsAuthorized(user.roles, o.roles)) {
          return (
            <Link key={`option_${index}`} to={o.route}>
              <p className={`${activeIndex === index ? "underline decoration-purple transition-colors duration-500 ease-in-out decoration-dotted" : ""} transition-all duration-500 ease-linear mt-4 hover:font-medium hover:text-purple hover:underline`}>
                {o.label}
              </p>
            </Link>
          );
        }
        return null;
      })}
      <Link
        onClick={() => {
          dispatch(logoutUser());
          dispatch(clearAfterPurchase());
        }}
        to={"/"}
      >
        <p className="mt-4 text-pink">Cerrar Sesión</p>
      </Link>
    </div>
  );
}

export default AccountSidebar;
