import React, { useCallback, useEffect } from "react";
import { useState } from "react";
import _ from "lodash";
import { Link, useParams } from "react-router-dom";
import { AiOutlineQrcode } from "react-icons/ai";
import { IoMdArrowRoundBack } from "react-icons/io";
import Swal from "sweetalert2";
import useAuthFetch from "../../hooks/useAuthFetch";
import {
  ESTADOS_ORDENES,
  METODOS_ENTREGA,
  USUARIOS_TICOLITAS,
} from "../../utils/constants";
import Spinner from "../../components/Spinner";
import Tabs from "../../components/Tabs";
import { useSelector } from "react-redux";
import moment from "moment";

const getNumberOfPackages = (order) => {
  const packagesQty = _.map(order.lineas, "cantidad");
  return packagesQty;
};

export function BodegaOrdenesPage() {
  const { idBodega } = useParams();

  const [bodega, setBodega] = useState(null);
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [tabsData, setTabsData] = useState([]);
  const [locations, setLocations] = useState([]);
  const [transOrigen, settransOrigen] = useState(null);
  const [transDestino, setTransDestino] = useState(null);

  const { token } = useSelector((store) => store.app.user);
  const authFetch = useAuthFetch();

  const fetchOrders = useCallback(async () => {
    setLoading(true);
    const bodegaFetch = await authFetch(
      `bodegas/${idBodega}?filter={"include":[
        {"relation":"ventas","scope":{"where":{"and":[{"idEstadoVenta":${ESTADOS_ORDENES.PREPARANDO_ORDEN}},{"idMetodoEntrega": {"nin":[${METODOS_ENTREGA.LOCAL}]}}]},"order":"fechaIngreso asc","offset":0,"limit":100,"skip":0,"include":[{"relation":"usuario"},{"relation":"transferencias"},{"relation":"metodoEntregaUtilizado"},
        {"relation":"lineas","scope":{"include":[{"relation":"producto"}]}}]}}]}`,
      null,
      "GET",
      token
    );
    const bodegaData = await bodegaFetch.json();
    setBodega(bodegaData);
    setLoading(false);

    const transferenciaFetch = await authFetch(
      `transferencias?filter={
				"where":{"idBodegaOrigen":${idBodega}, "fechaTransferenciaInicio": null},
				"include": [{"relation": "producto"}, {"relation": "bodegaDestino"}, {"relation": "bodegaOrigen"}],
				"order": ["createdAt desc"]
			}`,
      null,
      "GET",
      token
    );
    const transOrigenData = await transferenciaFetch.json();
    settransOrigen(transOrigenData);

    const transDestinoFetch = await authFetch(
      `transferencias?filter={
				"where":{"idBodegaDestino":${idBodega}, "fechaTransferenciaFin": null},
				"include": [{"relation": "producto"}, {"relation": "bodegaDestino"}, {"relation": "bodegaOrigen"}],
				"order": ["createdAt desc"]
			}`,
      null,
      "GET",
      token
    );
    const transDestinoData = await transDestinoFetch.json();
    setTransDestino(transDestinoData);

    const locationsFetch = await authFetch("provincia-canton-distrito");
    const locationsData = await locationsFetch.json();
    setLocations(locationsData);
  }, [idBodega, token]);

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchOrders();
  }, [fetchOrders]);

  const fetchPrintQr = async (text, url) => {
    setLoading(true);
    await fetch(`${bodega.urlServidorDeImpresion}-qr`, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        text,
        url,
      }),
    });
    setLoading(false);
  };

  const fetchPrintBill = async (order) => {
    const payload = {
      order,
      vendorId: bodega.vendorIdImpresora,
      productId: bodega.productIdImpresora,
    };
    setLoading(true);
    await fetch(bodega.urlServidorDeImpresion, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify(payload),
    });
    setLoading(false);
  };

  const updateOrderStatus = async (orderId) => {
    setLoading(true);
    const request = await authFetch(
      `ventas/${orderId}`,
      {
        idEstadoVenta: ESTADOS_ORDENES.LISTO_EN_BODEGA, //ready to deliver
      },
      "PATCH",
      token
    );

    if (
      request.status === 200 ||
      request.status === 201 ||
      request.status === 204 ||
      request.status === 202
    ) {
      Swal.fire({
        title: "¡Listo!",
        text: "Orden Lista para Entregar",
        icon: "success",
      });
    }

    if (
      request.status === 400 ||
      request.status === 401 ||
      request.status === 404 ||
      request.status === 402 ||
      request.status === 403 ||
      request.status === 500
    ) {
      Swal.fire({
        title: "¡Error!",
        text: "Ocurrio un error en el proceso de marcar la orden",
        icon: "error",
      });
    }
    setLoading(false);
    fetchOrders();
  };

  useEffect(() => {
    let tabs = [];
    if (bodega) {
      if (bodega.hasOwnProperty("ventas")) {
        tabs = tabs.concat([
          {
            label: `Ordenes para preparar (${bodega.ventas.length})`,
            data: bodega.ventas,
          },
        ]);
      } else {
        tabs = tabs.concat([
          {
            label: `Ordenes para preparar (0)`,
            data: [],
          },
        ]);
      }
      if (transOrigen) {
        tabs = tabs.concat([
          {
            label: `Transferencias para entregar (${transOrigen.length})`,
            data: transOrigen,
          },
        ]);
      }

      if (transDestino) {
        tabs = tabs.concat([
          {
            label: `Transferencias para recibir (${transDestino.length})`,
            data: transDestino,
          },
        ]);
      }

      setTabsData(tabs);
    }
  }, [bodega, transOrigen, transDestino]);

  const updatefechaTransferenciaInicio = async (t) => {
    setLoading(true);
    const fechaTransferenciaInicio = new Date();

    const request = await authFetch(
      `transferencias/${t.id}`,
      {
        idBodegaOrigen: t.idBodegaOrigen,
        idBodegaDestino: t.idBodegaDestino,
        idProducto: t.idProducto,
        cantidad: t.cantidad,
        fechaTransferenciaInicio: fechaTransferenciaInicio,
      },
      "PATCH",
      token
    );

    if (
      request.status === 200 ||
      request.status === 201 ||
      request.status === 204 ||
      request.status === 202
    ) {
      Swal.fire({
        title: "¡Listo!",
        text: "La transferencia está lista",
        icon: "success",
      });
    }

    if (
      request.status === 400 ||
      request.status === 401 ||
      request.status === 404 ||
      request.status === 402 ||
      request.status === 422 ||
      request.status === 500
    ) {
      Swal.fire({
        title: "¡Error!",
        text: "Ocurrio un error en el proceso de marcar la transferencia",
        icon: "error",
      });
    }
    fetchOrders();
  };

  const updateFechaTransferenciaFin = async (t) => {
    setLoading(true);

    const fechaTransferenciaFin = new Date();

    const request = await authFetch(
      `transferencias/${t.id}`,
      {
        idBodegaOrigen: t.idBodegaOrigen,
        idBodegaDestino: t.idBodegaDestino,
        idProducto: t.idProducto,
        cantidad: t.cantidad,
        fechaTransferenciaFin: fechaTransferenciaFin,
      },
      "PATCH",
      token
    );
    if (
      request.status === 200 ||
      request.status === 201 ||
      request.status === 204 ||
      request.status === 202
    ) {
      Swal.fire({
        title: "¡Listo!",
        text: "La transferencia está lista",
        icon: "success",
      });
    }

    if (
      request.status === 400 ||
      request.status === 401 ||
      request.status === 404 ||
      request.status === 402 ||
      request.status === 403 ||
      request.status === 500
    ) {
      Swal.fire({
        title: "¡Error!",
        text: "Ocurrio un error en el proceso de marcar la transferencia",
        icon: "error",
      });
    }

    fetchOrders();
  };

  return !bodega ? (
    <div className="w-full p-content pt-4 pb-16 min-h-[400px] bg-white">
      <Spinner />
    </div>
  ) : (
    <div className="w-full p-content pb-16">
      {loading && <Spinner />}
      <div>
        <div className="flex w-full items-center">
          <div className="w-full">
            <Link to="/tienda/bodegas">
              <button className="bg-pink text-white py-2 px-8 font-bold rounded-full float-right flex justify-center items-center">
                <IoMdArrowRoundBack className="text-lg" /> Volver
              </button>
            </Link>
          </div>
        </div>
        <p className="text-2xl font-bold mb-4">Bodega {bodega.nombre}</p>

        <Tabs
          onTabChange={(index) => {
            setActiveTab(index);
          }}
          activeTab={activeTab}
          tabs={tabsData}
        />

        {tabsData[activeTab] &&
        tabsData[activeTab]?.data !== undefined &&
        tabsData[activeTab].data.length > 0 ? (
          activeTab === 0 ? (
            tabsData[activeTab].data.map((v, index) => {
              const location = locations.find(
                (l) =>
                  l.idProvincia === v.provincia &&
                  l.idCanton === v.canton &&
                  l.idDistrito === v.distrito
              );
              return (
                <div
                  className="w-full border border-purple mb-4 p-6 rounded-lg"
                  key={`venta_${v.id}`}
                >
                  <div>
                    <p className="text-2xl">Orden #{v.id}</p>
                    <p className="text-lg">
                      Cliente:{" "}
                      {USUARIOS_TICOLITAS.includes(v.usuario.id)
                        ? v.nombreCompletoEntrega
                        : `${v.usuario.nombre}${
                            v.usuario.apellido1 ? ` ${v.usuario.apellido1}` : ""
                          }`}{" "}
                      (
                      {USUARIOS_TICOLITAS.includes(v.usuario.id)
                        ? v.telefono
                        : v.usuario.telefono}
                      )
                    </p>
                    <p className="text-lg">
                      Dirección:{" "}
                      {location ? `${location.nombreUbicacion} > ` : ""}
                      {v.direccionExacta}
                    </p>
                    <p className="text-lg mb-8">
                      Método de entrega: {v.metodoEntregaUtilizado.nombre}
                    </p>
                    <p className="text-lg mb-8">
                      Notas: {v.notasEntrega || "No hay"}
                    </p>
                    <p className="text-lg mb-8">
                      Fecha Ingreso:{" "}
                      {moment(v.fechaIngreso).format(
                        "dddd D [de] MMMM [a la(s)] hh:mm a"
                      )}
                    </p>

                    {v.hasOwnProperty("transferencias") &&
                      !v.transferencias[0].fechaTransferenciaInicio && (
                        <p className="text-pink font-semibold">
                          *Este pedido esta a la espera de una transferencia
                        </p>
                      )}
                    {v.lineas &&
                      v.lineas.map((l) => {
                        return (
                          <div
                            key={`venta_${v.id}_${l.linea}`}
                            className="flex items-center my-4 border-b border-borderGray pb-4"
                          >
                            <div className="w-4/12 md:w-2/12">
                              {l.producto && (
                                <img
                                  src={l.producto.fotoPrincipal}
                                  alt={`Foto ${l.producto.nombreTienda}`}
                                />
                              )}
                            </div>
                            {l.producto && (
                              <div className="flex flex-wrap items-center w-8/12 md:w-10/12 pl-4">
                                <p className="w-full font-bold">
                                  {l.producto.marca} {l.producto.nombreTienda}
                                </p>
                                {l.producto.pesoGramos && (
                                  <p className="w-full">
                                    Presentación:{" "}
                                    <span className="font-bold">
                                      {l.producto.pesoGramos < 1000
                                        ? `${l.producto.pesoGramos} gramos`
                                        : `${(
                                            l.producto.pesoGramos / 1000
                                          ).toFixed(2)} Kg`}
                                    </span>
                                  </p>
                                )}
                                <p className="w-full">
                                  Código de barras:{" "}
                                  <span className="font-bold">
                                    {l.producto.codigoBarras}
                                  </span>
                                </p>
                                <p className="w-full">
                                  Código TiColitas:{" "}
                                  <span className="font-bold">
                                    {l.producto.id}
                                  </span>
                                </p>
                                <p className="w-full">
                                  Código Proveedor:{" "}
                                  <span className="font-bold">
                                    {l.producto.codigoProveedor}
                                  </span>
                                </p>
                                <p>
                                  Cantidad:{" "}
                                  <span className="font-bold">
                                    {l.cantidad}
                                  </span>
                                </p>
                                {l.mascotas && (
                                  <div className="w-full mt-2">
                                    <button
                                      onClick={() => {
                                        fetchPrintQr(
                                          l.mascotas,
                                          `https://ticolitas.com/tienda/agregar/${
                                            l.producto.id
                                          }?telefono=${
                                            v.usuario.telefono
                                          }&nombre=${`${v.usuario.nombre}${
                                            v.usuario.apellido1
                                              ? ` ${v.usuario.apellido1}`
                                              : ""
                                          }`}&cantidad=${l.cantidad}&mascotas=${
                                            l.mascotas
                                          }&autoriza=${
                                            v.autorizaContactoWhatsapp &&
                                            v.confirmaAutorizaContactoWhatsapp
                                          }${
                                            l.consumoDiarioGramos
                                              ? `&consumo=${l.consumoDiarioGramos}`
                                              : ""
                                          }`
                                        );
                                      }}
                                      className="bg-purple py-2 px-6 rounded-3xl text-white truncate"
                                    >
                                      <AiOutlineQrcode className="inline mr-2 text-lg" />
                                      {l.mascotas}
                                    </button>
                                  </div>
                                )}
                              </div>
                            )}
                          </div>
                        );
                      })}
                  </div>
                  <div className="mt-8 flex justify-end">
                    {v.facturaImpresa === 1 && (
                      <button
                        onClick={() => {
                          fetchPrintBill(v);
                        }}
                        className="bg-purple py-2 px-2 md:px-6 text-sm md:text-base rounded-3xl text-white"
                      >
                        Imprimir Factura
                      </button>
                    )}
                    <button
                      onClick={() => {
                        fetchPrintQr(
                          `#${v.id} # Bultos ${getNumberOfPackages(v)}`,
                          `https://ticolitas.com/pedidos/${v.id}`
                        );
                      }}
                      className="ml-4 bg-purple py-2 px-2 md:px-6 text-sm md:text-base rounded-3xl text-white"
                    >
                      Imprimir QR Orden
                    </button>
                    <button
                      onClick={() => {
                        updateOrderStatus(v.id);
                      }}
                      className="ml-4 bg-cyan py-2 px-2 md:px-6 text-sm md:text-base rounded-3xl text-white"
                    >
                      Marcar como lista
                    </button>
                  </div>
                </div>
              );
            })
          ) : (
            tabsData[activeTab].data.map((v, index) => (
              <div
                className="w-full border border-purple mb-4 p-6 rounded-lg"
                key={`transferencia_${v.id}`}
              >
                <div>
                  <p className="text-2xl">Transferencia #{v.id}</p>
                  <p className="text-md my-3">
                    Bodega de destino:{" "}
                    <span className="font-medium">
                      {v.bodegaDestino.nombre}
                    </span>
                  </p>
                  <p className="text-md mb-3">
                    Bodega de origen:{" "}
                    <span className="font-medium">{v.bodegaOrigen.nombre}</span>
                  </p>
                  <p>
                    Tipo de transferencia:{" "}
                    <span className="font-medium color-primary">{v.idVenta ? `Transferencia por compra (#${v.idVenta})` : "Transferencia por relleno"}</span>
                  </p>
                  <br />
                  <strong className="text-md">Producto: </strong>
                  <div
                    key={`venta_${v.id}_${index}`}
                    className="flex items-center my-4 border-b border-borderGray pb-4"
                  >
                    <div className="w-4/12 md:w-2/12">
                      <img
                        src={v.producto.fotoPrincipal}
                        alt={`Foto ${v.producto.nombreTienda}`}
                      />
                    </div>
                    <div className="flex flex-wrap items-center w-8/12 md:w-10/12 pl-4">
                      <p className="w-full font-bold">
                        {v.producto.marca} {v.producto.nombreTienda}
                      </p>
                      {v.producto.pesoGramos && (
                        <p className="w-full">
                          Presentación:{" "}
                          <span className="font-bold">
                            {v.producto.pesoGramos < 1000
                              ? `${v.producto.pesoGramos} gramos`
                              : `${(v.producto.pesoGramos / 1000).toFixed(
                                  2
                                )} Kg`}
                          </span>
                        </p>
                      )}
                      <p className="w-full">
                        Código de barras:{" "}
                        <span className="font-bold">
                          {v.producto.codigoBarras}
                        </span>
                      </p>
                      <p className="w-full">
                        Código TiColitas:{" "}
                        <span className="font-bold">{v.producto.id}</span>
                      </p>
                      <p className="w-full">
                        Código Proveedor:{" "}
                        <span className="font-bold">
                          {v.producto.codigoProveedor}
                        </span>
                      </p>
                      <p>
                        Cantidad:{" "}
                        <span className="font-bold">{v.cantidad}</span>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mt-8 flex justify-end">
                  <button
                    onClick={() => {
                      activeTab === 1
                        ? updatefechaTransferenciaInicio(v)
                        : updateFechaTransferenciaFin(v);
                    }}
                    className="ml-4 bg-cyan py-2 px-2 md:px-6 text-sm md:text-base rounded-3xl text-white"
                  >
                    {activeTab === 1
                      ? "Marcar como enviada"
                      : "Marcar como lista"}
                  </button>
                </div>
              </div>
            ))
          )
        ) : (
          <p>
            {activeTab === 0
              ? "No hay pedidos para mostrar"
              : "No hay transferencias para mostrar"}
          </p>
        )}
      </div>
    </div>
  );
}

export default BodegaOrdenesPage;
