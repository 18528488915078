import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { SignupForm } from "../../components/SignupForm";

const useQuery = () => {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
};

export function SignUpPage() {

  const [redirectAddress, setRedirectAddress] = useState("");

  const query = useQuery();

  useEffect(() => {
    const redirectAddress = query.get("redirect");
    if (redirectAddress) {
      setRedirectAddress(redirectAddress);
    }
  }, [query]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="p-content-narrow">
      <SignupForm redirectAddress={redirectAddress ? redirectAddress : "/"} />
      <div className="w-full my-8 text-center">
        <p className="text-borderGray">
          ¿Ya tenés cuenta?{" "}
          <Link className="underline" to={`/log-in?redirect=${redirectAddress}`}>
            Ingresá aquí
          </Link>
        </p>
      </div>
    </div>
  );
}

export default SignUpPage;
