import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import LoginForm from "../../components/LoginForm";

const useQuery = () => {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
};

export function LoginPage() {

  const [redirectAddress, setRedirectAddress] = useState("");

  const query = useQuery();

  useEffect(() => {
    const redirectAddress = query.get("redirect");
    if (redirectAddress) {
      setRedirectAddress(redirectAddress);
    }
  }, [query]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="p-content-narrow">
      <LoginForm redirectAddress={redirectAddress ? redirectAddress : "/cuenta"} />
    </div>
  );
}

export default LoginPage;
