import React, { useCallback, useEffect } from "react";
import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { IoMdArrowRoundBack } from "react-icons/io";

import Spinner from "../../components/Spinner";
import Tabs from "../../components/Tabs";
import Pagination from "../../components/Pagination";
import useAuthFetch from "../../hooks/useAuthFetch";

import Modal from "../../components/Modal";
import { SearchBarOrders } from "../../components/SearchBar";
import TarjetaPedido from "../../components/TarjetaPedido";
import { useSelector } from "react-redux";

const useQuery = () => {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
};

export function PedidosPage({ user }) {
  const authFetch = useAuthFetch();
  const [orders, setOrders] = useState([]);
  const [page, setPage] = useState(0);
  const [ordersPerStatus, setOrdersPerStatus] = useState([]);
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [tabsData, setTabsData] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [showCancelOrderModal, setShowCancelOrderModal] = useState(false);
  const [orderToCancel, setOrderToCancel] = useState(null);
  const { token } = useSelector((store) => store.app.user);

  const query = useQuery();

  const fetchOrdersPerStatus = useCallback(async () => {
    setLoading(true);
    const ordersPerStatusFetchs = await authFetch(`estados-ordenes`);
    const ordersPerStatusData = await ordersPerStatusFetchs.json();
    const filteredStatuses = ordersPerStatusData.filter((os) => {
      return os.ordenes > 0;
    });
    setOrdersPerStatus(filteredStatuses);

    if (Array.isArray(filteredStatuses) && filteredStatuses.length > 0) {
      const pageSize = 10;
      const ordersFetch = await authFetch(
        `ventas?filter={"order":"fechaIngreso desc", "limit": ${pageSize}, "skip": ${
          page * pageSize
        },"where":{"idEstadoVenta": ${
          filteredStatuses[activeTab].id
        }},"include":[{"relation":"metodoPagoUtilizado"},{"relation":"metodoEntregaUtilizado"},{"relation":"usuario"},{"relation":"lineas","scope":{"include":[{"relation":"producto"}]}},{"relation":"transportista","scope":{"include":[{"relation":"usuario"}]}}]}`,
        null,
        "GET",
        token
      );

      const ordersData = await ordersFetch.json();
      setOrders(ordersData);

      const totalSalesFetch = await authFetch(
        `ventas/count?where={"idEstadoVenta": ${filteredStatuses[activeTab].id}}`,
        null,
        "GET",
        token
      );
      const totalSalesData = await totalSalesFetch.json();
      setTotalPages(Math.ceil(totalSalesData.count / pageSize));

      setLoading(false);
    } else {
      setLoading(false);
    }
  }, [activeTab, page]);

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchOrdersPerStatus();
  }, [fetchOrdersPerStatus]);

  useEffect(() => {
    let tabs = ordersPerStatus.map((os, index) => {
      return {
        label: `${os.estado} (${os.ordenes})`,
      };
    });
    setTabsData(tabs);
  }, [activeTab, ordersPerStatus]);

  useEffect(() => {
    const active = query.get("active");
    if (active !== null && active !== undefined) {
      setActiveTab(parseInt(active));
    }
  }, [query]);

  const fetchPrintBill = async (order) => {
    const warehouseOfOrderFetch = await authFetch(
      `bodegas/${order.idBodega}`
    );
    const warehouseData = await warehouseOfOrderFetch.json();
    const payload = {
      order,
      vendorId: warehouseData.vendorIdImpresora,
      productId: warehouseData.productIdImpresora,
    };
    setLoading(true);
    await fetch(warehouseData.urlServidorDeImpresion, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify(payload),
    });
    setLoading(false);
  };

  const cancelOrder = async (orderId) => {
    setLoading(true);
    await authFetch(`ventas/${orderId}/cancelar`, {}, "PATCH", token);
    fetchOrdersPerStatus();
  };

  return (
    <div className="w-full p-content pb-16 min-h-[400px]">
      {loading && <Spinner />}
      <Modal
        backgroundColor="bg-purple"
        closeModalFunction={() => {
          setOrderToCancel(null);
          setShowCancelOrderModal(false);
        }}
        open={showCancelOrderModal}
      >
        <div className="text-white p-8 pt-16 overflow-y-scroll h-full text-justify">
          <p className="mb-4">
            Realmente desea cancelar la Orden #{orderToCancel}. Esta acción no
            se podrá deshacer.
          </p>
          <div className="w-full flex flex-wrap justify-center sm:flex-nowrap sm:justify-end mt-4 gap-2">
            <button
              onClick={() => {
                setOrderToCancel(null);
                setShowCancelOrderModal(false);
              }}
              className="bg-white text-purple py-2 px-8 rounded-full"
            >
              Mantener orden
            </button>
            <button
              onClick={() => {
                cancelOrder(orderToCancel);
                setOrderToCancel(null);
                setShowCancelOrderModal(false);
              }}
              className="bg-orange text-white py-2 px-8 rounded-full"
            >
              Cancelar orden
            </button>
          </div>
        </div>
      </Modal>
      <div>
        <div className="flex w-full items-center">
          <div className="w-full">
            <Link to="/cuenta">
              <button className="bg-pink text-white py-2 px-8 font-bold rounded-full float-right flex justify-center items-center">
                <IoMdArrowRoundBack className="text-lg" /> Volver
              </button>
            </Link>
          </div>
        </div>
        <p className="text-2xl font-bold mb-4">Pedidos</p>

        <div className="w-full">
          <Tabs
            onTabChange={(index) => {
              setPage(0);
              setOrders([]);
              setActiveTab(index);
            }}
            activeTab={activeTab}
            tabs={tabsData}
          />

          <br />
          <SearchBarOrders />
          <br />
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-3 2xl:grid-cols-3">
            {orders && orders.length > 0 ? (
              <>
                {orders.map((v) => {
                  return (
                    <TarjetaPedido
                      key={`venta_${v.id}`}
                      user={user}
                      venta={v}
                      setOrderToCancel={setOrderToCancel}
                      setShowCancelOrderModal={setShowCancelOrderModal}
                      fetchPrintBill={fetchPrintBill}
                      backLink={`/pedidos/${v.id}?desde=/pedidos?active=${activeTab}`}
                    />
                  );
                })}
              </>
            ) : (
              !loading && (
                <p className="col-span-2">No hay pedidos para mostrar</p>
              )
            )}
          </div>

          <Pagination
            totalPages={totalPages}
            currentPage={page}
            onPageChange={(page) => {
              setPage(page);
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default PedidosPage;
