import React from "react";
import { useCallback } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { IoMdArrowRoundBack } from "react-icons/io";
import { Link, useNavigate } from "react-router-dom";
import Select from "../../components/Select";
import useAuthFetch from "../../hooks/useAuthFetch";
import { ClipLoader } from "react-spinners";
import { PendingOrdersTable } from "./components";
import RouteTable from "./components/RouteTable";
import { useSelector } from "react-redux";

export default function CreateRuta() {
  const authFetch = useAuthFetch();
  const user = useSelector((store) => store.app.user);
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  //TRANSPORTISTA
  const [idTransportista, setidTransportista] = useState();

  const fetchTransportista = useCallback(async () => {
    const idTransportistaRes = await authFetch("transportistas");
    const idTransportistaData = await idTransportistaRes.json();

    const transportista = idTransportistaData.find(
      (transportista) => transportista.idUsuario === user.id
    );

    setidTransportista(transportista.id);
  }, [user.id]);

  const assignDriver = async (orderId, driverId) => {
    await authFetch(
      `ventas/${orderId}`,
      {
        idTransportista: driverId,
      },
      "PATCH",
      user.token
    );
  };

  //BODEGA
  const [bodegaSaliente, setBodegaSaliente] = useState(1);
  const [bodegaFinal, setBodegaFinal] = useState(1);

  // VENTAS SIN ASIGNAR
  const [ventasSinAsignar, setVentasSinAsignar] = useState([]);
  const [ventasSinAsignarLoading, setVentasSinAsignarLoading] = useState(false);

  const fetchVentasSinAsignar = useCallback(async (idBodega = 1) => {
    const filter = {
      where: {
        and: [
          { idEstadoVenta: 15 },
          { idBodega: idBodega },
          { or: [{ idTransportista: 0 }, { idTransportista: null }] },
        ],
      },
      limit: 100,
      skip: 0,
    };

    setVentasSinAsignarLoading(true);
    const fechasSinAsignarRes = await authFetch(
      `ventas?filter=${JSON.stringify(filter)}`
    );
    const fechasSinAsignarData = await fechasSinAsignarRes.json();
    setVentasSinAsignar(fechasSinAsignarData);

    setVentasSinAsignarLoading(false);
  }, []);

  const deleteFromVentasSinAsignarTable = (idVenta) => {
    setVentasSinAsignar(
      ventasSinAsignar.filter((venta) => venta.id !== idVenta)
    );
  };

  const setCoordsToVentaSinAsignar = async (idVenta, latitud, longitud) => {
    await authFetch(
      `ventas/${idVenta}`,
      {
        latitud,
        longitud,
      },
      "PATCH",
      user.token
    );

    fetchVentasSinAsignar(bodegaSaliente);
  };

  //LISTA RUTA
  const [listaRuta, setListaRuta] = useState([]);

  const [distance, setDistance] = useState();
  const [time, setTime] = useState();

  const deleteFromlistaRuta = (idVenta) => {
    setListaRuta(listaRuta.filter((venta) => venta.id !== idVenta));
  };

  useEffect(() => {
    fetchVentasSinAsignar();
    fetchTransportista();
  }, [fetchVentasSinAsignar, fetchTransportista]);

  const createRoute = async () => {
    setLoading(true);
    await authFetch(
      "transportista/viaje",
      {
        idTransportista: idTransportista,
        fecha: new Date().toISOString().split("T")[0],
        tiempoSegundos: time,
        distanciaMetros: distance,
        idBodegaOrigen: bodegaSaliente,
        idBodegaDestino: bodegaFinal,
        lineas: listaRuta.map((venta, index) => ({
          idVenta: venta.id,
          orden: index + 1,
        })),
      },
      "POST",
      user.token
    );

    listaRuta.forEach(async (venta) => {
      await assignDriver(venta.id, idTransportista);
    });

    setLoading(false);

    navigate("/rutas");
  };

  return (
    <div className="w-full p-content pb-16">
      <div className="flex flex-col gap-4">
        <Link to="/rutas">
          <button className="bg-pink text-white py-2 px-8 font-bold rounded-full float-right flex justify-center items-center">
            <IoMdArrowRoundBack className="text-lg" /> Volver
          </button>
        </Link>

        <div className="flex flex-col gap-3">
          <h2 className="font-semibold text-3xl">Nueva ruta</h2>
          <div className="w-full flex flex-col gap-8">
            <div className="flex flex-col gap-6 p-10 shadow-xl rounded-lg">
              <h2 className="text-purple font-bold text-xl">
                Ordenes listas en bodega
              </h2>
              <Select
                label={"Selecciona la bodega de donde saldras"}
                className="p-4 border-purple border outline-purple rounded-full w-full pr-2"
                labelColor={"#000"}
                buttonClasses={
                  "p-4 border-purple border outline-purple rounded-full w-full pr-2"
                }
                value={bodegaSaliente}
                onChange={async (value) => {
                  setBodegaSaliente(value);
                  fetchVentasSinAsignar(value);
                }}
                options={[
                  {
                    label: "La Guacima",
                    value: 1,
                  },
                  {
                    label: "Heredia",
                    value: 2,
                  },
                ]}
              />

              <Select
                label={"Selecciona la bodega de donde terminarás"}
                className="p-4 border-purple border outline-purple rounded-full w-full pr-2"
                labelColor={"#000"}
                buttonClasses={
                  "p-4 border-purple border outline-purple rounded-full w-full pr-2"
                }
                value={bodegaFinal}
                onChange={async (value) => {
                  setBodegaFinal(value);
                }}
                options={[
                  {
                    label: "La Guacima",
                    value: 1,
                  },
                  {
                    label: "Heredia",
                    value: 2,
                  },
                ]}
              />
              {ventasSinAsignarLoading ? (
                <div className="flex items-center justify-center">
                  <ClipLoader size={40} />
                </div>
              ) : ventasSinAsignar && ventasSinAsignar.length > 0 ? (
                <PendingOrdersTable
                  ventas={ventasSinAsignar}
                  setListaRuta={setListaRuta}
                  listaRuta={listaRuta}
                  fetchVentasSinAsignar={fetchVentasSinAsignar}
                  deleteFromVentasSinAsignarTable={
                    deleteFromVentasSinAsignarTable
                  }
                  setCoordsToVentaSinAsignar={setCoordsToVentaSinAsignar}
                />
              ) : (
                <h3>No hay ordenes pendientes sin asignar en esta bodega</h3>
              )}
            </div>

            <div className="flex flex-col gap-6 p-10 shadow-xl rounded-lg">
              <h2 className="text-purple font-bold text-xl">
                Lista de la ruta
              </h2>

              {listaRuta.length === 0 ? (
                <h3>Aun no se han asignado ordenes a esta ruta</h3>
              ) : (
                <>
                  <RouteTable
                    listaRuta={listaRuta}
                    deleteFromlistaRuta={deleteFromlistaRuta}
                    setVentasSinAsignar={setVentasSinAsignar}
                    coordsBodega={BODEGA_COORDS[bodegaSaliente]}
                    coordsBodegaFinal={BODEGA_COORDS[bodegaFinal]}
                    setListaRuta={setListaRuta}
                    distance={distance}
                    setDistance={setDistance}
                    time={time}
                    setTime={setTime}
                  />

                  <div className="flex justify-end mt-5 ">
                    <button
                      className="bg-purple p-2 font-bold rounded-md text-white flex items-center justify-center gap-2"
                      onClick={() => createRoute()}
                      disabled={loading}
                    >
                      Guardar Ruta
                      {loading && <ClipLoader color="#fff" />}
                    </button>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const BODEGA_COORDS = {
  1: { lat: 9.96116007, lng: -84.25559857 },
  2: {
    lat: 9.969041,
    lng: -84.130447,
  },
};

// function getCoordsBodega(id) {
//   return id === 1 ? BODEGA_COORDS.guacima : BODEGA_COORDS.heredia;
// }
