import React, { createContext, useCallback, useEffect, useState } from "react";
import { IoMdArrowRoundBack } from "react-icons/io";
import { Link } from "react-router-dom";
import RecordatoriosTable from "../../components/RecordatoriosTable";
import useAuthFetch from "../../hooks/useAuthFetch";
import { DotLoader } from "react-spinners";
import { useSelector } from "react-redux";

export const recordatoriosContext = createContext();

const PAGE_SIZE = 10;

export default function RecordatoriosPage() {
  const authFetch = useAuthFetch();
  const [recordatorios, setRecordatorios] = useState();
  const [loading, setLoading] = useState(false);

  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);

  const [recordatorioSelected, setRecordatorioSelected] = useState();
  const [loadingEditar, setLoadingEditar] = useState(false);
  const { token } = useSelector((store) => store.app.user);

  const actualizarRecordatorios = useCallback(async () => {
    setLoading(true);
    const res = await authFetch(
      `listado-recordatorios?filter={"limit": ${PAGE_SIZE},"skip": ${
        page * PAGE_SIZE
      }}`
    );
    const totalReminderFetch = await authFetch(`orden-compras/count`);
    const totalReminder = await totalReminderFetch.json();
    setTotalPages(Math.ceil(totalReminder.count / PAGE_SIZE));
    const data = await res.json();
    setRecordatorios(data);
    setLoading(false);
  }, [page]);

  const editarRecordatorio = async (nuevoConsumo) => {
    setLoadingEditar(true);
    await authFetch(
      `recordatorios/venta/${recordatorioSelected.idVenta}/producto/${recordatorioSelected.idProducto}`,
      { nuevoConsumo },
      "PATCH",
      token
    );
    setLoadingEditar(false);
    actualizarRecordatorios();
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    actualizarRecordatorios();
  }, [page, actualizarRecordatorios]);

  return (
    <recordatoriosContext.Provider
      value={{
        loading,
        actualizarRecordatorios,
        recordatorioSelected,
        setRecordatorioSelected,
        loadingEditar,
        editarRecordatorio,
      }}
    >
      <div className="w-full p-content pb-16">
        <div className="flex flex-col gap-4">
          <Link to="/inventario">
            <button className="bg-pink text-white py-2 px-8 font-bold rounded-full float-right flex justify-center items-center">
              <IoMdArrowRoundBack className="text-lg" /> Volver
            </button>
          </Link>

          <h2 className="font-black text-2xl">Recordatorios</h2>
          {loading && <DotLoader color="#76236C" />}
          {recordatorios && (
            <RecordatoriosTable
              page={page}
              totalPages={totalPages}
              setPage={setPage}
              recordatorios={recordatorios}
            />
          )}
        </div>
      </div>
    </recordatoriosContext.Provider>
  );
}
