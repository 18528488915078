import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  showCart: false,
  cart: {
    userId: null,
    whatsappConfirmation: false,
    whatsappReconfirmation: false,
    discount: 0,
    productsCount: 0,
    total: 0,
    totalWithAnyDiscount: 0,
    totalWithEfectiveDiscount: 0,
    products: {},
  },
  user: {
    id: null,
    token: "",
    roles: [],
  },
};

export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    clearCart: (state) => {
      state.cart = initialState.cart;
    },
    showCart: (state) => {
      state.showCart = true;
    },
    hideCart: (state) => {
      state.showCart = false;
    },
    addToCart: (state, action) => {
      const product = action.payload.product;

      if (state.cart.products[product.id]) {
        state.cart.productsCount += state.cart.products[product.id].quantity;
      }

      state.cart.productsCount += action.payload.quantity;

      state.cart.total += product.precioVenta * action.payload.quantity;

      const descuento = product.descuentos ? product.descuentos[0] : null;

      if (descuento) {
        const exceedsDiscountQuantities =
          action.payload.quantity > descuento.cantidadDisponible &&
          descuento.cantidadDisponible !== null;

        if (
          validateDiscount(
            descuento.validoDesde,
            descuento.validaHasta,
            descuento.activo
          )
        ) {
          if (descuento.soloEnEfectivo) {
            //Si hay exceso solo aplicar descuento a las disponibles
            if (exceedsDiscountQuantities) {
              const diffWithoutDiscount =
                action.payload.quantity - descuento.cantidadDisponible;

              state.cart.totalWithEfectiveDiscount +=
                product.precioVenta *
                  (1 - descuento.porcentaje) *
                  descuento.cantidadDisponible +
                diffWithoutDiscount * product.precioVenta;

              state.cart.totalWithAnyDiscount +=
                product.precioVenta * action.payload.quantity;

              //Si no simplemente aplicar descuento
            } else {
              state.cart.totalWithEfectiveDiscount +=
                product.precioVenta *
                (1 - descuento.porcentaje) *
                action.payload.quantity;

              state.cart.totalWithAnyDiscount +=
                product.precioVenta * action.payload.quantity;
            }
          } else {
            if (exceedsDiscountQuantities) {
              const diffWithoutDiscount =
                action.payload.quantity - descuento.cantidadDisponible;

              state.cart.totalWithEfectiveDiscount +=
                product.precioVenta *
                  (1 - descuento.porcentaje) *
                  descuento.cantidadDisponible +
                diffWithoutDiscount * product.precioVenta;

              state.cart.totalWithAnyDiscount +=
                product.precioVenta *
                  (1 - descuento.porcentaje) *
                  descuento.cantidadDisponible +
                diffWithoutDiscount * product.precioVenta;
              //Si no simplemente aplicar descuento
            } else {
              state.cart.totalWithAnyDiscount +=
                product.precioVenta *
                (1 - descuento.porcentaje) *
                action.payload.quantity;

              state.cart.totalWithEfectiveDiscount +=
                product.precioVenta *
                (1 - descuento.porcentaje) *
                action.payload.quantity;
            }
          }
        } else {
          state.cart.totalWithEfectiveDiscount +=
            product.precioVenta * action.payload.quantity;

          state.cart.totalWithAnyDiscount +=
            product.precioVenta * action.payload.quantity;
        }
      } else {
        state.cart.totalWithEfectiveDiscount +=
          product.precioVenta * action.payload.quantity;

        state.cart.totalWithAnyDiscount +=
          product.precioVenta * action.payload.quantity;
      }

      if (
        !state.cart.whatsappConfirmation &&
        action.payload.whatsappConfirmation
      ) {
        state.cart.whatsappConfirmation = true;
      }
      if (
        state.cart.whatsappConfirmation &&
        !action.payload.whatsappConfirmation
      ) {
        state.cart.whatsappConfirmation = false;
      }

      state.cart.products[product.id] = {
        product: product,
        quantity:
          action.payload.quantity +
          (state.cart.products[product.id]
            ? state.cart.products[product.id].quantity
            : 0),
        petName: action.payload.petName,
        dailyConsumption: action.payload.dailyConsumption,
        estimatedOutOfFoodDate: action.payload.estimatedOutOfFoodDate,
      };
      if (!action.payload.dontOpenCart) {
        state.showCart = true;
      }
    },
    removeFromCart: (state, action) => {
      const product = state.cart.products[action.payload].product;

      const quantity = state.cart.products[action.payload].quantity;

      state.cart.productsCount -= quantity;

      //Delete subtotal from total
      state.cart.total -= product.precioVenta * quantity;

      const descuento = product.descuentos ? product.descuentos[0] : null;

      if (descuento) {
        if (
          validateDiscount(
            descuento.validoDesde,
            descuento.validaHasta,
            descuento.activo
          )
        ) {
          //Validate the discount
          const exceedsDiscountQuantities =
            state.cart.productsCount > descuento.cantidadDisponible &&
            descuento.cantidadDisponible !== null;

          //Si Excede la cantidad de descuento eliminar restar el total con descuento de las unidades con descuento y restar el precio total de las unidades que exceden

          if (exceedsDiscountQuantities) {
            const diffWithoutDiscount = quantity - descuento.cantidadDisponible;

            //Si el descuento es solo en efectivo aplicar descuento solo a las unidades con descuento en efectivo
            // Calculate the total with effective discount and any discount based on the discount rules
            if (descuento.soloEnEfectivo) {
              // Calculate the total with effective discount for cash payments
              state.cart.totalWithEfectiveDiscount -=
                product.precioVenta *
                  (1 - descuento.porcentaje) *
                  descuento.cantidadDisponible +
                diffWithoutDiscount * product.precioVenta;

              // Calculate the total with any discount
              state.cart.totalWithAnyDiscount -= product.precioVenta * quantity;
            } else {
              // Calculate the total with effective discount for non-cash payments
              state.cart.totalWithEfectiveDiscount -=
                product.precioVenta *
                  (1 - descuento.porcentaje) *
                  descuento.cantidadDisponible +
                diffWithoutDiscount * product.precioVenta;

              // Calculate the total with any discount
              state.cart.totalWithAnyDiscount -=
                product.precioVenta *
                  (1 - descuento.porcentaje) *
                  descuento.cantidadDisponible +
                diffWithoutDiscount * product.precioVenta;
            }
          } else {
            //Si no excede simplemente restar el total con descuento de las unidades con descuento
            if (descuento.soloEnEfectivo) {
              state.cart.totalWithEfectiveDiscount -=
                product.precioVenta * (1 - descuento.porcentaje) * quantity;

              state.cart.totalWithAnyDiscount -= product.precioVenta * quantity;
            } else {
              state.cart.totalWithEfectiveDiscount -=
                product.precioVenta * (1 - descuento.porcentaje) * quantity;

              state.cart.totalWithAnyDiscount -=
                product.precioVenta * (1 - descuento.porcentaje) * quantity;
            }
          }
        }
      } else {
        state.cart.totalWithEfectiveDiscount -= product.precioVenta * quantity;

        state.cart.totalWithAnyDiscount -= product.precioVenta * quantity;
      }

      delete state.cart.products[action.payload];

      if (state.cart.productsCount === 0) {
        state.cart = initialState.cart;
      }
    },
    reduceQuantityFromCart: (state, action) => {
      const fullProduct = state.cart.products[action.payload];
      const product = fullProduct.product;

      const descuento = product.descuentos ? product.descuentos[0] : null;

      state.cart.productsCount -= 1;
      fullProduct.quantity -= 1;

      state.cart.total -= product.precioVenta;

      if (descuento) {
        const exceedsDiscountQuantities =
          state.cart.products[action.payload].quantity >=
            descuento.cantidadDisponible &&
          descuento.cantidadDisponible !== null;

        if (
          validateDiscount(
            descuento.validoDesde,
            descuento.validaHasta,
            descuento.activo
          )
        ) {
          if (descuento.soloEnEfectivo) {
            state.cart.totalWithEfectiveDiscount -=
              product.precioVenta *
              (exceedsDiscountQuantities ? 1 : 1 - descuento.porcentaje);

            state.cart.totalWithAnyDiscount -= product.precioVenta;
          } else {
            state.cart.totalWithEfectiveDiscount -=
              product.precioVenta *
              (exceedsDiscountQuantities ? 1 : 1 - descuento.porcentaje);

            state.cart.totalWithAnyDiscount -=
              product.precioVenta *
              (exceedsDiscountQuantities ? 1 : 1 - descuento.porcentaje);
          }
        }
      } else {
        state.cart.totalWithEfectiveDiscount -= product.precioVenta;
        state.cart.totalWithAnyDiscount -= product.precioVenta;
      }

      if (state.cart.products[action.payload].quantity === 0) {
        delete state.cart.products[action.payload];
      }
      if (state.cart.productsCount === 0) {
        state.cart = initialState.cart;
      }
    },
    increaseQuantityInCart: (state, action) => {
      const product = state.cart.products[action.payload].product;

      state.cart.productsCount += 1;

      state.cart.total += product.precioVenta;

      const descuento = product.descuentos ? product.descuentos[0] : null;

      if (descuento) {
        const exceedsDiscountQuantities =
          state.cart.products[action.payload].quantity + 1 >
            descuento.cantidadDisponible &&
          descuento.cantidadDisponible !== null;

        if (
          validateDiscount(
            descuento.validoDesde,
            descuento.validaHasta,
            descuento.activo
          )
        ) {
          if (descuento.soloEnEfectivo) {
            state.cart.totalWithEfectiveDiscount +=
              product.precioVenta *
              (exceedsDiscountQuantities ? 1 : 1 - descuento.porcentaje);

            state.cart.totalWithAnyDiscount += product.precioVenta;
          } else {
            state.cart.totalWithEfectiveDiscount +=
              product.precioVenta *
              (exceedsDiscountQuantities ? 1 : 1 - descuento.porcentaje);

            state.cart.totalWithAnyDiscount +=
              product.precioVenta *
              (exceedsDiscountQuantities ? 1 : 1 - descuento.porcentaje);
          }
        }
      } else {
        state.cart.totalWithEfectiveDiscount += product.precioVenta;

        state.cart.totalWithAnyDiscount += product.precioVenta;
      }

      state.cart.products[action.payload].quantity += 1;
    },
    logUser: (state, action) => {
      state.user = action.payload;
    },
    logoutUser: (state) => {
      state.user = initialState.user;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  clearCart,
  showCart,
  hideCart,
  removeFromCart,
  addToCart,
  reduceQuantityFromCart,
  increaseQuantityInCart,
  logUser,
  logoutUser,
} = appSlice.actions;

export default appSlice.reducer;

export function validateDiscount(validoDesde, validaHasta, activo) {
  return (
    new Date(validoDesde) <= new Date() &&
    new Date(validaHasta) >= new Date() &&
    activo
  );
}
