import React, { useEffect } from 'react';
import noAutorizado from '../../assets/no-autorizado.jpg'

export function NoAutorizadoPage() {
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="bg-white w-full p-content pb-16 pt-4 flex items-center flex-col">
      <div className="">
        <img className="max-h-80" src={noAutorizado} alt="Gato y perro en una tina" />
      </div>
      <div className="w-full text-center mt-8">
        <p className="text-title text-6xl">
          Lo sentimos
        </p>
        <p className="text-title text-2xl">
          No estás autorizado para ingresar a esta página.
        </p>
      </div>
    </div>
  );
}

export default NoAutorizadoPage;