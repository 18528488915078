import React from "react";
import { Link } from "react-router-dom";
import {MdOutlineNavigateNext} from 'react-icons/md'

export function CategoryBreadcrumbs({ category, brand, color }) {
  let breadcrumb = [];
  let tempCategory = category.categoriaPadre;
  while (tempCategory) {
    breadcrumb.splice(
      0,
      0,
      <span
        key={`>_brand`}
        className={`${color || 'text-white'} font-title text-lg sm:text-2xl md:text-3xl uppercase`}
      >
      <MdOutlineNavigateNext className="inline text-lg sm:text-2xl md:text-3xl" />
      </span>
    );
    breadcrumb.splice(
      0,
      0,
      <Link
        key={`category_${tempCategory.id}`}
        to={`/tienda/categoria/${tempCategory.id}`}
      >
        <span
          className={`{ ${color || 'text-white'} } font-title text-lg sm:text-2xl md:text-3xl uppercase`}
        >
          {tempCategory.categoria.nombre}
        </span>
      </Link>
    );
    tempCategory = tempCategory.categoriaPadre;
  }
  if (brand) {
    breadcrumb.push(
      <Link
        key={`category_${category.id}`}
        to={`/tienda/categoria/${category.id}`}
      >
        <span
          key="current_category"
          className={`{ ${color || 'text-white'} } font-title text-lg sm:text-2xl md:text-3xl uppercase`}
        >
          {category.categoria.nombre}
        </span>
      </Link>
    );
    breadcrumb.push(
      <span
        key={`>_2_brand`}
        className={`${color || 'text-white'} font-title text-lg sm:text-2xl md:text-3xl uppercase`}
      >
      <MdOutlineNavigateNext className="inline text-lg sm:text-2xl md:text-3xl" />
      </span>
    );
    breadcrumb.push(
      <span
        key="current_category"
        className={`{ ${color || 'text-white'} } font-title text-lg sm:text-2xl md:text-3xl uppercase`}
      >
        {brand.nombreTienda}
      </span>
    );
  } else {
    breadcrumb.push(
      <span
        key="current_category"
        className={`{ ${color || 'text-white'} } font-title text-lg sm:text-2xl md:text-3xl uppercase`}
      >
        {category.categoria.nombre}
      </span>
    );
  }
  return breadcrumb;
}

export default CategoryBreadcrumbs;
