import React from "react";
import { AiOutlineArrowLeft, AiOutlineArrowRight, AiOutlineFastBackward, AiOutlineFastForward } from "react-icons/ai";

export function Pagination({ currentPage, onPageChange, totalPages }) {
  const renderPages = () => {
    const pages = [
      <div
        key={`page_primera`}  
      >
        <button
          disabled={currentPage === 0}
          onClick={() => {
            onPageChange(0);
          }}
          className="bg-purple text-white p-2 w-8 h-8 disabled:opacity-50"
        >
          <AiOutlineFastBackward className="h-4" />
        </button>
      </div>,
      <div
        key={`page_anterior`}
      >
        <button
          disabled={currentPage === 0}
          onClick={() => {
            onPageChange(currentPage - 1);
          }}
          className="bg-purple text-white p-2 w-8 h-8 disabled:opacity-50"
        >
          <AiOutlineArrowLeft className="h-4" />
        </button>
      </div>,
    ];
    for (let index = 0; index < totalPages; index++) {
      pages.push(
        <button
          key={`page_${index+1}`}
          onClick={() => {
            onPageChange(index);
          }}
          className={`${currentPage === index ? "bg-orange" : "bg-purple"} text-white p-2 w-8 h-8 flex items-center justify-center`}
        >
          {index + 1}
        </button>
      );
    }
    pages.push(
      <div
        key={`page_siguiente`}
      >
        <button
          disabled={currentPage === totalPages - 1}
          onClick={() => {
            onPageChange(currentPage + 1);
          }}
          className="bg-purple text-white p-2 w-8 h-8 disabled:opacity-50"
        >
          <AiOutlineArrowRight className="h-4" />
        </button>
      </div>
    );
    pages.push(
      <div
        key={`page_ultima`}
      >
        <button
          disabled={currentPage === totalPages - 1}
          onClick={() => {
            onPageChange(totalPages - 1);
          }}
          className="bg-purple text-white p-2 w-8 h-8 disabled:opacity-50"
        >
          <AiOutlineFastForward className="h-4" />
        </button>
      </div>
    );
    return pages;
  };

  return (
    <div className="my-8">
      <div className="w-full flex flex-wrap gap-1 justify-center">
        {renderPages()}
      </div>
    </div>
  );
}

export default Pagination;
