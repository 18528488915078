import React from "react";
import  { IoIosCloseCircleOutline } from 'react-icons/io';


export function Modal({ children, open, closeModalFunction, backgroundColor }) {
  return (
    open && (
      <div className="z-[400] fixed top-0 left-0 h-screen w-screen">
        <div
          onClick={() => {
            closeModalFunction();
          }}
          className="bg-baseGray/70 position fixed top-0 left-0 h-screen w-screen z-40"
        />
        <div
          className={`z-50 fixed top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] w-[70vw] h-[auto] max-h-[60vh] overflow-y-scroll ${
            backgroundColor || "bg-white"
          } rounded-3xl`}
        >
          <p
            onClick={() => {
              closeModalFunction();
            }}
            className="text-white text-4xl font-bold float-right mr-8 mt-8 cursor-pointer select-none absolute right-0 top-[-15px]"
          >
          <IoIosCloseCircleOutline />
          </p>
          {children}
        </div>
      </div>
    )
  );
}

export default Modal;
