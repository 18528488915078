import React from "react";

export function Tooltip({
  text,
  bgColor,
  textColor,
  children
}) {
  return (
    <div className="has-tooltip">
      <span className={`tooltip rounded-full text-sm ${bgColor} ${textColor} -ml-12 -mt-10 px-5 py-1`}>{text}</span>
      {children}
    </div>
  );
}

export default Tooltip;
