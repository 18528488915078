import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { IoMdArrowRoundBack } from "react-icons/io";
import DatePicker, { registerLocale } from "react-datepicker";
import es from "date-fns/locale/es";
import { v4 as uuidv4 } from "uuid";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import { isNaN } from "lodash";

import { CODIGO_TARIFA } from "../../utils/constants";
import useAuthFetch from "../../hooks/useAuthFetch";
import {
  deleteLineaOrdenCompra,
  setDefaultLienasOrdenCompra,
  setLineaOrdenCompra,
  updateLineaOrdenCompra,
} from "../../slices/inventorySlice";
import Select from "../../components/Select";
import NumberSteper from "../../components/NumberSteper";
import Spinner from "../../components/Spinner";
import "react-datepicker/dist/react-datepicker.css";
import { TableOrders } from "../../components/Table";
import { SearchProductOrden } from "../../components/Select/SearchProductOrden";
registerLocale("es", es);

const OrdenesCompraEdit = () => {
  const authFetch = useAuthFetch();
  const navigate = useNavigate();
  const { idOrden } = useParams();
  const { token } = useSelector((store) => store.app.user);

  const dispatch = useDispatch();
  const { lineasOrdeCompra } = useSelector((state) => state.inventory);
  const [lineas, setLineas] = useState(lineasOrdeCompra);

  const [catalogs, setCatalogs] = useState({
    providers: [],
    bodegas: [],
    products: [],
  });
  const [loading, setLoading] = useState(false);
  const [fecha_entrega_estimada, setFecha_entrega_estimada] = useState();
  const [formValues, setFormValues] = useState({
    proveedorId: "",
    bodegaId: "",
    refProvedor: "",
  });
  const [product, setProduct] = useState({
    idProducto: null,
    nombre: "",
    fotoPrincipal: "",
  });
  const [precioUnitario, setPrecioUnitario] = useState(0);
  const [cantidad, setCantidad] = useState(0);
  const [impuesto, setImpuesto] = useState(8);
  const [errors, setErrors] = useState({
    errorLinea: false,
    errorGeneral: false,
  });
  const [msgError, setMsgError] = useState({
    errorLinea: "",
    errorGeneral: "",
  });
  const [indexEdit, setIndexEdit] = useState(null);
  const [orden, setOrden] = useState(null);
  const [indexView, setIndexView] = useState(null);
  const [descuento, setDescuento] = useState(0);

  useEffect(() => {
    const fetchCatalogs = async () => {
      setLoading(true);

      const providersFetch = await authFetch(
        `proveedores?filter={ "where": {"activo": 1}, "order": ["nombre asc"]}`
      );
      const bodegasFetch = await authFetch(
        `bodegas?filter={"where": {"activo": 1}, "order": ["nombre asc"]}`
      );
      let products = [];
      if (formValues.proveedorId !== "") {
        const productsFetch = await authFetch(
          `productos?filter={"where":{"idProveedor": ${formValues.proveedorId}}, "include":[{"relation": "marca"}, {"relation": "lineasOrdenCompras","scope":{"limit":1,"order":["idOrdenCompra DESC"]}}]}`
        );
        products = await productsFetch.json();
      }
      const bodegas = await bodegasFetch.json();
      const providers = await providersFetch.json();
      setCatalogs({
        providers,
        bodegas,
        products,
      });
      setLoading(false);
    };
    fetchCatalogs();
    window.scrollTo(0, 0);
  }, [formValues.proveedorId]);
  // "where":{"proveedor":""}
  useEffect(() => {
    const fetchOrden = async () => {
      const data = await authFetch(
        `orden-compras/${idOrden}?filter={"include": [{"relation": "lineaOrdenCompras", "scope": {"include": [{"relation": "producto"}]}}]}`
      );
      const dataJSON = await data.json();
      if (dataJSON.lineaOrdenCompras) {
        dataJSON.lineaOrdenCompras = dataJSON.lineaOrdenCompras.map((l) =>
          addImpuestoDesc(l)
        );
      }
      setOrden(dataJSON);
    };
    if (!isNaN(idOrden)) {
      fetchOrden();
      window.scrollTo(0, 0);
    }
  }, [idOrden, dispatch]);

  const addImpuestoDesc = (l) => {
    if (l.porcentajeImpuesto === 1.13) {
      const linea = {
        cantidad: l.cantidad,
        id: l.linea,
        idProducto: l.producto.id,
        impuesto: 8,
        precioUnitario: l.precioUnitario,
        descuento: l.montoDescuento,
        producto: {
          nombre: l.producto.nombre,
          fotoPrincipal: l.producto.fotoPrincipal,
        },
        impuestoDesc: "Tarifa general 13%",
      };
      return linea;
    } else {
      const linea = {
        cantidad: l.cantidad,
        id: l.linea,
        idProducto: l.producto.id,
        impuesto: l.porcentajeImpuesto,
        precioUnitario: l.precioUnitario,
        descuento: l.montoDescuento,
        producto: {
          nombre: l.producto.nombre,
          fotoPrincipal: l.producto.fotoPrincipal,
        },
        impuestoDesc: "",
      };
      return linea;
    }
  };

  function addDaysToDate(date, days) {
    let res = new Date(date);
    res.setDate(res.getDate() + days);
    return res;
  }

  useEffect(() => {
    if (orden) {
      // setLoading(true);
      setFormValues({
        proveedorId: orden.idProveedor,
        bodegaId: orden.idBodegaDestino,
        refProvedor: orden.referenciaProveedor,
      });
      setFecha_entrega_estimada(addDaysToDate(orden.fechaEntregaEstimada, 1));

      if (lineas && lineas <= orden.lineas) {
        setLineas(orden.lineaOrdenCompras);
        dispatch(setDefaultLienasOrdenCompra(orden.lineaOrdenCompras));
      }

      if (lineas && lineas.length === 0) {
        if (orden.lineaOrdenCompras) {
          setLineas(orden.lineaOrdenCompras);
          dispatch(setDefaultLienasOrdenCompra(orden.lineaOrdenCompras));
        } else {
          // setLoading(false);
          return;
        }
      }
      // setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orden, dispatch]);

  const changeQuantity = (newValue) => {
    setCantidad((prev) => Math.max(prev + newValue, 0));
  };

  const longitudNumero = (num) => {
    let len = Math.ceil(Math.log(num + 1) / Math.LN10);
    if (len < 11) {
      return false;
    } else {
      return true;
    }
  };
  const addLinea = () => {
    if (product.idProducto === null) {
      setErrors({ ...errors, errorLinea: true });
      setMsgError({ ...msgError, errorLinea: "Seleccione un producto" });
      return;
    }
    if (cantidad === 0 || cantidad === null) {
      setErrors({ ...errors, errorLinea: true });
      setMsgError({ ...msgError, errorLinea: "Ingrese una cantidad" });
      return;
    }
    if (precioUnitario <= 0 || precioUnitario === null) {
      setErrors({ ...errors, errorLinea: true });
      setMsgError({ ...msgError, errorLinea: "Ingrese un precio unitario" });
      return;
    }
    if (descuento < 0 || precioUnitario === null) {
      setErrors({ ...errors, errorLinea: true });
      setMsgError({ ...msgError, errorLinea: "Ingrese un descuento valido" });
      return;
    }

    if (longitudNumero(precioUnitario)) {
      setErrors({ ...errors, errorLinea: true });
      setMsgError({
        ...msgError,
        errorLinea: "Ingrese un precio unitario inferior a las 10 cifras",
      });
      return;
    }

    if (indexEdit === null) {
      const impuestoDesc = CODIGO_TARIFA.find(
        (item) => item.valor === impuesto
      );
      const linea = {
        cantidad,
        id: uuidv4(),
        idProducto: product.idProducto,
        precioUnitario,
        descuento,
        producto: {
          nombre: product.nombre,
          fotoPrincipal: product.fotoPrincipal,
        },
        impuestoDesc: impuestoDesc.descripción,
        impuesto,
      };
      if (existProduct(linea)) {
        setLineas((prev) => [...prev, linea]);
        dispatch(setLineaOrdenCompra(linea));
      } else {
        setErrors({ ...errors, errorLinea: true });
        setMsgError({
          ...msgError,
          errorLinea:
            "Este producto ya existe dentro de la orden de compra, porfavor edite la linea en donde se encuntra dicho producto",
        });
        return;
      }
    }
    if (indexEdit) {
      const impuestoDesc = CODIGO_TARIFA.find(
        (item) => item.valor === impuesto
      );
      const linea = {
        cantidad,
        id: indexEdit,
        idProducto: product.idProducto,
        precioUnitario,
        descuento,
        producto: {
          nombre: product.nombre,
          fotoPrincipal: product.fotoPrincipal,
        },
        impuestoDesc: impuestoDesc.descripción,
        impuesto,
      };
      setLineas((oldLineas) =>
        oldLineas.map((l) => (l.id === linea.id ? linea : l))
      );
      dispatch(updateLineaOrdenCompra(linea));
    }

    // reset values
    setProduct({
      idProducto: null,
      nombre: "",
      fotoPrincipal: "",
    });
    setPrecioUnitario(0);
    setCantidad(0);
    setImpuesto(8);
    setIndexEdit(null);
    setDescuento(null);
    setErrors({ errorGeneral: false, errorLinea: false });
  };

  const removeLinea = (index, l) => {
    setLineas((prev) => {
      const newLineas = [...prev];
      newLineas.splice(index, 1);
      return newLineas;
    });
    if (lineas && lineas.length !== 0) {
      dispatch(deleteLineaOrdenCompra(l));
    }
  };
  const editLinea = (linea, index) => {
    setErrors({ ...errors, errorLinea: false });
    setIndexEdit(linea.id);
    setProduct({
      idProducto: linea.idProducto,
      nombre: linea.producto.nombre,
      fotoPrincipal: linea.producto.fotoPrincipal,
    });
    setPrecioUnitario(linea.precioUnitario);
    setCantidad(linea.cantidad);
    setImpuesto(linea.impuesto);
    setDescuento(linea.descuento);
    setIndexView(index + 1);
  };

  const existProduct = (linea) => {
    if (lineas) {
      for (let i = 0; i < lineas.length; i++) {
        if (lineas[i].idProducto === linea.idProducto) {
          return false;
        }
      }
      return true;
    }
    return true;
  };

  const salirOrden = async () => {
    Swal.fire({
      title: "¿Estas seguro que deseas salir?",
      text: "Si abandonas el módulo editar una orden de compra el avance de lo realizado se perdera",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Si, deseo salir",
      cancelButtonText: "Cancelar",
      confirmButtonColor: "#19D3C5",
      cancelButtonColor: "#E7004C",
      background: "#fafafa",
      color: "#76236C",
    }).then((result) => {
      if (result.isConfirmed) {
        navigate("/ordenes");
      }
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setErrors({ ...errors, errorGeneral: false });
    if (lineas && lineas.length === 0) {
      setLoading(false);
      setErrors({ ...errors, errorGeneral: true });
      setMsgError({
        ...msgError,
        errorGeneral: "Agregue al menos una linea de compra",
      });
      return;
    }
    if (fecha_entrega_estimada === null) {
      setLoading(false);
      setErrors({ ...errors, errorGeneral: true });
      setMsgError({
        ...msgError,
        errorGeneral: "Ingrese una fecha de entrega estimada",
      });
      return;
    }
    if (formValues.bodegaId === "" || formValues.bodegaId === null) {
      setLoading(false);
      setErrors({ ...errors, errorGeneral: true });
      setMsgError({ ...msgError, errorGeneral: "Seleccione una bodega" });
      return;
    }
    if (formValues.proveedorId === "" || formValues.proveedorId === null) {
      setLoading(false);
      setErrors({ ...errors, errorGeneral: true });
      setMsgError({
        ...msgError,
        errorGeneral: "Ingrese una referencia del proveedor",
      });
      return;
    }

    const lineasToSend = lineas.map((item, index) => {
      return {
        linea: index + 1,
        idProducto: item.idProducto,
        cantidad: item.cantidad,
        precioUnitario: Number(item.precioUnitario),
        idOrdenCompra: Number(idOrden),
        montoDescuento: Number(item.descuento),
        porcentajeImpuesto: item.impuesto,
      };
    });
    const OrdenesCompra = await authFetch(
      `orden-compras/${idOrden}`,
      {
        ordenCompra: {
          idProveedor: formValues.proveedorId,
          idBodegaDestino: formValues.bodegaId,
          referenciaProveedor: formValues.refProvedor,
          fechaEntregaEstimada: fecha_entrega_estimada,
          fechaMaximaPago: new Date(),
        },
        lineas: lineasToSend,
      },
      "PATCH",
      token
    );

    if (
      OrdenesCompra.status === 200 ||
      OrdenesCompra.status === 204 ||
      OrdenesCompra.status === 201 ||
      OrdenesCompra.status === 202
    ) {
      setLoading(false);
      Swal.fire({
        icon: "success",
        title: "Correcto",
        text: "Ordenes compra actualizada correctamente",
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp",
        },
        background: "#76236C",
        color: "#fafafa",
        confirmButtonColor: "#19D3C5",
      });
      setTimeout(() => {
        navigate("/ordenes");
      }, 400);
    } else {
      setLoading(false);
      setErrors({ ...errors, errorGeneral: true });
      setMsgError({
        ...msgError,
        errorGeneral: "Error al actualizar la orden de compra",
      });
      return;
    }
    if (OrdenesCompra.status === 400) {
      setLoading(false);
      const dat = await OrdenesCompra.json();
      setErrors({ ...errors, errorGeneral: true });
      setMsgError({
        ...msgError,
        errorGeneral: dat.message,
      });
      return;
    }
    if (OrdenesCompra.status === 500 || OrdenesCompra.status === 404) {
      setLoading(false);
      setErrors({ ...errors, errorGeneral: true });
      setMsgError({
        ...msgError,
        errorGeneral: "Error al actualizar la orden de compra",
      });
      return;
    }
    setLoading(false);
  };

  return (
    <div className="w-full p-content pb-16">
      {loading && lineas ? (
        <div className="min-h-[400px]">
          <Spinner />
        </div>
      ) : (
        <div>
          <div className="flex w-full items-center">
            <div className="w-full flex justify-between">
              <button
                className="bg-pink text-white py-2 px-8 font-bold rounded-full float-right flex justify-center items-center"
                onClick={salirOrden}
              >
                <IoMdArrowRoundBack className="text-lg" /> Volver
              </button>
            </div>
          </div>

          <h2 className="my-3 font-bold text-baseGray text-2xl">
            Editar una nueva orden de compra
          </h2>

          <form>
            <br />
            <div>
              <Select
                containerClasses="my-4"
                labelColor="text-base"
                buttonClasses="border border-purple"
                label="Proveedor:"
                required
                options={catalogs.providers.map((b) => {
                  return {
                    value: b.id,
                    label: b.nombre,
                  };
                })}
                onChange={(value) =>
                  setFormValues({ ...formValues, proveedorId: value })
                }
                value={formValues.proveedorId}
              />
            </div>
            <div>
              <Select
                containerClasses="my-4"
                labelColor="text-base"
                buttonClasses="border border-purple"
                label="Bodega de destino:"
                required
                options={catalogs.bodegas.map((b) => {
                  return {
                    value: b.id,
                    label: b.nombre,
                  };
                })}
                onChange={(value) =>
                  setFormValues({ ...formValues, bodegaId: value })
                }
                value={formValues.bodegaId}
              />
            </div>

            <div className="my-2">
              <label className="">Referencia del proveedor</label>
              <input
                onChange={(e) =>
                  setFormValues({ ...formValues, refProvedor: e.target.value })
                }
                className="w-full rounded-3xl my-2 h-12 p-6 ring-1 ring-purple outline-none"
                type={"text"}
                name="refProvedor"
                value={formValues.refProvedor}
                placeholder="Referencia del proveedor"
                required={true}
              />
            </div>
            <div className="my-4">
              <label className="">Fecha entrega estimada</label>
              <div
                // onChange={(evt) => {}}
                className="w-full rounded-3xl my-2 h-12 px-4 py-3 ring-1 ring-purple outline-none"
                // type={"number"}
                // placeholder="total_exonerado"
                required={true}
              >
                <DatePicker
                  className="-top-3 w-full focus:outline-none"
                  onChange={(date) => setFecha_entrega_estimada(date)}
                  selected={fecha_entrega_estimada}
                  locale={es}
                  dateFormat="MMMM dd, yyyy"
                  yearDropdownItemNumber={72}
                  showYearDropdown
                  scrollableYearDropdown
                  previousYearButtonLabel={"More"}
                  nextYearButtonLabel={"More"}
                />
              </div>
            </div>

            {lineas && lineas.length > 0 && (
              <TableOrders
                lineas={lineas}
                removeLinea={removeLinea}
                editLinea={editLinea}
              />
            )}
            <div>
              <hr className="my-4 bg-purple text-purple-border" />
              <label>
                {" "}
                {indexView
                  ? `Línea #${indexView}`
                  : `Línea #${lineas && lineas.length ? lineas.length + 1 : 1}`}
              </label>
              {formValues.proveedorId && (
                <div className="my-2">
                  <label className="">Producto</label>
                  <SearchProductOrden
                    products={catalogs.products}
                    setProduct={setProduct}
                    product={product}
                    conPrecio={true}
                    setPrecioUnitario={setPrecioUnitario}
                  />
                </div>
              )}
              <div className="my-2">
                <label className="ml-5">Cantidad</label>
                <div className="my-2">
                  <NumberSteper
                    label=""
                    value={cantidad}
                    onChangeValue={changeQuantity}
                    negativeStepSize={-1}
                    positiveStepSize={1}
                  />
                </div>
              </div>
              <div className="my-2">
                <label className="">Precio unitario por producto</label>
                <input
                  className="w-full rounded-3xl my-2 h-12 p-6 ring-1 ring-purple outline-none"
                  type={"number"}
                  name="precioUnitario"
                  onChange={(e) => setPrecioUnitario(e.target.value)}
                  placeholder="Precio unitario por producto"
                  value={Number(precioUnitario)}
                  required={true}
                />
              </div>

              <div className="my-2">
                <label className="">Descuento por producto</label>
                <input
                  className="w-full rounded-3xl my-2 h-12 p-6 ring-1 ring-purple outline-none"
                  type={"number"}
                  name="precioUnitario"
                  onChange={(e) => setDescuento(e.target.value)}
                  placeholder="Descuento por producto"
                  value={Number(descuento)}
                  required={true}
                />
              </div>
              <div className="my-2">
                <Select
                  containerClasses="my-2"
                  labelColor="text-base"
                  buttonClasses="border border-purple"
                  label="Tarifa del impuesto:"
                  required
                  options={CODIGO_TARIFA.map((b) => {
                    return {
                      value: b.valor,
                      label: b.descripción,
                    };
                  })}
                  onChange={(value) => {
                    setImpuesto(value);
                  }}
                  value={Number(impuesto)}
                />
              </div>
            </div>

            <div
              className="bg-purple cursor-pointer text-white py-2 px-8 font-bold rounded-full float-right flex justify-center items-center"
              onClick={addLinea}
            >
              {indexEdit !== null
                ? "Editar linea de compra"
                : "Agregar linea de compra"}
            </div>

            <br />
            <br />
            {errors.errorLinea && (
              <p className="text-pink text-center">{msgError.errorLinea}</p>
            )}
            <hr className="my-4 bg-purple text-purple-border" />
            {errors.errorGeneral && (
              <p className="text-pink text-center">{msgError.errorGeneral}</p>
            )}

            <input
              className="bg-cyan cursor-pointer text-white py-2 px-8 font-bold rounded-full float-right flex justify-center items-center transition-shadow duration-300 ease-linear hover:shadow-md hover:shadow-cyan/50"
              onClick={handleSubmit}
              type={"submit"}
              value={"Guardar"}
            />
          </form>
        </div>
      )}
    </div>
  );
};

export default OrdenesCompraEdit;
