import React, { useCallback, useEffect, useState } from "react";
import { IoMdArrowRoundBack } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import Pagination from "../../components/Pagination";
// import GoogleMapReact from "google-map-react";
import Spinner from "../../components/Spinner";
import {
  setDefaultLienasOrdenCompra,
  setOCBodega,
  setOCProveedor,
  setOCRefProveedor,
} from "../../slices/inventorySlice";
import useAuthFetch from "../../hooks/useAuthFetch";
import { ESTADO_ORDEN_COMPRA } from "../../utils/constants";
const OrdenesCompra = () => {
  const authFetch = useAuthFetch();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { token } = useSelector((store) => store.app.user);

  const [ordenes, setordenes] = useState();
  const [page, setPage] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [totalPages, setTotalPages] = useState(0);

  const [loading, setLoading] = useState(false);

  const fetchOrdenes = useCallback(async () => {
    const pageSize = 5;
    setLoading(true);

    const OrdenesFetch = await authFetch(
      `orden-compras?filter={
			"include": [{"relation": "bodega"}, {"relation": "proveedor"}, {"relation": "lineaOrdenCompras", "scope": {"include": [{"relation": "producto"}]}}],
			"limit": ${pageSize},
			"skip": ${page * pageSize},
			"order": ["createdAt DESC"]}`,
      {},
      "GET",
      token
    );
    const ordenesData = await OrdenesFetch.json();
    setordenes(ordenesData);
    const totalOrdenesFetch = await authFetch(
      `orden-compras/count`,
      {},
      "GET",
      token
    );
    const totalordenesData = await totalOrdenesFetch.json();
    setTotalPages(Math.ceil(totalordenesData.count / pageSize));
    setTotalCount(totalordenesData.count);

    setLoading(false);
    window.scrollTo(0, 0);
  }, [page, token]);

  const handleEditar = (id) => {
    dispatch(setDefaultLienasOrdenCompra([]));
    navigate(`/ordenes/${id}`);
  };

  const handleNuevaOrden = () => {
    dispatch(setDefaultLienasOrdenCompra([]));
    dispatch(setOCBodega({ bodegaId: "" }));
    dispatch(setOCRefProveedor({ refProvedor: "" }));
    dispatch(setOCProveedor({ proveedorId: "" }));
    navigate(`/ordenes/nuevo`);
  };

  const handleNuevaOrdenBySells = () => {
    dispatch(setDefaultLienasOrdenCompra([]));
    dispatch(setOCBodega({ bodegaId: "" }));
    dispatch(setOCRefProveedor({ refProvedor: "" }));
    dispatch(setOCProveedor({ proveedorId: "" }));
    navigate(`/ordenes/nuevo/ventas`);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchOrdenes();
  }, [fetchOrdenes]);

  const fetchDeleteOrdenCompra = async (id) => {
    setLoading(true);
    const ordenCompraFetch = await authFetch(
      `orden-compras/${id}`,
      {},
      "DELETE",
      token
    );
    if (
      ordenCompraFetch.status === 200 ||
      ordenCompraFetch.status === 201 ||
      ordenCompraFetch.status === 204
    ) {
      Swal.fire({
        icon: "success",
        title: "¡Correcto!",
        text: "Orden de compra eliminada correctamente",
        background: "#76236C",
        color: "#fafafa",
        confirmButtonColor: "#19D3C5",
      }).then(() => {
        setLoading(false);
        fetchOrdenes();
        setPage(0);
      });
    }
    if (
      ordenCompraFetch.status === 400 ||
      ordenCompraFetch.status === 401 ||
      ordenCompraFetch.status === 404 ||
      ordenCompraFetch.status === 500
    ) {
      Swal.fire({
        icon: "error",
        title: "¡Error!",
        text: "Error al eliminar la orden de compra",
        background: "#76236C",
        color: "#fafafa",
        confirmButtonColor: "#19D3C5",
      }).then(() => {
        setLoading(false);
      });
    }
  };

  const deleteOrdenCompra = async (id) => {
    Swal.fire({
      title: "¿Estas seguro que deseas eliminar esta orden de compra?",
      text: "Una vez realizada la acción no podrás revertirla",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Si, eliminar la orden de compra",
      cancelButtonText: "Cancelar",
      confirmButtonColor: "#19D3C5",
      cancelButtonColor: "#E7004C",
      background: "#76236C",
      color: "#fafafa",
    }).then((result) => {
      if (result.isConfirmed) {
        fetchDeleteOrdenCompra(id);
        fetchOrdenes();
        setPage(0);
      }
    });
  };

  const fetchMarcarPagaOrdenCompra = async (id) => {
    setLoading(true);
    const ordenCompraFetch = await authFetch(
      `orden-compras/${id}`,
      {
        ordenCompra: {
          fechaRealPago: new Date(),
        },
      },
      "PATCH",
      token
    );

    if (
      ordenCompraFetch.status === 200 ||
      ordenCompraFetch.status === 201 ||
      ordenCompraFetch.status === 204
    ) {
      Swal.fire({
        icon: "success",
        title: "¡Correcto!",
        text: "Orden de compra marcada como finalizada correctamente",
        background: "#fafafa",
        color: "#76236C",
        confirmButtonColor: "#19D3C5",
      });
      fetchOrdenes();
    }
    if (
      ordenCompraFetch.status === 400 ||
      ordenCompraFetch.status === 401 ||
      ordenCompraFetch.status === 404 ||
      ordenCompraFetch.status === 500
    ) {
      Swal.fire({
        icon: "error",
        title: "¡Error!",
        text: "Error al marcar como finalizada esta orden de compra",
        background: "#fafafa",
        color: "#76236C",
        confirmButtonColor: "#19D3C5",
      });
      fetchOrdenes();
    }
  };

  const marcarPagaOrdenCompra = async (id) => {
    const orden = ordenes.find((o) => o.id === id);
    const anyLinePending = orden.lineaOrdenCompras.some(
      (linea) => !linea.recibido
    );
    if (anyLinePending) {
      Swal.fire({
        icon: "error",
        title: "¡Error!",
        text: "No se puede marcar como finalizada la orden de compra ya que hay líneas pendientes de recibir.",
        background: "#fafafa",
        color: "#76236C",
        confirmButtonColor: "#19D3C5",
      });
      return;
    }
    Swal.fire({
      title:
        "¿Estas seguro que deseas marcar como finalizada esta orden de compra?",
      text: "Una vez realizada la acción no podrás revertirla",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Si, marcar como finalizada esta orden de compra",
      cancelButtonText: "Cancelar",
      confirmButtonColor: "#19D3C5",
      cancelButtonColor: "#E7004C",
      background: "#fafafa",
      color: "#76236C",
    }).then((result) => {
      if (result.isConfirmed) {
        fetchMarcarPagaOrdenCompra(id);
        generarTransferencias(id);
        fetchOrdenes();
        setPage(0);
      }
    });
  };

  const generarTransferencias = useCallback(
    async (idOrdenCompra) => {
      await authFetch(
        `/orden-compras/tranferencias-pendientes/${idOrdenCompra}`,
        {},
        "PATCH",
        token
      );
    },
    [token]
  );

  return (
    <>
      <div className="w-full p-content pb-16">
        {loading ? (
          <div className="min-h-[400px]">
            <Spinner />
          </div>
        ) : (
          <div>
            <div className="flex w-full items-center">
              <div className="w-full flex justify-between">
                <div>
                  <p className="text-2xl font-bold mb-4">
                    Ordenes de compra (Total: {totalCount})
                  </p>
                </div>

                <Link to="/inventario">
                  <button className="bg-pink text-white py-2 px-8 font-bold rounded-full float-right flex justify-center items-center">
                    <IoMdArrowRoundBack className="text-lg" /> Volver
                  </button>
                </Link>
              </div>
            </div>
            <div className="flex flex-col md:flex-row md:justify-between w-full mt-4 mb-2">
              <button
                className="bg-cyan text-white py-2 px-8 font-bold rounded-full"
                onClick={handleNuevaOrden}
              >
                {"+"} Nueva orden de compra
              </button>
              <button
                className="bg-purple text-white py-2 px-8 font-bold rounded-full"
                onClick={handleNuevaOrdenBySells}
              >
                {"+"} Nueva orden de compra con base en ventas
              </button>
            </div>
            <br />
            {ordenes &&
              ordenes.map((orden, index) => (
                <div
                  className="w-full ring-1 ring-purple rounded-lg mb-4 p-6"
                  key={index}
                >
                  <div>
                    <p className="text-2xl font-semibold text-baseGray">
                      Número de orden: #{orden.id}
                    </p>
                    {orden.hasOwnProperty("idEstadoOrdenCompra") && (
                      <p className="font-semibold my-2 text-purple rounded-md">
                        <span className="text-md text-baseGray font-bold">Estado</span>: {orden.idEstadoOrdenCompra !== undefined &&
                          ESTADO_ORDEN_COMPRA.find(
                            (o) => o.id === orden.idEstadoOrdenCompra
                          )?.valor}
                      </p>
                    )}
                    <p className="font-semibold my-2 text-purple rounded-md">
                      {orden.hasOwnProperty("idEstadoOrdenCompra") &&
                        orden.idEstadoOrdenCompra === null &&
                        orden.fechaRealPago !== null &&
                        "Finalizada"}
                    </p>
                    <p className="text-md font-medium text-baseGray my-2">
                      <b>Nombre del proveedor:</b> {orden.proveedor.nombre}
                    </p>
                    <p className="text-md font-medium text-baseGray my-2">
                      <b>Bodega destino:</b> {orden.bodega.nombre}
                    </p>
                    <p className="text-md font-medium text-baseGray my-2">
                      <b>Referencia del proveedor:</b>{" "}
                      {orden.referenciaProveedor}
                    </p>
                    <p className="text-md font-medium text-baseGray my-2">
                      {orden.hasOwnProperty("lineaOrdenCompras")
                        ? (<><span className="font-bold">Total de lineas de la orden:</span> {orden.lineaOrdenCompras.length}</>)
                        : "Esta orden no posee líneas"}
                    </p>
                    {orden.fechaRealPago === null ? (
                      orden.hasOwnProperty("idEstadoOrdenCompra") &&
                      orden.idEstadoOrdenCompra !== 135 ? (
                        <div>
                          {orden.hasOwnProperty("lineaOrdenCompras") && (
                            <Link
                              to={`/ordenes/marcar_recibida/${orden.id}`}
                              className="rounded-3xl py-2 px-8 ml-2 my-1 font-medium text-white bg-cyan"
                            >
                              Recibir
                            </Link>
                          )}
                          {orden.hasOwnProperty("lineaOrdenCompras") && (
                            <button
                              className="rounded-3xl py-2 px-8 ml-2 my-1 font-medium text-white bg-cyan"
                              onClick={() => marcarPagaOrdenCompra(orden.id)}
                            >
                              Marcar orden como finalizada
                            </button>
                          )}
                          <button
                            className="rounded-3xl ml-2 py-2 px-8 my-1 font-medium text-white bg-purple"
                            onClick={() => handleEditar(orden.id)}
                          >
                            Editar
                          </button>

                          <button
                            className="rounded-3xl py-2 px-8 ml-2 my-1 font-medium text-white bg-pink"
                            onClick={() => deleteOrdenCompra(orden.id)}
                          >
                            Eliminar
                          </button>
                        </div>
                      ) : (
                        <div>
                          <p className="text-cyan shadow-lg py-3 rounded-lg">
                            <span className="font-bold">Estado de la orden: </span>{" "}
                            {
                              ESTADO_ORDEN_COMPRA.find(
                                (o) => o.id === orden.idEstadoOrdenCompra
                              )?.valor
                            }
                          </p>
                          <Link to={`/ordenes/ver/${orden.id}`}>
                            <button className="rounded-3xl py-2 px-8 mt-2 font-medium text-white bg-cyan">
                              Ver la orden de compra
                            </button>
                          </Link>
                        </div>
                      )
                    ) : (
                      <div>
                        <Link to={`/ordenes/ver/${orden.id}`}>
                          <button className="rounded-3xl py-2 px-8 mt-2 font-medium text-white bg-cyan">
                            Ver la orden de compra
                          </button>
                        </Link>
                      </div>
                    )}
                  </div>
                </div>
              ))}
            <Pagination
              totalPages={totalPages}
              currentPage={page}
              onPageChange={(page) => {
                setPage(page);
              }}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default OrdenesCompra;
