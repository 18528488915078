import React from "react";
import { useContext } from "react";
import { CierreContext } from "../../containers/CierresPage";
import { numberToColonesFormat } from "../../utils/formatNumbers";
import MetodoPagoCard from "../MetodoPagoCard";
import PendientesPagoTable from "../PendientesPagoTable";

export default function CierreDetail() {
  const { cierre, filter } = useContext(CierreContext);

  const totalPendientes = cierre.pendientes.reduce((total, pendiente) => {
    return total + pendiente.montoPendiente;
  }, 0);

  const totalMontoRecibido = cierre.montoRecibido || 0.0;

  if (filter === "tienda" || filter === "transportista") {
    return (
      <div className="mt-5 flex flex-col gap-5">
        <div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3">
          <div className="border-purple border rounded-lg p-8 flex flex-col gap-3">
            <h4 className="text-xl">Total de ordenes</h4>
            <p className="font-bold">{cierre.totalOrdenes}</p>
          </div>
          <div className="border-purple border rounded-lg p-8 flex flex-col gap-3">
            <h4 className="text-xl">Monto total a recaudar</h4>
            <p className="font-bold">
              {numberToColonesFormat(calcularMontoTotal(cierre.cierreMontos))}
            </p>
          </div>
          <div className="border-purple border rounded-lg p-8 flex flex-col gap-3">
            <h4 className="text-xl">Pendientes de pago</h4>
            <p className="font-bold">{cierre.pendientes.length}</p>
          </div>
        </div>

        <div className="flex flex-col gap-3">
          {cierre.cierreMontos.map((cierreMonto) => (
            <MetodoPagoCard
              cierreMonto={cierreMonto}
              idCierre={cierre.id}
              key={cierreMonto.id | cierreMonto.idMetodoPago}
            />
          ))}
        </div>

        <PendientesPagoTable pendientes={cierre.pendientes} />
      </div>
    );
  }

  return (
    <div className="mt-5 flex flex-col gap-5">
      <div className="w-full grid grid-cols-1 lg:grid-cols-2 gap-3">
        <div className="border-purple border rounded-lg p-8 flex flex-col gap-3">
          <h4 className="text-xl">Total de ordenes</h4>
          <p className="font-bold">{cierre.totalOrdenes}</p>
        </div>
        <div className="border-purple border rounded-lg p-8 flex flex-col gap-3">
          <h4 className="text-xl">Monto total a recaudar</h4>
          <p className="font-bold">
            {numberToColonesFormat(calcularMontoTotal(cierre.cierreMontos))}
          </p>
        </div>
        <div className="border-purple border rounded-lg p-8 flex flex-col gap-3">
          <h4 className="text-xl">Pendientes de pago</h4>
          <p className="font-bold">{cierre.pendientes.length}</p>
        </div>
        <div className="border-purple border rounded-lg p-8 flex flex-col gap-3">
          <h4 className="text-xl">Monto recibido por el administrador</h4>
          <p className="font-bold">
            {numberToColonesFormat(totalMontoRecibido)}
          </p>
        </div>
      </div>

      <div className="flex flex-col gap-3">
        {cierre.cierreMontos.map((cierreMonto) => (
          <MetodoPagoCard
            cierreMonto={cierreMonto}
            idCierre={cierre.idMetodoPago}
            key={cierreMonto.id | cierreMonto.idMetodoPago}
            editable={false}
          />
        ))}
      </div>

      <PendientesPagoTable pendientes={cierre.pendientes} />

      <div className="flex flex-col mt-5 gap-4">
        <h2 className="font-bold">Balance del cierre del día</h2>
        <p className="font-medium">
          {" "}
          Monto Total a recaudar - Monto Recibido - Total Pendientes = 0
        </p>
        <p className="text-xl">
          {numberToColonesFormat(calcularMontoTotal(cierre.cierreMontos))} -{" "}
          {numberToColonesFormat(totalMontoRecibido)} -{" "}
          {numberToColonesFormat(totalPendientes)} ={" "}
          {numberToColonesFormat(
            calcularMontoTotal(cierre.cierreMontos) -
              totalMontoRecibido -
              totalPendientes
          )}
        </p>
      </div>
    </div>
  );
}

function calcularMontoTotal(cierreMontos) {
  let sum = 0;
  cierreMontos.forEach((monto) => (sum += Number(monto.monto_a_recolectar)));
  return sum;
}
