import React, { useCallback, useEffect, useRef, useState } from "react";
import _ from "lodash";
import {
  ESTADOS_ORDENES,
  METODOS_ENTREGA,
  USUARIOS_TICOLITAS,
} from "../../utils/constants";
import moment from "moment";
import { json, Link } from "react-router-dom";
import useAuthFetch from "../../hooks/useAuthFetch";
import { ClipLoader } from "react-spinners";
import { useSelector } from "react-redux";

export function TarjetaPedido({
  user,
  venta,
  setOrderToCancel,
  setShowCancelOrderModal,
  fetchPrintBill,
  backLink,
  showProducts,
}) {
  //Coments Hooks
  const [comentarios, setComentarios] = useState([]);
  const [loading, setLoading] = useState(false);
  const [comentarioFormOpen, setComentarioFormOpen] = useState(false);

  //Form Hooks
  const comentarioRef = useRef();
  const [comentarioFormError, setComentarioFormError] = useState("");
  const [cometarioPostLoading, setCometarioPostLoading] = useState(false);
  const [deletingComentarioLoading, setDeletingComentarioLoading] = useState(0);

  const { token } = useSelector((store) => store.app.user);
  const authFetch = useAuthFetch();

  const fetchComentarios = useCallback(async () => {
    if (comentarios.length === 0) {
      setLoading(true);
    }
    const res = await authFetch(
      `ventas/${venta.id}/comentarios`,
      null,
      "GET",
      token
    );
    const data = await res.json();
    setComentarios(data);
    setLoading(false);
  }, [venta.id, comentarios.length, token]);

  useEffect(() => {
    fetchComentarios();
  }, [fetchComentarios]);

  const agregarComentario = async () => {
    const comentarioValue = comentarioRef.current.value;
    if (comentarioValue === "") {
      setComentarioFormError("Por favor, ingresar un comentario válido");
    } else {
      setCometarioPostLoading(true);
      await authFetch(
        `ventas/${venta.id}/comentarios`,
        {
          idAutor: user.id,
          comentario: comentarioValue,
        },
        "POST",
        token
      );
      setCometarioPostLoading(false);
      setComentarioFormError("");
      setComentarioFormOpen(false);
      fetchComentarios();
    }
  };

  const eliminarComentario = async (idComentario) => {
    setDeletingComentarioLoading(idComentario);
    await authFetch(
      `ventas/${idComentario}/comentarios`,
      null,
      "DELETE",
      token
    );
    setDeletingComentarioLoading(0);
    fetchComentarios();
  };

  return (
    <div className="w-full ring-1 ring-purple rounded-lg mb-4 p-6">
      <div>
        <p className="text-2xl mb-4">Orden #{venta.id}</p>
        <p className="text-md mb-2">
          Cliente:{" "}
          {!USUARIOS_TICOLITAS.includes(venta.idUsuario) ? (
            <span className="font-medium">
              {venta.usuario.nombre}
              {venta.usuario.apellido1 ? ` ${venta.usuario.apellido1}` : ""} (
              <a
                className="text-cyan cursor-pointer"
                href={`https://api.whatsapp.com/send?phone=${venta.usuario.codigoPaisTelefono}${venta.usuario.telefono}`}
                target="_blank"
                rel="noreferrer"
              >
                {venta.usuario.telefono}
              </a>
              )
            </span>
          ) : (
            <span className="font-medium">
              {venta.idMetodoEntrega === METODOS_ENTREGA.LOCAL ? (
                "Cliente en tienda"
              ) : (
                <>
                  {venta.nombreCompletoEntrega || "Cliente no identificado"}
                  {venta.telefono && (
                    <a
                      className="text-cyan cursor-pointer"
                      href={`https://api.whatsapp.com/send?phone=${venta.codigoPaisTelefono}${venta.telefono}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {venta.telefono}
                    </a>
                  )}
                </>
              )}
            </span>
          )}
        </p>
        <p className="text-md mb-2">
          Fecha:{" "}
          <span className="font-medium">
            {moment(venta.fechaIngreso).format(
              "dddd D [de] MMMM [a la(s)] hh:mm a"
            )}
          </span>
        </p>
        <p className="text-md mb-2">
          Total:{" "}
          <span className="font-medium">
            {Intl.NumberFormat("es-CR", {
              style: "currency",
              currency: "CRC",
            }).format(venta.totalConTransporte)}
          </span>
        </p>
        <p className="text-md mb-2">
          Metodo de Entrega:{" "}
          <span className="font-medium">
            {venta.metodoEntregaUtilizado.nombre}
          </span>
        </p>
        <p className="text-md mb-2">
          Metodo de Pago:{" "}
          <span className="font-medium">
            {venta.metodoPagoUtilizado.nombre}
          </span>
        </p>
        <p className="text-md mb-2">
          Notas:{" "}
          <span className="font-medium">
            {!venta.notasEntreg || !venta.notasEntreg === "null" ? "No hay notas" : venta.notasEntreg}
          </span>
        </p>
      </div>
      {showProducts && (
        <div className="w-full">
          <div>
            <p className="text-lg font-bold mb-4">
              Productos (Total: {_.sum(_.map(venta.lineas, "cantidad"))})
            </p>
            {venta.lineas.map((l) => {
              return !l.producto ? null : (
                <div
                  key={`venta_${venta.id}_${l.linea}`}
                  className="flex items-center my-4 border-b border-borderGray pb-4"
                >
                  <div className="w-4/12 md:w-2/12">
                    <img
                      src={l.producto.fotoPrincipal}
                      alt={`Foto ${l.producto.nombreTienda}`}
                    />
                  </div>
                  <div className="flex flex-wrap items-center w-8/12 md:w-10/12 pl-4">
                    <p className="w-full font-medium">
                      {l.producto.marca} {l.producto.nombreTienda}
                    </p>
                    <p className="w-full">
                      Presentación:{" "}
                      <span className="font-medium">
                        {l.producto.pesoGramos < 1000
                          ? `${l.producto.pesoGramos} gramos`
                          : `${(l.producto.pesoGramos / 1000).toFixed(2)} Kg`}
                      </span>
                    </p>
                    <p className="w-full">
                      Código TiColitas:{" "}
                      <span className="font-medium">{l.producto.id}</span>
                    </p>
                    <p className="w-full">
                      Código Proveedor:{" "}
                      <span className="font-medium">
                        {l.producto.codigoProveedor}
                      </span>
                    </p>
                    <p className="w-full">
                      Cantidad:{" "}
                      <span className="font-medium">{l.cantidad}</span>
                    </p>
                    {l.mascotas && (
                      <p className="w-full">
                        Mascota(s):{" "}
                        <span className="font-medium">{l.mascotas}</span>
                      </p>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}

      <div className="my-5 flex flex-col gap-3">
        {!loading && comentarios.length > 0 && (
          <h3 className="text-xl font-bold">Comentarios</h3>
        )}
        {!loading ? (
          comentarios.map((comentario) => (
            <Comentario
              comentario={comentario}
              key={comentario.id}
              user={user}
              deleteComentario={eliminarComentario}
              deletingComentarioLoading={deletingComentarioLoading}
            />
          ))
        ) : (
          <div className="flex items-center justify-center">
            <ClipLoader />
          </div>
        )}

        {comentarioFormOpen && (
          <div className="mt-6 flex flex-col gap-3">
            <p className="text-sm font-medium">Agregar comentario: </p>
            <textarea
              ref={comentarioRef}
              className="bg-gray rounded-md p-2 border-purple text-sm outline-purple"
            ></textarea>
            {comentarioFormError && (
              <span className="text-pink text-sm">{comentarioFormError}</span>
            )}
            <div className="flex justify-end gap-4">
              <button
                onClick={() => agregarComentario()}
                className="bg-purple py-2 px-2 md:px-6 text-sm md:text-base rounded-full text-white flex items-center gap-2"
                disabled={cometarioPostLoading}
              >
                Enviar
                {cometarioPostLoading && <ClipLoader color="#fff" size={15} />}
              </button>
              <button
                onClick={() => {
                  setComentarioFormOpen(false);
                  setComentarioFormError("");
                }}
                className="bg-pink py-2 px-2 md:px-6 text-sm md:text-base rounded-full text-white"
              >
                Cancelar
              </button>
            </div>
          </div>
        )}
      </div>

      <div className="mt-6 flex flex-wrap gap-2">
        {!comentarioFormOpen && (
          <button
            className=" bg-cyan py-2 px-2 md:px-6 text-sm md:text-base rounded-3xl text-white"
            onClick={() => setComentarioFormOpen(true)}
          >
            Agregar comentario
          </button>
        )}

        {venta.idEstadoVenta !== ESTADOS_ORDENES.CANCELADA &&
          venta.idEstadoVenta !== ESTADOS_ORDENES.RECHAZADA_POR_ASOCIACION && (
            <button
              onClick={() => {
                setOrderToCancel(venta.id);
                setShowCancelOrderModal(true);
              }}
              className="bg-pink py-2 px-2 md:px-6 text-sm md:text-base rounded-3xl text-white"
            >
              Cancelar Orden
            </button>
          )}
        {venta.facturaImpresa === 1 &&
          user &&
          (_.intersection(user.roles, [
            "admin",
            "dependiente",
            "bodega",
            "administradorTienda",
          ]).length > 0 ||
            user.roles.indexOf("superAdmin") > -1) && (
            <button
              onClick={() => {
                fetchPrintBill(venta);
              }}
              className="bg-purple py-2 px-2 md:px-6 text-sm md:text-base rounded-3xl text-white"
            >
              Imprimir Factura
            </button>
          )}
        <Link to={backLink}>
          <button className="bg-purple py-2 px-2 md:px-6 text-sm md:text-base rounded-3xl text-white">
            Ver Detalles
          </button>
        </Link>
      </div>
    </div>
  );
}
export default TarjetaPedido;

function Comentario({
  comentario,
  deleteComentario,
  deletingComentarioLoading,
}) {
  const user = useSelector((store) => store.app.user);

  return (
    <div className="flex flex-col gap-2 rounded-md bg-gray p-5">
      <h4 className="font-bold text-sm">
        {comentario.nombreAutor} {comentario.apellidoAutor}
      </h4>
      <p className="text-xs">
        {moment(comentario.ultimaEdicion).format(
          "dddd D [de] MMMM [a la(s)] hh:mm a"
        )}
      </p>
      <p className="text-sm mt-2">{comentario.comentario}</p>
      {user && user.id === comentario.idAutor && (
        <div className="flex justify-end">
          <button
            className="text-pink text-sm flex gap-2 items-center"
            onClick={() => deleteComentario(comentario.id)}
          >
            Borrar Comentario
            {deletingComentarioLoading === comentario.id && (
              <ClipLoader color="#E7004C" size={12} />
            )}
          </button>
        </div>
      )}
    </div>
  );
}
