import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { logoutUser } from "../slices/appSlice";
import { clearAfterPurchase } from "../slices/checkoutSlice";

const baseUrl = process.env.REACT_APP_API_URL;

const useAuthFetch = () => {
  const dispatch = useDispatch();
  const { token } = useSelector((store) => store.app.user);

  const authFetch = useCallback(
    async (endpoint, data = null, method = "GET") => {
      const url = `${baseUrl}/${endpoint}`;
      let result;

      try {
        if (method === "GET") {
          result = await fetch(url, {
            method,
            headers: {
              "Content-type": "application/json",
              Authorization: token ? `Bearer ${token}` : undefined,
            },
          });
        } else {
          result = await fetch(url, {
            method,
            headers: {
              "Content-type": "application/json",
              Authorization: token ? `Bearer ${token}` : undefined,
            },
            body: JSON.stringify(data),
          });
        }

        if (result.status === 401) {
          dispatch(logoutUser());
          dispatch(clearAfterPurchase());
        }

        return result;
      } catch (error) {
        console.error("AuthFetch error:", error);
        return null; // Handle error appropriately here or rethrow
      }
    },
    [token, dispatch]
  );

  return authFetch;
};

export default useAuthFetch;
