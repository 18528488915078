import React, { useCallback, useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { IoMdArrowRoundBack } from "react-icons/io";

import Spinner from "../../components/Spinner";
import Modal from "../../components/Modal";
import useAuthFetch from "../../hooks/useAuthFetch";
import {
  ESTADOS_ORDENES,
  METODOS_ENTREGA,
  METODOS_PAGO,
  METODOS_PAGO_DESC,
} from "../../utils/constants";
import { useSelector } from "react-redux";

export function PickUpsPage({ user }) {
  const authFetch = useAuthFetch();
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showCancelOrderModal, setShowCancelOrderModal] = useState(false);
  const [orderToCancel, setOrderToCancel] = useState(null);
  const { token } = useSelector((store) => store.app.user);

  const fetchOrders = useCallback(async () => {
    setLoading(true);
    const ordersFetch = await authFetch(
      `ventas?filter={"order": "fechaIngreso desc","where":{"and":[{"idMetodoEntrega": ${METODOS_ENTREGA.PICKUP}},{"idEstadoVenta": {"inq": [${ESTADOS_ORDENES.LISTO_EN_BODEGA},${ESTADOS_ORDENES.PENDIENTE_PAGO}]}}]},"include":[{"relation":"estado"},{"relation":"metodoPagoUtilizado"},{"relation":"usuario"},{"relation":"lineas","scope":{"include":[{"relation":"producto"}]}},{"relation":"transportista","scope":{"include":[{"relation":"usuario"}]}}]}`
    );

    const ordersData = await ordersFetch.json();
    setOrders(ordersData);
    setLoading(false);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchOrders();
  }, [fetchOrders]);

  const setAsDelivered = async (order) => {
    setLoading(true);
    await authFetch(
      `ventas/${order.id}`,
      {
        fechaEntregaFinal: new Date(),
        idEstadoVenta: !order.fechaRealPago
          ? ESTADOS_ORDENES.PENDIENTE_PAGO
          : ESTADOS_ORDENES.FINALIZADA,
      },
      "PATCH",
      token
    );
    fetchOrders();
  };

  const setAsPaid = async (order, paymentMethod) => {
    setLoading(true);
    await authFetch(
      `ventas/${order.id}`,
      {
        fechaRealPago: new Date(),
        idMetodoPago: paymentMethod,
        metodoPago: METODOS_PAGO_DESC[paymentMethod],
        idEstadoVenta: !order.fechaEntregaFinal
          ? order.idEstadoVenta
          : ESTADOS_ORDENES.FINALIZADA,
      },
      "PATCH",
      token
    );
    fetchOrders();
  };

  const cancelOrder = async (orderId) => {
    setLoading(true);
    await authFetch(`ventas/${orderId}/cancelar`, {}, "PATCH", token);
    fetchOrders();
  };

  return (
    <div className="w-full p-content pb-16 min-h-[400px]">
      {loading && <Spinner />}
      <Modal
        backgroundColor="bg-purple"
        closeModalFunction={() => {
          setOrderToCancel(null);
          setShowCancelOrderModal(false);
        }}
        open={showCancelOrderModal}
      >
        <div className="text-white p-8 pt-16 overflow-y-scroll h-full text-justify">
          <p className="mb-4">
            Realmente desea cancelar la Orden #{orderToCancel}. Esta acción no
            se podrá deshacer.
          </p>
          <div className="w-full flex flex-wrap justify-center sm:flex-nowrap sm:justify-end mt-4 gap-2">
            <button
              onClick={() => {
                setOrderToCancel(null);
                setShowCancelOrderModal(false);
              }}
              className="bg-white text-purple py-2 px-8 rounded-full"
            >
              Mantener orden
            </button>
            <button
              onClick={() => {
                cancelOrder(orderToCancel);
                setOrderToCancel(null);
                setShowCancelOrderModal(false);
              }}
              className="bg-orange text-white py-2 px-8 rounded-full"
            >
              Cancelar orden
            </button>
          </div>
        </div>
      </Modal>
      <div>
        <div className="flex w-full items-center">
          <div className="w-full">
            <Link to="/cuenta">
              <button className="bg-pink text-white py-2 px-8 font-bold rounded-full float-right flex justify-center items-center">
                <IoMdArrowRoundBack className="text-lg" /> Volver
              </button>
            </Link>
          </div>
        </div>
        <p className="text-2xl font-bold mb-4">
          Pedidos para recoger en tienda
        </p>

        <div className="w-full">
          {orders.length > 0 ? (
            orders.map((v) => {
              return (
                <div
                  className="w-full ring-1 ring-purple rounded-lg mb-4 p-6"
                  key={`venta_${v.id}`}
                >
                  <div>
                    <p className="text-2xl mb-4">
                      Orden #{v.id} -{" "}
                      {Math.floor(
                        (Math.abs(new Date() - new Date(v.fechaIngreso)) /
                          1000 /
                          86400) *
                          24
                      )}{" "}
                      horas desde el ingreso
                    </p>
                    <p className="text-md">
                      Cliente:{" "}
                      <span className="font-medium">
                        {v.usuario.nombre}
                        {v.usuario.apellido1 ? ` ${v.usuario.apellido1}` : ""} (
                        <a
                          className="text-cyan cursor-pointer"
                          href={`https://api.whatsapp.com/send?phone=${v.usuario.codigoPaisTelefono}${v.usuario.telefono}`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {v.usuario.telefono}
                        </a>
                        )
                      </span>
                    </p>
                    <p className="text-md">
                      Estado:{" "}
                      <span className="font-medium">{v.estado.valor}</span>
                    </p>
                    <p className="text-md">
                      Notas:{" "}
                      <span className="font-medium">
                        {" "}
                        {v.notasEntrega || "No hay"}
                      </span>
                    </p>
                    <p className="text-md">
                      Metodo de Pago:{" "}
                      <span className="font-medium">
                        {v.metodoPagoUtilizado.nombre}
                      </span>
                    </p>
                    <p>
                      Total:{" "}
                      <span className="font-medium">
                        {Intl.NumberFormat("es-CR", {
                          style: "currency",
                          currency: "CRC",
                        }).format(v.totalConTransporte)}
                      </span>
                    </p>
                    {v.pagoRequiereFirma === 1 && (
                      <p className="font-medium text-pink">
                        Esta orden requiere firma al pagar con tarjeta
                      </p>
                    )}
                  </div>
                  <div className="mt-6 flex flex-wrap gap-2 justify-end">
                    {v.idEstadoVenta === ESTADOS_ORDENES.LISTO_EN_BODEGA &&
                      !v.fechaEntregaFinal &&
                      !v.fechaRealPago && (
                        <button
                          onClick={() => {
                            setOrderToCancel(v.id);
                            setShowCancelOrderModal(true);
                          }}
                          className=" bg-pink py-2 px-2 md:px-6 text-sm md:text-base rounded-3xl text-white"
                        >
                          Cancelar Orden
                        </button>
                      )}
                    {v.idEstadoVenta === ESTADOS_ORDENES.LISTO_EN_BODEGA &&
                      !v.fechaEntregaFinal && (
                        <button
                          onClick={() => {
                            setAsDelivered(v);
                          }}
                          className=" bg-cyan py-2 px-2 md:px-6 text-sm md:text-base rounded-3xl text-white"
                        >
                          Entregada
                        </button>
                      )}
                    {!v.fechaRealPago && (
                      <button
                        onClick={() => {
                          setAsPaid(v, METODOS_PAGO.TARJETA);
                        }}
                        className=" bg-cyan py-2 px-2 md:px-6 text-sm md:text-base rounded-3xl text-white"
                      >
                        Pagada con tarjeta
                      </button>
                    )}
                    {!v.fechaRealPago && (
                      <button
                        onClick={() => {
                          setAsPaid(v, METODOS_PAGO.EFECTIVO);
                        }}
                        className=" bg-cyan py-2 px-2 md:px-6 text-sm md:text-base rounded-3xl text-white"
                      >
                        Pagada con efectivo
                      </button>
                    )}
                    {!v.fechaRealPago && (
                      <button
                        onClick={() => {
                          setAsPaid(v, METODOS_PAGO.TRANSFERENCIA);
                        }}
                        className=" bg-cyan py-2 px-2 md:px-6 text-sm md:text-base rounded-3xl text-white"
                      >
                        Pagada con transferencia
                      </button>
                    )}
                    {!v.fechaRealPago && (
                      <button
                        onClick={() => {
                          setAsPaid(v, METODOS_PAGO.SINPE);
                        }}
                        className=" bg-cyan py-2 px-2 md:px-6 text-sm md:text-base rounded-3xl text-white"
                      >
                        Pagada con SINPE
                      </button>
                    )}
                    <Link to={`/pedidos/${v.id}?desde=/pick-ups`}>
                      <button className=" bg-purple py-2 px-2 md:px-6 text-sm md:text-base rounded-3xl text-white">
                        Ver Detalles
                      </button>
                    </Link>
                  </div>
                </div>
              );
            })
          ) : (
            <p>No hay pedidos para mostrar</p>
          )}
        </div>
      </div>
    </div>
  );
}

export default PickUpsPage;
