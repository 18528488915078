import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import _ from "lodash";

import AlertBar from "../AlertBar";
import Menu from "../Menu";
import Modal from "../Modal";
import BarCodeScanner from "../BarCodeScanner";

import logo from "../../assets/logo.svg";
import tacita from "../../assets/tacita.svg";
import cuenta from "../../assets/cuenta.svg";
import Tooltip from "../Tooltip";
import { logoutUser, showCart } from "../../slices/appSlice";
import { BsSearch } from "react-icons/bs";
import { ImBarcode } from "react-icons/im";
import { clearAfterPurchase } from "../../slices/checkoutSlice";
import Mixpanel from "../../services/mixpanel";

export function Header({ cart, alert, menuOptions, user }) {
  const navigate = useNavigate();
  const [productsCount, setProductsCount] = useState(0);
  const [bounceCartCount, setBounceCartCount] = useState(0);
  const [searchResults, setSearchResults] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [accountDropdownOpen, setAccountDropdownOpen] = useState(false);
  const [showBarcodeScanner, setShowBarcodeScanner] = useState(false);

  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    if (cart.productsCount !== productsCount) {
      setProductsCount(cart.productsCount);
      setBounceCartCount(true);
      setTimeout(() => {
        setBounceCartCount(false);
      }, 2000);
    }
  }, [cart, productsCount]);

  const clearSearch = () => {
    setSearchQuery("");
    setSearchResults([]);
  };

  return (
		<>
			<AlertBar alert={alert} />
			{showBarcodeScanner && (
				<Modal
					backgroundColor="bg-purple"
					open={true}
					closeModalFunction={() => {
						setShowBarcodeScanner(false);
					}}
				>
					<div className="flex flex-wrap justify-center items-center p-8">
						<p className="text-white mb-4 w-full">
							Escaneá el código de barras del producto:
						</p>
						<div style={{ marginTop: 30, width: 640, height: 320 }}>
							<BarCodeScanner
								onUpdate={(result) => {
									if (result && result.codeResult && result.codeResult.code) {
										navigate(
											`/tienda/busqueda?barras=${result.codeResult.code}`
										);
										setShowBarcodeScanner(false);
									}
								}}
							/>
						</div>
					</div>
				</Modal>
			)}
			<Menu options={menuOptions} />
			<div className="flex items-center w-full p-content py-6 space-x-2 md:space-x-6 justify-between">
				<div className="flex-none w-2/12 sm:w-1/6">
					<Link
						onClick={() => {
							Mixpanel.track(Mixpanel.TYPES.SEARCH_BAR_IR_A_HOME);
						}}
						to="/"
					>
						<img
							className="w-full max-h-[110px] cursor-pointer"
							src={logo}
							alt="Logo TiColitas"
						/>
					</Link>
				</div>
				<div className="w-7/12 sm:w-4/6">
					<div className="relative">
						<input
							className="w-full px-8 rounded-full ring-1 ring-borderGray h-14 font-body outline-none"
							placeholder="Buscar..."
							value={searchQuery}
							onKeyDown={(evt) => {
								if (evt.key === "Enter") {
									if (evt.target.value) {
										if (evt.target.value.trim() !== "") {
											Mixpanel.track(Mixpanel.TYPES.SEARCH_BAR_BUSCAR, {
												query: evt.target.value,
											});
											navigate(`/tienda/busqueda?query=${evt.target.value}`);
											clearSearch();
										}
									}
								}
							}}
							onChange={(evt) => {
								if (evt.target.value) {
									setSearchQuery(evt.target.value);
									setSearchResults([]);
								} else {
									clearSearch();
								}
							}}
						/>
						<div className="absolute right-5 top-2 z-50 flex">
							{user &&
								user.token &&
								(_.intersection(user.roles, [
									"admin",
									"dependiente",
									"administradorTienda",
									"mensajero",
									"bodega",
								]).length > 0 ||
									user.roles.indexOf("superAdmin") > -1) && (
									<button
										onClick={() => {
											setShowBarcodeScanner(true);
										}}
										className="mr-2"
										aria-label="Escanear código de barras"
									>
										<ImBarcode className="text-3xl text-borderGray" />
									</button>
								)}
							{searchQuery.trim() !== "" ? (
								<Link
									onClick={() => {
										Mixpanel.track(Mixpanel.TYPES.SEARCH_BAR_BUSCAR, {
											query: searchQuery,
										});
										clearSearch();
									}}
									to={`/tienda/busqueda?query=${searchQuery}`}
								>
									<span className="sr-only">Buscar producto</span>
									<button className="p-1 z-50" aria-label="Buscar producto">
										<BsSearch className="text-3xl text-borderGray" />
									</button>
								</Link>
							) : (
								<div>
									<span className="sr-only">Buscar producto</span>
									<button className="p-1 z-50" aria-label="Buscar producto">
										<BsSearch className="text-3xl text-borderGray" />
									</button>
								</div>
							)}
						</div>
					</div>
					{searchResults.length > 0 && (
						<>
							<div
								onClick={clearSearch}
								className="z-40 fixed top-0 left-0 h-screen w-screen bg-transparent"
							/>
							<div className="z-50 w-[50%] lg:w-[42%] absolute bg-white rounded-3xl ring-1 ring-borderGray p-6 max-h-80 overflow-auto">
								{searchResults.map((sr) => {
									if (sr.sinResultados) {
										return (
											<div className="flex w-full mt-4 items-center">
												<div className="w-full">
													<p className="ml-4">{sr.nombreTienda}</p>
												</div>
											</div>
										);
									} else {
										return (
											<Link
												to={`/tienda/producto/${sr.id}`}
												onClick={clearSearch}
											>
												<div className="flex w-full mt-4 items-center">
													<div className="w-3/12 md:1/12">
														<img
															src={sr.fotoPrincipal}
															alt={`Foto ${sr.nombreTienda}`}
														/>
													</div>
													<div className="w-9/12 md:11/12">
														<p className="ml-4">
															{sr.marca} - {sr.nombreTienda}
														</p>
													</div>
												</div>
											</Link>
										);
									}
								})}
							</div>
						</>
					)}
				</div>
				<div className="flex flex-wrap w-3/12 sm:w-1/6 justify-around">
					{user.token && (
						<div className="w-full text-center text-xs sm:text-base">
							Hola<span className="text-purple">{`, ${user.nombre}`}</span>
						</div>
					)}
					<div className="flex items-center w-full justify-around">
						<Tooltip
							text="Tacita de compra"
							bgColor="bg-purple"
							textColor="text-white"
						>
							<div
								onClick={() => {
									Mixpanel.track(Mixpanel.TYPES.SEARCH_BAR_ABRIR_TACITA);
									dispatch(showCart());
								}}
								className={`${bounceCartCount ? "animate-bounce" : ""}`}
							>
								<div className="bg-pink text-white px-2 rounded-full w-fit relative left-[50%] cursor-pointer top-[50%] translate-x-[-50%] translate-y-[30%] md:left-[50%] md:top-[50%] md:translate-x-[-50%] md:translate-y-[55%] z-20">
									{productsCount}
								</div>
								<img
									data-tooltip-target="tooltip-default"
									className="relative bottom-[10px] z-10 max-h-9 sm:max-h-9 md:max-h-max cursor-pointer"
									src={tacita}
									alt="Tacita de compra"
								/>
							</div>
						</Tooltip>
						<div className="relative">
							<img
								className="max-h-9 sm:max-h-9 md:max-h-max cursor-pointer"
								src={cuenta}
								alt="Perfil de usuario"
								onClick={() => {
									Mixpanel.track(Mixpanel.TYPES.SEARCH_BAR_ABRIR_MENU_CUENTA);
									setAccountDropdownOpen(true);
								}}
							/>
							{accountDropdownOpen && (
								<>
									<div
										onClick={() => {
											setAccountDropdownOpen(false);
										}}
										className="fixed inset-0 h-full w-full z-[600]"
									></div>
									<div className="absolute right-0 mt-2 w-48 bg-white rounded-md overflow-hidden shadow-xl z-[800] border-purple border">
										{user.token && (
											<Link
												onClick={() => {
													setAccountDropdownOpen(false);
												}}
												className="cursor-pointer"
												to="/cuenta"
											>
												<p className="block px-4 py-2 text-sm text-gray-800 border-b hover:bg-gray-200">
													Mi Cuenta
												</p>
											</Link>
										)}
										<p
											onClick={() => {
												if (user.token) {
													Mixpanel.track(
														Mixpanel.TYPES.SEARCH_BAR_CERRAR_SESION
													);
													dispatch(logoutUser());
													dispatch(clearAfterPurchase());
												} else {
													Mixpanel.track(
														Mixpanel.TYPES.SEARCH_BAR_IR_A_INICIAR_SESION
													);
													navigate(
														`/log-in?redirect=${location.pathname}${location.search}`
													);
												}
												setAccountDropdownOpen(false);
											}}
											className="cursor-pointer block px-4 py-2 text-sm text-gray-800 border-b hover:bg-gray-200"
										>
											{user.token ? "Cerrar Sesión" : "Iniciar Sesión"}
										</p>
									</div>
								</>
							)}
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default Header;
