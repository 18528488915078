import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export function AgregarPage() {
  const navigate = useNavigate();
  useEffect(() => {
    navigate("/pedidos");
  }, [navigate]);
  return <div className="w-full p-content pt-4 pb-16 min-h-[400px] bg-white" />;
}

export default AgregarPage;
