import React from "react";
import { Link } from "react-router-dom";

export function FamilyList({
	products,
	categoryId,
	marcaId,
	searchQuery,
}) {
	return (
		products && (
			<div className="flex flex-wrap gap-3 my-5">
				{products.map((p) => {
					return (
						p.idFamilia === null && (
							<Link
								key={`producto_${p.id}`}
								to={
									categoryId && marcaId
										? `/tienda/familia/${p.id}`
										: searchQuery
										? `/tienda/producto/${p.id}?query=${searchQuery}`
										: `/tienda/familia/${p.id}`
								}
								className={`cursor-pointer flex flex-wrap flex-[0_0_calc(100%)] sm:flex-[0_0_calc(33.3333333333%-0.5rem)] md:flex-[0_0_calc(33.3333333%-0.5rem)] lg:flex-[0_0_calc(33.3333333%-0.5rem)] items-center bg-backgroundGray py-4 px-8 rounded-xl transition-all duration-150  hover:drop-shadow-xl`}
							>
								<div
									className={`flex flex-wrap items-center bg-backgroundGray py-4 px-8 rounded-md `}
								>
									{p.fotoPrincipal ? (
										<img
											className="rounded-md"
											src={p.fotoPrincipal}
											alt={`Foto de ${p.nombreTienda}`}
										/>
									) : (
										<p className="text-listingProductGray font-bold">
											Fotografía no disponible
										</p>
									)}
									<p className="w-full text-sm text-listingProductGray mt-2 font-bold">{p.marca} {p.nombreTienda}</p>
								</div>
							</Link>
						)
					);
				})}
			</div>
		)
	);
}

export default FamilyList;
