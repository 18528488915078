import React from "react";

export function TermsAndConditionsGiveaway({
  textColor,
}) {
  return (
    <div className={`${textColor ? textColor : 'text-white'} p-8 overflow-y-scroll h-full text-justify`}>
      <p className="mb-2 text-lg font-bold">Detalles de la rifa</p>
      <p className="mb-2 mt-4 font-bold">- Dinámica para participar:</p>
      <p className="mb-2">
        1. El Cliente debe realizar una compra de cualquiera de nuestros
        productos incluidos en el sistema a través de nuestro sitio
        ticolitas.com, aplica solo para compras en línea de forma directa en
        ticolitas.com.
      </p>
      <p className="mb-2">
        2. Aplica para todos los métodos de pago que el cliente decida utilizar
        disponibles en Ticolitas.
      </p>
      <p className="mb-2">
        3. El cliente puede elegir como método de entrega: domicilio o recoger
        en tienda a través del código QR. El cliente debe revisar los sitios
        habilitados en nuestra página donde realizamos las entregas a domicilio
        y las tarifas de envío.
      </p>
      <p className="mb-2">
        4. Es necesario que el usuario mantenga actualizados los datos
        correctamente en la página, nombre y teléfono, además de marcar su
        aceptación para participar antes de finalizar su compra en la casilla:
        Deseo participar en la rifa del certificado de compra de 20.000 colones
        y acepto que me contacten por Whatsapp en caso de resultar ganador de la
        misma. En caso de no marcar esta casilla, no se incluirá el número de
        orden de compra dentro de la rifa.
      </p>
      <p className="mb-2">
        5. No aplica para la rifa todas las compras que se realicen en la tienda
        física o por vía de WhatsApp.
      </p>
      <p className="mb-2 mt-4 font-bold">- Fecha:</p>
      <p className="mb-2">
        Aplican todas las órdenes ingresadas a nuestro sistema en ticolitas.com
        desde el 10 al 28 de febrero 2022.
      </p>
      <p className="mb-2 mt-4 font-bold">- Premio:</p>
      <p className="mb-2">
        El premio consiste en un (uno) certificado de compra, para ser utilizado
        por el ganador de la rifa, en posteriores compras de productos que se
        encuentren disponibles en nuestro inventario, por un monto de 20.000
        colones.
      </p>
      <p className="mb-2 mt-4 font-bold">- Vigencia del certificado:</p>
      <p className="mb-2">
        El certificado de compra podrá ser utilizado del 2 al 31 de marzo 2022.
        Posterior al 31 de marzo, en caso de que no haya sido utilizado el mismo
        queda sin efecto.
      </p>
      <p className="mb-2 font-bold">- Sorteo:</p>
      <p className="mb-2">
        El ganador se generará de manera aleatoria mediante un sistema
        electrónico el 1 de marzo 2022 y se contactará vía WhatsApp para
        notificar del premio a la persona ganadora. Nuestro sistema genera
        órdenes de compra numéricas conforme van ingresando, lo que se colocará
        para la rifa es el número de cada orden de compra que genere cada
        cliente, que ingrese una orden directa en ticolitas.com.
      </p>
      <hr className="my-4"/>
      <p className="mb-2 mt-4">
        TiColitas se reserva todos los derechos sobre esta promoción, dejando a
        su discreción el momento en que quiera repetir la misma. Aplican
        Restricciones.
      </p>
      <p className="mb-2">
        Se constituye un acuerdo legal entre cualquier usuario y TiColitas.com
        dándose por entendido que el cliente, antes de solicitar algún servicio
        a través del sitio, ha revisado, entendido y acordado los términos
        expuestos en las políticas del portal.
      </p>
      <p className="mb-2">
        En caso de inconformidad, el usuario deberá abstenerse de proporcionar
        información personal, así como de levantar algún requerimiento
        comercial.
      </p>
    </div>
  );
}

export default TermsAndConditionsGiveaway;
