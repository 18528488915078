import React, { useCallback, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import BillingForm from "../../components/BillingForm";
import Cart from "../../components/Cart";
import CheckboxInput from "../../components/CheckboxInput";
import Select from "../../components/Select";

import {
  BODEGAS,
  UNIDAD_MEDIDA_UNIDAD,
  DEFAULT_POSITION_IN_SHIPPING_ORDER,
  ESTADOS_ORDENES,
  METODOS_ENTREGA,
  TIENDAS,
  METODOS_PAGO,
  COMPRA_POR_WHATSAPP,
  COMPRA_EN_TIENDA,
} from "../../utils/constants";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "../../components/Spinner";
import { clearCart } from "../../slices/appSlice";

import {
  setPrintBill,
  setBilling,
  setBillingDetails,
  clearAfterPurchase,
  setOrderNotes,
} from "../../slices/checkoutSlice";
import useAuthFetch from "../../hooks/useAuthFetch";
import { debounce } from "lodash";

const newBillingMockup = {
  nombreFactura: "",
  tipoIdentificacion: 0,
  identificacion: "",
  correoFactura: "",
};

export function CheckoutTiendaPage({ cart, user, userIsLogged }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const authFetch = useAuthFetch();

  const printBill = useSelector((state) => state.checkout.printBill);
  const billing = useSelector((state) => state.checkout.billing);
  const billingDetails = useSelector((state) => state.checkout.billingDetails);
  const orderNotes = useSelector((state) => state.checkout.orderNotes);

  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const [discountPrice, setDiscountPrice] = useState({});
  const [subTotalWhitDiscountPrice, setSubTotalWhitDiscountPrice] =
    useState(null);

  const [withWhatsapp, setWithWhatsapp] = useState(false);
  const { products } = useSelector((state) => state.app.cart);
  const paymentMethod = useSelector((state) => state.checkout.paymentMethod);

  useEffect(() => {
    let sumDiscount = 0;
    let subTotal = cart.total;

    if (products && discountPrice) {
      if (paymentMethod?.esEnEfectivo) {
        subTotal =
          cart.totalWithEfectiveDiscount > 0
            ? cart.totalWithEfectiveDiscount
            : subTotal;
      } else {
        subTotal =
          cart.totalWithAnyDiscount > 0 ? cart.totalWithAnyDiscount : subTotal;
      }
    }

    if (discountPrice) {
      for (const product in discountPrice) {
        if (discountPrice[product]) {
          const newTotalPrice =
            discountPrice[product].precioConDescuento *
            cart.products[product].quantity;
          const oldTotalPrice =
            cart.products[product].product.precioVenta *
            cart.products[product].quantity;
          sumDiscount += oldTotalPrice - newTotalPrice;
        }
      }
    }

    subTotal -= sumDiscount;

    setSubTotalWhitDiscountPrice(subTotal);
  }, [discountPrice, cart.products, cart.totalWithEfectiveDiscount]);

  const handleDebounceFn = async (inputValue, productId) => {
    if (inputValue.length >= 1 && inputValue.length <= 2) {
      const totalPrice = await authFetch(
        `producto-detalles/${productId}/descuento/${Number(inputValue)}`
      );

      const price = await totalPrice.json();
      setDiscountPrice((precie) => ({
        ...precie,
        [productId]: {
          precioConDescuento: price.precioConDescuento,
        },
      }));
    } else {
      setDiscountPrice((precie) => ({ ...precie, [productId]: null }));
    }
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceFn = useCallback(debounce(handleDebounceFn, 1000), []);

  const handleChange = (e, productId) => {
    if (Number(e.target.value) < 0 || isNaN(Number(e.target.value))) {
      return;
    }
    debounceFn(e.target.value, productId);
  };

  const generateOrderLines = () => {
    const orderLines = [];
    let lineCounter = 1;
    for (const product in cart.products) {
      const productLine = cart.products[product];
      const taxes = 1.13;
      const unitPriceWithoutTaxes =
        (productLine.product.precioVenta * 100) / 113;
      const subtotalWhitoutDiscount =
        unitPriceWithoutTaxes * productLine.quantity;
      const productDiscount = productLine?.product?.descuentos
        ? productLine.product.descuentos[0]
        : null;
      const manualDiscount = discountPrice[productLine.product.id];

      let discount = 0;

      if (productDiscount) {
        if (
          !productDiscount.soloEnEfectivo ||
          (productDiscount.soloEnEfectivo && paymentMethod.esEnEfectivo)
        ) {
          discount =
            unitPriceWithoutTaxes *
            productDiscount.porcentaje *
            productLine.quantity;
        }
      } else {
        if (manualDiscount) {
          discount =
            productLine.product.precioVenta * productLine.quantity -
            manualDiscount.precioConDescuento * productLine.quantity;
        }
      }

      const subtotalWithDiscount = subtotalWhitoutDiscount - discount;

      orderLines.push({
        linea: lineCounter++,
        idProducto: productLine.product.id,
        cantidad: productLine.quantity,
        consumoDiarioGramos: productLine.dailyConsumption * 100,
        porcentajeImpuesto: taxes,
        costoUnitario: productLine.product.precioVenta,
        subtotal: subtotalWithDiscount,
        totalImpuestos: subtotalWithDiscount * (taxes - 1),
        total: subtotalWhitoutDiscount * taxes,
        descuento: discount,
        totalConDescuento: subtotalWithDiscount * taxes,
        mascotas: productLine.petName || "",
        unidadMedida: UNIDAD_MEDIDA_UNIDAD,
      });
    }
    return orderLines;
  };

  const placeOrder = async () => {
    setLoading(true);
    const tempErrors = [];

    if (!user || !user.token) {
      tempErrors.push({
        message:
          'No has iniciado sesión. Si no tenés usuario podés crear tu usuario en esta misma pantalla en un solo paso y en segundos en la sección de "Cliente".',
        inputId: "noUser",
      });
      setErrors(tempErrors);
      setLoading(false);
      return;
    }

    if (billing) {
      const fieldsToCheck = {
        nombreFactura: "nombre",
        tipoIdentificacion: "tipo de identificación",
        identificacion: "identificación",
        correoFactura: "correo electrónico",
      };
      for (const field in fieldsToCheck) {
        if (!billingDetails[field]) {
          tempErrors.push({
            message: `Por favor completá el campo ${fieldsToCheck[field]} en la sección de "Facturación"`,
            inputId: field,
          });
        }
      }
    }

    if (tempErrors.length > 0) {
      setLoading(false);
      setErrors(tempErrors);
      return;
    }
    setErrors([]);

    const orderPayload = {
      venta: {
        idUsuario: user.id,
        idEstadoVenta: ESTADOS_ORDENES.FINALIZADA,
        realizada: true,
        totalDeTransporte: 0,
        totalConImpuestos: subTotalWhitDiscountPrice
          ? subTotalWhitDiscountPrice
          : cart.total,
        descuentoGlobal: cart.discount || 0,
        totalCancelado: null,
        fechaIngreso: new Date(),
        fechaMaximaPago: new Date(),
        fechaRealPago: new Date(),
        fechaEntregaInicio: new Date(),
        fechaEntregaEstimada: new Date(),
        fechaEntregaFinal: new Date(),
        nombreCompletoEntrega: null,
        latitud: null,
        longitud: null,
        urlWaze: null,
        provincia: null,
        canton: null,
        distrito: null,
        direccionExacta: null,
        notasEntrega: orderNotes,
        telefono: null,
        nombreFactura: billing ? billingDetails.nombreFactura : null,
        tipoIdentificacionFactura: billing
          ? billingDetails.tipoIdentificacion
          : null,
        identificacionFactura: billing ? billingDetails.identificacion : null,
        correoFactura: billing ? billingDetails.correoFactura : null,
        autorizaContactoWhatsapp: cart.whatsappConfirmation,
        confirmaAutorizaContactoWhatsapp: null,
        aceptaTerminosCondiciones: null,
        facturaImpresa: printBill,
        totalConTransporte: subTotalWhitDiscountPrice
          ? subTotalWhitDiscountPrice
          : cart.total,
        idMetodoPago: METODOS_PAGO.TARJETA,
        metodoPago: "Pago en tienda",
        pagoRequiereFirma: subTotalWhitDiscountPrice
          ? subTotalWhitDiscountPrice > 30000
            ? true
            : false
          : cart.total > 30000
          ? true
          : false,
        requierePos: true,
        indicacionesPostCompra: "No hay",
        idMetodoEntrega: METODOS_ENTREGA.LOCAL,
        metodoEntrega: "Compra en tienda TiColitas",
        distanciaTiendaMetros: 0,
        duracionDesdeTiendaMinutos: 0,
        montoEfectivo: null,
        idTiendaPickup: TIENDAS.TICOLITAS_GUACIMA,
        idBodega: BODEGAS.TICOLITAS_GUACIMA,
        idTransportista: null,
        posicionEnEntrega: DEFAULT_POSITION_IN_SHIPPING_ORDER,
        idOrigenCompra: withWhatsapp ? COMPRA_POR_WHATSAPP : COMPRA_EN_TIENDA,
      },
      lineas: generateOrderLines(),
    };

    if (billing && !billingDetails.id) {
      orderPayload.nuevaFacturacion = billingDetails;
    }

    const orderCreationFetch = await authFetch(
      "ventas",
      orderPayload,
      "POST",
      user.token
    );

    if (orderCreationFetch.status === 200) {
      const orderCreated = await orderCreationFetch.json();

      if (printBill) {
        const orderForPrint = orderPayload.venta;
        orderForPrint.id = orderCreated.id;
        orderForPrint.lineas = orderPayload.lineas;
        orderForPrint.lineas.forEach((linea) => {
          linea.producto = cart.products[linea.idProducto].product;
        });
        const payload = {
          order: orderForPrint,
          vendorId: 4070,
          productId: 33054,
        };
        setLoading(true);
        fetch("https://guacima.ticolitas.com/print", {
          method: "POST",
          headers: {
            "Content-type": "application/json",
          },
          body: JSON.stringify(payload),
        });
      }
      dispatch(clearCart());
      dispatch(clearAfterPurchase());
      navigate(`/pedidos/${orderCreated.id}?desde=checkout`);
    } else {
      try {
        const error = await orderCreationFetch.json();
        const message = error && error.error && error.error.message;
        if (message) {
          dispatch(
            setErrors([
              {
                message,
              },
            ])
          );
        } else {
          throw new Error();
        }
      } catch (error) {
        setErrors([
          {
            message:
              "Lo sentimos, ha ocurrido un error creando tu orden. Intentálo de nuevo, si el error persiste podés escribirnos para asistirte.",
          },
        ]);
      }
    }
    setLoading(false);
  };

  return (
    <div className="flex flex-wrap w-full p-content py-8 gap-2">
      {loading && <Spinner color="pink" />}
      <Cart
        cart={cart}
        checkout
        isPickup={false}
        isLocalDelivery={true}
        hideButtons
        additionalStyles="flex w-full"
        freeShipping={null}
        handleChange={handleChange}
        discountPrice={discountPrice}
        subTotalWhitDiscountPrice={subTotalWhitDiscountPrice}
      />
      {cart.productsCount > 0 ? (
        <>
          <div className="flex flex-wrap w-full bg-purple p-8">
            <div className="w-full">
              <p className="text-white text-xl mb-6">Información del pedido</p>
            </div>
            <div className="bg-white p-6 w-full mb-2">
              <div className="w-full mt-4">
                <Select
                  label="Factura electrónica:"
                  labelColor="text-base"
                  buttonClasses="border border-purple"
                  options={[
                    {
                      nombreFactura: "No deseo factura electrónica",
                      id: "noBilling",
                    },
                    {
                      nombreFactura: "+ Ingresar nuevos datos",
                      id: "newBilling",
                    },
                  ].map((b) => {
                    return {
                      value: b.id,
                      label: b.nombreFactura,
                    };
                  })}
                  onChange={(value) => {
                    if (value === "noBilling") {
                      dispatch(setBilling(false));
                      dispatch(setBillingDetails(newBillingMockup));
                      return;
                    }
                    if (value === "newBilling") {
                      dispatch(setBillingDetails(newBillingMockup));
                      dispatch(setBilling(true));
                      return;
                    }
                  }}
                  value={
                    (billingDetails && billingDetails.id) ||
                    (billing ? "newBilling" : "noBilling")
                  }
                />
              </div>
              <div className="w-full mt-4">
                {billing && billingDetails && (
                  <BillingForm
                    billing={billingDetails}
                    onChangeValue={(field, value) => {
                      dispatch(
                        setBillingDetails({
                          ...billingDetails,
                          [field]: value,
                        })
                      );
                    }}
                    onChangeAll={(value) => {
                      dispatch(setBillingDetails(value));
                    }}
                  />
                )}
              </div>
            </div>

            <div className="w-full mt-4">
              <label htmlFor="notasAlPedido" className="text-white">
                Notas al pedido:
              </label>
              <textarea
                placeholder="Ej: persona con quien dejar el pedido, contactarte antes por el medio que indiques, medidas de seguridad que debemos respetar."
                id="notasAlPedido"
                name="notasAlPedido"
                className="w-full rounded-3xl mt-2 p-6 outline-none border border-purple resize-none"
                rows={3}
                onChange={(evt) => {
                  dispatch(setOrderNotes(evt.target.value));
                }}
              />
            </div>
            <div className="w-full mt-4 flex flex-col gap-3">
              <CheckboxInput
                checked={printBill}
                onCheckChange={() => {
                  dispatch(setPrintBill(!printBill));
                }}
                labelColor="text-white"
                label={"Imprimir tiquete electrónico."}
              />
              <CheckboxInput
                checked={withWhatsapp}
                onCheckChange={() => {
                  dispatch(setWithWhatsapp(!withWhatsapp));
                }}
                labelColor="text-white"
                label={"Compra por Whatsapp"}
              />
            </div>
          </div>
          {errors && errors.length > 0 && (
            <div className="w-full my-4 border border-pink p-8">
              Es necesario verificar lo siguiente:
              {errors.map((e, index) => {
                return (
                  <p key={`error_${index}`} className="text-pink my-2 w-full">
                    {e.message}
                  </p>
                );
              })}
            </div>
          )}
          <Cart
            finalButton
            cart={cart}
            checkout
            checkoutErrors={errors && errors.length > 0}
            isPickup={false}
            isLocalDelivery={true}
            hideButtons
            hideProducts
            additionalStyles="flex w-full"
            placeOrderMethod={placeOrder}
            freeShipping={null}
            handleChange={handleChange}
            discountPrice={discountPrice}
            subTotalWhitDiscountPrice={subTotalWhitDiscountPrice}
          />
        </>
      ) : (
        <div className="w-full mt-4">
          <p className="w-full">
            Aún no has agregado nada a tu tacita de compras. Te invitamos a
            visitar nuestra tienda y/o utilizar la barra superior para buscar
            los productos favoritos de tu mascota.
          </p>
          <div className="my-8 text-center">
            <Link to="/tienda">
              <button className="bg-purple text-white h-12 px-12 rounded-full">
                Navegar la tienda
              </button>
            </Link>
          </div>
        </div>
      )}
    </div>
  );
}

export default CheckoutTiendaPage;
