import React from "react";

export function TermsAndConditions() {
  return (
    <div className="text-white p-8 overflow-y-scroll h-full text-justify">
      <p className="mb-2 text-lg font-bold">Términos y Condiciones</p>
      <p className="mb-2">
        Gracias por visitar{" "}
        <a target="_blank" rel="noreferrer" href="https://www.ticolitas.com/">
          https://www.ticolitas.com/
        </a>{" "}
        Por favor, lea los términos legales y políticas que se describen a
        continuación para poder brindarle la mejor experiencia de compra en
        línea. Estos aplicarán para la navegación del sitio de internet{" "}
        <a target="_blank" rel="noreferrer" href="https://www.ticolitas.com/">
          https://www.ticolitas.com/
        </a>{" "}
        y los servicios ofrecidos. Nos reservamos el derecho de modificar,
        agregar y/o eliminar las condiciones total o parcialmente en cualquier
        momento, a nuestra discreción, sin necesidad de previo aviso.
      </p>
      <p className="mb-2">
        Ticolitas S.R.L. es una Sociedad de Representación Limitada constituida
        por plazo indefinido de acuerdo a y bajo la jurisdicción de las leyes de
        Costa Rica.
      </p>
      <p className="mb-2">
        El uso de esta página y la aceptación de las siguientes políticas
        implica su acuerdo con los términos y condiciones que se establecen a
        continuación. Si no está de acuerdo con dichos términos y condiciones
        por favor no hacer uso de esta plataforma o contactarnos si existen
        dudas adicionales.
      </p>
      <p className="mb-2">1. Restricción de Uso</p>
      <p className="mb-2">
        Todo el contenido de este sitio es propiedad de Ticolitas S.R.L. El
        mismo no puede ser copiado, reproducido, duplicado, transmitido,
        vendido, publicado, distribuido o explotado de ninguna manera para
        propósitos comerciales, excepto que el usuario puede bajar y guardar en
        la memoria de su computadora una copia para su uso y referencia
        personal. No está permitido el uso de nuestra página para propósitos
        comerciales. El uso indebido constituye violación de los derechos de
        registro y propiedad intelectual, tanto de imágenes como de contenido.
      </p>
      <p className="mb-2">2. Colores y Fotografías</p>
      <p className="mb-2">
        En Ticolitas.com hemos hecho nuestro mejor esfuerzo por desplegar de la
        manera más exacta posible los colores de los productos que aparecen en
        nuestro sitio. Sin embargo, ya que los colores que el usuario observe
        dependen de su monitor, no podemos garantizar que los colores que se
        observen en pantalla sean exactos. Toda fotografía ha sido tomada con el
        fin representar de la mejor manera las características de cada producto,
        por lo que los accesorios usados para ambientar los productos no están
        incluidos y no forman parte de este. Por favor leer cuidadosamente la
        descripción de cada producto.
      </p>
      <p className="mb-2">3. Precio(s) y costo(s) de envío</p>
      <p className="mb-2">
        Los precios y promociones son exclusivamente para compras en
        Ticolitas.com y nuestra tienda física. Los precios presentados en
        Ticolitas.com incluyen todos los impuestos de venta y prestaciones de
        servicios vigentes en Costa Rica. Por ende, los precios varían según el
        país y moneda local.
      </p>
      <p className="mb-2">
        Los precios presentados en Ticolitas.com NO incluyen los gastos por
        envío a domicilio y/o lugar de referencia. Las zonas donde brindaremos
        este servicio a domicilio y/o lugar de referencia se encuentran
        previamente definidas en nuestro sitio web. El costo del transporte se
        define y se informa al cliente claramente en nuestro sitio web antes de
        finalizar la compra y este monto se calcula según los desplazamientos
        que tengamos que realizar desde nuestra tienda física, este es un
        acuerdo entre cualquier usuario y TiColitas.com, dándose por entendido
        que el cliente, antes de solicitar algún servicio a través del sitio, ha
        revisado, entendido y acordado los términos expuestos en las políticas
        del portal, en caso de inconformidad, el usuario deberá abstenerse de
        proporcionar información personal, así como de levantar algún
        requerimiento comercial.
      </p>
      <p className="mb-2">
        No se brindará el servicio a domicilio y/o entregas en el lugar de
        referencia, en zonas fuera de la cobertura indicada en nuestro sitio
        web. El cliente deberá aceptar antes de finalizar su compra el monto
        indicado correspondiente a transporte. Las compras que requieran el
        servicio de entrega a domicilio y/o lugar de referencia cuyo monto sea
        inferior a los 10.000 (diez mil) colones, tendrá un costo adicional
        (costo de envío, más IVA) al indicado en la página web / APP, dicho
        costo de envío, dependerá de la distancia recorrida y del valor del
        artículo comprado. Este costo de envío será acordado antes de realizar
        la entrega entre el comprador y Ticolitas S.R.L. mediante las vías que
        el comprador permita utilizar para dicha comunicación, no se realizará
        el servicio de entrega a domicilio y/o lugar de referencia, para compras
        menores a los 10.000 (diez mil) colones, sin el consentimiento previo
        expreso del comprador del monto final con IVA de este envío. El cliente
        debe asegurarse de colocar correctamente en nuestro sitio web la
        dirección donde requiere que sea entregado su pedido, también colocar de
        forma correcta el distrito o localidad que viene predeterminado y que
        indica las zonas donde realizamos actualmente las entregas a domicilio.
      </p>
      <p className="mb-2">
        Ticolitas S.R.L. se reserva el derecho de generar descuentos y/o
        promociones individuales a las personas físicas y/o jurídicas que tengan
        como fin el bien social de las mascotas, que sea claramente reconocidas
        y se pueda constatar fielmente dichas labores. Los precios de los
        productos ofrecidos en nuestro sitio web, APP, tienda física, pueden
        variar en cualquier momento sujetos a los precios de mercado de nuestros
        proveedores, cambios en las leyes del país, nos reservamos el derecho
        para realizar dichos cambios.
      </p>
      <p className="mb-2">4. Pedidos</p>
      <p className="mb-2">
        Ticolitas.com pone a su disposición entrega de compras en nuestras
        tiendas físicas, mediante el servicio de recoger en tienda (pick up) a
        través de código QR o de servicio a domicilio y/o lugar de referencia,
        dentro de las áreas previamente definidas en nuestro sitio web y/o redes
        sociales.
      </p>

      <p className="mb-2">5. Estado de los Pedidos</p>
      <p className="mb-2">
        La página Ticolitas.com cuenta con 5 estados de pedido:
      </p>
      <p className="mb-2">
        Pendiente: todo pedido que ingrese aprobado tendrá este estado hasta ser
        revisado,
      </p>
      <p className="mb-2">
        Procesando: Este estado lo obtiene el pedido cuando la transacción ha
        sido revisada y aprobada por el banco,
      </p>
      <p className="mb-2">
        Enviado: Cuando el pedido ha sido enviado a la dirección indicada por el
        cliente,
      </p>
      <p className="mb-2">
        Entregado: Cuando el pedido ha sido entregado al cliente,
      </p>
      <p className="mb-2">Cancelado: Cuando el pedido ha sido revocado.</p>
      <p className="mb-2">
        Nos reservamos el derecho de rechazar / cancelar cualquier pedido o de
        restringir las cantidades de cualquier pedido sin explicar los motivos,
        dicho rechazo y/o cancelación se notificará por medio de correo
        electrónico basado en la dirección que se indicó en el pedido.
      </p>
      <p className="mb-2">
        6. Entregas en tienda / envío de productos, medidas de protección.
      </p>
      <p className="mb-2">
        Comprendemos la importancia de cumplir con todos los protocolos de
        bioseguridad al realizar cualquier interacción con nuestros clientes,
        estamos asegurando el cumplimiento de todas las medidas sanitarias para
        protegerlo a usted y nuestros colaboradores. Somos un emprendimiento
        nuevo, queremos aprender y realizar nuestro mejor esfuerzo para poder
        llegar a más regiones del país. Si deseas consultar el estado de tu
        pedido, escríbenos a nuestro Web Chat.
      </p>
      <p className="mb-2">
        El plazo de entrega de los productos puede variar en tiempo, el sistema
        indicará al cliente, el día de entrega según nuestra capacidad de
        respuesta, en el momento en que el cliente crea su orden, esto con la
        idea de ser lo más transparentes posible y comunicarlo de forma
        anticipada, el cliente podrá tomar la decisión de continuar la compra y
        aceptar nuestros tiempos de envío o cancelar la orden, basado en estos
        datos de los tiempos de entrega.
      </p>
      <p className="mb-2">
        Estimamos un tiempo promedio de entrega de 24 a 72 horas. Solo se
        brindará el servicio a domicilio en los lugares previamente definidos en
        nuestra página Ticolitas.com. Los días hábiles de entrega, no incluyen,
        días feriados o de asueto, días en que los almacenes estén cerrados,
        días en que afecte alguna situación fuera de nuestro control o que, por
        caso fortuito o fuerza mayor, por cierres obligatorios, cuarentenas u
        órdenes sanitarias de emergencia, situaciones climáticas, así como
        cualquier otra situación, que impida el libre ejercicio de la actividad
        comercial o que superen nuestras mejores intenciones hacia nuestros
        clientes. Los fines de semana Ticolitas se reserva el derecho de
        habilitar o inhabilitar el servicio de entrega a domicilio, en caso de
        que se inhabilite, nuestro sistema le indicará al cliente, cuál es el
        tiempo de entrega estimado, para que el cliente lo tenga en
        consideración y tome la decisión más conveniente.
      </p>
      <p className="mb-2">
        Se advierte que el producto se entrega en la puerta de la ubicación.
      </p>
      <p className="mb-2">
        En caso de que el mensajero no pueda entregar el producto en su primer
        intento en el lugar indicado por el cliente, por diferentes causas que
        hayan imposibilitado al cliente a recibir el paquete, se hará un segundo
        intento solamente, para poder entregar el producto, en este caso, el
        personal de TiColitas podrá coordinar de previo con el cliente, para
        asegurar que la próxima visita sea exitosa. Si el segundo intento de
        envío también es fallido, el cliente deberá recoger el producto en
        nuestra tienda física.
      </p>
      <p className="mb-2">7. Métodos de pagos</p>
      <p className="mb-2">
        Las formas de pago autorizadas desde Ticolitas.com son las siguientes:
      </p>
      <p className="mb-2">
        Tarjetas de Crédito y Débito (Visa, Mastercard, American Express),
        cualquier transacción de más de 30.000 (treinta mil) colones, requiere
        la presentación de su cédula de identidad y firma del voucher.
      </p>
      <p className="mb-2">
        Pago en efectivo colones y dólares, en caso de pagar en dólares, se
        utilizará el tipo de cambio en ventanilla de ese día, correspondiente al
        valor de compra de dólares del banco seleccionado por TiColitas S.R.L.
      </p>
      <p className="mb-2">
        Transferencia Bancaria, a las cuentas indicadas en la página web / APP
      </p>
      <p className="mb-2">Transferencia Sinpe</p>
      <p className="mb-2">8. Moneda</p>
      <p className="mb-2">
        Los precios en Ticolitas.com son desplegados en colones, moneda de Costa
        Rica.
      </p>
      <p className="mb-2">9. Promociones.</p>
      <p className="mb-2">
        Las promociones presentadas en la página web serán exclusivamente para
        compras efectuadas por este medio.
      </p>
      <p className="mb-2">
        La vigencia y las restricciones de estos se pueden encontrar en los
        términos del mismo. Si el cliente autoriza a Ticolitas.com éste le
        enviará correos electrónicos con ofertas promocionales que pueden
        incluir códigos de descuento o ventas exclusivas.
      </p>
      <p className="mb-2">
        Cuando el cliente ya no desee recibir dicha información podrá notificar
        vía correo electrónico a{" "}
        <a target="_blank" rel="noreferrer" href="mailto:pedidos@ticolitas.com">
          pedidos@ticolitas.com
        </a>{" "}
        para que sea borrado de la lista de clientes preferentes.
      </p>
      <p className="mb-2">10. Disponibilidad de Productos</p>
      <p className="mb-2">
        Los productos mostrados en Ticolitas.com están sujetos a disponibilidad
        en las bodegas de Ticolitas y en algunos casos podrían no actualizarse
        las existencias en el momento de la compra.
      </p>
      <p className="mb-2">
        En caso de que el producto elegido no se encontrara en existencia se le
        notificará al cliente por medio del correo electrónico asignado o por
        teléfono si está proporcionado, para darles las siguientes opciones:
      </p>
      <p className="mb-2">1) El tiempo estimado de entrega del producto.</p>
      <p className="mb-2">
        2) En caso que el producto sea descontinuado / agotado, es decir que ya
        no entrará más en inventario de forma total o por un período de tiempo
        indefinido, se ofrecerá al cliente opciones de producto(s) sustitutos,
        similares y/o relacionados. Si este es de mayor valor, el cliente tiene
        la opción de dar la diferencia de dinero por adquirir otro producto.
      </p>
      <p className="mb-2">
        3) En caso de los productos de prescripción descontinuados / agotados,
        se le indicará al cliente que consulte con su Médico Veterinario de
        confianza para que le indique el nuevo producto que requiere comprar.
      </p>
      <p className="mb-2">
        Si las opciones anteriores no satisfacen las expectativas del cliente o
        no se recibe una respuesta en un lapso de 5 días hábiles, se hará
        efectiva una devolución total a la forma de pago original de la compra.
      </p>
      <p className="mb-2">11. Facturas y documentos de compras</p>
      <p className="mb-2">
        En TiColitas estimulamos la protección del ambiente, el no uso de
        material impreso como tiquetes de compra, vouchers, facturas
        electrónicas, etc., si desea que la persona que recibe el producto
        (destinatario) en el país de entrega, obtenga su tiquete de compra,
        voucher de forma física impresa, por favor seleccione 'SI' en la casilla
        de “envío de tiquete de compra y/o voucher” junto con la entrega” en el
        proceso de pago de nuestro sitio web o APP. No omitimos mencionar que en
        TiColitas independientemente del método de pago, envío de documentos
        físicos o electrónicos, tributamos con el 13% de IVA, en el 100% de las
        compras que realizan nuestros clientes.
      </p>
      <p className="mb-2">
        Si no se selecciona, se enviará al destinatario una constancia
        electrónica al correo indicado.
      </p>
      <p className="mb-2">12. Cambios y devoluciones</p>
      <p className="mb-2">
        Toda mercadería que adquiera en Ticolitas.com goza de garantía por temas
        de empaque al momento de la entrega (por favor verificar cuando reciba
        su producto, que no esté deteriorado y/o roto) o si se entrega producto
        que esté fuera de la fecha de vencimiento al momento de su venta.
      </p>
      <p className="mb-2">
        Para que tu trámite sea rápido es necesario que tome en cuenta la
        siguiente información:
      </p>
      <p className="mb-2">
        El artículo no debe haber sufrido deterioro por el mal uso.
      </p>
      <p className="mb-2">
        Si desea cambiar de mercadería porque no cubre sus necesidades, esta no
        debe haber sido usada, cualquier sello de garantía o empaque descartable
        debe estar en óptimas condiciones.
      </p>
      <p className="mb-2">
        Favor consultar con nuestro personal las marcas de alimentos que tienen
        garantía de sabor para sus mascotas, por consumo no mayor al 10% del
        total del empaque adquirido y con una fecha de compra que no supere los
        30 días.
      </p>
      <p className="mb-2">
        Si el artículo que adquiriste se encuentra averiado el día de la compra
        y por tal motivo lo compra en oferta, este no goza de garantía y no
        podrá ser cambiado o devuelto.
      </p>
      <p className="mb-2">13. Servicio al Cliente / Contactos</p>
      <p className="mb-2">
        Para cualquier duda, comentario o consulta nos puede contactar en la
        dirección de correo electrónico:{" "}
        <a target="_blank" rel="noreferrer" href="mailto:pedidos@ticolitas.com">
          pedidos@ticolitas.com
        </a>
      </p>
      <p className="mb-2">
        También puedes comunicarte con nosotros por medio de nuestras redes
        sociales.
      </p>
      <p className="mb-2">14. Privacidad y seguridad del sitio</p>
      <p className="mb-2">
        En TiColitas nos preocupa mucho la privacidad y datos de nuestros
        clientes, por eso continuamente trabajamos para actualizar cada día
        nuestros sistemas de seguridad.
      </p>
      <p className="mb-2">
        Toda la información que provee el cliente al momento de registrarse o
        realizar una compra será tratada con absoluta confidencialidad y
        privacidad. TiColitas.com se limita a resguardar la siguiente
        información del cliente:
      </p>
      <p className="mb-2">
        Información Personal: su nombre, dirección física, número de teléfono,
        método de pago y dirección de e-mail.
      </p>
      <p className="mb-2">
        Información no personalizada identificable: dirección IP, tipo de
        navegador, nombre de dominio y paginaciones específicas del website de
        Ticolitas.com se recopilan para ganar una mejor comprensión de las
        necesidades del desarrollo de sitio.
      </p>
      <p className="mb-2">
        Solicitaremos también datos de forma voluntaria que los dueños nos
        quieran brindar de su mascota, de manera opcional, para personalizar sus
        pedidos, estos incluyen, el nombre de la mascota, cantidad de alimento
        que consume diariamente, para poder ayudarle al dueño a estimar el día
        aproximado donde se le terminará el producto, estos datos se utilizarán
        con absoluta confidencialidad y privacidad. La calculadora que le indica
        al cliente cuándo se terminará el alimento de su mascota, genera un
        aproximado en días, que puede variar de la fecha real, debido a que solo
        se ajusta el cálculo, con base en raciones diarias de alimento que van
        en múltiplos de 50 g, también puede variar de la interpretación de la
        persona que utilice la herramienta, que no cuente con balanza para pesar
        las raciones, tamaño del recipiente que se utilice para servir el
        producto, por lo que no nos hacemos responsables de dar el dato exacto,
        sino que es un aproximado, que tiene como intención evitar que el
        producto se le agote al cliente, sin que se percate de forma anticipada.
        En TiColitas respetamos las indicaciones de los médicos veterinarios o
        la recomendación del fabricante sobre la ración diaria que debe consumir
        cada mascota, con base en su etapa de vida, raza, condición corporal,
        tamaño. La calculadora de días en que se terminará el alimento de
        TiColitas, no tiene como función, generar recomendaciones de raciones
        diarias de alimento para las mascotas.
      </p>
      <p className="mb-2">
        ACCESO Y NAVEGACIÓN EN EL SITIO WEB: EXCLUSIÓN DE GARANTÍAS Y
        RESPONSABILIDAD
      </p>
      <p className="mb-2">
        TiColitas no garantiza la continuidad, disponibilidad y utilidad del
        Sitio Web, ni de los Contenidos o Servicios. TiColitas hará todo lo
        posible por el buen funcionamiento del Sitio Web, sin embargo, no se
        responsabiliza ni garantiza que el acceso a este Sitio Web no vaya a ser
        ininterrumpido o que esté libre de error.
      </p>
      <p className="mb-2">
        Tampoco se responsabiliza o garantiza que el contenido o software al que
        pueda accederse a través de este Sitio Web, esté libre de error o cause
        un daño al sistema informático (software y hardware) del Usuario. En
        ningún caso TiColitas será responsable por las pérdidas, daños o
        perjuicios de cualquier tipo que surjan por el acceso, navegación y el
        uso del Sitio Web, incluyéndose, pero no limitándose, a los ocasionados
        a los sistemas informáticos o los provocados por la introducción de
        virus.
      </p>
      <p className="mb-2">
        TiColitas tampoco se hace responsable de los daños que pudiesen
        ocasionarse a los usuarios por un uso inadecuado de este Sitio Web. En
        particular, no se hace responsable en modo alguno de las caídas,
        interrupciones, falta o defecto de las telecomunicaciones que pudieran
        ocurrir.
      </p>
      <p className="mb-2">POLÍTICA DE ENLACES</p>
      <p className="mb-2">
        Se informa que el Sitio Web de TiColitas pone o puede poner a
        disposición de los Usuarios medios de enlace (como, entre otros, links,
        banners, botones), directorios y motores de búsqueda que permiten a los
        Usuarios acceder a sitios web pertenecientes y/o gestionados por
        terceros.
      </p>
      <p className="mb-2">
        La instalación de estos enlaces, directorios y motores de búsqueda en el
        Sitio Web tiene por objeto facilitar a los Usuarios la búsqueda de
        información y acceso a la información disponible en Internet, sin que
        pueda considerarse una sugerencia, recomendación o invitación para la
        visita de los mismos.
      </p>
      <p className="mb-2">
        TiColitas no ofrece ni comercializa por sí ni por medio de terceros los
        productos y/o servicios disponibles en dichos sitios enlazados.
      </p>
      <p className="mb-2">
        Asimismo, tampoco garantizará la disponibilidad técnica, exactitud,
        veracidad, validez o legalidad de sitios ajenos a su propiedad a los que
        se pueda acceder por medio de los enlaces.
      </p>
      <p className="mb-2">
        TiColitas en ningún caso revisará o controlará el contenido de otros
        sitios web, así como tampoco aprueba, examina ni hace propios los
        productos y servicios, contenidos, archivos y cualquier otro material
        existente en los referidos sitios enlazados.
      </p>
      <p className="mb-2">
        TiColitas no asume ninguna responsabilidad por los daños y perjuicios
        que pudieran producirse por el acceso, uso, calidad o licitud de los
        contenidos, comunicaciones, opiniones, productos y servicios de los
        sitios web no gestionados por TiColitas y que sean enlazados en este
        Sitio Web.
      </p>
      <p className="mb-2">
        El Usuario o tercero que realice un hipervínculo desde una página web de
        otro, distinto, sitio web al Sitio Web de TiColitas deberá saber que:
      </p>
      <p className="mb-2">
        No se permite la reproducción —total o parcialmente— de ninguno de los
        Contenidos y/o Servicios del Sitio Web sin autorización expresa de
        TiColitas.
      </p>
      <p className="mb-2">
        No se permite tampoco ninguna manifestación falsa, inexacta o incorrecta
        sobre el Sitio Web de TiColitas, ni sobre los Contenidos y/o Servicios
        del mismo.
      </p>
      <p className="mb-2">
        A excepción del hipervínculo, el sitio web en el que se establezca dicho
        hiperenlace no contendrá ningún elemento, de este Sitio Web, protegido
        como propiedad intelectual por el ordenamiento jurídico costarricense,
        salvo autorización expresa de TiColitas. El establecimiento del
        hipervínculo no implicará la existencia de relaciones entre TiColitas y
        el titular del sitio web desde el cual se realice, ni el conocimiento y
        aceptación de TiColitas de los contenidos, servicios y/o actividades
        ofrecidos en dicho sitio web, y viceversa.
      </p>
      <p className="mb-2">PROPIEDAD INTELECTUAL E INDUSTRIAL</p>
      <p className="mb-2">
        TiColitas por sí o como parte cesionaria, es titular de todos los
        derechos de propiedad intelectual e industrial del Sitio Web, así como
        de los elementos contenidos en el mismo (a título enunciativo y no
        exhaustivo, imágenes, sonido, audio, vídeo, software o textos, marcas o
        logotipos, combinaciones de colores, estructura y diseño, selección de
        materiales usados, programas de ordenador necesarios para su
        funcionamiento, acceso y uso, etc.). Serán, por consiguiente, obras
        protegidas como propiedad intelectual por el ordenamiento jurídico
        costarricense, siéndoles aplicables tanto la normativa costarricense en
        este campo, como los tratados internacionales relativos a la materia y
        suscritos por Costa Rica.
      </p>
      <p className="mb-2">
        Todos los derechos reservados. En virtud de lo dispuesto en la Ley de
        Propiedad Intelectual, quedan expresamente prohibidas la reproducción,
        la distribución y la comunicación pública, incluida su modalidad de
        puesta a disposición, de la totalidad o parte de los contenidos de esta
        página web, con fines comerciales, en cualquier soporte y por cualquier
        medio técnico, sin la autorización de TiColitas.
      </p>
      <p className="mb-2">
        El Usuario se compromete a respetar los derechos de propiedad
        intelectual e industrial de TiColitas. Podrá visualizar los elementos
        del Sitio Web o incluso imprimirlos, copiarlos y almacenarlos en el
        disco duro de su ordenador o en cualquier otro soporte físico siempre y
        cuando sea, exclusivamente, para su uso personal. El Usuario, sin
        embargo, no podrá suprimir, alterar, o manipular cualquier dispositivo
        de protección o sistema de seguridad que estuviera instalado en el Sitio
        Web.
      </p>
      <p className="mb-2">
        En caso de que el Usuario o tercero considere que cualquiera de los
        Contenidos del Sitio Web suponga una violación de los derechos de
        protección de la propiedad intelectual, deberá comunicarlo
        inmediatamente a TiColitas a través de los datos de contacto del
        apartado 13.Servicio al Cliente / Contactos / Información consultas de
        privacidad, de este Aviso Legal y Condiciones Generales de Uso.
      </p>
      <p className="mb-2">
        ACCIONES LEGALES, LEGISLACIÓN APLICABLE Y JURISDICCIÓN
      </p>
      <p className="mb-2">
        TiColitas se reserva la facultad de presentar las acciones civiles o
        penales que considere necesarias por la utilización indebida del Sitio
        Web y Contenidos, o por el incumplimiento de las presentes Condiciones.
      </p>
      <p className="mb-2">
        La relación entre el Usuario y TiColitas se regirá por la normativa
        vigente y de aplicación en el territorio costarricense. De surgir
        cualquier controversia con relación a la interpretación y/o a la
        aplicación de estas Condiciones las partes someterán sus conflictos a la
        jurisdicción ordinaria sometiéndose a los jueces y tribunales que
        correspondan conforme al derecho costarricense.
      </p>
      <p className="mb-2">Última modificación: 12 de octubre 2021.</p>
      <p className="mb-2">
        Datos que nos quiera brindar de su mascota de forma opcional para
        personalizar sus pedidos, como nombre de la mascota, cantidad de
        alimento que consume diariamente para poder recordarle cuando se le
        acabará el producto, se usarán con absoluta confidencialidad y
        privacidad.
      </p>
      <p className="mb-2">15. Información General</p>
      <p className="mb-2">Recomendaciones de seguridad a clientes.</p>
      <p className="mb-2">
        Desconecte siempre la sesión del navegador después de haber accedido a
        una zona de seguridad o de haber introducido al sistema el usuario y
        contraseña.
      </p>
      <p className="mb-2">
        Nunca solicitaremos información personal mediante correo electrónico,
        por lo que se recomienda al cliente no proporcionar ningún tipo de
        información que le sea solicitada por este medio.
      </p>
      <p className="mb-2">
        En caso de duda, el cliente podrá verificar la autenticidad del vínculo,
        dirección electrónica o correo electrónico en la que le soliciten
        información personal al correo electrónico{" "}
        <a target="_blank" rel="noreferrer" href="mailto:pedidos@ticolitas.com">
          pedidos@ticolitas.com
        </a>
        .
      </p>
      <p className="mb-2">
        La empresa no se hace responsable de errores del cliente o usuario o
        negligencia al usar los servicios que se ofrecen en este sitio y no
        cubre pérdidas ocasionadas por: errores de captura o el uso indebido del
        servicio, negligencia en el manejo o compartir su información que
        ocasione accesos no autorizados a cuentas, dejar la computadora sin
        atención durante una sesión en línea, etc.
      </p>
      <p className="mb-2">16. Conformidad del usuario</p>
      <p className="mb-2">
        Se constituye un acuerdo legal entre cualquier usuario y TiColitas.com
        dándose por entendido que el cliente, antes de solicitar algún servicio
        a través del sitio, ha revisado, entendido y acordado los términos
        expuestos en las políticas del portal.
      </p>
      <p className="mb-2">
        En caso de inconformidad, el usuario deberá abstenerse de proporcionar
        información personal, así como de levantar algún requerimiento
        comercial.
      </p>
    </div>
  );
}

export default TermsAndConditions;
