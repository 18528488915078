import React, { useCallback, useEffect, useState } from "react";
import { IoMdArrowRoundBack } from "react-icons/io";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";

import useAuthFetch from "../../hooks/useAuthFetch";
import Pagination from "../../components/Pagination";
import Spinner from "../../components/Spinner";
import { useSelector } from "react-redux";

const InventoryFamilyProduct = () => {
  const [variations, setvariations] = useState(null);
  const [page, setPage] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [loading, setLoading] = useState(false);
  const { token } = useSelector((store) => store.app.user);
  const authFetch = useAuthFetch();

  const fetchvariations = useCallback(async () => {
    const pageSize = 5;
    setLoading(true);

    const familiasFetch = await authFetch(
      `familias?filter={
			"limit": ${pageSize},
			"skip": ${page * pageSize},
			"order": ["createdAt DESC"]}`
    );

    const variationsData = await familiasFetch.json();
    setvariations(variationsData);
    const totalVariationsFetch = await authFetch(`familias/count`);
    const totalVariationsData = await totalVariationsFetch.json();
    setTotalPages(Math.ceil(totalVariationsData.count / pageSize));
    setTotalCount(totalVariationsData.count);
    setLoading(false);
  }, [page]);

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchvariations();
  }, [fetchvariations]);

  const fetchDeleteCatalogo = async (id) => {
    const familiasFetch = await authFetch(
      `familias/${id}`,
      {},
      "DELETE",
      token
    );
    if (
      familiasFetch.status === 200 ||
      familiasFetch.status === 201 ||
      familiasFetch.status === 204
    ) {
      Swal.fire({
        icon: "success",
        title: "¡Correcto!",
        text: "Familia de productos eliminada correctamente",
        background: "#76236C",
        color: "#fafafa",
        confirmButtonColor: "#19D3C5",
      });
      fetchvariations();
    }
    if (
      familiasFetch.status === 400 ||
      familiasFetch.status === 401 ||
      familiasFetch.status === 404 ||
      familiasFetch.status === 500
    ) {
      Swal.fire({
        icon: "error",
        title: "¡Error!",
        text: "Error al eliminar una familia de productos",
        background: "#76236C",
        color: "#fafafa",
        confirmButtonColor: "#19D3C5",
      });
    }
  };

  const deleteCatalogo = async (id) => {
    Swal.fire({
      title: "¿Estas seguro que deseas eliminar esta familia de productos?",
      text: "Una vez realizada la acción no podrás revertirla",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Si, eliminar",
      cancelButtonText: "Cancelar",
      confirmButtonColor: "#19D3C5",
      cancelButtonColor: "#E7004C",
      background: "#76236C",
      color: "#fafafa",
    }).then((result) => {
      if (result.isConfirmed) {
        fetchDeleteCatalogo(id);
        fetchvariations();
        setPage(0);
      }
    });
  };

  return (
    <div className="w-full p-content pb-16">
      {loading && <Spinner />}
      <div className="min-h-[400px]">
        <div className="flex w-full items-center">
          <div className="w-full flex justify-between">
            <div>
              <p className="text-2xl font-bold mb-4">
                Familias de productos (Total: {totalCount})
              </p>

              <Link to={`/inventario/familias/${"nueva"}`}>
                <button className="bg-cyan text-white py-2 px-6 font-bold rounded-full flex justify-center items-center">
                  {"+"} Nueva familia
                </button>
              </Link>
            </div>

            <Link to="/inventario">
              <button className="bg-pink text-white py-2 px-8 font-bold rounded-full float-right flex justify-center items-center">
                <IoMdArrowRoundBack className="text-lg" /> Volver
              </button>
            </Link>
          </div>
        </div>
        <br />

        {variations && variations.length > 0 ? (
          <>
            <Pagination
              totalPages={totalPages}
              currentPage={page}
              onPageChange={(page) => {
                setPage(page);
              }}
            />

            {variations.map((c, index) => (
              <div
                className="w-full ring-1 ring-purple rounded-lg mb-4 p-6"
                key={index}
              >
                <div className="grid grid-cols-1 md:grid-cols-1 gap-4">
                  <div>
                    <br />
                    <p className="text-2xl">{c.familia.nombre}</p>
                    {c.familia.fotoPrincipal && (
                      <img
                        src={c.familia.fotoPrincipal}
                        alt={c.familia.nombre}
                        className="h-32 mt-8 ml-5"
                      />
                    )}
                  </div>
                  <div className="flex w-full justify-end mt-6">
                    {
                      //     <Link
                      // 	to={`/variations/valores/${c.id}`}
                      // 	className="rounded-3xl py-2 px-8 my-1 font-medium text-white bg-cyan mx-3"
                      // >
                      // 	Ver valores
                      // </Link>
                    }
                    <Link
                      to={`/inventario/familias/${c.familia.id}`}
                      className="rounded-3xl py-2 px-8 my-1 font-medium text-white bg-purple"
                    >
                      Editar
                    </Link>
                    <button
                      className="rounded-3xl py-2 px-8 ml-2 my-1 font-medium text-white bg-pink"
                      onClick={() => deleteCatalogo(c.familia.id)}
                    >
                      Eliminar
                    </button>
                  </div>
                </div>
              </div>
            ))}
            <Pagination
              totalPages={totalPages}
              currentPage={page}
              onPageChange={(page) => {
                setPage(page);
              }}
            />
          </>
        ) : (
          !loading && <p>No hay variaciones para mostrar</p>
        )}
      </div>
    </div>
  );
};

export default InventoryFamilyProduct;
