import { configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import storageSession from "redux-persist/lib/storage/session";
import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import thunk from "redux-thunk";

import appReducer from "./slices/appSlice";
import checkoutReducer from "./slices/checkoutSlice";
import appSessionReducer from "./slices/appSessionSlice";
import { inventorySlice } from "./slices";

const appSessionPersistConfig = { key: "appSession", storage: storageSession };

const reducers = combineReducers({
  app: appReducer,
  checkout: checkoutReducer,
  appSession: persistReducer(appSessionPersistConfig, appSessionReducer),
  inventory: inventorySlice,
});

const rootPersistConfig = {
  key: "root",
  storage,
  blacklist: ["appSession"],
};

// Save the state to localStorage
const saveToLocalStorage = (state) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem("persistedState", serializedState);
  } catch (e) {
    console.warn("Could not save state", e);
  }
};

// Load the state from localStorage
const loadFromLocalStorage = () => {
  try {
    const serializedState = localStorage.getItem("persistedState");
    if (serializedState === null) return undefined;

    const loadedState = JSON.parse(serializedState);

    return loadedState;
  } catch (e) {
    console.warn("Could not retrieve state", e);
    return undefined;
  }
};

// Load the state from localStorage
const preloadedState = loadFromLocalStorage();

const persistedReducer = persistReducer(rootPersistConfig, reducers);

const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware: [thunk],
  preloadedState,
});

store.subscribe(() => saveToLocalStorage(store.getState()));

export default store;
