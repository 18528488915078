/* eslint-disable no-unused-vars */

import React, { useEffect, useRef } from "react";
import { useState } from "react";
import GoogleMapReact from "google-map-react";
import Autocomplete from "../Autocomplete";
import Marker from "../Marker";
import Select from "../Select";
import { useCallback } from "react";
import pin from "../../assets/pin.png";

export function GoogleMap({
  onPointChange,
  address,
  forceSpecificDeliveryPoint,
  preCompleted,
}) {
  /* eslint-disable no-unused-vars */
  const [mapApiLoaded, setMapApiLoaded] = useState(false);
  const [mapInstance, setMapInstance] = useState(null);
  const [mapApi, setMapApi] = useState(null);
  const [center, setCenter] = useState([address.latitud, address.longitud]);
  const [draggable, setDraggable] = useState(true);
  const [lat, setLat] = useState(address.latitud);
  const [lng, setLng] = useState(address.longitud);
  const [userIsInDeliveryPlace, setUserIsInDeliveryPlace] =
    useState(preCompleted);
  const [locationShared, setLocationShared] = useState(false);
  const [userSharedLocation, setUserSharedLocation] = useState(null);

  const mapRef = useRef(null);

  const setLatLngAndNotify = useCallback(
    (sourceLat, sourceLng) => {
      setLat(sourceLat);
      setLng(sourceLng);
      onPointChange({
        lat: sourceLat,
        lng: sourceLng,
      });
    },
    [onPointChange]
  );

  const getCurrentLocation = useCallback(() => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        setLocationShared(true);
        setCenter([position.coords.latitude, position.coords.longitude]);
        setUserSharedLocation([
          position.coords.latitude,
          position.coords.longitude,
        ]);
        setLatLngAndNotify(position.coords.latitude, position.coords.longitude);
      });
    }
  }, [setLatLngAndNotify]);

  useEffect(() => {
    if (userIsInDeliveryPlace && !locationShared) {
      getCurrentLocation();
    }
  }, [userIsInDeliveryPlace, locationShared, getCurrentLocation]);

  useEffect(() => {
    if (address.id && (address.latitud !== lat || address.longitud !== lng)) {
      setLat(address.latitud);
      setLng(address.longitud);
      setCenter([address.latitud, address.longitud]);
    }
  }, [address.id, address.latitud, address.longitud, lat, lng]);

  const onMarkerInteraction = () => {
    setDraggable(false);
  };

  const onMarkerInteractionMouseUp = (childKey, childProps, mouse) => {
    setDraggable(true);
    setLatLngAndNotify(mouse.lat, mouse.lng);
  };

  const _onChange = ({ center }) => {
    setCenter([center.lat, center.lng]);
  };

  const _onClick = (value) => {
    setLatLngAndNotify(value.lat, value.lng);
  };

  const apiHasLoaded = (map, maps) => {
    setMapApiLoaded(true);
    setMapInstance(map);
    setMapApi(maps);
  };

  const addPlace = (place) => {
    setLatLngAndNotify(
      place.geometry.location.lat(),
      place.geometry.location.lng()
    );
  };

  return (
    <div>
      {!address.id && (
        <>
          <Select
            required
            containerClasses="my-2"
            labelColor="text-base"
            buttonClasses="border border-purple"
            label="¿Estás en el lugar donde querés recibir tu pedido en este momento?:"
            subLabel="Al indicarnos que estás en el punto de entrega se te
            solicitará compartir tu ubicación para poder llegar fácilmente a
            entregar tu pedido."
            options={[
              {
                label: "Sí",
                value: true,
              },
              {
                label: "No",
                value: false,
              },
            ]}
            onChange={(value) => {
              setUserIsInDeliveryPlace(value);
              if (value === false && !forceSpecificDeliveryPoint) {
                onPointChange(null);
              }
            }}
            value={userIsInDeliveryPlace}
          />
          {userIsInDeliveryPlace === false &&
            mapApiLoaded &&
            forceSpecificDeliveryPoint && (
              <div>
                <Autocomplete
                  map={mapInstance}
                  mapApi={mapApi}
                  addplace={addPlace}
                />
              </div>
            )}
        </>
      )}
      <div
        className={`w-full ${address.id ? "my-4" : "mb-4"} ${
          userIsInDeliveryPlace === true ||
          (address.id && address.latitud) ||
          (userIsInDeliveryPlace !== null && forceSpecificDeliveryPoint)
            ? "block"
            : "hidden"
        }`}
      >
        {!address.id ? (
          <>
            {forceSpecificDeliveryPoint ? (
              <div className="text-sm pt-2 mb-4 text-borderGray">
                {userIsInDeliveryPlace
                  ? "Hemos colocado el pin en la ubicación donde estás en este momento, si estás satisfecho con la ubicación podés continuar. Tambien podés ajustar la ubicación "
                  : "Al ingresar una ubicación en la casilla de arriba pondremos el pin en el mapa lo más cercano posible a este punto de referencia y después podrás ajustar la ubicación "}{" "}
                arrastrando el pin{" "}
                <img
                  className="inline h-4"
                  src={pin}
                  alt="Pin de google maps"
                />{" "}
                si estás utilizando un mouse. Si estás utilizando una pantalla
                táctil debes tocar una única vez el punto de destino donde
                querés colocarlo sin arrastrar el mismo.
              </div>
            ) : (
              <div className="text-sm pt-2 mb-4 text-borderGray">
                Hemos colocado el pin en la ubicación donde estás en este
                momento, si NO estás realmente en el punto de entrega por favor
                cambiá tu selección anterior.
              </div>
            )}
          </>
        ) : null}
        <div className="relative h-[500px] mt-20">
          <GoogleMapReact
            center={center}
            zoom={parseInt(process.env.REACT_APP_GOOGLE_MAPS_ZOOM)}
            draggable={draggable && forceSpecificDeliveryPoint}
            onChange={_onChange}
            onChildMouseDown={
              forceSpecificDeliveryPoint ? onMarkerInteraction : null
            }
            onChildMouseUp={
              forceSpecificDeliveryPoint ? onMarkerInteractionMouseUp : null
            }
            onChildMouseMove={
              forceSpecificDeliveryPoint ? onMarkerInteraction : null
            }
            onClick={forceSpecificDeliveryPoint ? _onClick : null}
            bootstrapURLKeys={{
              key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
              libraries: ["places", "geometry"],
            }}
            yesIWantToUseGoogleMapApiInternals
            onGoogleApiLoaded={({ map, maps }) => apiHasLoaded(map, maps)}
          >
            <Marker text={"Tu punto de entrega"} lat={lat} lng={lng} />
          </GoogleMapReact>
        </div>
      </div>
      {userIsInDeliveryPlace &&
        locationShared &&
        userSharedLocation &&
        (userSharedLocation[0] !== lat || userSharedLocation[1] !== lng) &&
        !address.id && (
          <button
            onClick={() => {
              getCurrentLocation();
            }}
            className="bg-purple text-white h-12 mb-4 px-8 rounded-full"
          >
            Devolver a mi ubicación
          </button>
        )}
    </div>
  );
}

export default GoogleMap;
