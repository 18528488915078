import React, { useCallback, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Modal from "../../components/Modal";
import useAuthFetch from "../../hooks/useAuthFetch";
import Spinner from "../../components/Spinner";
import { SearchBarOrders } from "../../components/SearchBar";
import { IoMdArrowRoundBack } from "react-icons/io";
import TarjetaPedido from "../../components/TarjetaPedido";
import Pagination from "../../components/Pagination";
import { useSelector } from "react-redux";

const useQuery = () => {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
};

const SearchPedidos = ({ user }) => {
  const query = useQuery();
  const [searchResults, setSearchResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showCancelOrderModal, setShowCancelOrderModal] = useState(false);
  const [orderToCancel, setOrderToCancel] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const { token } = useSelector((store) => store.app.user);
  const authFetch = useAuthFetch();

  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);

  const cancelOrder = async (orderId) => {
    setLoading(true);
    await authFetch(`ventas/${orderId}/cancelar`, {}, "PATCH", token);
    fetchProducts();
  };

  const fetchPrintBill = async (order) => {
    const warehouseOfOrderFetch = await authFetch(
      `bodegas/${order.idBodega}`
    );
    const warehouseData = await warehouseOfOrderFetch.json();
    const payload = {
      order,
      vendorId: warehouseData.vendorIdImpresora,
      productId: warehouseData.productIdImpresora,
    };
    setLoading(true);
    await fetch(warehouseData.urlServidorDeImpresion, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify(payload),
    });
    setLoading(false);
  };

  const fetchProducts = useCallback(async () => {
    window.scrollTo(0, 0);
    const pageSize = 10;
    const valor = query.get("valor");
    setSearchQuery(valor);
    if (valor) {
      setLoading(true);
      const request = await authFetch(
        "ventas/buscar",
        {
          page: {
            current: page + 1,
            size: pageSize,
          },
          query: valor,
        },
        "POST",
        token
      );
      const sales = await request.json();
      setSearchResults(sales);

      const totalVentasFetch = await authFetch(`ventas/count`, null, "GET", token);
      const totalVentasData = await totalVentasFetch.json();
      setTotalPages(Math.ceil(totalVentasData.count / pageSize));
      setLoading(false);
    }
  }, [page, query, token]);

  useEffect(() => {
    fetchProducts();
  }, [fetchProducts]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="w-full p-content pb-16 min-h-[400px]">
      {loading && <Spinner />}
      <Modal
        backgroundColor="bg-purple"
        closeModalFunction={() => {
          setOrderToCancel(null);
          setShowCancelOrderModal(false);
        }}
        open={showCancelOrderModal}
      >
        <div className="text-white p-8 pt-16 overflow-y-scroll h-full text-justify">
          <p className="mb-4">
            Realmente desea cancelar la Orden #{orderToCancel}. Esta acción no
            se podrá deshacer.
          </p>
          <div className="w-full flex flex-wrap justify-center sm:flex-nowrap sm:justify-end mt-4 gap-2">
            <button
              onClick={() => {
                setOrderToCancel(null);
                setShowCancelOrderModal(false);
              }}
              className="bg-white text-purple py-2 px-8 rounded-full"
            >
              Mantener orden
            </button>
            <button
              onClick={() => {
                cancelOrder(orderToCancel);
                setOrderToCancel(null);
                setShowCancelOrderModal(false);
              }}
              className="bg-orange text-white py-2 px-8 rounded-full"
            >
              Cancelar orden
            </button>
          </div>
        </div>
      </Modal>

      <div>
        <div className="w-full">
          <Link to="/pedidos">
            <button className="bg-pink text-white py-2 px-8 font-bold rounded-full float-right flex justify-center items-center">
              <IoMdArrowRoundBack className="text-lg" /> Volver
            </button>
          </Link>
        </div>
      </div>
      <p className="text-2xl font-bold mb-4">Pedidos</p>

      <div className="w-full">
        <br />
        <SearchBarOrders presetSearchQuery={searchQuery} />
        <br />
      </div>

      {searchResults && searchResults.length > 0 ? (
        <>
          {searchResults.map((v) => {
            return (
              <TarjetaPedido
                key={`venta_${v.id}`}
                user={user}
                venta={v}
                setOrderToCancel={setOrderToCancel}
                setShowCancelOrderModal={setShowCancelOrderModal}
                fetchPrintBill={fetchPrintBill}
                backLink={`/pedidos/${v.id}?desde=/pedidos/busqueda?valor=${searchQuery}`}
                showProducts
              />
            );
          })}
          <Pagination
            totalPages={totalPages}
            currentPage={page}
            onPageChange={(page) => {
              setPage(page);
            }}
          />
        </>
      ) : (
        !loading && <p>No hay pedidos para mostrar</p>
      )}
    </div>
  );
};
export default SearchPedidos;
