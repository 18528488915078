import React from "react";
import { useContext } from "react";
import { useSelector } from "react-redux";
import { CierreContext } from "../../containers/CierresPage";
import useAuthFetch from "../../hooks/useAuthFetch";

export default function CierreNotFound() {
  const {
    fechaValue,
    transportistaValue,
    tiendaValue,
    filter,
    setLoading,
    actualizarCierre,
  } = useContext(CierreContext);

  const { token } = useSelector((store) => store.app.user);
  const authFetch = useAuthFetch();

  const generateCierre = async () => {
    if (filter === "transportista") {
      setLoading(true);
      await authFetch(
        `cierres/transportista/${transportistaValue}/fecha/${fechaValue}`,
        null,
        "POST",
        token
      );
      await authFetch(
        `cierres/transportista/${transportistaValue}/fecha/${fechaValue}`,
        null,
        "GET",
        token
      );
      await actualizarCierre();
      setLoading(false);
    } else {
      setLoading(true);
      await authFetch(
        `cierres/tienda/${tiendaValue}/fecha/${fechaValue}`,
        null,
        "POST",
        token
      );
      await authFetch(
        `cierres/tienda/${tiendaValue}/fecha/${fechaValue}`,
        null,
        "GET",
        token
      );
      await actualizarCierre();

      setLoading(false);
    }
  };

  return (
    <div className="w-full flex flex-col gap-10 justify-center my-3 items-center">
      <p className="text-xl font-semibold text-purple">
        El cierre solicitado no existe
      </p>
      <button
        onClick={() => generateCierre()}
        className="bg-orange text-white px-3 py-2 rounded-full"
      >
        Calcular Cierre
      </button>
    </div>
  );
}
