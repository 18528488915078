import React, { useContext, useState } from "react";
import { recordatoriosContext } from "../../containers/RecordatoriosPage";
import Modal from "../Modal";
import {
  formatDate,
  formatDateSpanishToInputFormat,
  plusDaysDate,
} from "../../utils/formatDates";
import { ClipLoader } from "react-spinners";

export default function ModalEditRecordatorio({
  backgroundColor,
  open,
  closeModalFunction,
}) {
  const { recordatorioSelected, loadingEditar, editarRecordatorio } =
    useContext(recordatoriosContext);

  const [diasParaRecordar, setDiasParaRecordar] = useState(
    Math.round(recordatorioSelected?.duracionDias)
  );

  const [consumoDiarioGramos, setConsumoDiarioGramos] = useState(
    Math.floor(
      recordatorioSelected?.pesoProductoGramos /
        recordatorioSelected?.duracionDias
    )
  );

  const [fechaParaRecordar, setFechaParaRecordar] = useState(
    plusDaysDate(
      recordatorioSelected.ultimaCompra,
      recordatorioSelected.duracionDias
    )
  );

  const [error, setError] = useState("");

  const handleSubmit = async () => {
    if (
      (diasParaRecordar === 0) |
      (diasParaRecordar === "") |
      (consumoDiarioGramos === 0) |
      (consumoDiarioGramos === "")
    ) {
      setError("No puedes dejar espacios vácios o nulos");
    } else {
      await editarRecordatorio(consumoDiarioGramos);
      closeModalFunction();
    }
  };

  if (recordatorioSelected) {
    return (
      <Modal
        backgroundColor={backgroundColor}
        open={open}
        closeModalFunction={closeModalFunction}
      >
        <div className="bg-white p-10 shadow-sm">
          <h2 className="text-xl font-semibold">
            {recordatorioSelected.nombreCliente}{" "}
            {recordatorioSelected.apellidoCliente}
          </h2>
          <p>{recordatorioSelected.nombreProducto}</p>

          <div className="flex flex-col gap-4 mt-4">
            <div className="flex flex-col gap-2">
              <label>Fecha de última compra:</label>
              <input
                value={formatDate(new Date(recordatorioSelected.ultimaCompra))}
                disabled={true}
                type={"date"}
                className={"rounded-full border-purple border p-3"}
              />
            </div>
            <div className="flex flex-col gap-2">
              <label>Días para recordar:</label>
              <input
                value={diasParaRecordar}
                onChange={async (e) => {
                  if (isNaN(e.target.value)) {
                    return;
                  } else {
                    await setDiasParaRecordar(Number(e.target.value));
                    await setConsumoDiarioGramos(
                      Math.floor(
                        recordatorioSelected.pesoProductoGramos /
                          Number(e.target.value)
                      )
                    );
                    setFechaParaRecordar(
                      plusDaysDate(
                        recordatorioSelected.ultimaCompra,
                        Number(e.target.value)
                      )
                    );
                  }
                }}
                type={"text"}
                className={"rounded-full border-purple border p-3"}
              />
            </div>
            <div className="flex flex-col gap-2">
              <label>Consumo Díario (gramos):</label>
              <input
                value={consumoDiarioGramos}
                onChange={async (e) => {
                  await setConsumoDiarioGramos(Number(e.target.value));
                  await setDiasParaRecordar(
                    Math.floor(
                      recordatorioSelected.pesoProductoGramos /
                        Number(e.target.value)
                    )
                  );

                  setFechaParaRecordar(
                    plusDaysDate(
                      recordatorioSelected.ultimaCompra,
                      Math.floor(
                        recordatorioSelected.pesoProductoGramos /
                          Number(e.target.value)
                      )
                    )
                  );
                }}
                type={"text"}
                className={"rounded-full border-purple border p-3"}
              />
            </div>
            <div className="flex flex-col gap-2">
              <label>Fecha para recordatorio:</label>
              <input
                type={"date"}
                className={"rounded-full border-purple border p-3"}
                disabled={true}
                value={formatDateSpanishToInputFormat(fechaParaRecordar)}
              />
            </div>

            {error && <p className="text-pink">{error}</p>}

            <div className="flex justify-end gap-2 mt-4 ">
              <button
                className="bg-purple text-white py-2 px-3 rounded-lg flex gap-2 items-center"
                onClick={() => handleSubmit()}
              >
                {loadingEditar && <ClipLoader color="#fff" />}
                Editar
              </button>

              <button
                className="bg-pink text-white py-2 px-3 rounded-lg"
                onClick={() => closeModalFunction()}
              >
                Cancelar
              </button>
            </div>
          </div>
        </div>
      </Modal>
    );
  }

  return null;
}
