import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  whatsappReconfirmation: false,
  giveawayAuthorization: false,
  acceptTermsAndConditions: false,
  printBill: false,
  addressDetails: null,
  country: null,
  selectedStore: null,
  billing: false,
  checkSearchID: false,
  billingDetails: null,
  paymentMethod: null,
  activeSection: null,
  stores: [],
  paymentMethods: [],
  cashAmount: 0,
  orderNotes: null,
  shippingMethods: [],
  shippingMethod: null,
  shippingCost: null,
  previousShippingCost: null,
  warehouse: null,
  previousTollCost: null,
  shippingDate: null,
  estimatedHoursForShipping: null,
  shippingDistance: null,
  shippingTime: null,
  userAddresses: [],
  userBillingAddresses: [],
  newUserName: "",
  newUserLastName: "",
  newUserEmail: "",
  newUserPhone: "",
};

export const checkoutSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setWhatsappReconfirmation: (state, action) => {
      state.whatsappReconfirmation = action.payload;
    },
    setGiveawayAuthorization: (state, action) => {
      state.giveawayAuthorization = action.payload;
    },
    setAcceptTermsAndConditions: (state, action) => {
      state.acceptTermsAndConditions = action.payload;
    },
    setPrintBill: (state, action) => {
      state.printBill = action.payload;
    },
    setAddressDetails: (state, action) => {
      state.addressDetails = action.payload;
    },
    setSelectedStore: (state, action) => {
      state.selectedStore = action.payload;
    },
    setBilling: (state, action) => {
      state.billing = action.payload;
    },
    setCheckSearchID: (state, action) => {
      state.checkSearchID = action.payload;
    },
    setBillingDetails: (state, action) => {
      state.billingDetails = action.payload;
    },
    setPaymentMethod: (state, action) => {
      state.paymentMethod = action.payload;
    },
    setActiveSection: (state, action) => {
      state.activeSection = action.payload;
    },
    setStores: (state, action) => {
      state.stores = action.payload;
    },
    setPaymentMethods: (state, action) => {
      state.paymentMethods = action.payload;
    },
    setCashAmount: (state, action) => {
      state.cashAmount = action.payload;
    },
    setOrderNotes: (state, action) => {
      state.orderNotes = action.payload;
    },
    setShippingMethods: (state, action) => {
      state.shippingMethods = action.payload;
    },
    setShippingMethod: (state, action) => {
      state.shippingMethod = action.payload;
    },
    setShippingCost: (state, action) => {
      state.shippingCost = action.payload;
    },
    setPreviousShippingCost: (state, action) => {
      state.previousShippingCost = action.payload;
    },
    setPreviousTollCost: (state, action) => {
      state.previousTollCost = action.payload;
    },
    setTollCost: (state, action) => {
      state.tollCost = action.payload;
    },
    setShippingDate: (state, action) => {
      state.shippingDate = action.payload;
    },
    setEstimatedHoursForShipping: (state, action) => {
      state.estimatedHoursForShipping = action.payload;
    },
    setShippingDistance: (state, action) => {
      state.shippingDistance = action.payload;
    },
    setShippingTime: (state, action) => {
      state.shippingTime = action.payload;
    },
    setUserAddresses: (state, action) => {
      state.userAddresses = action.payload;
    },
    setUserBillingAddresses: (state, action) => {
      state.userBillingAddresses = action.payload;
    },
    setNewUserName: (state, action) => {
      state.newUserName = action.payload;
    },
    setNewUserLastName: (state, action) => {
      state.newUserLastName = action.payload;
    },
    setNewUserEmail: (state, action) => {
      state.newUserEmail = action.payload;
    },
    setNewUserPhone: (state, action) => {
      state.newUserPhone = action.payload;
    },
    setWarehouse: (state, action) => {
      state.warehouse = action.payload;
    },
    clearAfterPurchase: (state) => {
      state.whatsappReconfirmation = false;
      state.acceptTermsAndConditions = false;
      state.printBill = false;
      state.addressDetails = null;
      state.country = null;
      state.selectedStore = null;
      state.billing = false;
      state.billingDetails = null;
      state.paymentMethod = null;
      state.activeSection = null;
      state.stores = [];
      state.paymentMethods = [];
      state.cashAmount = 0;
      state.orderNotes = null;
      state.shippingMethods = [];
      state.shippingMethod = null;
      state.shippingCost = null;
      state.tollCost = null;
      state.shippingDate = null;
      state.estimatedHoursForShipping = null;
      state.shippingDistance = null;
      state.shippingTime = null;
      state.userAddresses = [];
      state.userBillingAddresses = [];
      state.newUserName = "";
      state.newUserLastName = "";
      state.newUserEmail = "";
      state.newUserPhone = "";
      state.checkSearchID = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setWhatsappReconfirmation,
  setGiveawayAuthorization,
  setAcceptTermsAndConditions,
  setPrintBill,
  setAddressDetails,
  setSelectedStore,
  setBilling,
  setBillingDetails,
  setPaymentMethod,
  setActiveSection,
  setStores,
  setPaymentMethods,
  setCashAmount,
  setOrderNotes,
  setShippingMethods,
  setShippingMethod,
  setShippingCost,
  setPreviousShippingCost,
  setPreviousTollCost,
  setTollCost,
  setShippingDate,
  setEstimatedHoursForShipping,
  setShippingDistance,
  setShippingTime,
  setUserAddresses,
  setUserBillingAddresses,
  setNewUserName,
  setNewUserLastName,
  setNewUserEmail,
  setNewUserPhone,
  clearAfterPurchase,
  setWarehouse,
  setCheckSearchID
} = checkoutSlice.actions;

export default checkoutSlice.reducer;
