import React from "react";

const formatCurrency = (value) => {
  return new Intl.NumberFormat("es-CR", {
    style: "currency",
    currency: "CRC",
  }).format(value);
};

export function ProductPrice({ product, color }) {
  return (
    <div className="mt-4">
      <div className="flex gap-3">
        <p className={`${color || "text-listingProductGray"} text-lg`}>
          {formatCurrency(product.precioVenta)}
          <br />
          <span className={`${color || "text-listingProductGray"} text-xs`}>
            I.V.A. incluido <br />
            Precio Regular
          </span>
        </p>
      </div>

      {product.descuentos &&
        (product?.descuentos[0]?.cantidadDisponible !== 0 ||
          product?.descuentos[0]?.cantidadDisponible === null) &&
        product.descuentos.map((descuento) => {
          if (
            new Date(descuento.validoDesde) <= new Date() &&
            new Date(descuento.validaHasta) >= new Date() &&
            descuento.activo
          ) {
            return (
              <div className="flex gap-4 items-center">
                <div className="flex flex-col ali" key={descuento.id}>
                  <p className="font-semibold text-xl text-green mt-3 mb-1">
                    {formatCurrency(
                      product.precioVenta * (1 - descuento.porcentaje)
                    )}
                  </p>
                  <span
                    className={`${color || "text-listingProductGray"} text-xs`}
                  >
                    *Precio con descuento{" "}
                    {descuento.soloEnEfectivo && "pagando en efectivo"}{" "}
                    <br></br>
                    {` (Por tiempo limitado. Aplican restricciones)`}
                  </span>
                </div>

                <div className="flex rounded-full bg-green text-white text-xs font-bold p-2 items-center justify-center w-auto h-16 ">
                  -{descuento.porcentaje * 100}%*
                </div>
              </div>
            );
          } else {
            return null;
          }
        })}
    </div>
  );
}

export default ProductPrice;
