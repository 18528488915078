import {
  DirectionsRenderer,
  GoogleMap,
  Marker,
  useJsApiLoader,
} from "@react-google-maps/api";
import React, { useCallback, useEffect, useState } from "react";
import { IoMdArrowRoundBack } from "react-icons/io";
import { BiEdit } from "react-icons/bi";
import { Link, useParams } from "react-router-dom";
import useAuthFetch from "../../hooks/useAuthFetch";
import { ClipLoader } from "react-spinners";
import { FaWhatsapp } from "react-icons/fa";
import useGeolocation from "../../hooks/useGeolocation";
import { sumarSegundos } from "./components/RouteTable";
import moment from "moment";
import { useSelector } from "react-redux";
import {
  ESTADOS_ORDENES,
  METODOS_PAGO,
  METODOS_PAGO_DESC,
} from "../../utils/constants";

export default function RouteDetail() {
  const authFetch = useAuthFetch();
  const user = useSelector((store) => store.app.user);

  const { id } = useParams();
  const [route, setRoute] = useState({
    loading: false,
    data: {},
  });

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  });

  const { coordinates, error } = useGeolocation();

  const [distance, setDistance] = useState();

  function obtenerDuracionAcumulada(position) {
    if (distance) {
      let sum = 0;
      if (distance.routes[0].legs.length > 0) {
        for (let index = 0; index <= position; index++) {
          sum += distance.routes[0].legs[index].duration.value;
        }
      }
      return sum;
    }
  }

  async function marcarComoEntregada(ventaId, estado) {
    const updates = {
      idEstadoVenta: estado,
    };

    await authFetch(
      `ventas/${ventaId}`,
      estado === ESTADOS_ORDENES.ENTREGADA
        ? { ...updates, fechaEntregaFinal: new Date() }
        : updates,
      "PATCH",
      user.token
    );

    fetchRoute();
  }

  const fetchRoute = useCallback(async () => {
    setRoute({
      loading: true,
      data: {},
    });

    const routeResponse = await authFetch(`/transportista/viaje/${id}`);
    const data = await routeResponse.json();

    setRoute({
      loading: false,
      data,
    });
  }, [id]);

  useEffect(() => {
    fetchRoute();
  }, [id, fetchRoute]);

  useEffect(() => {
    if (isLoaded && route.data.lineas) {
      const calculateTimeBetweenPointsInSeconds = async () => {
        const directionService = new window.google.maps.DirectionsService();

        //array de cordenadas
        const waypoints = route.data.lineas.map((venta) => ({
          // eslint-disable-next-line no-undef
          location: new google.maps.LatLng(venta.latitud, venta.longitud),
          stopover: true,
        }));

        const response = await directionService.route({
          origin: {
            lat: Number(coordinates.latitude),
            lng: Number(coordinates.longitude),
          },
          destination: {
            lat: Number(route.data.destino.latitud),
            lng: Number(route.data.destino.longitud),
          },
          travelMode: "DRIVING",
          waypoints,
        });

        setDistance(response);
      };

      calculateTimeBetweenPointsInSeconds();
    }
  }, [isLoaded, route.data, coordinates]);

  if (route.loading || !isLoaded || !route.data) {
    return (
      <div className="w-full p-content pb-16 ">
        <div className="flex flex-col gap-8">
          <div className="flex items-center justify-center min-h-screen">
            <ClipLoader size={68} />
          </div>
        </div>
      </div>
    );
  }

  const assignDriver = async (orderId, driverId) => {
    await authFetch(
      `ventas/${orderId}`,
      {
        idTransportista: driverId,
      },
      "PATCH",
      user.token
    );

    fetchRoute();
  };

  const setAsPaid = async (orderId, paymentMethod) => {
    await authFetch(
      `ventas/${orderId}`,
      {
        fechaRealPago: new Date(),
        idMetodoPago: paymentMethod,
        metodoPago: METODOS_PAGO_DESC[paymentMethod],
        idEstadoVenta: ESTADOS_ORDENES.FINALIZADA,
      },
      "PATCH",
      user.token
    );
    fetchRoute();
  };

  return (
    <div className="w-full p-content pb-16">
      <div className="flex flex-col gap-8">
        <div className="flex gap-2 justify-end">
          <Link to="/rutas">
            <button className="bg-pink text-white py-2 px-8 font-bold rounded-full float-right flex justify-center items-center">
              <IoMdArrowRoundBack className="text-lg" /> Volver
            </button>
          </Link>
          <Link to={`edit`}>
            <button className="bg-purple  gap-2 text-white py-2 px-8 font-bold rounded-full float-right flex justify-center items-center">
              <BiEdit className="text-lg" /> Editar
            </button>
          </Link>
        </div>

        {route.data ? (
          <div className="flex flex-col gap-3">
            <h2 className="font-bold text-xl text-purple">
              Ruta #{route.data.id}
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-5 mb-10">
              <p>
                <strong>Transportista: </strong>
                {route.data.transportista}
              </p>
              <p>
                <strong>Fecha: </strong>
                {route.data.fecha}
              </p>

              <p>
                <strong>Bodega Origen: </strong>
                {route.data.idBodegaOrigen === 1 ? "La Guacima" : "Heredía"}
              </p>

              <p>
                <strong>Bodega Destino: </strong>
                {route.data.idBodegaDestino === 1 ? "La Guacima" : "Heredía"}
              </p>

              <p>
                <strong>Duración: </strong>
                {(route.data.tiempoSegundos / 3600).toFixed(2)} Horas
              </p>

              <p>
                <strong>Distancia: </strong>
                {(route.data.distanciaMetros / 1000).toFixed(2)} Kílometros
              </p>
            </div>

            {route.data.lineas && (
              <div className="my-10 grid grid-cols-1 md:grid-cols-2 2xl:grid-cols-3 gap-3">
                {route.data.lineas.map((ruta, index) => (
                  <div
                    key={ruta.id}
                    className="rounded-lg shadow-lg p-10 border border-[#ddd]"
                  >
                    <h3 className="font-bold text-purple text-xl mb-2">
                      Orden No. {index + 1}
                    </h3>
                    <p className="font-bold text-orange mb-5">
                      {!error && distance
                        ? ruta.estadoVenta !== ESTADOS_ORDENES.FINALIZADA &&
                          ruta.estadoVenta !== ESTADOS_ORDENES.CANCELADA &&
                          ruta.estadoVenta !== ESTADOS_ORDENES.ENTREGADA
                          ? `Hora estimada de llegada: ${moment(
                              sumarSegundos(obtenerDuracionAcumulada(index))
                            ).format("h:mm A")}`
                          : ""
                        : ""}
                    </p>

                    {ruta.estadoVenta === ESTADOS_ORDENES.FINALIZADA && (
                      <p className="font-bold text-orange">Finalizada</p>
                    )}

                    {ruta.estadoVenta === ESTADOS_ORDENES.ENTREGADA && (
                      <p className="font-bold text-orange">Entregada</p>
                    )}

                    {ruta.estadoVenta === ESTADOS_ORDENES.PENDIENTE_PAGO && (
                      <p className="font-bold text-orange">Pendiente de pago</p>
                    )}

                    {ruta.estadoVenta === ESTADOS_ORDENES.CANCELADA && (
                      <p className="font-bold text-pink">Cancelada</p>
                    )}

                    <div className="flex flex-col gap-2">
                      <p>
                        <strong>ID Orden: </strong> {ruta.id}
                      </p>
                      <p>
                        <strong>Nombre Entrega: </strong>{" "}
                        {ruta.nombreCompletoEntrega}
                      </p>
                      <p>
                        <strong>Dirección: </strong> <br />{" "}
                        {ruta.direccionExacta}
                      </p>
                    </div>

                    <div className="mt-10 flex flex-wrap gap-2 flex-row">
                      {!error &&
                        distance &&
                        (ruta.estadoVenta === ESTADOS_ORDENES.LISTO_EN_BODEGA ||
                          ruta.estadoVenta === ESTADOS_ORDENES.EN_CAMINO) && (
                          <>
                            <a
                              href={`https://api.whatsapp.com/send?phone=+${
                                ruta.codigoPaisTelefono
                              }${ruta.telefono}&text=Hola soy ${user.nombre} ${
                                user.apellido1
                              } y soy el encargado de tu pedido de Ticolitas, estaré llegando a las ${moment(
                                sumarSegundos(obtenerDuracionAcumulada(index))
                              ).format("h:mm A")} aproximadamente.`}
                              className="bg-[#25D366] text-white px-3 py-2 rounded-md flex items items-center justify-center gap-2"
                              target="_blank"
                              rel="noreferrer"
                            >
                              <FaWhatsapp />
                              Notificar
                            </a>

                            <a
                              href={`https://api.whatsapp.com/send?phone=+${ruta.codigoPaisTelefono}${ruta.telefono}&text=Hola, le saluda Daniel Rodriguez de TiColitas. Estoy a minutos de poder entregarle el pedido que solicitó para sus mascostas. Le agradecería me oriente si hay alguna indicación particular que deba conocer para hacerle más sencilla y agradable su entrega. De antemano le agradezco su atención y respuesta. Le comparto mi foto 👇🐶🐱.`}
                              className="bg-[#25D366] text-white px-3 py-2 rounded-md flex items items-center justify-center gap-2"
                              target="_blank"
                              rel="noreferrer"
                            >
                              <FaWhatsapp />
                              Pedir Indicaciones Whatsapp
                            </a>
                          </>
                        )}

                      {ruta.estadoVenta === ESTADOS_ORDENES.LISTO_EN_BODEGA && (
                        <>
                          <a
                            onClick={() =>
                              marcarComoEntregada(
                                ruta.id,
                                ESTADOS_ORDENES.EN_CAMINO
                              )
                            }
                            className="bg-purple text-white px-3 py-2 rounded-md flex items items-center justify-center gap-2"
                            target="_blank"
                            rel="noreferrer"
                            href={`https://www.waze.com/ul?ll=${ruta.latitud},${ruta.longitud}&navigate=yes`}
                          >
                            Iniciar Viaje
                          </a>

                          <button
                            onClick={() =>
                              marcarComoEntregada(
                                ruta.id,
                                ESTADOS_ORDENES.CANCELADA
                              )
                            }
                            className="bg-pink text-white px-3 py-2 rounded-md flex items items-center justify-center gap-2"
                          >
                            Cancelar Orden
                          </button>

                          <button
                            onClick={() => assignDriver(ruta.id, 0)}
                            className="bg-pink text-white px-3 py-2 rounded-md flex items items-center justify-center gap-2"
                          >
                            Desasignar
                          </button>
                        </>
                      )}

                      {ruta.estadoVenta === ESTADOS_ORDENES.EN_CAMINO && (
                        <>
                          <button
                            className="bg-orange text-white px-3 py-2 rounded-md flex items items-center justify-center gap-2"
                            onClick={() =>
                              marcarComoEntregada(
                                ruta.id,
                                ESTADOS_ORDENES.ENTREGADA
                              )
                            }
                          >
                            Marcar como entregada
                          </button>
                        </>
                      )}

                      {(ruta.estadoVenta === ESTADOS_ORDENES.LISTO_EN_BODEGA ||
                        ruta.estadoVenta === ESTADOS_ORDENES.EN_CAMINO ||
                        ruta.estadoVenta === ESTADOS_ORDENES.PENDIENTE_PAGO ||
                        ruta.estadoVenta === ESTADOS_ORDENES.ENTREGADA) && (
                        <>
                          <button
                            className="bg-dog text-white px-3 py-2 rounded-md flex items items-center justify-center gap-2"
                            onClick={() => {
                              setAsPaid(ruta.id, METODOS_PAGO.TARJETA);
                            }}
                          >
                            Pagada con tarjeta
                          </button>

                          <button
                            onClick={() => {
                              setAsPaid(ruta.id, METODOS_PAGO.EFECTIVO);
                            }}
                            className="bg-dog text-white px-3 py-2 rounded-md flex items items-center justify-center gap-2"
                          >
                            Pagada con efectivo
                          </button>

                          <button
                            className="bg-dog text-white px-3 py-2 rounded-md flex items items-center justify-center gap-2"
                            onClick={() => {
                              setAsPaid(ruta.id, METODOS_PAGO.TRANSFERENCIA);
                            }}
                          >
                            Pagada con transferencia
                          </button>

                          <button
                            onClick={() => {
                              setAsPaid(ruta.id, METODOS_PAGO.SINPE);
                            }}
                            className="bg-dog text-white px-3 py-2 rounded-md flex items items-center justify-center gap-2"
                          >
                            Pagada con SINPE
                          </button>
                        </>
                      )}

                      <Link to={`/pedidos/${ruta.id}?desde=/envios`}>
                        <button className="bg-purple text-white px-3 py-2 rounded-md flex items items-center justify-center gap-2">
                          Ver Detalles
                        </button>
                      </Link>
                    </div>
                  </div>
                ))}
              </div>
            )}

            {route.data.id && (
              <Map
                origin={{
                  lat: Number(route.data.origen.latitud),
                  lng: Number(route.data.origen.longitud),
                }}
                destination={{
                  lat: Number(route.data.destino.latitud),
                  lng: Number(route.data.destino.longitud),
                }}
                waypoints={route.data.lineas}
              />
            )}
          </div>
        ) : (
          <div>No existe una ruta con id # {id} </div>
        )}
      </div>
    </div>
  );
}

function Map({ origin, destination, waypoints }) {
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  });

  const [route, setRoute] = useState();

  const getRoute = useCallback(async () => {
    // eslint-disable-next-line no-undef
    const directionService = new google.maps.DirectionsService();
    const result = await directionService.route({
      origin: origin,
      destination: destination,
      // eslint-disable-next-line no-undef
      waypoints: formatWaypoints(waypoints),
      // eslint-disable-next-line no-undef
      travelMode: google.maps.TravelMode.DRIVING,
      optimizeWaypoints: true,
    });

    setRoute(result);
  }, [destination, origin, waypoints]);

  useEffect(() => {
    if (isLoaded) {
      getRoute();
    }
  }, [isLoaded, getRoute]);

  if (!isLoaded) {
    return <p>Cargando mapa...</p>;
  }

  return (
    <GoogleMap zoom={25} center={origin} mapContainerClassName="map-container">
      {route && (
        <DirectionsRenderer
          directions={route}
          options={{
            polylineOptions: {
              strokeColor: "#FF0000",
            },
            suppressMarkers: true,
          }}
        />
      )}

      {origin.lat === destination.lat && origin.lng === destination.lng ? (
        <Marker
          label={{ text: "O/F", color: "#FFF" }}
          position={{
            lat: Number(origin.lat),
            lng: Number(origin.lng),
          }}
        />
      ) : (
        <>
          <Marker
            label={{ text: "O", color: "#FFF" }}
            position={{
              lat: Number(origin.lat),
              lng: Number(origin.lng),
            }}
          />
          <Marker
            label={{ text: "F", color: "#FFF" }}
            position={{
              lat: Number(destination.lat),
              lng: Number(destination.lng),
            }}
          />
        </>
      )}

      {waypoints &&
        waypoints.map((waypoint, index) => (
          <Marker
            key={"Marker " + index}
            label={{ text: index + 1 + "", color: "#FFF" }}
            position={{
              lat: Number(waypoint.latitud),
              lng: Number(waypoint.longitud),
            }}
          />
        ))}
    </GoogleMap>
  );
}

function formatWaypoints(points) {
  return points.map((point) => {
    // eslint-disable-next-line no-undef
    const stop = new google.maps.LatLng(point.latitud, point.longitud);
    return {
      location: stop,
      stopover: true,
    };
  });
}
