import { Link, useNavigate, useParams } from "react-router-dom";
import { IoMdArrowRoundBack } from "react-icons/io";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import Spinner from "../../components/Spinner";
import useAuthFetch from "../../hooks/useAuthFetch";
import { useSelector } from "react-redux";

const FormValores = () => {
  const { idCatalogo, idValor } = useParams();
  const navigate = useNavigate();
  const [loading, setloading] = useState(false);
  const [error, setError] = useState(false);
  const [msgError, setMsgError] = useState("");
  const [catalogo, setCatalogo] = useState(null);
  const [valorCatalogo, setValorCatalogo] = useState("");
  const { token } = useSelector((store) => store.app.user);
  const authFetch = useAuthFetch();
  
  useEffect(() => {
    const fetchCatalogo = async () => {
      const data = await authFetch(`catalogos/${idValor}`);
      const dataJSON = await data.json();
      setCatalogo(dataJSON);
      setloading(false);
    };
    if (!isNaN(idValor)) {
      setloading(true);
      fetchCatalogo();
    }
  }, [idValor]);

  useEffect(() => {
    if (catalogo) {
      setValorCatalogo(catalogo.valor);
    }
  }, [catalogo]);

  const handleSubmit = () => {
    if (
      valorCatalogo === "" ||
      valorCatalogo === null ||
      valorCatalogo === undefined
    ) {
      setMsgError("El catálogo debe tener un nombre ");
      setError(true);
      return;
    }

    if (isNaN(idValor)) {
      handlePost(valorCatalogo);
    }

    if (!isNaN(idValor)) {
      handlePacth(valorCatalogo);
    }
  };

  const handlePost = async (valor) => {
    let request = await authFetch(
      "catalogos",
      {
        idCatalogo: Number(idCatalogo),
        valor,
      },
      "POST",
      token
    );
    if (request.status === 200 || request.status === 204) {
      Swal.fire({
        icon: "success",
        title: "Correcto",
        text: "Valor agregado correctamente",
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp",
        },
        background: "#76236C",
        color: "#fafafa",
        confirmButtonColor: "#19D3C5",
      });
      setTimeout(() => {
        navigate(`/catalogos/valores/${idCatalogo}`);
      }, 400);
      setError(false);
    }

    if (
      request.status === 400 ||
      request.status === 404 ||
      request.status === 402 ||
      request.status === 401 ||
      request.status === 422 ||
      request.status === 500
    ) {
      setMsgError("Error al agregar un catálogo");
      setError(true);
      return;
    }
  };

  const handlePacth = async (valor) => {
    let request = await authFetch(
      `catalogos/${idValor}`,
      {
        valor,
      },
      "PATCH",
      token
    );
    if (request.status === 200 || request.status === 204) {
      Swal.fire({
        icon: "success",
        title: "Correcto",
        text: "Valor actualizado correctamente",
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp",
        },
        background: "#76236C",
        color: "#fafafa",
        confirmButtonColor: "#19D3C5",
      });
      setTimeout(() => {
        navigate(`/catalogos/valores/${idCatalogo}`);
      }, 400);
      setError(false);
    }

    if (
      request.status === 400 ||
      request.status === 404 ||
      request.status === 402 ||
      request.status === 401 ||
      request.status === 422 ||
      request.status === 500
    ) {
      setMsgError("Error al agregar un valor al catálogo");
      setError(true);
      return;
    }
  };

  return (
    <div className="w-full p-content pb-16">
      <div className="flex w-full items-center">
        <div className="w-full">
          <Link to={`/catalogos/valores/${idCatalogo}`}>
            <button className="bg-pink text-white py-2 px-8 font-bold rounded-full float-right flex justify-center items-center">
              <IoMdArrowRoundBack className="text-lg" /> Volver
            </button>
          </Link>
        </div>
      </div>

      <h2 className="my-3 font-bold text-baseGray text-2xl">
        {isNaN(idValor) ? "Agregar valor" : "Editar valor"}
      </h2>
      <br />

      {loading ? (
        <div className="my-20">
          <Spinner />
        </div>
      ) : (
        <div>
          <br />
          <div>
            <label className="ml-5">Nombre del valor</label>
            <input
              onChange={(evt) => {
                setValorCatalogo(evt.target.value);
              }}
              className="w-full rounded-3xl my-2 h-12 p-6 ring-1 ring-purple outline-none"
              type={"text"}
              name="valorCatalogo"
              value={valorCatalogo}
              placeholder="Nombre del valor"
              required={true}
            />
          </div>

          {error && (
            <p className="text-pink text-lg font-medium text-center">
              {msgError} *
            </p>
          )}
          <button
            className="bg-cyan text-white py-2 px-8 my-3 font-bold rounded-full float-right flex transition-shadow duration-300 ease-in-out justify-center items-center hover:shadow-xl hover:shadow-cyan/30"
            onClick={handleSubmit}
          >
            Guardar
          </button>
        </div>
      )}
    </div>
  );
};

export default FormValores;
