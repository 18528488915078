import React from "react";

export const TableOrders = ({ lineas, removeLinea, editLinea, justView }) => {
  return (
    <div className="w-full my-6  ">
      <hr className="my-4 bg-purple text-purple-border" />
      <p>Líneas de la orden de compra</p>
      <div className="w-full md:w-9/12 grid grid-cols-1 md:grid-cols-5 items-center gap-4 font-semibold my-4">
        <p className="col-span-2">Producto</p>
        <p className="hidden md:block">Cantidad</p>
        <p className="hidden md:block mx-6">Precio unitario</p>
        <p className="hidden md:block mx-6">Impuesto</p>
      </div>
      {lineas.map((l, index) => (
        <div
          className={`w-ful flex ${index % 2 ? "bg-purple/10" : ""}`}
          key={index}
        >
          <div className="w-11/12 grid grid-cols-1 md:grid-cols-5 items-center gap-4">
            <div className="flex md:col-span-2 overflow-hidden">
              <img
                className="w-16 h-16 text-xs"
                src={
                  l.producto.fotoPrincipal
                    ? l.producto.fotoPrincipal
                    : "https://polpo-assets.s3.amazonaws.com/production/ticolitas/productos/800/noactivo.jpg"
                }
                alt={`Foto de ${index}`}
              />
              <p className="text-xs overflow-hidden flex items-center">{l.producto.nombre}</p>
            </div>
            <p className="hidden md:block">{l.cantidad}</p>
            <p className="hidden md:block overflow-hidden">
              {l.precioUnitario}
            </p>
            <p className="text-sm hidden md:block">
              Tarifa general 13%
              {/*l.impuestoDesc ? l.impuestoDesc : l.porcentajeImpuesto*/}
            </p>
          </div>
          {!justView && (
            <div className="flex justify-center items-center">
              <div
                className="cursor-pointer text-xs py-2 px-3 rounded-lg bg-pink text-white mx-2"
                onClick={() => removeLinea(index, l)}
              >
                Eliminar
              </div>
              <div
                className="cursor-pointer text-xs py-2 px-3 rounded-lg bg-purple text-white mx-2"
                onClick={() => editLinea(l, index)}
              >
                Editar
              </div>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};
