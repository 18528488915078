import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { IoIosCloseCircleOutline } from "react-icons/io";
import _ from "lodash";
import moment from "moment";
import "moment/locale/es";
import { clearCart, hideCart } from "../../slices/appSlice";
import NumberSteper from "../NumberSteper";
import ProductName from "../ProductName";
import ProductPrice from "../ProductPrice";
import {
  reduceQuantityFromCart,
  increaseQuantityInCart,
} from "../../slices/appSlice";
import Mixpanel from "../../services/mixpanel";
import CheckboxInput from "../CheckboxInput";
import tacita from "../../assets/tacita.svg";

moment.updateLocale("es");

const canSaleInStore = (user) => {
  if (user && user.token) {
    if (
      _.intersection(user.roles, [
        "admin",
        "dependiente",
        "administradorTienda",
      ]).length > 0 ||
      user.roles.indexOf("superAdmin") > -1
    ) {
      return true;
    }
  }
  return false;
};

/*const getEstimatedTimeForPickupText = (pickupShippingMethod) => {
  if (pickupShippingMethod) {
    const currentDate = new Date();
    const currentPlus30Minutes = new Date(currentDate.getTime() + 30 * 60000);
    if (
      currentPlus30Minutes.getHours() > pickupShippingMethod.horaFin ||
      (currentPlus30Minutes.getHours() === pickupShippingMethod.horaFin &&
        currentPlus30Minutes.getMinutes() > pickupShippingMethod.minutosFinal)
    ) {
      currentPlus30Minutes.setDate(currentDate.getDate() + 1);
      currentPlus30Minutes.setHours(9, 30);
      return currentPlus30Minutes;
    } else {
      if (
        currentPlus30Minutes.getHours() < pickupShippingMethod.horaInicio ||
        (currentPlus30Minutes.getHours() === pickupShippingMethod.horaInicio &&
          currentPlus30Minutes.getMinutes() <
            pickupShippingMethod.minutosInicio)
      ) {
        currentPlus30Minutes.setHours(9, 30);
        return currentPlus30Minutes;placeOrderMethodTransporte:


      } else {
        return currentPlus30Minutes;
      }
    }
  }
};*/

export function Cart({
  cart,
  user,
  showCart,
  checkout,
  checkoutErrors,
  additionalStyles,
  hideButtons,
  hideProducts,
  finalButton,
  placeOrderMethod,
  shippingCost,
  tollCost,
  shippingDate,
  estimatedHoursForShipping,
  isPickup,
  isLocalDelivery,
  giveawayAuthorization,
  setGiveawayAuthorization,
  setShowTermsAndConditionsGiveaway,
  shippingInAssociationOffice,
  district,
  showTime,
  freeShipping,
  toogleShipping,
  handleChange,
  discountPrice,
  subTotalWhitDiscountPrice,
  differedTransportCost,
  setDifferedTransportCost,
  codeRef = null,
  loading,
}) {
  const dispatch = useDispatch();
  const [
    showAlertOfMaxQuantityInStockExceeded,
    setShowAlertOfMaxQuantityInStockExceeded,
  ] = useState(null);
  const { roles } = useSelector((state) => state.app.user);

  const changeQuantityValue = (productId, value) => {
    setShowAlertOfMaxQuantityInStockExceeded(null);

    if (value < 0) {
      Mixpanel.track(Mixpanel.TYPES.TACITA_REDUCIR_CANTIDAD_PRODUCTO, {
        producto: productId,
        nombreProducto: cart.products[productId].product.nombreTienda,
        cantidadEnBodega: cart.products[productId].product.cantidadEnBodega,
        cantidadActual: cart.products[productId].quantity,
      });
      dispatch(reduceQuantityFromCart(productId));
    } else {
      const product = cart.products[productId].product;
      if (product.descuento && product.descuento > 0) {
        if (
          cart.products[productId].quantity + 1 <=
          product.unidadesDisponiblesDescuento
        ) {
          Mixpanel.track(Mixpanel.TYPES.TACITA_AUMENTAR_CANTIDAD_PRODUCTO, {
            producto: productId,
            nombreProducto: cart.products[productId].product.nombreTienda,
            cantidadEnBodega: product.unidadesDisponiblesDescuento,
            cantidadActual: cart.products[productId].quantity,
            enDescuento: true,
          });
          dispatch(increaseQuantityInCart(productId));
        } else {
          Mixpanel.track(
            Mixpanel.TYPES.TACITA_ERROR_AUMENTAR_CANTIDAD_PRODUCTO,
            {
              producto: productId,
              nombreProducto: cart.products[productId].product.nombreTienda,
              cantidadEnBodega: product.cantidadEnBodega,
              cantidadActual: cart.products[productId].quantity,
              enDescuento: true,
            }
          );
          dispatch(increaseQuantityInCart(productId));
          setShowAlertOfMaxQuantityInStockExceeded(productId);
        }
      } else {
        if (cart.products[productId].quantity + 1 <= product.cantidadEnBodega) {
          Mixpanel.track(Mixpanel.TYPES.TACITA_AUMENTAR_CANTIDAD_PRODUCTO, {
            producto: productId,
            nombreProducto: cart.products[productId].product.nombreTienda,
            cantidadEnBodega: product.cantidadEnBodega,
            cantidadActual: cart.products[productId].quantity,
          });
          dispatch(increaseQuantityInCart(productId));
        } else {
          Mixpanel.track(
            Mixpanel.TYPES.TACITA_ERROR_AUMENTAR_CANTIDAD_PRODUCTO,
            {
              producto: productId,
              nombreProducto: cart.products[productId].product.nombreTienda,
              cantidadEnBodega: product.cantidadEnBodega,
              cantidadActual: cart.products[productId].quantity,
            }
          );
          setShowAlertOfMaxQuantityInStockExceeded(productId);
        }
      }
    }
  };
  // get current route with react router dom v6
  const { pathname } = useLocation();
  const isCheckout = pathname.includes("checkout");

  const [isJustOneAccesory, setIsJustOneAccesory] = useState(false);
  const [thereAreAccesories, setThereAreAccesories] = useState(false);

  useEffect(() => {
    if (cart.productsCount === 1) {
      const product = cart.products[Object.keys(cart.products)[0]].product;
      if (product.especie === "Accesorios") {
        setIsJustOneAccesory(true);
      } else {
        setIsJustOneAccesory(false);
      }
    } else {
      setIsJustOneAccesory(false);
      const accesories = Object.keys(cart.products).filter(
        (product) => cart.products[product].product.especie === "Accesorios"
      );
      if (accesories.length > 0) {
        setThereAreAccesories(true);
      } else {
        setThereAreAccesories(false);
      }
    }
  }, [cart, cart.products, cart.productsCount]);

  const renderProducts = () => {
    const products = [];
    for (const productId in cart.products) {
      const product = cart.products[parseInt(productId)].product;
      const render = (
        <div key={`product_in_cart_${productId}`}>
          <div className="w-full flex items-center">
            <div className="w-2/5 lg:w-3/5">
              <Link
                onClick={() => {
                  Mixpanel.track(Mixpanel.TYPES.TACITA_CERRAR);
                  dispatch(hideCart());
                }}
                to={`/tienda/producto/${product.id}`}
              >
                <img
                  src={product.fotoPrincipal}
                  alt={`Foto de ${product.nombreTienda}`}
                  className=""
                />
              </Link>
            </div>
            <div className="w-2/3 lg:w-9/12 pl-4">
              <Link
                onClick={() => {
                  Mixpanel.track(Mixpanel.TYPES.TACITA_IR_A_PRODUCTO, {
                    producto: productId,
                    nombreProducto: product.nombreTienda,
                  });
                  dispatch(hideCart());
                }}
                to={`/tienda/producto/${product.id}`}
              >
                <ProductName color="text-white" product={product} />
              </Link>
              <ProductPrice
                hideDiscountText
                color="text-white"
                product={product}
              />
              {roles && roles.includes("administradorTienda") && isCheckout && (
                <div className="flex flex-col mt-2">
                  <label className="text-white text-sm mb-2">
                    Descuento sobre precio de lista:{" "}
                  </label>
                  <input
                    className="w-full rounded-3xl max-w-[250px] my-2 h-12 p-6 border border-purple outline-none"
                    type="number"
                    onChange={(e) => handleChange(e, productId, false)}
                  />
                </div>
              )}

              <div className="flex flex-col mt-2">
                <label className="text-white text-sm mb-2">
                  Usar código de descuento:{" "}
                </label>
                <input
                  className="w-full rounded-3xl max-w-[250px] my-2 h-12 p-6 border border-purple outline-none"
                  type="text"
                  ref={codeRef}
                  onChange={(e) => handleChange(e, productId, true)}
                />
              </div>

              {discountPrice && discountPrice[productId] && (
                <div className="flex flex-col mt-2">
                  <label className="text-white text-sm mb-2">
                    *Precio con descuento (Máximo 3 productos):{" "}
                  </label>
                  <span className="text-white text-sm">
                    ₡ {discountPrice[productId].precioConDescuento.toFixed(2)}
                    <br />
                    I.V.A. incluido
                  </span>
                </div>
              )}
            </div>
          </div>
          <div className="mt-4">
            <NumberSteper
              label="Cantidad:"
              labelColor="text-white"
              value={cart.products[parseInt(productId)].quantity}
              onChangeValue={(value) => {
                changeQuantityValue(productId, value);
              }}
              negativeStepSize={-1}
              positiveStepSize={1}
            />
            {roles && roles.includes("administradorTienda") && isCheckout && (
              <div className="flex flex-col my-4">
                <label className="text-white">Costo de transporte: </label>
                <input
                  className="rounded-full p-3"
                  value={differedTransportCost}
                  onChange={(e) => {
                    //Validate if the value is a number
                    if (!isNaN(e.target.value)) {
                      setDifferedTransportCost(Number(e.target.value));
                    }
                  }}
                />
              </div>
            )}

            {showAlertOfMaxQuantityInStockExceeded === productId &&
              product.descuento &&
              product.descuento > 0 && (
                <p className="text-sm text-pink">
                  {`El precio de este producto ha superado el stock disponible con descuento, no se aplicará descuento despues de las ${product.unidadesDisponiblesDescuento} unidades.`}
                </p>
              )}
            {showAlertOfMaxQuantityInStockExceeded === productId &&
              (!product.descuento || product.descuento === 0) && (
                <p className="text-sm text-orange">
                  Lo sentimos, pronto tendremos más unidades disponibles de este
                  artículo.
                </p>
              )}
          </div>
          <hr className="my-4 border-white/[.55]" />
        </div>
      );
      products.push(render);
    }
    return <div className="mt-4">{products}</div>;
  };

  const transportCost =
    differedTransportCost || (shippingCost || 0) + (tollCost || 0);

  return showCart || checkout ? (
    <div
      className={`no-scrollbar ${additionalStyles || ""} overflow-y-scroll   ${
        !checkout ? "z-50" : ""
      }`}
    >
      <div
        onClick={() => {
          Mixpanel.track(Mixpanel.TYPES.TACITA_CERRAR);
          dispatch(hideCart());
        }}
        className={`no-scrollbar animate__animated animate__fadeIn ${
          checkout ? "hidden" : "z-30 hidden md:block"
        } fixed left-0 top-0 md:w-[40vw] lg:w-[70vw] h-screen bg-borderGray/30`}
      ></div>
      <div
        className={`no-scrollbar animate__animated animate__bounceInRight ${
          checkout
            ? "w-full"
            : "fixed right-0 top-0 w-screen md:w-[60vw] lg:w-[40vw] h-screen z-[60]"
        } bg-purple px-8 py-8 overflow-y-auto `}
      >
        <div className={`${checkout ? "hidden" : ""} w-full mb-10`}>
          <p
            onClick={() => {
              Mixpanel.track(Mixpanel.TYPES.TACITA_CERRAR);
              dispatch(hideCart());
            }}
            className="text-white text-5xl font-bold float-right cursor-pointer select-none"
          >
            <IoIosCloseCircleOutline />
          </p>
        </div>
        <div className="flex items-center">
          <img
            className="brightness-[500] w-12 h-12"
            src={tacita}
            alt="Tacita de compra"
          />
          <span className="text-white m-2">Tacita de compra</span>
        </div>
        {cart.productsCount > 0 && !hideProducts && renderProducts()}

        <div className="flex flex-col gap-2">
          {isCheckout && (
            <div className="flex justify-between mt-8 text-white">
              <p className="text-xl">Subtotal:</p>
              <p className="text-xl">
                {Intl.NumberFormat("es-CR", {
                  style: "currency",
                  currency: "CRC",
                }).format(subTotalWhitDiscountPrice)}
              </p>
            </div>
          )}

          {getTwoDecimalNumber(cart.totalWithAnyDiscount) !==
            getTwoDecimalNumber(cart.total) &&
          getTwoDecimalNumber(cart.totalWithAnyDiscount) ? (
            <div className="flex justify-between mt-8 text-white">
              <p className="text-xl">Subtotal con cualquier tipo de pago:</p>
              <p className="text-xl">
                {Intl.NumberFormat("es-CR", {
                  style: "currency",
                  currency: "CRC",
                }).format(cart.totalWithAnyDiscount)}
              </p>
            </div>
          ) : null}

          {getTwoDecimalNumber(cart.totalWithEfectiveDiscount) !==
            getTwoDecimalNumber(cart.totalWithAnyDiscount) &&
          getTwoDecimalNumber(cart.totalWithEfectiveDiscount) !==
            getTwoDecimalNumber(cart.totalWithAnyDiscount) ? (
            <div className="flex justify-between mt-8 text-white">
              <p className="text-xl">Subtotal con pago en efectivo:</p>
              <p className="text-xl">
                {Intl.NumberFormat("es-CR", {
                  style: "currency",
                  currency: "CRC",
                }).format(cart.totalWithEfectiveDiscount)}
              </p>
            </div>
          ) : null}
        </div>

        {roles &&
          roles.includes("administradorTienda") &&
          isCheckout &&
          freeShipping !== null &&
          isCheckout && (
            <>
              <br />
              <CheckboxInput
                label="Aplicar transporte gratuito a la orden"
                labelColor="text-white"
                onCheckChange={(value) => {
                  toogleShipping(value);
                }}
                checked={freeShipping}
              />
            </>
          )}

        {checkout && !isPickup && !isLocalDelivery && (
          <div className="flex justify-between mt-8 text-white">
            <p className="text-xl">Transporte:</p>

            {!freeShipping && transportCost > 0 && (
              <p className="text-xl">
                {Intl.NumberFormat("es-CR", {
                  style: "currency",
                  currency: "CRC",
                }).format(transportCost)}
              </p>
            )}

            {shippingCost === null &&
              tollCost === null &&
              !district &&
              !differedTransportCost && (
                <p className="text-xl max-w-[40%] text-right">
                  Por favor elija una ubicación para calcular el costo de envío.
                </p>
              )}

            {((shippingCost === null && tollCost === null && district) ||
              (shippingCost === 0 &&
                (tollCost === 0 || tollCost === null))) && (
              <p className="text-xl max-w-[40%] text-right">GRATIS</p>
            )}
          </div>
        )}
        {checkout &&
          shippingDate !== null &&
          !isLocalDelivery &&
          !shippingInAssociationOffice && (
            <div className="flex justify-between mt-8 text-white">
              <p className="text-xl">
                {isPickup
                  ? showTime && "Disponible para recoger a partir del:"
                  : "Tu pedido se entregará:"}
              </p>
              <p className="text-xl max-w-[40%] text-right">
                {}
                {isPickup
                  ? showTime
                    ? isJustOneAccesory
                      ? "En 5 días hábiles"
                      : `${moment(shippingDate).format(
                          "dddd D [de] MMMM [a la(s)] hh:mm a"
                        )}${
                          thereAreAccesories
                            ? "*(Los accesorios se entregan en 5 días hábiles.)"
                            : ""
                        }`
                    : "Calculando tiempo..."
                  : `${
                      isJustOneAccesory
                        ? "En 5 días hábiles"
                        : `${estimatedHoursForShipping}${
                            thereAreAccesories
                              ? " *(Los accesorios se entregan en 5 días hábiles.)"
                              : ""
                          }` || "-"
                    }`}
              </p>
            </div>
          )}
        {checkout && (
          <div className="flex justify-between mt-8 text-white">
            <p className="text-xl">Total:</p>
            <p className="text-xl">
              {Intl.NumberFormat("es-CR", {
                style: "currency",
                currency: "CRC",
              }).format(
                subTotalWhitDiscountPrice + (freeShipping ? 0 : transportCost)
              )}
            </p>
          </div>
        )}
        {!hideButtons && (
          <>
            <div className="w-full mt-8">
              <button
                onClick={() => {
                  Mixpanel.track(Mixpanel.TYPES.TACITA_SEGUIR_COMPRANDO);
                  dispatch(hideCart());
                  window.scrollTo(0, 0);
                }}
                className="bg-white text-purple py-2 px-8 rounded-full w-full"
              >
                Seguir Comprando
              </button>
            </div>
            {cart.productsCount > 0 && (
              <div className="w-full mt-8">
                <Link to="/checkout">
                  <button
                    onClick={() => {
                      Mixpanel.track(Mixpanel.TYPES.TACITA_FINALIZAR_COMPRA);
                      dispatch(hideCart());
                    }}
                    className="bg-orange text-white py-2 px-8 rounded-full w-full"
                  >
                    Finalizar Compra
                  </button>
                </Link>
              </div>
            )}
            {cart.productsCount > 0 && canSaleInStore(user) && (
              <div className="w-full mt-8">
                <Link to="/checkout-pos">
                  <button
                    onClick={() => {
                      Mixpanel.track(Mixpanel.TYPES.TACITA_FINALIZAR_COMPRA);
                      dispatch(hideCart());
                    }}
                    className="bg-orange text-white py-2 px-8 rounded-full w-full"
                  >
                    Finalizar Compra en Tienda
                  </button>
                </Link>
              </div>
            )}
            <div className="w-full mt-8">
              <button
                onClick={() => {
                  dispatch(clearCart());
                  Mixpanel.track(Mixpanel.TYPES.TACITA_LIMPIAR_CARRITO);
                  window.scrollTo(0, 0);
                }}
                className="bg-white text-purple py-2 px-8 rounded-full w-full"
              >
                Limpiar Tacita
              </button>
            </div>
          </>
        )}
        {setGiveawayAuthorization && (
          <div className="w-full mt-12">
            <CheckboxInput
              checked={giveawayAuthorization}
              onCheckChange={() => {
                dispatch(setGiveawayAuthorization(!giveawayAuthorization));
              }}
              labelColor="text-white"
              label={
                "Deseo participar en la rifa del certificado de compra de 20.000 colones y acepto que me contacten por WhatsApp en caso de resultar ganador de la misma."
              }
              highlightedLabel={" Ver términos y condiciones de la promoción."}
              onClickHighlight={() => {
                dispatch(setShowTermsAndConditionsGiveaway(true));
              }}
            />
          </div>
        )}
        {finalButton && (
          <>
            {cart.productsCount > 0 && (
              <div className="w-full mt-8">
                {checkoutErrors && (
                  <p className="text-orange my-2 w-full">
                    Por favor verificá los errores presentados y volvé a
                    intentarlo
                  </p>
                )}
                <Link to="/checkout">
                  <button
                    onClick={() => {
                      placeOrderMethod();
                    }}
                    className="bg-orange text-white py-2 px-8 rounded-full w-full"
                    disabled={loading}
                  >
                    Finalizar Compra
                  </button>
                </Link>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  ) : null;
}

export default Cart;

function getTwoDecimalNumber(number) {
  return Number(number.toFixed(2));
}
