import React from "react";
import { numberToColonesFormat } from "../../utils/formatNumbers";

export default function PendientesPagoTable({ pendientes }) {
  if (pendientes.length === 0) {
    return <p className="text-center my-4">No hay pagos pendientes</p>;
  }

  // const getTotalPendientes = (pendientes) => {
  //   return pendientes.reduce((pendiente) => pendiente.montoPendiente);
  // };

  return (
    <div className="flex flex-col gap-4 mt-5">
      <h2 className="font-bold text-2xl">Pendientes del día</h2>
      <table className="table-auto">
        <thead>
          <tr className="border-b-2">
            <th className="py-4">Orden</th>
            <th className="py-4">Cliente</th>
            <th className="py-4">Medio de Pago</th>
            <th className="py-4">Monto Pendiente</th>
            <th className="py-4">Teléfono</th>
            <th className="py-4">Acción</th>
          </tr>
        </thead>
        <tbody>
          {pendientes.map((pendiente) => (
            <tr className="border-b-[0.5px]" key={pendiente.orden}>
              <td align="center" className="py-4">
                {pendiente.orden}
              </td>
              <td align="center" className="py-4">
                {pendiente.cliente}
              </td>
              <td align="center" className="py-4">
                {pendiente.metodoPago}
              </td>
              <td align="center" className="py-4">
                {numberToColonesFormat(pendiente.montoPendiente)}
              </td>
              <td align="center" className="py-4">
                {pendiente.telefono}
              </td>
              <td align="center" className="py-4">
                <a
                  href={`https://api.whatsapp.com/send?phone=${
                    pendiente.codigoPais
                  }${
                    pendiente.telefono
                  }&text=Hola ${pendiente.cliente.trim()}, te recordamos que tienes un pago pendiente por ₡${
                    pendiente.montoPendiente
                  }
                  mediante ${pendiente.metodoPago.toLowerCase()}, te agradecemos que nos envies el comprobante de pago.`}
                  className="bg-cyan text-white px-3 py-2 rounded-md"
                  target="_blank"
                  rel="noreferrer"
                >
                  Recordar
                </a>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <p className="mt-3">
        Total Pendiente:{" "}
        <span className="font-bold">
          {numberToColonesFormat(pendientes.reduce((total, pendiente) => {
            return total + Math.floor(pendiente.montoPendiente);
          }, 0))}
        </span>
      </p>
    </div>
  );
}
