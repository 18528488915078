import { useJsApiLoader } from "@react-google-maps/api";
import React, { useCallback, useEffect, useState } from "react";
import { ClipLoader } from "react-spinners";
import { DndContext, closestCenter } from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import RouteTableRow from "./RouteTableRow";
import { FaMapSigns } from "react-icons/fa";
import Map from "./Map";

export default function RouteTable({
  listaRuta,
  deleteFromlistaRuta,
  setVentasSinAsignar,
  coordsBodega,
  setListaRuta,
  coordsBodegaFinal,
  distance,
  setDistance,
  time,
  setTime,
}) {
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  });

  const [distances, setDistances] = useState([]);
  const [route, setRoute] = useState();

  const [markers, setMarkers] = useState([]);

  const calculateMarkers = useCallback(() => {
    const markers = listaRuta.map((venta) => ({
      lat: venta.latitud,
      lng: venta.longitud,
      label: "" + venta.id,
    }));

    setMarkers(markers);
  }, [listaRuta]);

  function obtenerDuracionAcumulada(position) {
    let sum = 0;
    if (distances.length > 0) {
      for (let index = 0; index <= position; index++) {
        sum += distances[index].duration;
      }
    }
    return sum;
  }

  const optimizeRuta = useCallback(async () => {
    if (isLoaded) {
      const service = new window.google.maps.DirectionsService();

      //array de cordenadas
      const waypoints = listaRuta.map((venta) => ({
        // eslint-disable-next-line no-undef
        location: new google.maps.LatLng(venta.latitud, venta.longitud),
        stopover: true,
      }));

      // Define las opciones de la solicitud
      const distances = await service.route({
        origin: coordsBodega,
        destination: coordsBodegaFinal,
        travelMode: window.google.maps.TravelMode.DRIVING,
        unitSystem: window.google.maps.UnitSystem.METRIC,
        waypoints: waypoints,
        optimizeWaypoints: true,
      });

      setRoute(distances);
      const waypointOrder = distances.routes[0].waypoint_order;
      let newListaRuta = [];

      waypointOrder.forEach((index) => {
        newListaRuta.push(listaRuta[index]);
      });

      let totalTime = 0;
      let totalDistance = 0;

      distances.routes[0].legs.forEach((leg) => {
        totalTime += leg.duration.value;
        totalDistance += leg.distance.value;
      });

      setDistance(totalDistance);
      setTime(totalTime);

      setListaRuta(newListaRuta);
      calculateMarkers();
    }
  }, [
    listaRuta,
    setListaRuta,
    isLoaded,
    coordsBodega,
    calculateMarkers,
    coordsBodegaFinal,
    setDistance,
    setTime,
  ]);

  useEffect(() => {
    if (isLoaded) {
      const calculateDistance = async () => {
        // Crea una instancia del objeto DistanceMatrixService
        const service = new window.google.maps.DirectionsService();

        //array de cordenadas
        const waypoints = listaRuta.map((venta) => ({
          // eslint-disable-next-line no-undef
          location: new google.maps.LatLng(venta.latitud, venta.longitud),
          stopover: true,
        }));

        // Define las opciones de la solicitud
        const distances = await service.route({
          origin: coordsBodega,
          destination: coordsBodegaFinal,
          travelMode: window.google.maps.TravelMode.DRIVING,
          unitSystem: window.google.maps.UnitSystem.METRIC,
          waypoints: waypoints,
          optimizeWaypoints: false,
        });

        setRoute(distances);

        let totalTime = 0;
        let totalDistance = 0;

        distances.routes[0].legs.forEach((leg) => {
          totalTime += leg.duration.value;
          totalDistance += leg.distance.value;
        });

        setDistance(totalDistance);
        setTime(totalTime);

        setDistances(
          distances.routes[0].legs.map((leg) => ({
            distance: leg.distance.value,
            duration: leg.duration.value,
          }))
        );
      };

      calculateDistance();
      calculateMarkers();
    }
  }, [
    isLoaded,
    listaRuta,
    coordsBodega,
    calculateMarkers,
    coordsBodegaFinal,
    setDistance,
    setTime,
  ]);

  async function handleDragEnd(event) {
    const { active, over } = event;

    if (active.id !== over.id) {
      const activeIndex = listaRuta.findIndex(
        (items) => items.id === active.id
      );
      const overIndex = listaRuta.findIndex((items) => items.id === over.id);
      const newItems = arrayMove(listaRuta, activeIndex, overIndex);
      await setListaRuta(newItems);
    }
  }

  if (loadError) return <div>Error al cargar tabla</div>;
  if (!isLoaded)
    return (
      <div className="flex items-center justify-center">
        <ClipLoader color="#76236C" className="text-center" />;
      </div>
    );

  return (
    <>
      <div className="overflow-x-auto flex gap-5 flex-col items-start">
        <table className="table-auto w-full">
          <thead>
            <tr className="bg-gray-200 text-gray-600 text-sm uppercase">
              <th className="py-2 px-4"></th>
              <th className="py-2 px-4">No. Orden</th>
              <th className="py-2 px-4">Nombre Completo Entrega</th>
              <th className="py-2 px-4">Dirección Exacta</th>
              <th className="py-2 px-4">Hora de llegada aproximada</th>
              <th className="py-2 px-4">Acciones</th>
            </tr>
          </thead>
          <tbody className="text-gray-600 text-sm font-light">
            <DndContext
              collisionDetection={closestCenter}
              onDragEnd={handleDragEnd}
            >
              <SortableContext
                items={listaRuta}
                strategy={verticalListSortingStrategy}
              >
                {listaRuta.map((venta, index) => {
                  return (
                    <RouteTableRow
                      venta={venta}
                      sumarSegundos={sumarSegundos}
                      obtenerDuracionAcumulada={obtenerDuracionAcumulada}
                      deleteFromlistaRuta={deleteFromlistaRuta}
                      setVentasSinAsignar={setVentasSinAsignar}
                      index={index}
                      key={venta.id}
                    />
                  );
                })}
              </SortableContext>
            </DndContext>
          </tbody>
        </table>
      </div>

      <div className="flex flex-col lg:flex-row w-full lg:justify-between gap-4">
        <div className="flex flex-col text-sm  lg:text-xl">
          <p className="flex gap-2">
            <strong>Duración:</strong>
            {(time / 3600).toFixed(2)} horas
          </p>
          <p className="flex gap-2">
            <strong>Distancia:</strong>
            {(distance / 1000).toFixed(2)} Kilometros
          </p>
        </div>
        <button
          onClick={() => optimizeRuta()}
          className="bg-orange text-white p-2 rounded-lg shadow-lg  w-auto flex gap-2 items-center justify-center"
        >
          Optimizar Ruta
          <FaMapSigns />
        </button>
      </div>

      {route && (
        <div className="overflow-x-auto">
          <Map
            origin={coordsBodega}
            route={route}
            markers={markers}
            finalStop={coordsBodegaFinal}
          />
        </div>
      )}
    </>
  );
}

const HOLGURA_SECONDS = 5 * 60;

export function sumarSegundos(segundos) {
  // crea una nueva instancia de Date con la fecha y hora actuales
  const fechaActual = new Date();

  // agrega los segundos especificados a la fecha actual
  fechaActual.setSeconds(fechaActual.getSeconds() + segundos + HOLGURA_SECONDS);

  // convierte la fecha a una cadena en formato ISO y la retorna
  return fechaActual.toISOString();
}
