import React from "react";
import promoDogCat from "../../assets/promo-dog-cat.png";

export function PromoBanner() {
  return (
    <div className="flex flex-wrap bg-pinkPromo bg-promo-hero bg-no-repeat bg-cover w-full rounded-md">
      <div className="w-full md:w-2/3 p-5 md:p-10">
        <p className="font-body tracking-widest text-white text-2xl sm:text-4xl md:text-5xl lg:text-5xl">
          ¡Concurso de Febrero!
        </p>
        <p className="font-body tracking-widest text-white text-md lg:text-lg">
          Participá en este concurso realizando una compra en nuestro sitio{" "}
          <strong>ticolitas.com</strong>. El ganador recibirá un cupón de{" "}
          <strong>₡20.000,00</strong> que podrá utilizar en su próxima compra.
          Podés ver los términos y condiciones{" "}
          <a
            className="text-white font-bold underline"
            href="/reglamento-promo-febrero"
            target="_blank"
            rel="noreferrer"
          >
            aquí.
          </a>
        </p>
      </div>
      <div className="w-full px-4 md:px-0 md:w-1/3 flex items-end">
        <img
          className="pt-5 max-h-[400px]"
          src={promoDogCat}
          alt="Promo TiColitas"
        />
      </div>
    </div>
  );
}

export default PromoBanner;
