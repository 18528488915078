import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import useAuthFetch from "../../hooks/useAuthFetch";
import { logUser } from "../../slices/appSlice";
import { Spinner } from "../Spinner";
import { GrFormViewHide, GrFormView } from "react-icons/gr";
import Mixpanel from "../../services/mixpanel";

export function LoginForm({ redirectAddress, noSignUpButton }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [recoveryCode, setRecoveryCode] = useState("");
  const [viewPasswordConfirmation, setViewPasswordConfirmation] =
    useState(false);
  const [forgotPassword, setForgotPassword] = useState(false);
  const [userHaveARecoveryCode, setUserHaveARecoveryCode] = useState(false);
  const [viewPassword, setViewPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { token } = useSelector((store) => store.app.user);
  const authFetch = useAuthFetch();

  const postLogin = async () => {
    if (email && password) {
      setLoading(true);
      setError(null);
      const loginFetch = await authFetch(
        `usuarios/login`,
        {
          email,
          password,
        },
        "POST",
        token
      );
      try {
        if (loginFetch.status === 200) {
          const loginData = await loginFetch.json();
          setLoading(false);
          dispatch(logUser(loginData));
          Mixpanel.identify(loginData.id);
          Mixpanel.track(Mixpanel.TYPES.LOGIN_INICIAR_SESION);
          navigate(redirectAddress);
        } else {
          setLoading(false);
          if (loginFetch.status === 401) {
            setError("Usuario o contraseña inválidos.");
            Mixpanel.track(Mixpanel.TYPES.LOGIN_ERROR_INICIAR_SESION, {
              email,
              error: "usuario_contrasenia_invalidos",
            });
          } else {
            setError(
              "Ha ocurrido un error. Por favor verificá los datos y volvé a intentarlo."
            );
            Mixpanel.track(Mixpanel.TYPES.LOGIN_ERROR_INICIAR_SESION, {
              email,
              error: "desconocido",
            });
          }
        }
      } catch (error) {
        setLoading(false);
        setError(
          "Ha ocurrido un error. Por favor verificá los datos y volvé a intentarlo."
        );
        Mixpanel.track(Mixpanel.TYPES.LOGIN_ERROR_INICIAR_SESION, {
          email,
          error: "desconocido",
        });
      }
    } else {
      setError("Es necesario completar todos los datos");
      Mixpanel.track(Mixpanel.TYPES.LOGIN_ERROR_INICIAR_SESION, {
        email,
        error: "faltan_datos",
      });
    }
  };

  const postForgotPassword = async () => {
    if (email) {
      setLoading(true);
      setError(null);
      const loginFetch = await authFetch(
        `usuarios/reset-password/init`,
        {
          email,
        },
        "POST",
        token
      );
      try {
        if (loginFetch.status === 200) {
          setUserHaveARecoveryCode(true);
          Mixpanel.track(Mixpanel.TYPES.LOGIN_SOLICITA_TOKEN, {
            email,
          });
        } else {
          if (loginFetch.status === 401) {
            setError("Usuario o contraseña inválidos.");
            Mixpanel.track(Mixpanel.TYPES.LOGIN_SOLICITA_TOKEN_ERROR, {
              email,
              error: "usuario_contrasenia_invalidos",
            });
          } else {
            if (loginFetch.status === 429) {
              setError(
                "La cuenta ha alcanzado el límite diario para solicitar un re-establecimiento de contraseña."
              );
              Mixpanel.track(Mixpanel.TYPES.LOGIN_SOLICITA_TOKEN_ERROR, {
                email,
                error: "limite_diario_alcanzado",
              });
            } else {
              setError(
                "Ha ocurrido un error. Por favor verificá los datos y volvé a intentarlo."
              );
              Mixpanel.track(Mixpanel.TYPES.LOGIN_SOLICITA_TOKEN_ERROR, {
                email,
                error: "desconocido",
              });
            }
          }
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        setError(
          "Ha ocurrido un error. Por favor verificá los datos y volvé a intentarlo."
        );
        Mixpanel.track(Mixpanel.TYPES.LOGIN_SOLICITA_TOKEN_ERROR, {
          email,
          error: "desconocido",
        });
      }
    } else {
      setError("Debe ingresar su correo");
      Mixpanel.track(Mixpanel.TYPES.LOGIN_SOLICITA_TOKEN_ERROR, {
        email,
        error: "faltan_datos",
      });
    }
  };

  const postNewPassword = async () => {
    if (email && password && passwordConfirmation && recoveryCode) {
      if (password !== passwordConfirmation) {
        setError(
          "La contraseña y la confirmación de la contraseña deben coincidir."
        );
        Mixpanel.track(Mixpanel.TYPES.LOGIN_RESTAURA_CONTRASENIA_ERROR, {
          email,
          error: "contrasenias_diferentes",
        });
      } else {
        setLoading(true);
        setError(null);
        const loginFetch = await authFetch(
          `usuarios/reset-password/finish`,
          {
            resetKey: recoveryCode,
            password,
            confirmPassword: passwordConfirmation,
          },
          "PUT",
          token
        );
        try {
          if (loginFetch.status === 200) {
            setLoading(false);
            Mixpanel.track(Mixpanel.TYPES.LOGIN_RESTAURA_CONTRASENIA, {
              email,
            });
            postLogin();
          } else {
            setLoading(false);
            if (loginFetch.status === 401) {
              setError("Usuario o contraseña inválidos.");
              Mixpanel.track(Mixpanel.TYPES.LOGIN_RESTAURA_CONTRASENIA_ERROR, {
                email,
                error: "usuario_contrasenia_invalidos",
              });
            } else {
              setError(
                "Ha ocurrido un error. Por favor verificá los datos y volvé a intentarlo."
              );
              Mixpanel.track(Mixpanel.TYPES.LOGIN_RESTAURA_CONTRASENIA_ERROR, {
                email,
                error: "desconocido",
              });
            }
          }
        } catch (error) {
          setLoading(false);
          setError(
            "Ha ocurrido un error. Por favor verificá los datos y volvé a intentarlo."
          );
          Mixpanel.track(Mixpanel.TYPES.LOGIN_RESTAURA_CONTRASENIA_ERROR, {
            email,
            error: "desconocido",
          });
        }
      }
    } else {
      setError("Es necesario completar todos los campos.");
      Mixpanel.track(Mixpanel.TYPES.LOGIN_RESTAURA_CONTRASENIA_ERROR, {
        email,
        error: "faltan_datos",
      });
    }
  };

  return (
    <>
      <div>{loading && <Spinner color={"pink"} />}</div>
      <div>
        <input
          onChange={(evt) => {
            setEmail(evt.target.value.toLowerCase());
          }}
          className="w-full rounded-3xl my-2 h-12 p-6 border border-purple outline-none"
          value={email}
          type={"text"}
          placeholder="Correo Electrónico"
        />
      </div>
      {!forgotPassword ? (
        <>
          <div className="w-full container flex justify-center items-center">
            <div className="relative w-full">
              <input
                onChange={(evt) => {
                  setPassword(evt.target.value);
                }}
                className="w-full rounded-3xl my-2 h-12 p-6 border border-purple outline-none"
                value={password}
                type={viewPassword ? "text" : "password"}
                placeholder="Contraseña"
              />
              <div className="absolute right-5 top-4">
                <button
                  onClick={() => {
                    setViewPassword(!viewPassword);
                  }}
                >
                  {viewPassword ? (
                    <GrFormView className="text-3xl text-borderGray" />
                  ) : (
                    <GrFormViewHide className="text-3xl text-borderGray" />
                  )}
                </button>
              </div>
            </div>
          </div>
          <div className="w-full my-2">
            {error && <p className="text-pink text-center">{error}</p>}
            <button
              onClick={() => {
                postLogin();
              }}
              className="bg-purple text-white h-12 px-8 rounded-full w-full outline-none"
            >
              Ingresar
            </button>
          </div>
          <div className="w-full mt-8 mb-4 text-center">
            <p className="text-borderGray">
              ¿Olvidaste tu contraseña?{" "}
              <span
                onClick={() => {
                  setForgotPassword(true);
                  setPassword("");
                  setViewPassword(false);
                  setError(null);
                }}
                className="cursor-pointer underline text-cyan"
              >
                Recuperar contraseña
              </span>
            </p>
          </div>
          {!noSignUpButton && (
            <div className="w-full mb-8 text-center">
              <p className="text-borderGray">
                ¿No tenés una cuenta?{" "}
                <Link
                  className="underline text-cyan"
                  to={`/registro?redirect=${redirectAddress}`}
                >
                  Registrate aquí
                </Link>
              </p>
            </div>
          )}
        </>
      ) : (
        <>
          {userHaveARecoveryCode && (
            <>
              <input
                onChange={(evt) => {
                  setRecoveryCode(evt.target.value);
                }}
                className="w-full rounded-3xl my-2 h-12 p-6 border border-purple"
                value={recoveryCode}
                type={"text"}
                placeholder="Ingresa aquí el código de recuperación"
              />
              <div className="w-full container flex justify-center items-center">
                <div className="relative w-full">
                  <input
                    onChange={(evt) => {
                      setPassword(evt.target.value);
                    }}
                    className="w-full rounded-3xl my-2 h-12 p-6 border border-purple"
                    value={password}
                    type={viewPassword ? "text" : "password"}
                    placeholder="Nueva contraseña"
                  />
                  <div className="absolute right-5 top-4">
                    <button
                      onClick={() => {
                        setViewPassword(!viewPassword);
                      }}
                    >
                      {viewPassword ? (
                        <GrFormView className="text-3xl text-borderGray" />
                      ) : (
                        <GrFormViewHide className="text-3xl text-borderGray" />
                      )}
                    </button>
                  </div>
                </div>
              </div>
              <div className="w-full container flex justify-center items-center">
                <div className="relative w-full">
                  <input
                    onChange={(evt) => {
                      setPasswordConfirmation(evt.target.value);
                    }}
                    className="w-full rounded-3xl my-2 h-12 p-6 border border-purple"
                    value={passwordConfirmation}
                    type={viewPasswordConfirmation ? "text" : "password"}
                    placeholder="Confirmar nueva contraseña"
                  />
                  <div className="absolute right-5 top-4">
                    <button
                      onClick={() => {
                        setViewPasswordConfirmation(!viewPasswordConfirmation);
                      }}
                    >
                      {viewPasswordConfirmation ? (
                        <GrFormView className="text-3xl text-borderGray" />
                      ) : (
                        <GrFormViewHide className="text-3xl text-borderGray" />
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </>
          )}
          <div className="w-full my-2">
            {userHaveARecoveryCode && (
              <p className="text-purple text-center mb-4">
                Te hemos enviado un código a tu correo electrónico. Por favor
                seguí las instrucciones del correo para reestablecer tu
                contraseña.
              </p>
            )}
            {error && <p className="text-pink text-center">{error}</p>}
            <button
              onClick={() => {
                if (userHaveARecoveryCode) {
                  postNewPassword();
                } else {
                  postForgotPassword();
                }
              }}
              className="bg-purple text-white h-12 px-8 rounded-full w-full"
            >
              Recuperar contraseña
            </button>
          </div>
          <div className="w-full mt-8 mb-8 text-center">
            <p className="text-borderGray">
              ¿Ya la recordaste?{" "}
              <span
                onClick={() => {
                  setForgotPassword(false);
                  setRecoveryCode("");
                  setUserHaveARecoveryCode(false);
                  setPassword("");
                  setPasswordConfirmation("");
                  setError(null);
                  setViewPassword(false);
                  setViewPasswordConfirmation(false);
                }}
                className="cursor-pointer underline text-cyan"
              >
                Volver a inicio de sesión
              </span>
            </p>
          </div>
        </>
      )}
    </>
  );
}

export default LoginForm;
