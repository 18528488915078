import React, { useCallback, useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

import NotFoundForm from "../../components/NotFoundForm";
import ProductList from "../../components/ProductList";
import Spinner from "../../components/Spinner";
import useAuthFetch from "../../hooks/useAuthFetch";

const useQuery = () => {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
};

export function SearchPage() {
  const authFetch = useAuthFetch();
  const [products, setProducts] = useState(null);
  const [searchQuery, setSearchQuery] = useState(null);

  const navigate = useNavigate();

  const query = useQuery();

  const notFoundFormRef = useRef(null);

  const fetchProductByBarcode = useCallback(
    async (codigo) => {
      const request = await authFetch(
        `producto-detalles?filter={"where":{"codigoBarras": "${codigo}", "activo": 1}}`
      );
      const products = await request.json();
      navigate(`/tienda/producto/${products[0].id}`);
    },
    [navigate]
  );

  const fetchProducts = useCallback(async (search) => {
    const filter = {
      include: [
        { relation: "familias", where: { idFamilia: null, activo: 1 } },
        { relation: "descuentos" },
      ],
    };
    const request = await authFetch(
      `producto-detalles/buscar/?filter=${JSON.stringify(filter)}}`,
      {
        page: {
          current: 1,
          size: 1000,
        },
        query: search,
      },
      "POST"
    );
    const products = await request.json();
    setProducts(products);
  }, []);

  useEffect(() => {
    const searchQueryParam = query.get("query");
    if (searchQueryParam) {
      setProducts(null);
      setSearchQuery(searchQueryParam);
      fetchProducts(searchQueryParam);
      window.scrollTo(0, 0);
    } else {
      const barcodeParam = query.get("barras");
      if (barcodeParam) {
        fetchProductByBarcode(barcodeParam);
        window.scrollTo(0, 0);
      }
    }
  }, [query, fetchProductByBarcode, fetchProducts]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [fetchProducts]);

  return (
    <div>
      {!products ? (
        <div className="w-full p-content pt-4 pb-16 min-h-[400px] bg-white">
          <Spinner />
        </div>
      ) : (
        <div className="w-full p-content pt-4 pb-16">
          {searchQuery && (
            <p className="mb-4">Resultados para "{searchQuery}"</p>
          )}
          {products.length === 0 && (
            <p>
              No se han encontrado productos para la búsqueda especificada.
              Intenta buscar de nuevo con otros términos.
            </p>
          )}
          <ProductList
            notFoundFormRef={notFoundFormRef}
            products={products}
            searchQuery={searchQuery}
          />
          <Link to="/tienda">
            <button className="bg-purple text-white h-12 px-8 rounded-full w-full mt-8">
              Navegar la tienda
            </button>
          </Link>
        </div>
      )}
      <NotFoundForm ref={notFoundFormRef} />
    </div>
  );
}

export default SearchPage;
