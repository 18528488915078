import React, { useCallback, useEffect, useState } from "react";
import { IoMdArrowRoundBack } from "react-icons/io";
import { Link } from "react-router-dom";
import { DotLoader } from "react-spinners";
import { useSelector } from "react-redux";
import Pagination from "../../components/Pagination";
import useAuthFetch from "../../hooks/useAuthFetch";

export default function ClientPage() {
  const [searchTerm, setSearchTerm] = useState("");
  const [users, setUsers] = useState([]);
  const { token } = useSelector((store) => store.app.user);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [userCount, setUserCount] = useState(0);

  const authFetch = useAuthFetch();

  const fetchUsers = useCallback(async () => {
    setLoading(true);

    const res = await authFetch(
      `usuarios?searchTerm=${searchTerm}&offset=${16 * page}&size=${16}`,
      null,
      "GET"
    );

    const data = await res.json();
    setUsers(data);
    setLoading(false);
  }, [page, searchTerm]);

  const fetchUserCount = useCallback(async () => {
    const res = await authFetch(
      `usuarios/count?searchTerm=${searchTerm}`,
      null,
      "GET",
      token
    );

    const data = await res.json();
    setUserCount(data);
  }, [token, searchTerm]);

  useEffect(() => {
    fetchUsers();
    fetchUserCount();
  }, [fetchUsers, fetchUserCount]);

  return (
    <div className="w-[90%] max-w-7xl mx-auto my-0">
      <div className="flex flex-col gap-4 my-4">
        <Link to="/cuenta">
          <button className="bg-pink text-white py-2 px-8 font-bold rounded-full float-right flex justify-center items-center">
            <IoMdArrowRoundBack className="text-lg" /> Volver
          </button>
        </Link>

        <p className="text-2xl font-bold mb-4">Clientes</p>

        {loading && (
          <div className="flex justify-center items-center">
            <DotLoader color="#76236C" />
          </div>
        )}
        <input
          placeholder="Buscar por nombre"
          className="p-4 border border-b-borderGray rounded-md focus:outline-none focus:ring-2 focus:ring-purple focus:border-transparent"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <div className="grid grid-cols-1 md:grid-cols-2  xl:grid-cols-4 gap-4">
          {users &&
            users.map((user) => (
              <div
                className="flex gap-3 p-10 rounded-md shadow-md bg-white  flex-col border border-[#ddd]"
                key={user.telefono}
              >
                <p className="font-bold text-purple truncate">
                  {user.nombre} {user.nombreEntrega}
                </p>
                <p>{user.telefono}</p>
                <Link
                  to={`/clientes/${user.telefono}`}
                  className="self-end p-4 font-bold text-orange rounded-lg text-sm"
                >
                  <button>Ver compras</button>
                </Link>
              </div>
            ))}
        </div>

        <Pagination
          currentPage={page}
          onPageChange={setPage}
          totalPages={Math.round(userCount / 16)}
        />
      </div>
    </div>
  );
}
