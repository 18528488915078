import React, { useEffect, useState } from "react";
import useAuthFetch from "../../hooks/useAuthFetch";
import Select from "../../components/Select";
import Spinner from "../../components/Spinner";
import CheckboxInput from "../../components/CheckboxInput";
import _ from "lodash";
import moment from "moment";
import "moment/locale/es";
import { getCountries, getCountryCallingCode } from "react-phone-number-input";
import es from "react-phone-number-input/locale/es.json";
import { useSelector } from "react-redux";
moment.updateLocale("es");

const sortedCountries = (countries, labels) => {
  const listOfCountries = [];
  countries.forEach((country) => {
    listOfCountries.push({
      label: `${labels[country]} +${getCountryCallingCode(country)}`,
      value: `${labels[country]} +${getCountryCallingCode(country)}`,
      numericValue: parseInt(getCountryCallingCode(country)),
    });
  });
  return [
    {
      label: "Costa Rica +506",
      value: "Costa Rica +506",
      numericValue: 506,
    },
  ].concat(
    _.sortBy(listOfCountries, (c) => {
      return c.label;
    })
  );
};

export function CitasPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { token } = useSelector((store) => store.app.user);
  const [error, setError] = useState();
  const [success, setSuccess] = useState(false);
  const [ferias, setFerias] = useState([]);
  const [feria, setFeria] = useState(null);
  const [citas, setCitas] = useState([]);
  const [cita, setCita] = useState(null);
  const [loading, setLoading] = useState(false);
  const [countryValue, setCountryValue] = useState();
  const [appointmentData, setAppointmentData] = useState({
    mascota: "",
    encargado: "",
    codigoPaisTelefono: "",
    telefono: "",
    notasPrevias: "",
  });
  const [authorizeAppointment, setAuthorizeAppointment] = useState(false);
  const authFetch = useAuthFetch();

  useEffect(() => {
    const fetchFerias = async () => {
      setLoading(true);
      const feriasFetch = await authFetch("ferias");
      const feriasData = await feriasFetch.json();
      setFerias(feriasData);
      setLoading(false);
    };

    fetchFerias();
  }, []);

  useEffect(() => {
    const fetchCitas = async () => {
      setLoading(true);
      const citasFetch = await authFetch(
        `citas?filter={"where":{"and":[{"idFeria": ${feria}},{"mascota": null}]}}`
      );
      const citasData = await citasFetch.json();
      setCitas(citasData);
      setLoading(false);
    };

    fetchCitas();
  }, [feria]);

  const postCita = async () => {
    if (
      appointmentData.mascota &&
      appointmentData.encargado &&
      appointmentData.codigoPaisTelefono &&
      appointmentData.telefono &&
      appointmentData.notasPrevias &&
      authorizeAppointment
    ) {
      setLoading(true);
      setError(null);
      const postCitasFetch = await authFetch(
        `citas/${cita}`,
        {
          mascota: appointmentData.mascota,
          encargado: appointmentData.encargado,
          codigoPaisTelefono: appointmentData.codigoPaisTelefono,
          telefono: parseInt(appointmentData.telefono),
          notasPrevias: appointmentData.notasPrevias,
        },
        "PATCH",
        token
      );
      try {
        if (postCitasFetch.status === 204) {
          setSuccess(true);
          setLoading(false);
          window.scrollTo(0, 0);
        } else {
          setSuccess(false);
          setLoading(false);
          setError(
            "Ha ocurrido un error. Por favor verifique los datos y vuelva a intentarlo."
          );
        }
      } catch (error) {
        setSuccess(false);
        setLoading(false);
        setError(
          "Ha ocurrido un error. Por favor verifique los datos y vuelva a intentarlo."
        );
      }
    } else {
      setError("Debe completar todos los campos");
    }
  };

  return (
    <div className="bg-white w-full p-content pb-16 pt-2 flex flex-col">
      <h1 className="font-bold text-2xl mb-4">
        Asesorías para ferias de bienestar de su mascota
      </h1>
      {loading && <Spinner />}
      {success ? (
        <p>
          ¡Gracias! Hemos reservado tu espacio. Te agradecemos ser puntual a la
          hora de asistir.
        </p>
      ) : (
        <>
          <Select
            containerClasses="my-2"
            labelColor="text-base"
            buttonClasses="border border-purple"
            label="Seleccioná la feria a la que que querés asistir:"
            required
            options={ferias.map((f) => {
              return {
                value: f.id,
                label: f.nombre,
              };
            })}
            onChange={(value) => {
              setFeria(value);
            }}
            value={feria}
          />
          {feria &&
            (citas.length > 0 ? (
              <Select
                containerClasses="my-2"
                labelColor="text-base"
                buttonClasses="border border-purple"
                label="Seleccioná el espacio que querés agendar:"
                required
                options={citas.map((c) => {
                  return {
                    value: c.id,
                    label: `${moment(c.fechaHora).format(
                      "dddd D [de] MMMM hh:mm a"
                    )} (${c.duracionMinutos} minutos)`,
                  };
                })}
                onChange={(value) => {
                  setCita(value);
                }}
                value={cita}
              />
            ) : (
              !loading && (
                <p>
                  Lo sentimos, ya no hay espacios disponibles para este evento
                </p>
              )
            ))}
          {cita && (
            <>
              <h1 className="font-bold text-xl my-4">Datos para la reserva</h1>
              <div>
                <label htmlFor="mascota" className="text-base">
                  Nombre de la(s) mascota(s):
                  <span className="text-pink">*</span>
                </label>
                <input
                  id="mascota"
                  name="mascota"
                  className="w-full rounded-3xl my-2 h-12 p-6 border border-purple"
                  value={appointmentData.mascota}
                  onChange={(evt) => {
                    setAppointmentData({
                      ...appointmentData,
                      mascota: evt.target.value,
                    });
                  }}
                />
              </div>
              <div>
                <label htmlFor="encargado" className="text-base">
                  Nombre del encargado:
                  <span className="text-pink">*</span>
                </label>
                <input
                  id="encargado"
                  name="encargado"
                  className="w-full rounded-3xl my-2 h-12 p-6 border border-purple"
                  value={appointmentData.encargado}
                  onChange={(evt) => {
                    setAppointmentData({
                      ...appointmentData,
                      encargado: evt.target.value,
                    });
                  }}
                />
              </div>
              <div>
                <Select
                  required
                  containerClasses="my-2"
                  labelColor="text-base"
                  buttonClasses="border border-purple"
                  label="Código Telefónico de País:"
                  options={sortedCountries(getCountries(), es)}
                  onChange={(value, option) => {
                    setCountryValue(value);
                    setAppointmentData({
                      ...appointmentData,
                      codigoPaisTelefono: option.numericValue,
                    });
                  }}
                  value={countryValue}
                />
              </div>
              <div>
                <label htmlFor="phone" className="text-base">
                  Teléfono:
                  <span className="text-pink">*</span>
                </label>
                <input
                  id="telefono"
                  name="telefono"
                  className="w-full rounded-3xl my-2 h-12 p-6 border border-purple"
                  value={appointmentData.telefono}
                  placeholder={"Por favor ingresa únicamente números"}
                  onChange={(evt) => {
                    const justNumbers = evt.target.value.replace(/[^0-9]/g, "");
                    setAppointmentData({
                      ...appointmentData,
                      telefono: justNumbers,
                    });
                  }}
                />
              </div>
              <div>
                <label htmlFor="notasPrevias" className="text-base">
                  Notas para la doctora:
                  <span className="text-pink">*</span>
                </label>
                <textarea
                  placeholder="Algun detalle que considere importante para que la doctora tenga en cuenta previo a la asesoría."
                  id="notasPrevias"
                  name="notasPrevias"
                  className="w-full rounded-3xl mt-2 p-6 border border-purple"
                  rows={3}
                  onChange={(evt) => {
                    setAppointmentData({
                      ...appointmentData,
                      notasPrevias: evt.target.value,
                    });
                  }}
                />
              </div>
              <div className="mt-2">
                <CheckboxInput
                  inputBorderColor="purple"
                  checked={authorizeAppointment}
                  onCheckChange={() => {
                    setAuthorizeAppointment(!authorizeAppointment);
                  }}
                  labelColor="text-base"
                  label={
                    "Autorizo a TiColitas a contactarme a través de whatsapp o llamada telefónica para confirmar mi asistencia a la asesoría."
                  }
                />
              </div>
              <div className="w-full mt-8 mb-2">
                {error && <p className="text-pink text-center">{error}</p>}
                <button
                  onClick={() => {
                    postCita();
                  }}
                  className="bg-purple text-white h-12 px-8 rounded-full w-full"
                >
                  Reservar Espacio
                </button>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
}

export default CitasPage;
