import { lazy } from "react";

const BodegasPage = lazy(() =>
	import(/* webpackChunkName: "InventarioBodegas" */ "../BodegasPage")
);
const BodegasForm = lazy(() =>
	import(/* webpackChunkName: "InventarioBodegasForm" */ "../BodegasPage/form")
);
const ProveedoresPage = lazy(() =>
	import(
		/* webpackChunkName: "InventarioProveedores" */ "../ProveedoresPage/index"
	)
);
const ProveedoresForm = lazy(() =>
	import(
		/* webpackChunkName: "InventarioProvForm" */ "../ProveedoresPage/form.js"
	)
);
const MarcasPage = lazy(() =>
	import(/* webpackChunkName: "InventarioMarcas" */ "../MarcasBodega/index")
);
const MarcasForm = lazy(() =>
	import(/* webpackChunkName: "InventarioMarcasForm" */ "../MarcasBodega/Form")
);
const CategoriaCatalogos = lazy(() =>
	import(
		/* webpackChunkName: "InventarioCategoria" */ "../CategoriasBodega/index.js"
	)
);
const CategoriaForm = lazy(() =>
	import(
		/* webpackChunkName: "InventarioCategoriaForm" */ "../CategoriasBodega/formCatalogo"
	)
);
const JerarquiaPage = lazy(() =>
	import(
		/* webpackChunkName: "InventarioJerarquia" */ "../CategoriasBodega/index_jerarquia"
	)
);
const JerarquiaForm = lazy(() =>
	import(
		/* webpackChunkName: "InventarioJerarquia" */ "../CategoriasBodega/formJerarquia"
	)
);


const TransferenciasList = lazy(() =>
	import(
		/* webpackChunkName: "InventarioJerarquia" */ "../Transferencias/index"
	)
);

const TransferenciasForm = lazy(() =>
	import(
		/* webpackChunkName: "InventarioJerarquia" */ "../Transferencias/form"
	)
);

export {
	BodegasPage,
	BodegasForm,
	ProveedoresPage,
	ProveedoresForm,
	MarcasPage,
	MarcasForm,
	CategoriaCatalogos,
	CategoriaForm,
	JerarquiaPage,
	JerarquiaForm,
	TransferenciasList,
	TransferenciasForm
};
