import React, { useCallback, useEffect } from "react";
import { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { IoMdArrowRoundBack } from "react-icons/io";
import { useDispatch } from "react-redux";
import _ from "lodash";

import Spinner from "../../components/Spinner";
import useAuthFetch from "../../hooks/useAuthFetch";
import Pagination from "../../components/Pagination";
import { setCategoriesProduct } from "../../slices/inventorySlice";
import CheckboxInput from "../../components/CheckboxInput";

const PAGE_SIZE = 12;

const useQuery = () => {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
};

export function InventarioproductsPage({ user }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [products, setProducts] = useState([]);
  const [brands, setBrands] = useState([]);
  const [brandFilter, setBrandFilter] = useState(["all"]);
  const [page, setPage] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [loading, setLoading] = useState(false);
  const authFetch = useAuthFetch();

  const query = useQuery();

  const fetchProducts = useCallback(async () => {
    setLoading(true);

    const brandsRequest = await authFetch(
      'marcas?filter={"offset": 0,"limit": 300,"skip": 0,"order": ["nombre asc"]}'
    );
    const brandsData = await brandsRequest.json();
    setBrands(brandsData);

    const filterFromParam = query.get("filtro");
    if (filterFromParam) {
      setBrandFilter(JSON.parse(filterFromParam));
    } else {
      setBrandFilter(["all"].concat(_.map(brandsData, "id")));
    }

    setLoading(false);
  }, [query]);

  useEffect(() => {
    async function fetchData() {
      const productsFetch = await authFetch(
        `productos?filter={"limit": ${PAGE_SIZE}, "skip": ${
          page * PAGE_SIZE
        },"order": ["idMarca","nombreTienda","pesoGramos"]
        ${
          brandFilter.length === 0 || brandFilter[0] === "all"
            ? brandFilter.length === 0
              ? ', "where": { "idMarca": ""}'
              : ""
            : `, "where": { "idMarca": { "inq": ${JSON.stringify(brandFilter)} }}`
        }
      }`
      );
      const productsData = await productsFetch.json();
      setProducts(productsData);
    
      const totalProductsFetch = await authFetch(`productos/count${
        brandFilter.length === 0 || brandFilter[0] === "all"
          ? brandFilter.length === 0
            ? '?where={ "idMarca": ""}'
            : ""
          : `?where={ "idMarca": { "inq": ${JSON.stringify(brandFilter)} }}`
      }`);
      const totalProductsData = await totalProductsFetch.json();
      setTotalPages(Math.ceil(totalProductsData.count / PAGE_SIZE));
      setTotalCount(totalProductsData.count);
    }
    fetchData();
  }, [brandFilter, page]);

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchProducts();
  }, [fetchProducts]);

  useEffect(() => {
    const search = query.get("query");
    if (search !== null && search !== undefined) {
    }
  }, [query]);

  return (
    <div className="flex flex-wrap lg:flex-nowrap w-full px-16 pt-4 pb-16 min-h-[400px]">
      {loading && <Spinner />}
      <div className="w-full flex flex-wrap lg:block lg:flex-nowrap lg:w-3/12 mb-4">
        <p className="mb-4 w-full">Marcas</p>
        <CheckboxInput
          containerClass={"mb-2 ml-2 lg:ml-0"}
          checked={brandFilter.includes("all")}
          onCheckChange={() => {
            setPage(0);
            const brandIndex = brandFilter.indexOf("all");
            if (brandIndex === -1) {
              brandFilter.push("all");
              setBrandFilter(["all"].concat(_.map(brands, "id")));
            } else {
              setBrandFilter([]);
            }
          }}
          labelColor="text-base"
          label={"Todas las marcas"}
        />
        {brands.map((b, index) => {
          const isChecked = brandFilter.indexOf(b.id) !== -1;
          return (
            <CheckboxInput
              key={`filter_${index}`}
              containerClass={"mb-2 ml-2 lg:ml-0"}
              checked={isChecked}
              onCheckChange={() => {
                setPage(0);
                const newBrandFilter = _.clone(brandFilter);
                if (!isChecked) {
                  newBrandFilter.push(b.id);
                  setBrandFilter(newBrandFilter);
                } else {
                  const brandIndex = newBrandFilter.indexOf(b.id);
                  newBrandFilter.splice(brandIndex, 1);
                  const allIndex = newBrandFilter.indexOf("all");
                  if (allIndex !== -1) {
                    newBrandFilter.splice(allIndex, 1);
                  }
                  setBrandFilter(newBrandFilter);
                }
              }}
              labelColor="text-base"
              label={b.nombreTienda}
            />
          );
        })}
      </div>
      <div className="w-full lg:w-9/12">
        <div>
          <div className="flex w-full items-center">
            <div className="w-full">
              <Link to="/inventario">
                <button className="bg-pink text-white py-2 px-8 font-bold rounded-full float-right flex justify-center items-center">
                  <IoMdArrowRoundBack className="text-lg" /> Volver
                </button>
              </Link>
            </div>
          </div>
          <p className="text-2xl font-bold mb-4">
            Productos (Total: {totalCount})
          </p>
          <div className="flex w-full items-center mb-8">
            <div className="w-full">
              <Link to={`/inventario/productos/${"new"}`}>
                <button
                  className="bg-cyan text-white py-2 px-8 font-bold rounded-full"
                  onClick={() => dispatch(setCategoriesProduct([]))}
                >
                  {"+"} Nuevo producto
                </button>
              </Link>
            </div>
          </div>
          <div className="w-full">
            {products && products.length > 0 ? (
              <>
                {products && (
                  <div className="flex flex-wrap gap-2">
                    {products.map((p) => {
                      return (
                        <div
                          onClick={() => {
                            navigate(`/inventario/productos/${p.id}`);
                          }}
                          key={`producto_${p.id}`}
                          className={`cursor-pointer flex flex-wrap flex-[0_0_calc(50%-0.5rem)] sm:flex-[0_0_calc(33.3333333333%-0.5rem)] md:flex-[0_0_calc(33.3333333%-0.5rem)] lg:flex-[0_0_calc(33.3333333%-0.5rem)] items-center bg-backgroundGray py-4 px-8 rounded-md`}
                        >
                          {p.fotoPrincipal ? (
                            <img
                              className="rounded-md"
                              src={
                                p.nombreTienda && p.activo === 1
                                  ? p.fotoPrincipal
                                  : "https://polpo-assets.s3.amazonaws.com/production/ticolitas/productos/800/noactivo.jpg"
                              }
                              alt={`Foto de ${p.nombreTienda || p.nombre}`}
                            />
                          ) : (
                            <p className="text-listingProductGray font-bold">
                              Fotografía no disponible
                            </p>
                          )}
                          <p className="w-full text-sm text-listingProductGray mt-2 font-bold">
                            {p.nombreTienda || p.nombre}
                          </p>
                          {p.prescrito === 1 && (
                            <span className="text-pink">
                              Alimento de Prescripción
                            </span>
                          )}
                          {p.pesoGramos && (
                            <p className="w-full text-sm text-listingProductGray mt-2">
                              {"Presentación: "}
                              <br />
                              <span className="font-bold">
                                {p.pesoGramos < 1000
                                  ? `${p.pesoGramos} gramos`
                                  : `${(p.pesoGramos / 1000).toFixed(2)} Kg`}
                              </span>
                            </p>
                          )}
                          <p className="w-full text-sm text-listingProductGray mt-2">
                            {"Id de TiColitas: "}
                            <br />
                            <span className="font-bold">
                              {p.id}
                            </span>
                          </p>
                          {p.codigoProveedor && (
                            <p className="w-full text-sm text-listingProductGray mt-2">
                              {"Código de proveedor: "}
                              <br />
                              <span className="font-bold">
                                {p.codigoProveedor}
                              </span>
                            </p>
                          )}
                        </div>
                      );
                    })}
                  </div>
                )}
                <Pagination
                  totalPages={totalPages}
                  currentPage={page}
                  onPageChange={(page) => {
                    window.scrollTo(0, 0);
                    setPage(page);
                  }}
                />
              </>
            ) : (
              !loading && <p>No hay productos para mostrar</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default InventarioproductsPage;
