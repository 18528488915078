import React, { useContext, useState } from "react";
import { plusDaysDate, spanishDate } from "../../utils/formatDates";
import { Pagination } from "../../components/Pagination";
import { recordatoriosContext } from "../../containers/RecordatoriosPage";
import ModalEditRecordatorio from "../ModalEditRecordatorio";

export default function RecordatoriosTable({ totalPages, page, setPage, recordatorios }) {

  const [modalOpen, setModalOpen] = useState(false);

  const { recordatorioSelected, setRecordatorioSelected } =
    useContext(recordatoriosContext);

  return (
    <>
      <table className="table-auto text-sm">
        <thead>
          <tr className="border-b-2">
            <th className="py-4">Producto</th>
            <th className="py-4">Nombre</th>
            <th className="py-4">Cliente</th>
            <th className="py-4">Última compra</th>
            <th className="py-4">Duración en días</th>
            <th className="py-4">Fecha para recordar</th>
            <th className="py-4">Acción</th>
          </tr>
        </thead>
        {recordatorios ? (
          <tbody>
            {recordatorios.map((recordatorio, i) => (
              <tr
                className="border-b-[0.5px]"
                key={recordatorio.nombre_cliente + i}
              >
                <td align="center" className="py-4">
                  <img
                    src={recordatorio.fotoProducto}
                    className={"rounded-md w-10 h-10"}
                    alt={recordatorio.nombreProducto}
                  />
                </td>
                <td align="center" className="py-4">
                  {recordatorio.nombreProducto}
                </td>
                <td align="center" className="py-4">
                  {recordatorio.nombreCliente} {recordatorio.apellidoCliente}
                </td>
                <td align="center" className="py-4">
                  {spanishDate(recordatorio.ultimaCompra)}
                </td>
                <td align="center" className="py-4">
                  {Math.round(recordatorio.duracionDias)}
                </td>
                <td align="center" className="py-4">
                  {plusDaysDate(
                    recordatorio.ultimaCompra,
                    Math.floor(recordatorio.duracionDias)
                  )}
                </td>
                <td align="center" className="py-4">
                  <button
                    className="bg-cyan text-white py-2 px-3 rounded-lg"
                    onClick={() => {
                      setRecordatorioSelected(recordatorio);
                      setModalOpen(true);
                    }}
                  >
                    Editar
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        ) : (
          <p>No hay registros de recordatorios</p>
        )}
      </table>
      <Pagination
        currentPage={page}
        onPageChange={setPage}
        totalPages={totalPages}
      />
      {recordatorioSelected && (
        <ModalEditRecordatorio
          backgroundColor={"white"}
          open={modalOpen}
          closeModalFunction={() => {
            setModalOpen(false);
            setRecordatorioSelected(null);
          }}
        />
      )}
    </>
  );
}
