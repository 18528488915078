import React from "react";

export function Marker({ 
    text,
    onClick
 }) {
  return (
    <div
      alt={text}
      onClick={onClick}
      className="absolute h-9 w-9 bg-pin bg-contain bg-no-repeat select-none translate-x-[-50%] translate-y-[-50%] cursor-grab"
    ></div>
  );
}

export default Marker;
