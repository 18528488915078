import React, { useEffect, useState } from "react";
import useAuthFetch from "../../hooks/useAuthFetch";
import Select from "../Select";
import { debounce } from "lodash";

export function BillingForm({ billing, onChangeValue, onChangeAll }) {
  const [idTypes, setIdTypes] = useState([]);
  const authFetch = useAuthFetch();

  useEffect(() => {
    fetchIdTypes();
  }, []);

  const fetchIdTypes = async () => {
    const fetchIdTypes = await authFetch(
      'catalogos/7?filter={"include":[{"relation":"catalogos","scope":{"offset":0,"limit":100,"skip":0}}]}'
    );
    const data = await fetchIdTypes.json();
    setIdTypes(data.catalogos);
  };

  const fetchBilling = async (newIdentification) => {
    const fetchBilling = await authFetch(
      `usuario-facturacion?filter={"where":{"identificacion":"${newIdentification}"}}`
    );
    const data = await fetchBilling.json();
    if (data.length > 0) {
      const exactIdentificacion = data.find(
        (d) => d.identificacion === newIdentification
      );
      if (exactIdentificacion) {
        onChangeAll(exactIdentificacion);
      }
    }
  }

  const debouncedFetchBilling = debounce(fetchBilling, 1000);
  
  return (
    <div>
      <div>
        <label htmlFor="nombreFactura" className="text-base">
          Nombre completo:
        </label>
        <input
          id="nombreFactura"
          name="nombreFactura"
          className="w-full rounded-3xl my-2 h-12 p-6 border border-purple"
          value={billing.nombreFactura}
          onChange={(evt) => {
            onChangeValue("nombreFactura", evt.target.value);
          }}
        />
      </div>
      <div>
        <Select
          containerClasses="my-2"
          labelColor="text-base"
          buttonClasses="border border-purple"
          label="Tipo de identificación:"
          options={idTypes.map((type) => {
            return {
              value: type.idEnCatalogo,
              label: type.valor,
            };
          })}
          onChange={(value) => {
            onChangeValue("tipoIdentificacion", value);
          }}
          value={billing.tipoIdentificacion}
        />
      </div>
      <div>
        <label htmlFor="identificacion" className="text-base">
          Identificación:
        </label>
        <input
          id="identificacion"
          name="identificacion"
          className="w-full rounded-3xl my-2 h-12 p-6 border border-purple"
          value={billing.identificacion}
          onChange={(evt) => {
            onChangeValue("identificacion", evt.target.value);
            if (evt.target.value && evt.target.value.length > 5) {
              debouncedFetchBilling(evt.target.value);
            }
          }}
        />
      </div>
      <div>
        <label htmlFor="correoFactura" className="text-base">
          Correo para enviar factura:
        </label>
        <input
          id="correoFactura"
          name="correoFactura"
          className="w-full rounded-3xl my-2 h-12 p-6 border border-purple"
          value={billing.correoFactura}
          onChange={(evt) => {
            onChangeValue("correoFactura", evt.target.value);
          }}
        />
      </div>
    </div>
  );
}

export default BillingForm;
