import { useSortable } from "@dnd-kit/sortable";
import React from "react";
import { RxDragHandleDots2 } from "react-icons/rx";
import * as moment from "moment";
import { CSS } from "@dnd-kit/utilities";

export default function RouteTableRow({
  venta,
  sumarSegundos,
  obtenerDuracionAcumulada,
  deleteFromlistaRuta,
  setVentasSinAsignar,
  index,
}) {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: venta.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <tr
      className="border-b border-gray-200 hover:bg-gray-100"
      key={venta.id}
      ref={setNodeRef}
      style={style}
    >
      <td>
        <RxDragHandleDots2
          {...attributes}
          {...listeners}
          size={40}
          color="#999"
          className="border-none outline-none touch-none"
        />
      </td>
      <td className="py-2 px-4" align="center">
        {venta.id}
      </td>
      <td className="py-2 px-4" align="center">
        {venta.nombreCompletoEntrega}
      </td>
      <td className="py-2 px-4" align="center">
        {venta.direccionExacta}
      </td>
      <td className="py-2 px-4" align="center">
        {moment(sumarSegundos(obtenerDuracionAcumulada(index))).format(
          "h:mm A"
        )}
      </td>
      <td className="py-2 px-4 flex flex-col gap-2 text-xs">
        <button
          className="bg-purple hover:bg-green-600 text-white font-bold py-2 px-4 rounded w-full"
          disabled={venta.longitud === null || venta.latitud === null}
          onClick={() => {
            deleteFromlistaRuta(venta.id);
            setVentasSinAsignar((prev) => [...prev, venta]);
          }}
        >
          Eliminar de la ruta
        </button>
      </td>
    </tr>
  );
}
