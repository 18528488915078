import React, { useEffect } from "react";
import { useState } from "react";

export const PickerVariaciones = ({
	productos,
	variaciones,
	fetchProduct,
	idFamilia,
	fetchFamilia,
	productToFecth,
}) => {
	const [allProducts, setAllProducts] = useState([]);
	const [newArray, setNewArray] = useState([]);
	const [show, setShow] = useState(false);
	const [currentProduct, setCurrentProduct] = useState(null);
	// const [selectedProduct, setSelectedProduct] = useState(null)
	let otraMas = [];
	let array = [];

	useEffect(() => {
		if (productos && variaciones) {
			productos.map((p) =>
				p.variaciones.map((pv) =>
					variaciones.map(
						(v) =>
							pv.idVariacion === v.id &&
							setAllProducts((prev) => [
								...prev,
								{ producto: p.producto, variacion: { ...pv, ...v } },
							])
					)
				)
			);
		}
	}, [productos, variaciones]);

	if (allProducts.length > 0) {
		let hash = {};
		array = allProducts;
		array = array.filter((o) =>
			hash[o.variacion.valor] ? false : (hash[o.variacion.valor] = true)
		);
	}

	const filterVariations = (p) => {
		setCurrentProduct(p);
		otraMas = [];
		let data = [];
		let llamarEndpoint = false;

		allProducts.map(
			(ap) => ap.variacion.valor === p.variacion.valor && data.push(ap)
		);
		data.map((p) =>
			// eslint-disable-next-line
			allProducts.map((ap) => {
				if (p.producto.id === ap.producto.id) otraMas = [...otraMas, ap];
			})
		);

		let hash = {};
		otraMas = otraMas.filter((o) =>
			hash[o.variacion.valor] ? false : (hash[o.variacion.valor] = true)
		);
		setNewArray(otraMas);
		setShow(true);

		if (data.length === 1) {
			fetchProduct(data[0].producto.id);
		}

		if (data.length > 1) {
			data.map((d) =>
				d.producto.id === p.producto.id
					? (llamarEndpoint = true)
					: (llamarEndpoint = false)
			);
			if (llamarEndpoint) {
				fetchProduct(p.producto.id);
			}
		}
	};
	
	const fetchInProduct = (p, tipoInput) => {
		if (currentProduct) {
			if (
				currentProduct.producto.id === p.producto.id &&
				currentProduct.variacion.tipoInput === tipoInput 
			) {
				return;
			} else {
				fetchProduct(p.producto.id);
			}
		}
	};

	const resetValues = () => {
		setShow(false);
		setNewArray([]);
		fetchFamilia(idFamilia);
	};

	return (
		<>
			<div className="w-full my-4">
				{show === false &&
					variaciones.map((v) => (
						<div key={v.id}>
							<p className="font-semibold text-baseGray">{v.nombre}</p>
							<div className="w-full flex flex-wrap animate__animated animate__fadeIn">
								{array.map((p, index) => (
									<div key={index} className="">
										{p.variacion.id === v.id && (
											<div>
												{v.tipoInput === 150 ? (
													<div
														className="ring-1 hover:shadow-xl ring-baseGray/60 rounded-xl p-5 m-3 w-32 text-sm cursor-pointer text-center overflow-hidden duration-200 transition-all ease-in"
														onClick={() => filterVariations(p)}
													>
														<img
															src={p.producto.fotoPrincipal}
															alt={p.producto.nombre}
														/>
													</div>
												) : (
													<div
														className="ring-1 hover:shadow-xl ring-baseGray/60 rounded-xl p-5 m-3 w-32 text-sm cursor-pointer text-center overflow-hidden duration-200 transition-all ease-in"
														onClick={() => filterVariations(p)}
													>
														<p>{p.variacion.valor}</p>
													</div>
												)}
											</div>
										)}
									</div>
								))}
							</div>
						</div>
					))}
			</div>
			{show === true && (
				<div className="w-full my-4">
					<button
						className="bg-purple/90 p-2 rounded-lg mb-5 text-sm text-white font-medium"
						onClick={resetValues}
					>
						Deseleccionar
					</button>

					{newArray.length > 0 &&
						variaciones.map((v) => (
							<div key={v.id}>
								<p className="font-semibold text-baseGray">
									{v.nombre}
									{currentProduct.variacion.id &&
										v.id &&
										currentProduct.variacion.nombre === v.nombre &&
										`: ${currentProduct.variacion.valor}`}
								</p>
								<div className="w-full flex flex-wrap animate__animated animate__fadeIn">
									{newArray.map((p, index) => (
										<div key={index} className="">
											{p.variacion.id === v.id && (
												<div>
													{v.tipoInput === 150 ? (
														<div
															className={`rounded-xl p-5 m-3 w-32 text-sm cursor-pointer text-center overflow-hidden duration-200 transition-all ease-linear hover:shadow-xl
														${
															currentProduct &&
															currentProduct.producto.id === p.producto.id &&
															currentProduct.variacion.tipoInput === 150 &&
															currentProduct.variacion.valor === p.variacion.valor
																? "ring-2 ring-baseGray/80 "
																: "ring-1 ring-baseGray/60 "
														}
														${
															productToFecth &&
															productToFecth.id === p.producto.id
																? "ring-2 ring-baseGray/80 "
																: "ring-1 ring-baseGray/60 "
														}
													`}
															onClick={() => fetchInProduct(p, 150)}
														>
															<img
																src={p.producto.fotoPrincipal}
																alt={p.producto.nombre}
															/>
														</div>
													) : (
														<div
															className={`rounded-xl p-5 m-3 w-32 text-sm cursor-pointer text-center overflow-hidden duration-200 transition-all ease-linear hover:shadow-xl
															${
																currentProduct &&
																currentProduct.producto.id === p.producto.id &&
																currentProduct.variacion.tipoInput === 151 &&
																currentProduct.variacion.valor === p.variacion.valor
																	? "ring-2 ring-baseGray/80 "
																	: "ring-1 ring-baseGray/60 "
															}
															
															${
																productToFecth &&
																productToFecth.id === p.producto.id
																	? "ring-2 ring-baseGray/80 "
																	: "ring-1 ring-baseGray/60 "
															}`}
															onClick={() => fetchInProduct(p, 151)}
														>
															<p>{p.variacion.valor}</p>
														</div>
													)}
												</div>
											)}
										</div>
									))}
								</div>
							</div>
						))}
				</div>
			)}
		</>
	);
};
