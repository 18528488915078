import React, { useEffect, useRef } from "react";

export function Autocomplete({ map, mapApi, addplace }) {
  const inputRef = useRef(null);

  useEffect(() => {
    const inputToClean = inputRef.current;
    if (mapApi) {
      const options = {
        types: [],
        componentRestrictions: { country: ["cr"] },
      };
      const newAutocomplete = new mapApi.places.Autocomplete(
        inputRef.current,
        options
      );
      newAutocomplete.addListener("place_changed", () => {
        const place = newAutocomplete.getPlace();
    
        if (!place.geometry) return;
        if (place.geometry.viewport) {
          map.fitBounds(place.geometry.viewport);
        } else {
          map.setCenter(place.geometry.location);
          map.setZoom(17);
        }
    
        addplace(place);
        inputRef.current.blur();
      });
      newAutocomplete.bindTo("bounds", map);

      // returned function will be called on component unmount
      return () => {
        mapApi.event.clearInstanceListeners(inputToClean);
      };
    }
  }, [addplace, map, mapApi]);

  const clearSearchBox = () => {
    inputRef.current.value = "";
  };

  return (
    <div>
      <label htmlFor="nombre" className="text-base">
        Por favor escriba un punto de referencia cercano a la ubicación de entrega lo más específico posible:<span className="text-pink">*</span>
      </label>
      <input
        className="w-full rounded-3xl my-2 h-12 p-6 border border-purple"
        ref={inputRef}
        type="text"
        onFocus={clearSearchBox}
        placeholder="Ej: Tienda TiColitas"
      />
    </div>
  );
}

export default Autocomplete;
