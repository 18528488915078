import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { IoMdArrowRoundBack } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import Pagination from "../../components/Pagination";
import Select from "../../components/Select";
import Spinner from "../../components/Spinner";
import useAuthFetch from "../../hooks/useAuthFetch";
import { ClipLoader } from "react-spinners";
import { BiPlusCircle, BiTrash } from "react-icons/bi";
import { CODIGO_TARIFA } from "../../utils/constants";
import { useSelector } from "react-redux";

export default function NewOrdenBySells() {
  const authFetch = useAuthFetch();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { token } = useSelector((store) => store.app.user);

  const [lines, setLines] = useState([]);
  const [errors, setErrors] = useState({
    errorLinea: false,
    errorGeneral: false,
  });
  const [msgError, setMsgError] = useState({
    errorLinea: "",
    errorGeneral: "",
  });

  const [order, setOrder] = useState({
    loading: false,
  });

  const [provider, setProvider] = useState({
    data: [],
    loading: false,
    error: null,
    selected: null,
  });

  const [sellData, setSellData] = useState({
    data: [],
    loading: false,
    searching: false,
    error: null,
    paginationData: {
      totalItems: 0,
      totalPages: 0,
      currentPage: 0,
    },
  });

  const getSellInfo = (idProducto) => {
    let sellsData = {};

    const dataProduct = sellData.data.filter(
      (row) => row.idProducto === idProducto
    );

    dataProduct.forEach((row) => {
      if (row.nombreBodega === "TiColitas - Heredia Ulloa") {
        sellsData = {
          ...sellsData,
          heredia: row.cantidadVendidaUltimoMes,
        };
      }

      if (row.nombreBodega === "TiColitas - La Guacima") {
        sellsData = {
          ...sellsData,
          guacima: row.cantidadVendidaUltimoMes,
        };
      }
    });

    return sellsData;
  };

  const fetchProvideers = async () => {
    setProvider((prev) => ({
      ...prev,
      loading: true,
    }));
    const providerFetch = await authFetch(
      `proveedores?filter={ "where": {"activo": 1}, "order": ["nombre asc"]}`
    );

    const provider = await providerFetch.json();

    setProvider((prev) => ({
      ...prev,
      loading: false,
      data: provider,
      selected: provider[0].id,
    }));
  };

  const setPage = async (index) => {
    window.scrollTo(0, 0);
    fetchSellData(index);
  };

  const handleSellData = async (e) => {
    e.preventDefault();
    setSellData((prev) => ({
      ...prev,
      searching: true,
      pagination: {
        ...prev.pagination,
        page: 0,
      },
    }));
    setLines([]);
    await fetchSellData();
    setSellData((prev) => ({
      ...prev,
      searching: false,
    }));
  };

  const fetchSellData = async (page = 0) => {
    setSellData((prev) => ({
      ...prev,
      loading: true,
    }));

    const sellDataFetch = await authFetch(
      `orden-compras/bodega/proveedor/${provider.selected}?page=${page}&size=10`
    );

    const { data, paginationData } = await sellDataFetch.json();

    setSellData((prev) => ({
      ...prev,
      data: data,
      loading: false,
      paginationData,
    }));
  };

  const [providerRef, setProviderRef] = useState("");

  const addLine = (linea) => {
    setLines((prev) => [...prev, linea]);
  };

  const plusQuantity = (salt, idProducto) => {
    const modifiedLines = lines.map((line) => {
      if (line.idProducto === idProducto) {
        line.cantidad = Math.max(1, line.cantidad + salt);
      }
      return line;
    });

    setLines(modifiedLines);
  };

  const plusQuantityTransferencia = (salt, idProducto) => {
    const modifiedLines = lines.map((line) => {
      if (line.idProducto === idProducto) {
        line.transferenciaHeredia = Math.max(
          0,
          line.transferenciaHeredia + salt
        );

        line.transferenciaHeredia = Math.min(
          line.cantidad,
          line.transferenciaHeredia
        );
      }
      return line;
    });

    setLines(modifiedLines);
  };

  const setImpuesto = (value, idProducto) => {
    const modifiedLines = lines.map((line) => {
      if (line.idProducto === idProducto) {
        line.porcentajeImpuesto = value;
      }
      return line;
    });
    setLines(modifiedLines);
  };

  const getImpuesto = (idProducto) => {
    lines.forEach((line) => {
      if (line.idProducto === idProducto) {
        return line.porcentajeImpuesto;
      }
    });
  };

  const removeLine = (idProducto) => {
    const newLines = lines.filter((line) => line.idProducto !== idProducto);
    setLines(newLines);
  };

  const isInTheLinesArray = (idProducto) => {
    const idOfTheArray = lines.map((line) => {
      return line.idProducto;
    });
    return idOfTheArray.includes(idProducto);
  };

  const saveOrder = async () => {
    const lineasToSend = lines.map((item, index) => {
      return {
        linea: index + 1,
        idProducto: item.idProducto,
        cantidad: item.cantidad,
        montoDescuento: 0,
        precioUnitario: Number(item.precioUnitario),
        porcentajeImpuesto: item.porcentajeImpuesto,
      };
    });

    const linesWithTransferencias = lines.filter(
      (line) => line.transferenciaHeredia > 0
    );

    setOrder((prev) => ({ ...prev, loading: true }));

    const OrdenesCompra = await authFetch(
      `orden-compras`,
      {
        ordenCompra: {
          idProveedor: provider.selected,
          idBodegaDestino: 1,
          referenciaProveedor: providerRef,
        },
        lineas: lineasToSend,
      },
      "POST",
      token
    );

    const orden = await OrdenesCompra.json();

    const transferenciasPendientes = linesWithTransferencias.map((line) => {
      return {
        id_orden_compra: orden.ordenCompra.id,
        id_bodega_destino: 2,
        id_producto: line.idProducto,
        cantidad: line.transferenciaHeredia,
      };
    });

    await authFetch(
      `/orden-compras/tranferencias-pendientes`,
      transferenciasPendientes,
      "POST",
      token
    );

    setOrder((prev) => ({ ...prev, loading: false }));
    if (OrdenesCompra.status === 200 || OrdenesCompra.status === 204) {
      setLoading(false);
      Swal.fire({
        icon: "success",
        title: "Correcto",
        text: "Ordenes compra creada correctamente",
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp",
        },
        background: "#76236C",
        color: "#fafafa",
        confirmButtonColor: "#19D3C5",
      });
      setTimeout(() => {
        navigate("/ordenes");
      }, 400);
    } else {
      setLoading(false);
      setErrors({ ...errors, errorGeneral: true });
      setMsgError({
        ...msgError,
        errorGeneral: "Error al crear la orden de compra",
      });
      return;
    }
    if (OrdenesCompra.status === 400) {
      setLoading(false);
      setErrors({ ...errors, errorGeneral: true });
      setMsgError({
        ...msgError,
        errorGeneral: "Ocurrió un error al crear la orden de compra",
      });
      return;
    }
    if (
      OrdenesCompra.status === 500 ||
      OrdenesCompra.status === 404 ||
      OrdenesCompra.status === 422
    ) {
      setLoading(false);
      setErrors({ ...errors, errorGeneral: true });
      setMsgError({
        ...msgError,
        errorGeneral: "Ocurrió un error al crear la orden de compra",
      });
      return;
    }
  };

  useEffect(() => {
    fetchProvideers();
  }, []);

  const salirOrden = async () => {
    Swal.fire({
      title: "¿Estas seguro que deseas salir?",
      text: "Si abandonas el módulo agregar una orden de compra el avance de lo realizado se perdera",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Si, deseo salir",
      cancelButtonText: "Cancelar",
      confirmButtonColor: "#19D3C5",
      cancelButtonColor: "#E7004C",
      background: "#fafafa",
      color: "#76236C",
    }).then((result) => {
      if (result.isConfirmed) {
        navigate("/ordenes");
      }
    });
  };

  return (
    <div>
      <div className="w-full p-content pb-16">
        {loading ? (
          <div className="min-h-[400px]">
            <Spinner />
          </div>
        ) : (
          <div>
            <div className="flex w-full items-center">
              <div className="w-full flex justify-between">
                <button
                  className="bg-pink text-white py-2 px-8 font-bold rounded-full float-right flex justify-center items-center"
                  onClick={() => salirOrden()}
                >
                  <IoMdArrowRoundBack className="text-lg" /> Volver
                </button>
              </div>
            </div>

            <h2 className="my-3 font-bold text-baseGray text-2xl mt-10">
              Agrega una nueva orden de compra con base en las ventas
            </h2>

            <form className="flex flex-col" onSubmit={handleSellData}>
              <div>
                {provider.loading ? (
                  <p>Loading...</p>
                ) : (
                  <Select
                    containerClasses="my-4"
                    labelColor="text-base"
                    buttonClasses="border border-purple"
                    label="Proveedor:"
                    required
                    options={provider.data.map((provider) => {
                      return {
                        label: provider.nombre,
                        value: provider.id,
                      };
                    })}
                    onChange={(value) =>
                      setProvider((prev) => ({
                        ...prev,
                        selected: value,
                      }))
                    }
                    value={provider.selected}
                  />
                )}
              </div>
              <div className="flex justify-end mt-3">
                <button
                  type="submit"
                  className="bg-purple text-white rounded-lg px-3 py-2 flex items-center gap-2"
                  disabled={sellData.searching}
                >
                  {sellData.searching && <ClipLoader color="#fff" size={12} />}
                  Buscar
                </button>
              </div>
            </form>

            {sellData.data.length !== 0 && (
              <>
                {sellData.loading ? (
                  <div className="flex justify-center items-center">
                    <ClipLoader color="#76236C" size={50} />
                  </div>
                ) : (
                  <table className="table-auto text-xs w-full mt-4">
                    <thead>
                      <tr className="border-b-2">
                        <th className="py-2">Foto del producto</th>
                        <th className="py-2">Información producto</th>
                        <th className="py-2">Bodega</th>
                        <th className="py-2">Cantidad en bodega</th>
                        <th className="py-2">Cantidad en transito</th>
                        <th className="py-2">Cantidad vendida</th>
                        <th className="py-2">Cantidad vendida último mes</th>
                        <th className="py-2">Precio Compra</th>
                        <th className="py-2">Precio Venta</th>
                        <th className="py-2"></th>
                      </tr>
                    </thead>
                    {sellData.data ? (
                      <tbody>
                        {sellData.data.map((row, i) => (
                          <tr
                            className="border-b-[0.5px]"
                            key={`${row.nombreBodega}-${row.idProducto}-${i}`}
                          >
                            <td align="center" className="py-2">
                              <img
                                src={row.fotoProducto}
                                className={"rounded-md w-10 h-10"}
                                alt={row.nombreProveedor}
                              />
                            </td>
                            <td align="center" className="py-2">
                              <div className="flex flex-col gap-1">
                                <p>{row.marca}</p>
                                <p>{row.codigoProducto}</p>
                                <p>{row.nombreProveedor}</p>
                                <p>{row.presentacionKg}</p>
                              </div>
                            </td>
                            <td align="center" className="py-2">
                              {row.nombreBodega}
                            </td>
                            <td align="center" className="py-2">
                              {row.cantidadEnBodega}
                            </td>
                            <td align="center" className="py-2">
                              {row.cantidadEnTransito}
                            </td>
                            <td align="center" className="py-2">
                              {row.cantidadVendida}
                            </td>
                            <td align="center" className="py-2">
                              {row.cantidadVendidaUltimoMes}
                            </td>
                            <td align="center" className="py-2">
                              {row.precioCompra}
                            </td>
                            <td align="center" className="py-2">
                              {row.precioVenta}
                            </td>
                            <td>
                              <div className="flex gap-4 items-center justify-center">
                                {!isInTheLinesArray(row.idProducto) && (
                                  <button
                                    type="button"
                                    className="bg-purple text-white rounded-lg px-3 py-2 flex items-center"
                                    onClick={() => {
                                      const { guacima = 0, heredia = 0 } =
                                        getSellInfo(row.idProducto);

                                      addLine({
                                        idProducto: row.idProducto,
                                        producto: `${row.codigoProducto} - ${
                                          row.marca
                                        } ${
                                          row.presentacionKg
                                            ? " - " + row.presentacionKg + "Kg"
                                            : ""
                                        }. (${guacima} ventas en Guacima, ${heredia} ventas en Heredia)`,
                                        fotoProducto: row.fotoProducto,
                                        cantidad: 1,
                                        precioUnitario: row.precioCompra,
                                        porcentajeImpuesto: 8,
                                        transferenciaHeredia: 0,
                                      });
                                    }}
                                  >
                                    <BiPlusCircle size={15} />
                                  </button>
                                )}
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    ) : (
                      <p>No hay registros de recordatorios</p>
                    )}
                  </table>
                )}

                <Pagination
                  currentPage={sellData.paginationData.currentPage}
                  onPageChange={setPage}
                  totalPages={sellData.paginationData.totalPages}
                />
              </>
            )}

            {lines.length !== 0 && (
              <div className="flex flex-col w-full mt-10 gap-2">
                <h2 className="text-purple font-bold text-lg">
                  Resumen de la orden de compra
                </h2>
                <table className="table-auto text-xs w-full mt-4">
                  <thead>
                    <tr className="border-b-2">
                      <th className="py-2">Producto</th>
                      <th className="py-2">Cantidad</th>
                      <th className="py-2">Transferencia a Heredia</th>
                      <th className="py-2">Precio unitario</th>
                      <th className="py-2">Impuesto</th>
                      <th className="py-2"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {lines.map((line) => (
                      <tr key={line.idProducto}>
                        <td className="py-2">
                          <div className="flex gap-2">
                            <img
                              className="rounded-full w-8 h-8"
                              alt={line.producto}
                              src={line.fotoProducto}
                            />
                            <p>{line.producto}</p>
                          </div>
                        </td>
                        <td className="py-2" align="center">
                          <div className="flex gap-4 justify-center items-center">
                            <button
                              type="button"
                              className="bg-purple text-white rounded-lg py-1 px-2 flex items-center text-sm"
                              onClick={() => plusQuantity(-1, line.idProducto)}
                            >
                              -
                            </button>
                            <p className="text-lg font-medium">
                              {line.cantidad}
                            </p>

                            <button
                              type="button"
                              className="bg-purple text-white rounded-lg py-1 px-2 flex items-center text-sm"
                              onClick={() => plusQuantity(1, line.idProducto)}
                            >
                              +
                            </button>
                          </div>
                        </td>
                        <td className="py-2" align="center">
                          <div className="flex gap-4 justify-center items-center">
                            <button
                              type="button"
                              className="bg-purple text-white rounded-lg py-1 px-2 flex items-center text-sm"
                              onClick={() =>
                                plusQuantityTransferencia(-1, line.idProducto)
                              }
                            >
                              -
                            </button>
                            <p className="text-lg font-medium">
                              {line.transferenciaHeredia}
                            </p>

                            <button
                              type="button"
                              className="bg-purple text-white rounded-lg py-1 px-2 flex items-center text-sm"
                              onClick={() =>
                                plusQuantityTransferencia(1, line.idProducto)
                              }
                            >
                              +
                            </button>
                          </div>
                        </td>
                        <td className="py-2" align="center">
                          {line.precioUnitario}
                        </td>
                        <td className="py-2" align="center">
                          <select
                            className="border border-purple outline-purple p-1 rounded-md"
                            value={getImpuesto(line.idProducto)}
                            onChange={(e) =>
                              setImpuesto(
                                Number(e.target.value),
                                line.idProducto
                              )
                            }
                          >
                            {CODIGO_TARIFA.map((opt) => (
                              <option key={opt.valor} value={opt.valor}>
                                {opt.descripción}
                              </option>
                            ))}
                          </select>
                        </td>
                        <td align="center">
                          <button
                            type="button"
                            className="bg-pink text-white rounded-lg px-3 py-2 flex items-center"
                            onClick={() => removeLine(line.idProducto)}
                          >
                            <BiTrash />
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}

            <div className="my-2">
              <label className="">Referencia del proveedor</label>
              <input
                onChange={(e) => setProviderRef(e.target.value)}
                className="w-full rounded-3xl my-2 h-12 p-6 ring-1 ring-purple outline-none"
                type={"text"}
                name="refProvedor"
                value={providerRef}
                placeholder="Referencia del proveedor"
                required={true}
              />
            </div>

            <div className="flex mt-10 w-full justify-end">
              {errors.errorGeneral && (
                <p className="text-pink text-center">{msgError.errorGeneral}</p>
              )}
              <button
                className="bg-cyan text-white py-2 px-8 font-bold rounded-full float-right flex justify-center items-center gap-2"
                onClick={() => saveOrder()}
              >
                {order.loading && <ClipLoader color="#fff" size={12} />}
                Guardar
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
