import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import "../../index.css";

export function SelectMultiple({
  buttonClasses,
  containerClasses,
  label,
  labelColor,
  onChange,
  options,
  required,
  spliceElement = false,
  subLabel,
  value,
  cleanVariations
}) {
  const ulRef = useRef(null);

  const [labels, setLabels] = useState([]);

  const { categoriesProduct } = useSelector((state) => state.inventory);

  useEffect(() => {
    if (categoriesProduct && categoriesProduct.length > 0) {
      setLabels(categoriesProduct);
    }
  }, [categoriesProduct]);

  const handleChangeInputs = (o) => {
    if(spliceElement){
      if (value.includes(o.value)) {
        // remove
        return
      } else {
        value.push(o.value);
      }
    }else{

      if (value.includes(o.value)) {
        // remove
        value.splice(value.indexOf(o.value), 1);
      } else {
        value.push(o.value);
      }
    }

    onChange(value);
    setLabels(value);
  };

  const cleanAll = () => {
    onChange([]);
    setLabels([]);  
    cleanVariations && cleanVariations();
  };

  const [showSelect, setShowSelect] = useState(false);

  useEffect(() => {
    document.addEventListener("mousedown", closeSelect);
  }, []);

  const closeSelect = (evt) => {
    if (ulRef && ulRef.current && !ulRef.current.contains(evt.target)) {
      setShowSelect(false);
    }
  };

  return (
    <div className={`w-full ${containerClasses || ""}`}>
      <div className="space-y-1">
        <label
          id="assigned-to-label"
          className={` ${labelColor || "text-white"}`}
        >
          {label}
          {required ? <span className="text-pink">*</span> : ""}
        </label>
        {subLabel && <p className="text-sm mb-4 text-borderGray">{subLabel}</p>}
        <div className="relative">
          <div className="w-full rounded-md h-2/3 ">
            {
              Array.isArray(value) && value.length > 0 && (
                <p
                  className="font-semibold text-sm text-purple-border my-3 cursor-pointer select-none"
                  onClick={cleanAll}
                >
                  Borrar selección
                </p>
              )
            }
            <button
              onClick={() => {
                setShowSelect(!showSelect);
              }}
              type="button"
              className={`${buttonClasses} cursor-default relative w-full rounded-3xl min-h-12 bg-white pl-6 pr-10 py-2 text-left focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition ease-in-out duration-150 sm:text-sm sm:leading-5`}
            >
              <div className="flex items-center space-x-3">
                <span className="">
                  {labels.length > 0
                    ? labels.map((i, index) => {
                        return (
                          <li key={index} className='list-none'>
                            {options.map((o, index) => {
                              if (o.value === i) {
                                return <p key={index}>{o.label}</p>;
                              } else {
                                return null;
                              }
                            })}
                          </li>
                        );
                      })
                    : "Seleccione una opción"}
                </span>
              </div>

              <span className="absolute inset-y-0 right-5 flex items-center pr-2 pointer-events-none">
                <svg
                  className="h-5 w-5 text-gray-400"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path
                    d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                    clipRule="evenodd"
                    fillRule="evenodd"
                  ></path>
                </svg>
              </span>
            </button>
          </div>
          {showSelect && (
            <div className="absolute mt-1 w-full min-h-[3rem] z-30 rounded-3xl bg-white shadow-lg none border border-purple no-scrollbar">
              <ul
                ref={ulRef}
                className="max-h-56 rounded-md py-1 text-base leading-6 shadow-xs overflow-auto no-scrollbar focus:outline-none sm:text-sm sm:leading-5"
              >
                {options.map((o, index) => {
                  return (
                    <li
                      key={`option_${index}`}
                      id="assigned-to-option-1"
                      className="text-gray-900 cursor-default select-none relative py-2 pl-4 pr-9"
                      onClick={() => {
                        setShowSelect(false);
                        handleChangeInputs(o);
                      }}
                    >
                      <div className="flex items-center space-x-3 cursor-pointer">
                        <span className="font-normal block truncate text-black">
                          {o.label}
                        </span>
                      </div>
                      {labels.includes(o.value) && (
                        <span className="absolute inset-y-0 right-0 flex items-center pr-4 text-indigo-600 none">
                          <svg
                            className="h-5 w-5"
                            fill="rgb(21 128 61)"
                            viewBox="0 0 20 20"
                          >
                            <path
                              fillRule="evenodd"
                              d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                              clipRule="evenodd"
                            ></path>
                          </svg>
                        </span>
                      )}
                    </li>
                  );
                })}
              </ul>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default SelectMultiple;
