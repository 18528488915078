import React from "react";
import { Link } from "react-router-dom";
import Mixpanel from "../../services/mixpanel.js";

export function Menu({ options }) {
  return (
    <div className="w-full p-content flex h-16 bg-gray">
      <div className="hidden md:w-1/2 md:flex items-center">
        <p className="text-base text-baseGray font-body invisible sm:visible">
          ¡Bienvenido a nuestra{" "}
          <Link
            onClick={() => {
              Mixpanel.track(Mixpanel.TYPES.HEADER_IR_A_TIENDA_BIENVENIDO);
            }}
            className="underline"
            to="/tienda"
          >
            tienda!
          </Link>
        </p>
      </div>
      <div className="w-full md:w-1/2 flex items-center ">
        <div className="w-full flex justify-between">
          {options.map((o, index) => {
            return o.onClick ? (
              <p
                key={`menu_option_${index}`}
                className="text-base text-baseGray font-body cursor-pointer"
                onClick={() => {
                  Mixpanel.track(Mixpanel.TYPES.HEADER_IR_A_OPCION_MENU, {
                    label: o.label,
                  });
                  o.onClick();
                }}
              >
                {o.label}
              </p>
            ) : (
              <Link
                onClick={() => {
                  Mixpanel.track(Mixpanel.TYPES.HEADER_IR_A_OPCION_MENU, {
                    label: o.label,
                    link: o.link,
                  });
                }}
                key={`menu_option_${index}`}
                className="text-base text-baseGray font-body"
                to={o.link}
              >
                {o.label}
              </Link>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default Menu;
