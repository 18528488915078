import React, { useEffect } from 'react';
import image404 from '../../assets/404.png'

export function NotFoundPage() {
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="bg-white w-full p-content pb-16 pt-4 flex items-center flex-col">
      <div className="">
        <img src={image404} alt="Gato y perro en una tina" />
      </div>
      <div className="w-full text-center">
        <p className="text-title text-9xl">
          404
        </p>
        <p className="text-title text-3xl">
          ¡UPS! Algo raro pasó aquí.<br/>No encontramos lo que buscas.
        </p>
      </div>
    </div>
  );
}

export default NotFoundPage;