import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { BsSearch } from "react-icons/bs";

export const SearchBarOrders = ({
  presetSearchQuery
}) => {
  const [valueChanged, setValueChanged] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const navigate = useNavigate();
  const clearSearch = () => {
    setSearchQuery("");
  };

  return (
    <div>
      <div className="relative">
        <input
          className="w-full px-8 rounded-full ring-1 ring-borderGray h-14 font-body outline-none"
          placeholder="Buscar..."
          value={valueChanged ? searchQuery : presetSearchQuery || ""}
          onKeyDown={(evt) => {
            if (evt.key === "Enter") {
              if (evt.target.value) {
                if (evt.target.value.trim() !== "") {
                  navigate(`/pedidos/busqueda?valor=${evt.target.value}`);
                  clearSearch();
                }
              }
            }
          }}
          onChange={(evt) => {
            setValueChanged(true);
            if (evt.target.value) {
              setSearchQuery(evt.target.value);
            } else {
              clearSearch();
            }
          }}
        />
        <div className="absolute right-5 top-2 z-50 flex">
          {searchQuery.trim() !== "" ? (
            <Link
              onClick={() => {
                clearSearch();
              }}
              to={`/pedidos/busqueda?valor=${searchQuery}`}
            >
              <span className="sr-only">Buscar pedido</span>
              <button className="p-1 z-50" aria-label="Buscar producto">
                <BsSearch className="text-3xl text-borderGray" />
              </button>
            </Link>
          ) : (
            <div>
              <span className="sr-only">Buscar pedido</span>
              <button className="p-1 z-50" aria-label="Buscar producto">
                <BsSearch className="text-3xl text-borderGray" />
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
