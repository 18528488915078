import React, { Fragment, useCallback, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { IoMdArrowRoundBack } from "react-icons/io";
import { FaRegTrashAlt } from "react-icons/fa";
import useAuthFetch from "../../hooks/useAuthFetch";

import Spinner from "../../components/Spinner";
import "react-datepicker/dist/react-datepicker.css";
import { isNaN } from "lodash";
import Modal from "../../components/Modal";
import { useSelector } from "react-redux";

export const MarcarRecibida = () => {
  const authFetch = useAuthFetch();
  const { idOrden } = useParams();
  const [loading, setLoading] = useState(false);
  const [lineas, setLineas] = useState([]);
  const [ordenId, setOrdenId] = useState(null);
  const [lienaActive, setLienaActive] = useState(null);
  const [valuesEdit, setValuesEdit] = useState({
    cantidad: null,
    precioUnitario: null,
  });
  const [openDetails, setOpenDetails] = useState(false);
  const [codgBarras, setCodgBarras] = useState("");
  const { token } = useSelector((store) => store.app.user);

  const [error, setError] = useState({
    alert: false,
    message: "",
  });

  const [errorLinea, setErrorLinea] = useState({
    alert: false,
    message: "",
  });

  const fetchOrden = useCallback(async () => {
    setLoading(true);
    const data = await authFetch(
      `orden-compras/${idOrden}?filter={"include": [{"relation": "lineaOrdenCompras", "scope": {"include": [{"relation": "producto"}]}}]}`
    );
    const dataJSON = await data.json();
    setOrdenId(dataJSON.id);
    setLineas(dataJSON.lineaOrdenCompras);
    setLoading(false);
  }, [idOrden]);

  useEffect(() => {
    if (!isNaN(idOrden)) {
      fetchOrden();
    }
    window.scrollTo(0, 0);
  }, [idOrden, fetchOrden]);

  const marcarComoPaga = async (linea) => {
    lineas.find((l) => l.linea === linea.linea).recibido = 1;
    await setLineas([...lineas]);

    const { idProducto } = linea;
    const lineaPatch = await authFetch(
      `linea-orden-compras/orden/${idOrden}/producto/${idProducto}`,
      {
        linea: linea.linea,
        idProducto: idProducto,
        precioUnitario: linea.precioUnitario,
        cantidad: linea.cantidad,
        recibido: 1,
      },
      "PATCH",
      token
    );

    if (lineaPatch.status === 422) {
      const data = await lineaPatch.json();
      setErrorLinea({
        alert: true,
        message: data.error.message,
      });
      return;
    }

    if (
      (lineaPatch.status === 400 || 404 || 500 || 401) &&
      lineaPatch.status !== 204
    ) {
      setErrorLinea({
        alert: true,
        message:
          "Ocurrio un error al actualizar una línea, por favor recargue el navegador y vuelva a intentarlo.",
      });
      return;
    }

    setErrorLinea({
      alert: false,
      message: "",
    });
  };

  const desmarcarComoPaga = async (linea) => {
    lineas.find((l) => l.linea === linea.linea).recibido = 0;
    await setLineas([...lineas]);

    const { idProducto } = linea;
    const lineaPatch = await authFetch(
      `linea-orden-compras/orden/${idOrden}/producto/${idProducto}`,
      {
        linea: linea.linea,
        idProducto: idProducto,
        precioUnitario: linea.precioUnitario,
        cantidad: linea.cantidad,
        recibido: 0,
      },
      "PATCH",
      token
    );

    if (lineaPatch.status === 422) {
      const data = await lineaPatch.json();
      setErrorLinea({
        alert: true,
        message: data.error.message,
      });
      return;
    }

    if (
      (lineaPatch.status === 400 || 404 || 500 || 401) &&
      lineaPatch.status !== 204
    ) {
      setErrorLinea({
        alert: true,
        message:
          "Ocurrio un error al actualizar una línea, por favor recargue el navegador y vuelva a intentarlo.",
      });
      return;
    }

    setErrorLinea({
      alert: false,
      message: "",
    });
  };

  const editLinea = (linea) => {
    setLienaActive(linea);
    setValuesEdit({
      cantidad: linea.cantidad,
      precioUnitario: linea.precioUnitario,
    });
  };

  const saveLinea = (linea) => {
    linea = {
      ...linea,
      cantidad: valuesEdit.cantidad,
      precioUnitario: valuesEdit.precioUnitario,
      recibido: 1,
    };
    const newLineas = lineas;
    const elememtIndex = newLineas.findIndex(
      (item) => item.linea === linea.linea
    );
    newLineas[elememtIndex] = linea;
    setLineas(newLineas);
    setLienaActive(null);
    setValuesEdit({
      cantidad: null,
      precioUnitario: null,
    });

    updateLinea(linea);
  };

  // const handleSubmit = async (e) => {
  // 	e.preventDefault();
  // };
  const handleOpenModal = () => {
    setOpenDetails(true);
    setCodgBarras("");
    setError({
      alert: false,
      message: "",
    });
  };

  const handleSearch = () => {
    if (codgBarras === "") {
      setError({
        alert: true,
        message: "El código de barras no puede estar vacio",
      });
    } else {
      setError({
        alert: false,
        message: "",
      });

      const linea = lineas.find((l) => l.producto.codigoBarras === codgBarras);

      if (linea) {
        setOpenDetails(false);
        setLienaActive(linea);
      } else {
        setError({
          alert: true,
          message:
            "El código de barras es incorrecto o no se encuentra en la orden",
        });
      }
    }
  };

  const deleteLinea = (index, l) => {
    setLineas((prev) => {
      const newLineas = [...prev];
      newLineas.splice(index, 1);
      return newLineas;
    });

    deletingLinea(l);
  };

  if (lienaActive) {
    const element = document.getElementById(lienaActive.linea);
    element.scrollIntoView({
      behavior: "smooth",
      block: "center",
      inline: "nearest",
    });
  }

  const updateLinea = async (l) => {
    const { idProducto } = l;

    setLoading(true);
    const lineaPatch = await authFetch(
      `linea-orden-compras/orden/${idOrden}/producto/${idProducto}`,
      {
        linea: l.linea,
        idProducto: idProducto,
        precioUnitario: Number(l.precioUnitario),
        cantidad: Number(l.cantidad),
        recibido: 1,
      },
      "PATCH",
      token
    );

    if (lineaPatch.status === 422) {
      const data = await lineaPatch.json();
      setErrorLinea({
        alert: true,
        message: data.error.message,
      });
      setLoading(false);
      return;
    }

    if (
      (lineaPatch.status === 400 || 404 || 500 || 401) &&
      lineaPatch.status !== 204
    ) {
      setErrorLinea({
        alert: true,
        message:
          "Ocurrio un error al actualizar una línea, por favor recargue el navegador y vuelva a intentarlo.",
      });
      setLoading(false);
      return;
    }

    if (lineaPatch.status === 204) {
      setErrorLinea({
        alert: false,
        message: "",
      });
      setLoading(false);
      fetchOrden();
    }

    setErrorLinea({
      alert: false,
      message: "",
    });
    setLoading(false);
  };

  const deletingLinea = async (l) => {
    setLoading(true);

    const { idProducto } = l;
    const lineaPatch = await authFetch(
      `linea-orden-compras/orden/${idOrden}/producto/${idProducto}`,
      {},
      "DELETE",
      token
    );

    if (lineaPatch.status === 422) {
      const data = await lineaPatch.json();
      setErrorLinea({
        alert: true,
        message: data.error.message,
      });
      setLoading(false);
      return;
    }

    if (
      (lineaPatch.status === 400 || 404 || 500 || 401) &&
      lineaPatch.status !== 204
    ) {
      setErrorLinea({
        alert: true,
        message:
          "Ocurrio un error al eliminar una línea, por favor recargue el navegador y vuelva a intentarlo.",
      });
      setLoading(false);
      return;
    }

    setErrorLinea({
      alert: false,
      message: "",
    });
    setLoading(false);
  };

  return (
    <div className="w-full p-content pb-16 min-h-[400px]">
      {loading && <Spinner />}
      <div>
        <div className="flex w-full items-center">
          <div className="w-full flex justify-between">
            <div>
              <p className="text-lg font-medium">Número de orden: #{ordenId}</p>
              <br />
              <Link
                to={`/ordenes/marcar_recibida/${ordenId}/lineaNueva`}
                className="bg-cyan text-white py-2 px-8 font-bold rounded-full"
              >
                + Agregar una linea
              </Link>
            </div>
            <Link to="/ordenes">
              <button className="bg-pink text-white py-2 px-8 font-bold rounded-full float-right flex justify-center items-center">
                <IoMdArrowRoundBack className="text-lg" /> Volver
              </button>
            </Link>
          </div>
        </div>

        <div>
          <br />
          <button
            className="bg-purple text-white py-2 px-8 font-bold rounded-full float-right flex justify-center items-center"
            onClick={handleOpenModal}
          >
            Escaner código de barras
          </button>
        </div>

        <Modal
          backgroundColor="bg-purple no-scrollbar"
          open={openDetails}
          closeModalFunction={() => {
            setOpenDetails(false);
          }}
          className="no-scrollbar"
        >
          <div className="w-full h-full flex flex-col justify-center items-center">
            <br />
            <br />
            <p className="text-white font-medium">Digite el código de barras</p>
            <br />
            <div className="flex flex-col md:flex-row w-full justify-center items-center">
              <input
                className="rounded-3xl text-sm md:text-lg w-9/12 mx-5 my-2 h-12 p-6 ring-1 ring-purple outline-none"
                type={"text"}
                placeholder="Código de barras"
                required={true}
                name="codgBarras"
                value={codgBarras}
                onChange={(e) => setCodgBarras(e.target.value)}
              />
              <input
                className="bg-cyan text-sm md:text-lg cursor-pointer text-white mx-5 py-2 px-8 font-bold rounded-full float-right flex justify-center items-center transition-shadow duration-300 ease-linear hover:shadow-md hover:shadow-cyan/50"
                onClick={handleSearch}
                type={"submit"}
                value={"Buscar"}
              />
            </div>
            <br />

            {error.alert && (
              <div className="text-white font-medium">{error.message}</div>
            )}
            <br />
          </div>
        </Modal>

        <form>
          <br />
          {lineas !== undefined && lineas.length > 0 && (
            <div className="w-full my-6  ">
              <hr className="my-4 bg-purple text-purple-border" />
              <p>Líneas de la orden de compra</p>
              <div className="w-full md:w-9/12 grid grid-cols-1 md:grid-cols-6 items-center gap-4 font-semibold my-4">
                <p className="col-span-2">Producto</p>
                <p className="hidden md:block">Cantidad</p>
                <p className="hidden md:block mx-6">Precio unitario</p>
                <p className="hidden md:block mx-6 col-span-2">
                  Código de barras
                </p>
              </div>
              {lineas.map((l, index) => (
                <Fragment key={`${index}_${l.linea}`}>
                  <div
                    className={`w-full my-1 flex ${
                      lienaActive && lienaActive.linea === l.linea
                        ? "bg-yellow/20"
                        : l.recibido === 1
                        ? "bg-cyan/20"
                        : ""
                    }`}
                    id={l.linea}
                  >
                    <div className="w-11/12 grid grid-cols-2 md:grid-cols-6 items-center gap-4">
                      <div className="flex col-span-2 overflow-hidden">
                        <img
                          className="w-16 h-16 rounded-lg text-xs m-1.5"
                          src={
                            l.producto.fotoPrincipal
                              ? l.producto.fotoPrincipal
                              : "https://polpo-assets.s3.amazonaws.com/production/ticolitas/productos/800/noactivo.jpg"
                          }
                          alt={`Foto de ${index}`}
                        />
                        <p className="text-xs">{l.producto.nombre}</p>
                      </div>
                      <label className="text-xs font-medium block md:hidden">
                        <b>Cantidad:</b>
                      </label>
                      <input
                        className={`${
                          lienaActive && lienaActive.linea === l.linea
                            ? "bg-baseGray/20 px-2 py-1 rounded-md focus:outline-purple my-1 md:my-0"
                            : "bg-transparent"
                        }`}
                        value={
                          lienaActive === l ? valuesEdit.cantidad : l.cantidad
                        }
                        disabled={
                          lienaActive && lienaActive.linea === l.linea
                            ? false
                            : true
                        }
                        type={"Number"}
                        step="any"
                        name="cantidad"
                        onChange={(e) =>
                          setValuesEdit({
                            ...lienaActive,
                            cantidad: e.target.value,
                          })
                        }
                      />
                      <label className="text-xs font-medium block md:hidden">
                        <b>Precio unitario:</b>
                      </label>
                      <input
                        className={`${
                          lienaActive && lienaActive.linea === l.linea
                            ? "bg-baseGray/20 px-2 py-1 rounded-md focus:outline-purple my-3 md:my-0"
                            : "bg-transparent"
                        }`}
                        value={
                          lienaActive === l
                            ? valuesEdit.precioUnitario
                            : l.precioUnitario
                        }
                        disabled={
                          lienaActive && lienaActive.linea === l.linea
                            ? false
                            : true
                        }
                        name="precioUnitario"
                        type={"Number"}
                        step="any"
                        onChange={(e) =>
                          setValuesEdit({
                            ...lienaActive,
                            precioUnitario: e.target.value,
                          })
                        }
                      />
                      <p className="text-sm hidden md:block col-span-2">
                        {l.producto.codigoBarras}
                      </p>
                    </div>
                    <div className="flex justify-center items-center">
                      {lienaActive && lienaActive.linea === l.linea ? (
                        <div
                          className="cursor-pointer text-xs py-2 px-3 rounded-lg bg-pink text-white mx-2"
                          onClick={() => setLienaActive(null)}
                        >
                          Cancerlar
                        </div>
                      ) : l.recibido === 0 ? (
                        <div
                          style={{
                            fontSize: "10px",
                          }}
                          className="cursor-pointer py-2 px-3 rounded-lg bg-cyan text-white mx-2"
                          onClick={() => marcarComoPaga(l)}
                        >
                          Marcar como recibida
                        </div>
                      ) : (
                        <div
                          style={{
                            fontSize: "10px",
                          }}
                          className="cursor-pointer py-2 px-3 rounded-lg bg-orange text-white mx-2"
                          onClick={() => desmarcarComoPaga(l)}
                        >
                          Desmarcar
                        </div>
                      )}
                      {lienaActive && lienaActive.linea === l.linea ? (
                        <div
                          className="cursor-pointer text-xs py-2 px-3 rounded-lg bg-purple text-white mx-2"
                          onClick={() => saveLinea(l)}
                        >
                          Guardar
                        </div>
                      ) : (
                        <>
                          <button
                            className={`cursor-pointer text-xs py-3 px-3 rounded-lg text-white mx-2 ${
                              l.recibido !== 0 ? "bg-purple/40" : " bg-purple"
                            }`}
                            onClick={() => editLinea(l)}
                            disabled={l.recibido !== 0 ? true : false}
                          >
                            Editar
                          </button>
                          <button
                            className={`cursor-pointer text-xs py-3 px-3 rounded-lg text-white mx-2 ${
                              l.recibido !== 0 ? "bg-pink/40" : " bg-pink"
                            }`}
                            disabled={l.recibido !== 0 ? true : false}
                            onClick={() => deleteLinea(index, l)}
                          >
                            <FaRegTrashAlt />
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                  <hr />
                </Fragment>
              ))}
            </div>
          )}
          {errorLinea.alert && (
            <p className="text-pink text-center font-semibold py-2">
              {errorLinea.message} *
            </p>
          )}
          {
            // <input
            // 	className="bg-cyan cursor-pointer text-white py-2 px-8 font-bold rounded-full float-right flex justify-center items-center transition-shadow duration-300 ease-linear hover:shadow-md hover:shadow-cyan/50"
            // 	onClick={handleSubmit}
            // 	type={"submit"}
            // 	value={"Guardar"}
            // />
          }
        </form>
      </div>
    </div>
  );
};
