import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { IoMdArrowRoundBack } from "react-icons/io";
import { SearchProduct } from "../../components/Select/SearchProduct";
import useAuthFetch from "../../hooks/useAuthFetch";
import Spinner from "../../components/Spinner";
import { useSelector } from "react-redux";

export const FormInventarioNuevo = () => {
  const { idBodega } = useParams();
  const navigate = useNavigate();

  const [cantidad, setCantidad] = useState({
    cantidadBodega: "",
    cantidadTransito: "",
  });
  const [loading, setLoading] = useState(false);
  const [product, setProduct] = useState(null);
  const [error, setError] = useState(false);
  const [msgError, setMsgError] = useState("");
  const [catalogs, setCatalogs] = useState({
    products: [],
  });
  const { token } = useSelector((store) => store.app.user);
  const authFetch = useAuthFetch();

  useEffect(() => {
    const fetchCatalogs = async () => {
      setLoading(true);

      const productFetch = await authFetch(
        `productos/no-relacionados-a-bodega`, null, "GET", token
      );
      const products = await productFetch.json();

      setCatalogs({
        ...catalogs,
        products,
      });
      setLoading(false);
    };

    if (idBodega) {
      fetchCatalogs();
    }
    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idBodega]);

  const handlePost = async () => {
    if (cantidad.cantidadBodega < 0 || cantidad.cantidadBodega === "") {
      setMsgError("Por favor ingrese una cantidad válida");
      setError(true);
      return;
    }
    if (cantidad.cantidadTransito < 0 || cantidad.cantidadTransito === "") {
      setMsgError("Por favor ingrese una cantidad válida");
      setError(true);
      return;
    }
    if (product === null) {
      setMsgError("Por favor seleccione un producto");
      setError(true);
      return;
    }
    setError(false);

    const request = await authFetch(
      `bodega-productos`,
      {
        idBodega: Number(idBodega),
        idProducto: product.idProducto,
        cantidadEnBodega: cantidad.cantidadBodega,
        cantidadEnTransito: cantidad.cantidadTransito,
        fechaIngresoEstimada: new Date(),
      },
      "POST",
      token
    );

    if (request.status === 200 || request.status === 204) {
      Swal.fire({
        icon: "success",
        title: "Correcto",
        text: "Producto agregado correctamente",
        background: "#76236C",
        confirmButtonColor: "#19D3C5",
        color: "#fafafa",
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp",
        },
      });
      setError(false);
      setTimeout(() => {
        navigate(`/bodegas/inventario/${idBodega}`);
      }, 400);
    }

    if (
      request.status === 400 ||
      request.status === 500 ||
      request.status === 404 ||
      request.status === 402
    ) {
      setError(true);
      setMsgError("Ocurrió un error al agregar el producto");
    }

    if (request.status === 422) {
      setError(true);
      setMsgError("El producto ya existe en la bodega");
    }
  };

  return (
    <div className="w-full p-content pb-16">
      {loading && <Spinner />}
      <>
        <div>
          <div className="flex w-full items-center">
            <div className="w-full flex justify-between">
              <div>
                <h2 className="font-bold text-xl text-baseGray">
                  {" "}
                  Agregar un nuevo producto
                </h2>
              </div>
              <Link to={`/bodegas/inventario/${idBodega}`}>
                <button className="bg-pink text-white py-2 px-8 font-bold rounded-full float-right flex justify-center items-center">
                  <IoMdArrowRoundBack className="text-lg" /> Volver
                </button>
              </Link>
            </div>
          </div>
          <br />
        </div>

        <div>
          <label className="my-2">Seleccione el producto:</label>
          <span className="text-pink">*</span>
          <SearchProduct
            products={catalogs.products}
            setProduct={setProduct}
            product={product}
            notQuantity={true}
          />
          <div>
            <label className="ml-5">Cantidad en bodega</label>
            <span className="text-pink">*</span>

            <input
              onChange={(evt) => {
                setCantidad({
                  ...cantidad,
                  cantidadBodega: Number(evt.target.value),
                });
              }}
              className="w-full rounded-3xl my-2 h-12 p-6 ring-1 ring-purple outline-none"
              type={"Number"}
              name="nameBodega"
              value={cantidad.cantidadBodega}
              step="any"
              placeholder="Cantidad en bodega"
              required={true}
            />
          </div>

          <div>
            <label className="ml-5">Cantidad en transito</label>
            <span className="text-pink">*</span>

            <input
              onChange={(evt) => {
                setCantidad({
                  ...cantidad,
                  cantidadTransito: Number(evt.target.value),
                });
              }}
              className="w-full rounded-3xl my-2 h-12 p-6 ring-1 ring-purple outline-none"
              type={"Number"}
              value={cantidad.cantidadTransito}
              name="nameBodega"
              step="any"
              placeholder="Cantidad en transito"
              required={true}
            />
          </div>

          <input
            className="bg-cyan text-white py-2 px-8 my-3 font-bold rounded-full float-right flex cursor-pointer transition-shadow duration-300 ease-in-out justify-center items-center hover:shadow-xl hover:shadow-cyan/30"
            type="submit"
            value={"Guardar"}
            onClick={handlePost}
          />
          {error && <p className="text-pink text-center">{msgError}</p>}
        </div>
      </>
    </div>
  );
};
