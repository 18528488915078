import { useState, useEffect } from "react";

const useGeolocation = () => {
  const [coordinates, setCoordinates] = useState({
    latitude: null,
    longitude: null,
  });
  const [error, setError] = useState(null);

  useEffect(() => {
    const handleSuccess = (position) => {
      const { latitude, longitude } = position.coords;
      setCoordinates({ latitude, longitude });
    };

    const handleError = (error) => {
      setError(error.message);
    };

    if (!navigator.geolocation) {
      setError("Geolocation is not supported by your browser.");
    } else {
      navigator.geolocation.getCurrentPosition(handleSuccess, handleError);
    }

    return () => {
      // Limpiar los eventos o tareas pendientes si el componente se desmonta
      // Esto cancela la suscripción a la API de geolocalización
      navigator.geolocation.clearWatch(handleSuccess);
    };
  }, []);

  return { coordinates, error };
};

export default useGeolocation;
