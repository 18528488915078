import { Link, useNavigate, useParams } from "react-router-dom";
import { IoMdArrowRoundBack } from "react-icons/io";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import Spinner from "../../components/Spinner";
import useAuthFetch from "../../hooks/useAuthFetch";
import Select from "../../components/Select";
import { INPUTS_VALORES } from "../../utils/constants";
import { useSelector } from "react-redux";

const FormVariation = () => {
  const { idVariation } = useParams();
  const navigate = useNavigate();
  const [loading, setloading] = useState(false);
  const [error, setError] = useState(false);
  const [msgError, setMsgError] = useState("");
  const [variation, setvariation] = useState(null);
  const [catalogoInput, setCatalogoInput] = useState(null);
  const [valorvariation, setValorvariation] = useState("");
  const { token } = useSelector((store) => store.app.user);
  const authFetch = useAuthFetch();

  useEffect(() => {
    const fetchVariation = async () => {
      const data = await authFetch(`variaciones/${idVariation}`);
      const dataJSON = await data.json();
      setvariation(dataJSON);
      setloading(false);
    };
    if (!isNaN(idVariation)) {
      setloading(true);
      fetchVariation();
    }
  }, [idVariation]);

  useEffect(() => {
    if (variation) {
      setValorvariation(variation.nombre);
      setCatalogoInput(variation.tipoInput);
    }
  }, [variation]);

  const handleSubmit = () => {
    if (
      valorvariation === "" ||
      valorvariation === null ||
      valorvariation === undefined
    ) {
      setMsgError("La varación debe tener un nombre ");
      setError(true);
      return;
    }
    if (
      catalogoInput === "" ||
      catalogoInput === null ||
      catalogoInput === undefined
    ) {
      setMsgError("La varación debe tener un input asociado");
      setError(true);
      return;
    }

    if (isNaN(idVariation)) {
      console.table({ valorvariation, catalogoInput });
      handlePost();
    }

    if (!isNaN(idVariation)) {
      handlePacth();
      console.table({ valorvariation, catalogoInput });
    }
  };

  const handlePost = async () => {
    let request = await authFetch(
      "variaciones",
      {
        nombre: valorvariation,
        tipoInput: catalogoInput,
      },
      "POST",
      token
    );
    if (request.status === 200 || request.status === 204) {
      Swal.fire({
        icon: "success",
        title: "Correcto",
        text: "Catálogo agregado correctamente",
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp",
        },
        background: "#76236C",
        color: "#fafafa",
        confirmButtonColor: "#19D3C5",
      });
      setTimeout(() => {
        navigate("/variaciones_producto");
      }, 400);
      setError(false);
    }

    if (
      request.status === 400 ||
      request.status === 404 ||
      request.status === 402 ||
      request.status === 401 ||
      request.status === 422 ||
      request.status === 500
    ) {
      setMsgError("Error al agregar una variación");
      setError(true);
      return;
    }
  };

  const handlePacth = async () => {
    let request = await authFetch(
      `variaciones/${idVariation}`,
      {
        nombre: valorvariation,
        tipoInput: catalogoInput,
      },
      "PATCH",
      token
    );
    if (request.status === 200 || request.status === 204) {
      Swal.fire({
        icon: "success",
        title: "Correcto",
        text: "Catálogo actualizado correctamente",
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp",
        },
        background: "#76236C",
        color: "#fafafa",
        confirmButtonColor: "#19D3C5",
      });
      setTimeout(() => {
        navigate("/variaciones_producto");
      }, 400);
      setError(false);
    }

    if (
      request.status === 400 ||
      request.status === 404 ||
      request.status === 402 ||
      request.status === 401 ||
      request.status === 422 ||
      request.status === 500
    ) {
      setMsgError("Error al actualizar una variacion");
      setError(true);
      return;
    }
  };

  return (
    <div className="w-full p-content pb-16">
      <div className="flex w-full items-center">
        <div className="w-full">
          <Link to="/variaciones_producto">
            <button className="bg-pink text-white py-2 px-8 font-bold rounded-full float-right flex justify-center items-center">
              <IoMdArrowRoundBack className="text-lg" /> Volver
            </button>
          </Link>
        </div>
      </div>

      <h2 className="my-3 font-bold text-baseGray text-2xl">
        {isNaN(idVariation) ? "Agregar variación" : "Editar variación"}
      </h2>
      <br />

      {loading ? (
        <div className="my-20">
          <Spinner />
        </div>
      ) : (
        <div>
          <div>
            <label className="ml-5">Nombre de la variación</label>
            <input
              onChange={(evt) => {
                setValorvariation(evt.target.value);
              }}
              className="w-full rounded-3xl my-2 h-12 p-6 ring-1 ring-purple outline-none"
              type={"text"}
              name="valorvariation"
              value={valorvariation}
              placeholder="Nombre de la variación"
              required={true}
            />
          </div>

          <Select
            required
            containerClasses="my-3"
            labelColor="text-base"
            buttonClasses="border border-purple"
            label={`Seleccione un input para la variación`}
            options={INPUTS_VALORES.map((b) => {
              return {
                value: b.id,
                label: b.valor,
              };
            })}
            onChange={(value) => {
              setCatalogoInput(value);
            }}
            value={catalogoInput}
          />
          {error && (
            <p className="text-pink text-lg font-medium text-center">
              {msgError} *
            </p>
          )}
          <button
            className="bg-cyan text-white py-2 px-8 my-3 font-bold rounded-full float-right flex transition-shadow duration-300 ease-in-out justify-center items-center hover:shadow-xl hover:shadow-cyan/30"
            onClick={handleSubmit}
          >
            Guardar
          </button>
        </div>
      )}
    </div>
  );
};

export default FormVariation;
