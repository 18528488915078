import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { IoMdArrowRoundBack } from "react-icons/io";

import AccountSidebar from "../../components/AccountSidebar";

const options = [
  {
    label: "Bodegas",
    route: "/bodegas",
    roles: ["bodega", "admin", "administradorTienda", "mensajero"],
  },
  {
    label: "Proveedores",
    route: "/proveedores",
    roles: ["bodega", "admin", "administradorTienda"],
  },
  /*{
    label: "Ordenes de compra",
    route: "/ordenes",
    roles: ["bodega", "admin", "administradorTienda"],
  },*/
  {
    label: "Productos",
    route: "/productos",
    roles: ["bodega", "admin", "administradorTienda"],
  },
  {
    label: "Ordenes de compra",
    route: "/ordenes",
    roles: ["bodega", "admin", "administradorTienda"],
  },
  {
    label: "Marcas",
    route: "/marcas",
    roles: ["bodega", "admin", "administradorTienda"],
  },
  {
    label: "Catálogo de categorías",
    route: "/categorias_catalogo",
    roles: ["bodega", "admin", "administradorTienda"],
  },
  {
    label: "Jerarquía de categorías",
    route: "/categorias_jerarquia",
    roles: ["bodega", "admin", "administradorTienda"],
  },
  {
    label: "Transferencias",
    route: "/transferencias",
    roles: ["bodega", "admin", "administradorTienda"],
  },
  {
    label: "Catálogos",
    route: "/catalogos",
    roles: ["bodega", "admin", "administradorTienda"],
  },
  {
    label: "Variaciones",
    route: "/variaciones_producto",
    roles: ["bodega", "admin", "administradorTienda"],
  },
  {
    label: "Familias de productos",
    route: "/inventario/familias",
    roles: ["bodega", "admin", "administradorTienda"],
  },
  {
    label: "Cierres",
    route: "/cierres",
    roles: ["admin", "administradorTienda"],
  },
  {
    label: "Recordatorios",
    route: "/recordatorios",
    roles: ["admin", "administradorTienda"],
  },
  {
    label: "Pagos pendientes",
    route: "/pendientes",
    roles: ["mensajero"],
  },
];

export function InventarioPage() {
  const user = useSelector((state) => state.app.user);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="w-full p-content pb-16 min-h-[400px]">
      <div>
        <div className="flex w-full items-center">
          <div className="w-full">
            <Link to="/cuenta">
              <button className="bg-pink text-white py-2 px-8 font-bold rounded-full float-right flex justify-center items-center">
                <IoMdArrowRoundBack className="text-lg" /> Volver
              </button>
            </Link>
          </div>
        </div>
        <div className="flex flex-wrap sm:flex-nowrap w-full">
          <div className="w-full sm:w-3/12">
            <AccountSidebar options={options} user={user} />
            <hr className="visible sm:hidden my-4" />
          </div>
          <div className="w-full sm:w-9/12 mt-4">
            <div className="ml-6">
              <p className="text-2xl font-bold mb-4">Inventario</p>
            </div>
            <div className="ml-6">
              <p className="mb-2">
                En esta sección podrás gestionar el inventario de cada una de
                las bodegas de TiColitas.
              </p>
              <p className="mb-2">
                Para poder ingresar productos a una bodega se debe realizar a
                través de las ordenes de compra.
              </p>
              <p className="mb-2">
                Cada orden de compra va ligada a un proveedor, por lo que si el
                proveedor aun no se encuentra en el sistema es necesario crearlo
                en la sección de proveedores.
              </p>
              <p className="mb-2">
                Para navegar por el sistema de inventarios utilizá las opciones
                del menú.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default InventarioPage;
