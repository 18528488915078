import React, { useEffect, useState } from "react";
import Confetti from "react-confetti";

import PromoBanner from "../../components/PromoBanner";

export function SorteoPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [ganador, setGanador] = useState("");
  const [finished, setFinished] = useState(false);

  const ordenes = [
    "Orden #396",
    "Orden #399",
    "Orden #405",
    "Orden #413",
    "Orden #416",
    "Orden #417",
    "Orden #418",
    "Orden #419",
    "Orden #421",
    "Orden #423",
    "Orden #425",
    "Orden #431",
    "Orden #434",
    "Orden #440",
    "Orden #441",
    "Orden #444",
    "Orden #445",
    "Orden #450",
    "Orden #452",
    "Orden #454",
    "Orden #455",
    "Orden #456",
    "Orden #459",
    "Orden #463",
    "Orden #467",
    "Orden #479",
    "Orden #481",
    "Orden #482",
    "Orden #485",
    "Orden #488",
    "Orden #489",
    "Orden #490",
    "Orden #494",
    "Orden #495",
    "Orden #497",
    "Orden #502",
    "Orden #508",
    "Orden #509",
    "Orden #518",
    "Orden #519",
    "Orden #524",
    "Orden #528",
    "Orden #531",
    "Orden #534",
    "Orden #535",
    "Orden #542",
    "Orden #560",
    "Orden #565",
    "Orden #568",
    "Orden #569",
    "Orden #572",
    "Orden #575",
  ];

  return (
    <div className="bg-white w-full p-content pb-16 pt-2 flex flex-col">
      <PromoBanner />
      <h1 className="font-bold my-4">Ordenes participantes: </h1>
      <div className="flex">
        <div className="w-1/2 overflow-y-scroll max-h-64 border-2 rounded-3xl p-4">
          <ul>
            {ordenes.map((o) => {
              return <li>{o}</li>;
            })}
          </ul>
        </div>
        <div className="w-1/2 pl-4 pt-6">
          {ganador && (
            <h1 className="text-center font-bold text-2xl mb-4">
              Ganador: {ganador}
            </h1>
          )}
          <button
            onClick={() => {
              const interval = setInterval(function () {
                setGanador(ordenes[Math.floor(Math.random() * ordenes.length)]);
              }, 100);

              setTimeout(() => {
                clearInterval(interval);
                setFinished(true);
              }, 7000);
            }}
            className="w-full bg-pink text-white p-10 rounded-3xl"
          >
            Obtener ganador
          </button>
          {finished && (
            <>
              <h1 className="text-center font-bold text-2xl mt-4">
                ¡Muchas Felicidades!
              </h1>
              <Confetti width={1500} height={1500} />
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default SorteoPage;
