import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { IoMdArrowRoundBack } from "react-icons/io";
import Spinner from "../../components/Spinner";
import Tabs from "../../components/Tabs";
import Modal from "../../components/Modal";
import useAuthFetch from "../../hooks/useAuthFetch";
import {
  ESTADOS_ORDENES,
  METODOS_ENTREGA,
  METODOS_PAGO_DESC,
} from "../../utils/constants";
import { useSelector } from "react-redux";

import { DndContext, closestCenter } from "@dnd-kit/core";

import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import EnvioCard from "./EnvioCard";

export function EnviosPage({ user }) {
  const [orders, setOrders] = useState([]);
  const [locations, setLocations] = useState([]);
  const [userDriverPofile, setUserDriverProfile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [tabsData, setTabsData] = useState([]);
  const [showCancelOrderModal, setShowCancelOrderModal] = useState(false);
  const [orderToCancel, setOrderToCancel] = useState(null);
  const { token } = useSelector((store) => store.app.user);
  const authFetch = useAuthFetch();

  const [items, setItems] = useState([]);

  const fetchOrders = useCallback(async () => {
    setLoading(true);
    const ordersFetch = await authFetch(
      `ventas?filter={"order": "posicionEnEntrega ASC","where":{"and":[{"idMetodoEntrega": ${METODOS_ENTREGA.A_DOMICILIO}},{"idEstadoVenta": {"inq": [${ESTADOS_ORDENES.LISTO_EN_BODEGA},${ESTADOS_ORDENES.EN_CAMINO},${ESTADOS_ORDENES.PENDIENTE_PAGO}]}}]},"include":[{"relation":"estado"},{"relation":"usuario"},{"relation":"lineas","scope":{"include":[{"relation":"producto"}]}},{"relation":"transportista","scope":{"include":[{"relation":"usuario"}]}}]}`,
      null,
      "GET",
      token
    );

    const ordersData = await ordersFetch.json();
    setOrders(ordersData);

    const locationsFetch = await authFetch("provincia-canton-distrito");
    const locationsData = await locationsFetch.json();
    setLocations(locationsData);

    setLoading(false);
  }, []);

  const fetchUserDriverProfile = useCallback(async () => {
    setLoading(true);
    const userDriverFetch = await authFetch(
      `transportistas?filter={"where":{"idUsuario": ${user.id}}}`,
      null,
      "GET",
      token
    );

    const userDriverData = await userDriverFetch.json();
    if (userDriverData[0]) {
      setUserDriverProfile(userDriverData[0]);
    }
    setLoading(false);
  }, [user.id]);

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchOrders();
    fetchUserDriverProfile();
  }, [fetchOrders, fetchUserDriverProfile]);

  const assignDriver = async (orderId, driverId) => {
    setLoading(true);
    await authFetch(
      `ventas/${orderId}`,
      {
        idTransportista: driverId,
      },
      "PATCH",
      token
    );
    fetchOrders();
  };

  const setAsDelivered = async (order) => {
    setLoading(true);
    await authFetch(
      `ventas/${order.id}`,
      {
        fechaEntregaFinal: new Date(),
        idEstadoVenta: !order.fechaRealPago
          ? ESTADOS_ORDENES.PENDIENTE_PAGO
          : ESTADOS_ORDENES.FINALIZADA,
      },
      "PATCH",
      token
    );
    fetchOrders();
  };

  const setAsPaid = async (order, paymentMethod) => {
    setLoading(true);
    await authFetch(
      `ventas/${order.id}`,
      {
        fechaRealPago: new Date(),
        idMetodoPago: paymentMethod,
        metodoPago: METODOS_PAGO_DESC[paymentMethod],
        idEstadoVenta: !order.fechaEntregaFinal
          ? order.idEstadoVenta
          : ESTADOS_ORDENES.FINALIZADA,
      },
      "PATCH",
      token
    );
    fetchOrders();
  };

  const setAsOnTheWay = async (order) => {
    setLoading(true);
    await authFetch(
      `ventas/${order.id}`,
      {
        idEstadoVenta: ESTADOS_ORDENES.EN_CAMINO,
        fechaEntregaInicio: new Date(),
      },
      "PATCH",
      token
    );
    fetchOrders();
  };

  const changeOrderInDelivery = useCallback(
    async (newItems) => {
      setLoading(true);

      await Promise.all(
        newItems.map(async (item, index) => {
          await authFetch(
            `ventas/${item.id}`,
            {
              posicionEnEntrega: index + 1,
            },
            "PATCH",
            token
          );
        })
      );

      setLoading(false);

      fetchOrders();
    },
    [fetchOrders, token]
  );

  const cancelOrder = async (orderId) => {
    setLoading(true);
    await authFetch(`ventas/${orderId}/cancelar`, {}, "PATCH", token);
    fetchOrders();
  };

  useEffect(() => {
    let tabs = [];
    if (userDriverPofile) {
      tabs.push({
        label: `Mis Envíos (${
          userDriverPofile
            ? orders.filter(
                (o) =>
                  o.idTransportista === userDriverPofile.id &&
                  o.idEstadoVenta !== ESTADOS_ORDENES.PENDIENTE_PAGO
              ).length
            : 0
        })`,
        data: userDriverPofile
          ? orders.filter(
              (o) =>
                o.idTransportista === userDriverPofile.id &&
                o.idEstadoVenta !== ESTADOS_ORDENES.PENDIENTE_PAGO
            )
          : [],
      });
    }
    tabs = tabs.concat([
      {
        label: `Sin Asignar (${
          orders.filter((o) => !o.idTransportista).length
        })`,
        data: orders.filter((o) => !o.idTransportista),
      },
      {
        label: `Pendientes de Pago (${
          orders.filter((o) =>
            userDriverPofile
              ? o.idTransportista === userDriverPofile.id &&
                o.idEstadoVenta === ESTADOS_ORDENES.PENDIENTE_PAGO
              : o.idEstadoVenta === ESTADOS_ORDENES.PENDIENTE_PAGO
          ).length
        })`,
        data: orders.filter((o) =>
          userDriverPofile
            ? o.idTransportista === userDriverPofile.id &&
              o.idEstadoVenta === ESTADOS_ORDENES.PENDIENTE_PAGO
            : o.idEstadoVenta === ESTADOS_ORDENES.PENDIENTE_PAGO
        ),
      },
      {
        label: `Todos (${orders.length})`,
        data: orders,
      },
    ]);
    setTabsData(tabs);
    setItems(tabs[activeTab].data);
  }, [orders, userDriverPofile, activeTab]);

  const setAddressLocation = async (orderId, userAddressId) => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(async (position) => {
        setLoading(true);
        const currentLatitude = position.coords.latitude;
        const currentLongitude = position.coords.longitude;
        const urlWaze = `https://www.waze.com/ul?ll=${currentLatitude}%2C${currentLongitude}&navigate=yes&zoom=17`;
        if (userAddressId) {
          await authFetch(
            `usuario-direcciones/${userAddressId}`,
            {
              latitud: currentLatitude,
              longitud: currentLongitude,
              urlWaze,
            },
            "PATCH",
            token
          );
        }
        await authFetch(
          `ventas/${orderId}`,
          {
            latitud: currentLatitude,
            longitud: currentLongitude,
            urlWaze,
          },
          "PATCH",
          token
        );
        setLoading(false);
      });
    }
  };

  async function handleDragEnd(event) {
    const { active, over } = event;

    if (active.id !== over.id) {
      const activeIndex = items.findIndex((items) => items.id === active.id);
      const overIndex = items.findIndex((items) => items.id === over.id);
      const newItems = arrayMove(items, activeIndex, overIndex);
      setItems(newItems);

      changeOrderInDelivery(newItems);
    }
  }

  return (
    <div className="w-full p-content pb-16 min-h-[400px]">
      {loading && <Spinner />}
      <Modal
        backgroundColor="bg-purple"
        closeModalFunction={() => {
          setOrderToCancel(null);
          setShowCancelOrderModal(false);
        }}
        open={showCancelOrderModal}
      >
        <div className="text-white p-8 pt-16 overflow-y-scroll h-full text-justify">
          <p className="mb-4">
            Realmente desea cancelar la Orden #{orderToCancel}. Esta acción no
            se podrá deshacer.
          </p>
          <div className="w-full flex flex-wrap justify-center sm:flex-nowrap sm:justify-end mt-4 gap-2">
            <button
              onClick={() => {
                setOrderToCancel(null);
                setShowCancelOrderModal(false);
              }}
              className="bg-white text-purple py-2 px-8 rounded-full"
            >
              Mantener orden
            </button>
            <button
              onClick={() => {
                cancelOrder(orderToCancel);
                setOrderToCancel(null);
                setShowCancelOrderModal(false);
              }}
              className="bg-orange text-white py-2 px-8 rounded-full"
            >
              Cancelar orden
            </button>
          </div>
        </div>
      </Modal>
      <div>
        <div className="flex w-full items-center">
          <div className="w-full">
            <Link to="/cuenta">
              <button className="bg-pink text-white py-2 px-8 font-bold rounded-full float-right flex justify-center items-center">
                <IoMdArrowRoundBack className="text-lg" /> Volver
              </button>
            </Link>
          </div>
        </div>
        <p className="text-2xl font-bold mb-4">Pedidos para entregar</p>

        <div className="w-full">
          <Tabs
            onTabChange={(index) => {
              setActiveTab(index);
              setItems(tabsData[index].data);
            }}
            activeTab={activeTab}
            tabs={tabsData}
          />

          {!items ? (
            <Spinner />
          ) : items.length > 0 ? (
            <DndContext
              collisionDetection={closestCenter}
              onDragEnd={handleDragEnd}
            >
              <SortableContext
                items={items}
                strategy={verticalListSortingStrategy}
              >
                {items.map((v, index) => (
                  <EnvioCard
                    key={v.id}
                    id={v.id}
                    v={v}
                    location={locations.find(
                      (l) =>
                        l.idProvincia === v.provincia &&
                        l.idCanton === v.canton &&
                        l.idDistrito === v.distrito
                    )}
                    changeOrderInDelivery={changeOrderInDelivery}
                    setAsOnTheWay={setAsOnTheWay}
                    setOrderToCancel={setOrderToCancel}
                    setShowCancelOrderModal={setShowCancelOrderModal}
                    assignDriver={assignDriver}
                    setAddressLocation={setAddressLocation}
                    setAsPaid={setAsPaid}
                    index={index}
                    userDriverPofile={userDriverPofile}
                    setAsDelivered={setAsDelivered}
                  />
                ))}
              </SortableContext>
            </DndContext>
          ) : (
            <p>No hay pedidos para mostrar</p>
          )}
        </div>
      </div>
    </div>
  );
}
export default EnviosPage;
