import React from "react";
import { useSortable } from "@dnd-kit/sortable";
import {
  ESTADOS_ORDENES,
  METODOS_PAGO,
  USUARIOS_TICOLITAS,
} from "../../utils/constants";
import _ from "lodash";
import { Link } from "react-router-dom";
import { CSS } from "@dnd-kit/utilities";
import { RxDragHandleDots2 } from "react-icons/rx";

export default function EnvioCard({
  v,
  location,
  setAsOnTheWay,
  setOrderToCancel,
  setShowCancelOrderModal,
  assignDriver,
  setAddressLocation,
  setAsPaid,
  userDriverPofile,
  setAsDelivered,
}) {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: v.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div
      className="w-full ring-1 ring-purple rounded-lg mb-4 p-6 bg-white "
      ref={setNodeRef}
      style={style}
    >
      <div>
        <div className="flex gap-2 touch-none">
          <RxDragHandleDots2
            {...attributes}
            {...listeners}
            size={40}
            color="#444"
            className="border-none outline-none"
          />
          <p className="text-2xl mb-4">
            Orden #{v.id} -{" "}
            {Math.floor(
              (Math.abs(new Date() - new Date(v.fechaIngreso)) / 1000 / 86400) *
                24
            )}{" "}
            horas desde el ingreso
          </p>
        </div>

        <p className="text-md">
          Cliente:{" "}
          {USUARIOS_TICOLITAS.includes(v.usuario) ? (
            <span className="font-medium">
              {v.usuario.nombre}
              {v.usuario.apellido1 ? ` ${v.usuario.apellido1}` : ""} (
              <a
                className="text-cyan cursor-pointer"
                href={`https://api.whatsapp.com/send?phone=${v.usuario.codigoPaisTelefono}${v.usuario.telefono}`}
                target="_blank"
                rel="noreferrer"
              >
                {v.usuario.telefono}
              </a>
              )
            </span>
          ) : (
            <span className="font-medium">
              {v.nombreCompletoEntrega}(
              <a
                className="text-cyan cursor-pointer"
                href={`https://api.whatsapp.com/send?phone=${v.codigoPaisTelefono}${v.telefono}`}
                target="_blank"
                rel="noreferrer"
              >
                {v.telefono}
              </a>
              )
            </span>
          )}
        </p>
        <p className="text-md">
          Transportista:{" "}
          {v.transportista ? (
            <span className="font-medium">
              {v.transportista.usuario.nombre}
              {v.transportista.usuario.apellido1
                ? ` ${v.transportista.usuario.apellido1}`
                : ""}
            </span>
          ) : (
            <span className="font-medium">No asignado</span>
          )}
        </p>
        <p className="text-md">
          Estado: <span className="font-medium">{v.estado.valor}</span>
        </p>
        <p className="text-md">
          Dirección:{" "}{location ? <span className="font-medium">{`${location.nombreUbicacion} > `}</span> : ""}
          <span className="font-medium">
            {v.direccionExacta} (Ir con{" "}
            <a
              className="text-cyan cursor-pointer"
              href={v.urlWaze}
              target="_blank"
              rel="noreferrer"
            >
              Waze
            </a>
            )
          </span>
        </p>
        <p className="text-md">
          Notas:{" "}
          <span className="font-medium"> {v.notasEntrega || "No hay"}</span>
        </p>
        <p className="text-md">
          Metodo de Pago: <span className="font-medium">{v.metodoPago}</span>
        </p>
        <p className="text-md">
          Distancia:{" "}
          <span className="font-medium">
            {(v.distanciaTiendaMetros / 1000).toFixed(2)} Kms
          </span>
        </p>
        <p className="text-md">
          Tiempo:{" "}
          <span className="font-medium">
            {Math.ceil(v.duracionDesdeTiendaMinutos / 60)} minutos
          </span>
        </p>
        <p>
          Total:{" "}
          <span className="font-medium">
            {Intl.NumberFormat("es-CR", {
              style: "currency",
              currency: "CRC",
            }).format(v.totalConTransporte)}
          </span>
        </p>
        {v.pagoRequiereFirma === 1 && (
          <p className="font-medium text-pink">
            Esta orden requiere firma al pagar con tarjeta
          </p>
        )}
        <h1 className="font-bold text-lg my-4">Productos</h1>
        {v.lineas.map((l) => {
          return (
            <div
              key={`venta_${v.id}_${l.linea}`}
              className="flex items-center my-4 border-b border-borderGray pb-4"
            >
              <div className="w-4/12 md:w-2/12">
                {l.producto && (
                  <img
                    src={l.producto.fotoPrincipal}
                    alt={`Foto ${l.producto.nombreTienda}`}
                  />
                )}
              </div>
              <div className="flex flex-wrap items-center w-8/12 md:w-10/12 pl-4">
                {l.producto && (
                  <>
                    <p className="w-full font-bold">
                      {l.producto.marca} {l.producto.nombreTienda}
                    </p>
                    {l.producto.pesoGramos && (
                      <p className="w-full">
                        Presentación:{" "}
                        <span className="font-bold">
                          {l.producto.pesoGramos < 1000
                            ? `${l.producto.pesoGramos} gramos`
                            : `${(l.producto.pesoGramos / 1000).toFixed(2)} Kg`}
                        </span>
                      </p>
                    )}
                    <p className="w-full">
                      Cantidad: <span className="font-bold">{l.cantidad}</span>
                    </p>
                    <p className="w-full">
                      Precio unitario:{" "}
                      <span className="font-bold">
                        {Intl.NumberFormat("es-CR", {
                          style: "currency",
                          currency: "CRC",
                        }).format(l.costoUnitario)}
                      </span>
                    </p>
                    <p className="w-full">
                      Total:{" "}
                      <span className="font-bold">
                        {Intl.NumberFormat("es-CR", {
                          style: "currency",
                          currency: "CRC",
                        }).format(l.total)}
                      </span>
                    </p>

                    {l.descuento > 0 ? (
                      <p className="w-full text-purple">
                        Total con descuento aplicado:{" "}
                        <span className="font-bold">
                          {Intl.NumberFormat("es-CR", {
                            style: "currency",
                            currency: "CRC",
                          }).format(l.totalConDescuento)}
                        </span>
                      </p>
                    ) : null}
                  </>
                )}
              </div>
            </div>
          );
        })}
      </div>
      <div className="mt-6 flex flex-wrap gap-2 justify-end">
        {v.idEstadoVenta === ESTADOS_ORDENES.LISTO_EN_BODEGA && (
          <button
            onClick={() => {
              setOrderToCancel(v.id);
              setShowCancelOrderModal(true);
            }}
            className=" bg-pink py-2 px-2 md:px-6 text-sm md:text-base rounded-3xl text-white"
          >
            Cancelar Orden
          </button>
        )}
        {userDriverPofile &&
          v.idTransportista === userDriverPofile.id &&
          v.idEstadoVenta === ESTADOS_ORDENES.LISTO_EN_BODEGA && (
            <button
              onClick={() => {
                assignDriver(v.id, 0);
              }}
              className=" bg-pink py-2 px-2 md:px-6 text-sm md:text-base rounded-3xl text-white"
            >
              Desasignarme
            </button>
          )}
        {userDriverPofile &&
          v.idTransportista === userDriverPofile.id &&
          v.idEstadoVenta === ESTADOS_ORDENES.LISTO_EN_BODEGA &&
          !v.fechaEntregaInicio && (
            <>
              {v.urlWaze ? (
                <a
                  onClick={() => {
                    setAsOnTheWay(v);
                  }}
                  href={v.urlWaze}
                  target="_blank"
                  rel="noreferrer"
                >
                  <button className=" bg-cyan py-2 px-2 md:px-6 text-sm md:text-base rounded-3xl text-white">
                    Iniciar Viaje
                  </button>
                </a>
              ) : (
                <button
                  onClick={() => {
                    setAsOnTheWay(v);
                  }}
                  className=" bg-cyan py-2 px-2 md:px-6 text-sm md:text-base rounded-3xl text-white"
                >
                  Iniciar Viaje
                </button>
              )}
            </>
          )}
        {userDriverPofile &&
          v.idTransportista === userDriverPofile.id &&
          v.idEstadoVenta === ESTADOS_ORDENES.EN_CAMINO &&
          v.fechaEntregaInicio && (
            <button
              onClick={() => {
                setAddressLocation(v.id, v.idUsuarioDireccion);
              }}
              className=" bg-cyan py-2 px-2 md:px-6 text-sm md:text-base rounded-3xl text-white"
            >
              Fijar Ubicación
            </button>
          )}
        {userDriverPofile &&
          v.idTransportista === userDriverPofile.id &&
          v.idEstadoVenta === ESTADOS_ORDENES.EN_CAMINO &&
          !v.fechaEntregaFinal && (
            <button
              onClick={() => {
                setAsDelivered(v);
              }}
              className=" bg-cyan py-2 px-2 md:px-6 text-sm md:text-base rounded-3xl text-white"
            >
              Entregada
            </button>
          )}
        {/*
            
            Hola, le saluda ${
                  v.transportista.nombre.split(" ")[0]
                } de TiColitas. Estoy a minutos de poder entregarle el pedido que solicitó para ${_.compact(_.(v.lineas,"mascota")).join(", ")}. Le agradecería 
            me oriente si hay alguna indicación particular que deba conocer para hacerle más sencilla y 
            agradable su entrega. De antemano le agradezco su atención y respuesta. Aquí voy 👇🐶🐱.
            */}
        {userDriverPofile &&
          v.idTransportista === userDriverPofile.id &&
          (v.idEstadoVenta === ESTADOS_ORDENES.LISTO_EN_BODEGA ||
            v.idEstadoVenta === ESTADOS_ORDENES.EN_CAMINO) &&
          !v.fechaEntregaInicio && (
            <a
              href={`https://api.whatsapp.com/send?phone=${
                v.codigoPaisTelefono || v.usuario.codigoPaisTelefono
              }${v.telefono || v.usuario.telefono}&text=Hola, le saluda ${
                v.transportista.nombre.split(" ")[0]
              } de TiColitas. Estoy a minutos de poder entregarle el pedido que solicitó para ${
                _.compact(_.map(v.lineas, "mascota")).length > 0
                  ? _.compact(_.map(v.lineas, "mascota")).join(", ")
                  : " su(s) mascota(s)"
              }. Le agradecería 
                  me oriente si hay alguna indicación particular que deba conocer para hacerle más sencilla y 
                  agradable su entrega. De antemano le agradezco su atención y respuesta. Le comparto mi foto 👇🐶🐱.`}
              target="_blank"
              rel="noreferrer"
            >
              <button className=" bg-cyan py-2 px-2 md:px-6 text-sm md:text-base rounded-3xl text-white">
                Pedir Indicaciones Whatsapp
              </button>
            </a>
          )}
        {userDriverPofile &&
          v.idTransportista === userDriverPofile.id &&
          (v.idEstadoVenta === ESTADOS_ORDENES.LISTO_EN_BODEGA ||
            v.idEstadoVenta === ESTADOS_ORDENES.EN_CAMINO) &&
          !v.fechaEntregaInicio && (
            <a
              href={`https://api.whatsapp.com/send?phone=${
                v.codigoPaisTelefono || v.usuario.codigoPaisTelefono
              }${v.telefono || v.usuario.telefono}&text=Hola, le saluda ${
                v.transportista.nombre.split(" ")[0]
              } de TiColitas. Estoy iniciando el viaje para hacer la entrega del pedido que solicitó para ${
                _.compact(_.map(v.lineas, "mascota")).length > 0
                  ? _.compact(_.map(v.lineas, "mascota")).join(", ")
                  : " su(s) mascota(s)"
              }. Me gustaría saber si está disponible para recibir el pedido. De antemano le agradezco su atención y respuesta. Le comparto mi foto 👇🐶🐱.`}
              target="_blank"
              rel="noreferrer"
            >
              <button className=" bg-cyan py-2 px-2 md:px-6 text-sm md:text-base rounded-3xl text-white">
                Notificar Whatsapp
              </button>
            </a>
          )}
        {userDriverPofile &&
          v.idTransportista === userDriverPofile.id &&
          !v.fechaRealPago && (
            <button
              onClick={() => {
                setAsPaid(v, METODOS_PAGO.TARJETA);
              }}
              className=" bg-cyan py-2 px-2 md:px-6 text-sm md:text-base rounded-3xl text-white"
            >
              Pagada con tarjeta
            </button>
          )}
        {userDriverPofile &&
          v.idTransportista === userDriverPofile.id &&
          !v.fechaRealPago && (
            <button
              onClick={() => {
                setAsPaid(v, METODOS_PAGO.EFECTIVO);
              }}
              className=" bg-cyan py-2 px-2 md:px-6 text-sm md:text-base rounded-3xl text-white"
            >
              Pagada con efectivo
            </button>
          )}
        {userDriverPofile &&
          v.idTransportista === userDriverPofile.id &&
          !v.fechaRealPago && (
            <button
              onClick={() => {
                setAsPaid(v, METODOS_PAGO.TRANSFERENCIA);
              }}
              className=" bg-cyan py-2 px-2 md:px-6 text-sm md:text-base rounded-3xl text-white"
            >
              Pagada con transferencia
            </button>
          )}
        {userDriverPofile &&
          v.idTransportista === userDriverPofile.id &&
          !v.fechaRealPago && (
            <button
              onClick={() => {
                setAsPaid(v, METODOS_PAGO.SINPE);
              }}
              className=" bg-cyan py-2 px-2 md:px-6 text-sm md:text-base rounded-3xl text-white"
            >
              Pagada con SINPE
            </button>
          )}
        {userDriverPofile && !v.idTransportista && (
          <button
            onClick={() => {
              assignDriver(v.id, userDriverPofile.id);
            }}
            className=" bg-purple py-2 px-2 md:px-6 text-sm md:text-base rounded-3xl text-white"
          >
            Asignarme
          </button>
        )}
        <Link to={`/pedidos/${v.id}?desde=/envios`}>
          <button className=" bg-purple py-2 px-2 md:px-6 text-sm md:text-base rounded-3xl text-white">
            Ver Detalles
          </button>
        </Link>
      </div>
    </div>
  );
}
