import React, { useState } from "react";
import Modal from "../Modal";

export function AlertBar({ alert }) {
	const [openDetails, setOpenDetails] = useState(false);

	return (
		<div className="grid justify-items-center content-center h-10 bg-purple no-scrollbar">
			<p className="text-base text-white font-title">
				Conocé{" "}
				<span
					className="font-bold underline text-xl cursor-pointer"
					onClick={() => {
						setOpenDetails(true);
					}}
				>
					aquí
				</span>{" "}
				el costo de nuestro servicio de transporte.
			</p>
			<Modal
				backgroundColor="bg-purple no-scrollbar"
				open={openDetails}
				closeModalFunction={() => {
					setOpenDetails(false);
				}}
				className="no-scrollbar"
			>
				<div className="flex flex-wrap justify-center items-center p-8 no-scrollbar">
					<p className="text-white mb-4 w-full">
						<br />
						Nuestro costo de transporte y zonas donde actualmente está
						disponible el servicio de entrega, se muestra en la sección de
						Información del pedido / Entrega a domicilio / Selección del
						Distrito, también puede consultar si tenemos cobertura en algún
						distrito en particular y el costo del mismo al Whatsapp 83133113:
					</p>
			
					<p className="text-white mb-4 w-full">
						<span className="font-bold my-1">Distritos con envío gratuito:</span> La
						Guácima y San Rafael de Alajuela por compras superiores a ₡10.000
						colones.
						<br />
						<br />
						<span className="font-bold">
							Distritos con Tarifa de ₡900 colones:
              </span>{" "}
               				San José, Escazú,
							Desamparados, Mora, Goicoechea, Santa Ana, Alajuelita, Tibás,
							Moravia, Montes De Oca, Curridabat, Alajuela, Heredia,
							Barva, Santo Domingo, Santa Bárbara, San Rafael, San Isidro,
							Belén, Flores, San Pablo, por compras superiores a ₡10.000
							colones.
						<br />
						<br />
						<span className="font-bold">
							Distritos con Tarifa de ₡1.500 colones:
						</span>{" "}
						Cualquier otro distrito previamente acordado con el
						cliente, por compras superiores a ₡25.000 colones En compras
						inferiores a ₡10.000 independientemente de alguno de los sitios
						habilitados en nuestra página para realizar entregas a domicilio, el
						sistema generará el cálculo final del costo del transporte. Es
						importante que lo revise antes de finalizar su compra.
					</p>
					<p className="text-white mb-4 w-full">
						<span className="font-bold">
							En compras inferiores a ₡10.000 independientemente de alguno de
							los sitios habilitados en nuestra página para realizar entregas a
							domicilio, el sistema generará el cálculo final del costo del
							transporte. Es importante que lo revise antes de finalizar su
							compra.
						</span>{" "}
					</p>
					<p className="text-white mb-4 w-full">
						Dándose por entendido que el cliente, antes de solicitar algún
						servicio a través del sitio, ha revisado, entendido y acordado los
						términos expuestos en las políticas del portal, en caso de
						inconformidad, el usuario deberá abstenerse de proporcionar
						información personal, así como de levantar algún requerimiento
						comercial.
					</p>
					<p className="text-white mb-4 w-full">
						<span className="font-bold">
							Todos los montos incluyen el I.V.A.
						</span>
					</p>
				</div>
			</Modal>
		</div>
	);
}

export default AlertBar;
