import React from "react";

export function NumberSteper({
  label,
  labelColor,
  value,
  onChangeValue,
  negativeStepSize,
  positiveStepSize,
}) {
  return (
    <>
      <label
        className={`w-full ${labelColor || "text-borderGray"}`}
        htmlFor="cantidad"
      >
        {label}
      </label>
      <div className="w-full relative inline-block">
        <div
          className="bg-white select-none flex items-center w-full px-8 rounded-full border border-borderGray h-14 font-body outline-none justify-center"
          type="number"
          id="cantidad"
          name="cantidad"
        >
          {value}
        </div>
        <div className="left-[25px] top-[45px] w-6 absolute bg-white rounded-lg h-1">
          <button
            onClick={() => {
              onChangeValue(negativeStepSize);
            }}
            type="button"
            className="-top-8 relative bg-transparent border-0 text-xl p-0 block"
          >
            -
          </button>
        </div>
        <div className="right-[20px] top-[20px] w-6 absolute bg-white rounded-lg h-8">
          <button
            onClick={() => {
              onChangeValue(positiveStepSize);
            }}
            type="button"
            className="-top-2 -rotate-90 relative bg-transparent border-0 text-xl p-0 block"
          >
            +
          </button>
        </div>
      </div>
    </>
  );
}

export default NumberSteper;
